import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, ButtonBase, Box } from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";
import Highlighter from "react-highlight-words";
import HoverVideoPlayer from 'react-hover-video-player';
import { useWindowSize } from '../../constant/useWindowSize';
import './TopCharts.scss';

const DefiCharts = ({ searchState, data, mainChartTitle, otherChartsData }) => {
  const [mobileView, setMobileView] = useState(false);
  const [tabletView, setTabletView] = useState(false);

  const [ window_width ] = useWindowSize();
  useEffect(() => {
    if (window_width < 1280 && window_width >= 960) {
      setTabletView(true);
    } else if (window_width <= 960) {
      setMobileView(true);
      setTabletView(false);
    } else {
      setMobileView(false);
      setTabletView(false);
    }
  }, [window_width]);

  const displayDesktop = () => {
    return (
      <Grid container spacing={3}>
        <Grid item md>
          <Grid container spacing={3}>
            {data && data.map((val) => {
              return (
                <Grid item md={4} key={val._id} className={`${val.withLongImage ? 'd-none' : ''} ${val.hidden ? 'd-none' : ''}`}>
                  <ButtonBase href={val.href} target="_blank">
                    <div className="img-container">
                      <img src={val.image} alt={val.name} />
                    </div>
                  </ButtonBase>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        {data && data.map((val) => {
          return (
            <Grid item key={val._id} className={`${val.withLongImage ? '' : 'd-none'} ${val.hidden ? 'd-none' : ''}`}>
              <ButtonBase href={val.href} target="_blank">
                <div className="img-container">
                  <img className="w-100" src={val.image_long ? val.image_long : val.image} alt={val.name} />
                  {(val.videoOnHover && !mobileView) &&
                    <div className="hover-video-container">
                      <HoverVideoPlayer
                        className="hover-video-player"
                        videoSrc={val.videoOnHover}
                        pausedOverlay={
                          <img className="w-100" src={val.image_long ? val.image_long : val.image} alt={val.name} />
                        }
                        loadingOverlay={
                          <div className="loading-spinner-overlay" />
                        }
                      />
                    </div>
                  }
                </div>
              </ButtonBase>
            </Grid>
          )
        })}
      </Grid>
    )
  }

  const displayTablet = () => {
    const items = (start, end) => {
      let cut_data = data.slice(start, end).map((val, i) => {
        return (
          <Grid item md key={val._id}>
            <ButtonBase href={val.href} target="_blank">
              <div className="img-container">
                <img src={val.image} alt={val.name} />
              </div>
            </ButtonBase>
          </Grid>
        )
      })
      return cut_data
    }

    return (
      <>
        <Grid container spacing={3}>
          {items(0, 5)}
        </Grid>
        <Grid container spacing={3}>
          {items(5, 10)}
        </Grid>
      </>
    )
  }

  const displayMobile = () => {
    return (
      <Grid container spacing={3} >
        {data && data.map((val) => (
          <Grid item xs={4} key={val._id}>
            <ButtonBase href={val.href} target="_blank">
              <div className="img-container">
                <img src={val.image} alt={val.name} />
              </div>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <>
      <div className="section section-charts">
        <Container>
          <Typography variant="h2" className="section-title">
            <Highlighter
              searchWords={[searchState]}
              textToHighlight={mainChartTitle}
            />
          </Typography>
          {tabletView
            ? displayTablet()
            : mobileView
              ? displayMobile()
              : displayDesktop()
          }
        </Container>
      </div>
      <div className="section section-other-charts">
        <Container>
          <Typography variant="h2" className="section-title">
            <Highlighter
              searchWords={[searchState]}
              textToHighlight="Others"
            />
          </Typography>
          <Grid container spacing={3}>
            {otherChartsData && otherChartsData.map((val) => (
              <Grid item xs={6} md={4} key={val._id}>
                <ButtonBase
                  {...{
                    to: val.href,
                    component: RouterLink,
                  }}
                >
                  <Box className={`box box-${val.name.toLowerCase()}`}></Box>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default DefiCharts;