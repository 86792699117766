import React, {useEffect} from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// importing all the domain/marketplace
/* import ThemeOne from "../domain/marketplace/theme-one"; */
// import ExploreOne from "../domain/marketplace/explore-one";
// import ExploreTwo from "../domain/marketplace/explore-two";
// import ExploreThree from "../domain/marketplace/explore-three";
// import ExploreFour from "../domain/marketplace/explore-four";
// import Auctions from "../domain/marketplace/auctions";
// import ItemDetails from "../domain/marketplace/item-details";
// /* import Activity from "../domain/marketplace/activity"; */
// import Blog from "../domain/marketplace/blog";
// import BlogSingle from "../domain/marketplace/blog-single";
// import HelpCenter from "../domain/marketplace/help-center";
// import Authors from "../domain/marketplace/authors";
// import Author from "../domain/marketplace/author";
// import WalletConnect from "../domain/marketplace/wallet-connect";
// import Create from "../domain/marketplace/create";
// import Login from "../domain/marketplace/login";
// import Signup from "../domain/marketplace/signup";
// import Contact from "../domain/marketplace/contact";
// import Sell from '../domain/marketplace/old/sell'
import Collection from '../domain/marketplace/collection'

const MarketplaceRoutes = () => {
  
    return (
      <>
            <Route exact path="/nft-collection/:id" component={Collection} />
      </>
    );
  
}
export default MarketplaceRoutes;