import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import xml2js from 'xml2js'
import moment from 'moment';
// import { v4 as uuidv4 } from 'uuid';
import Slider from "react-slick";
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Grid } from '@material-ui/core';
// import image1 from '../../assets/images/section-media/image-1.jpg';
// import image2 from '../../assets/images/section-media/image-2.jpg';
// import image3 from '../../assets/images/section-media/image-3.jpg';
import Highlighter from "react-highlight-words";
import { API_BASE_URL } from '../../constant/config';
import { useWindowSize } from '../../constant/useWindowSize';
import { useTranslation } from 'react-i18next';

const SectionMedia = ({ searchState, setApiLoader }) => {
  const [mediaData, setData] = useState();
  const {t} = useTranslation()
  // Axios call
  // const fetchData = () => {

  //   setApiLoader(true);

  //   axios({
  //     method: 'GET',
  //     url: `${API_BASE_URL}/download/xml-parser.php`,
  //     // url: 'https://cointelegraph.com/feed',
  //     // headers: {
  //     //   "Content-type": "text/xml",
  //     //   "Access-Control-Allow-Origin": "*",
  //     //   "Access-Control-Allow-Credentials": "true",
  //     //   "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
  //     //   "Access-Control-Max-Age": "1000",
  //     //   "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token , Authorization",
  //     // }
  //   }).then(result => {
  //     const data = result.data;
  //     let parser = new xml2js.Parser();
  //     parser.parseString(
  //       data,
  //       function (err, result) {
  //         if (err) {
  //           console.log(data);
  //           console.log(err);
  //         } else {
  //           let jsonFile = result.rss.channel ? result.rss.channel[0].item : [];
  //           let cut_data = jsonFile.slice(0, 3)
  //           setData(cut_data);
  //         }
  //       }
  //     )
  //     setApiLoader(false);
  //   }).catch(error => {
  //     console.log(error);
  //     console.log(error.response);
  //     setData(null);
  //     setTimeout(()=>{
  //       setApiLoader(false);
  //     }, 2000)
  //   });
  // };

  const fetchData = () => {

    setApiLoader(true);

    axios({
      method: 'GET',
      url: `${API_BASE_URL}/crypto/news`,
    }).then(result => {
      const data = result.data.results.data.cointelegraphFeed;
      setData(data);
      setApiLoader(false);
    }).catch(error => {
      console.log(error);
      console.log(error.response);
      setData();
      setTimeout(()=>{
        setApiLoader(false);
      }, 2000)
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const data = [
  //   {
  //     _id: uuidv4(),
  //     image: image1,
  //     title: 'The importance of Ethereum layer 2 scalability solutions',
  //     href: 'https://dappradar.com/blog/the-importance-of-ethereum-layer-2-scalability-solutions',
  //     date: '10/07/20',
  //   },
  //   {
  //     _id: uuidv4(),
  //     image: image2,
  //     title: 'Uniswap monthly volume exceeds Coinbase in September',
  //     href: 'https://www.theblockcrypto.com/linked/79775/uniswap-coinbase-monthly-volume-september',
  //     date: '10/07/20',
  //   },
  //   {
  //     _id: uuidv4(),
  //     image: image3,
  //     title: 'Dapp Ecosystem Trends: Q3 Flash Report',
  //     href: 'https://dappradar.com/blog/dapp-ecosystem-trends-q3-flash-report',
  //     date: '10/07/20',
  //   },
  // ]

  const [mobileView, setMobileView] = useState(false);

  const [ window_width ] = useWindowSize();
  useEffect(() => {
    if(window_width < 960) setMobileView(true)
    else setMobileView(false)
  }, [window_width]);

  const displayDesktop = () => {
    return (
      <Grid container spacing={3}>
        {(mediaData && mediaData.length > 0) && mediaData.map((val) => {
          let get_id = val.link.split('/');
          let _id = get_id.pop();
          let date = moment(val.pubDate).format('YYYY-MM-DD'); 
          let image = val.enclosure.url;
          return (
            <Grid item xs={12} md={4} key={_id}>
              <Card className="media-card" elevation={0} variant="outlined">
                <CardActionArea href={val.link} target="_blank">
                  <CardMedia
                    className="image"
                    component="img"
                    alt={val.title}
                    image={image}
                    title={val.title}
                  />
                  <CardContent>
                    <Typography variant="h4">
                      <Highlighter
                        searchWords={[searchState]}
                        textToHighlight={val.title}
                      />
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <p className="date">
                    {date}
                  </p>
                </CardActions>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    )
  }

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const displayMobile = () => {
    return (
      <Slider {...settings}>
        {(mediaData && mediaData.length > 0) && mediaData.map((val) => {
          let get_id = val.link.split('/');
          let _id = get_id.pop();
          let date = moment(val.pubDate).format('YYYY-MM-DD'); 
          let image = val.enclosure.url;
          return (
            <div key={_id} className="media-card-slide">
              <Card className="media-card" elevation={0} variant="outlined">
                <CardActionArea href={val.link} target="_blank">
                  <CardMedia
                    component="img"
                    alt={val.title}
                    height="215"
                    image={image}
                    title={val.title}
                  />
                  <CardContent>
                    <Typography variant="h4">
                      <Highlighter
                        searchWords={[searchState]}
                        textToHighlight={val.title}
                      />
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <p className="date">{date}</p>
                </CardActions>
              </Card>
            </div>
          )
        })}
      </Slider>
    )
  }

  return (
    <div className="section section-media">
      {(mediaData && mediaData.length > 0) &&
        <Typography variant="h2" className="section-title">
        <Highlighter
          searchWords={[searchState]}
          textToHighlight={t("MEDIA")}
        />
      </Typography>
      }
      {mobileView ? displayMobile() : displayDesktop()}
    </div>
  );
}

export default SectionMedia;