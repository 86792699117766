import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import Highlighter from "react-highlight-words";
import ImageWithHover from '../../components/image-with-hover/ImageWithHover';
import { useTranslation } from 'react-i18next';

const SectionRecommended = ({ data, md_view, xs_view, searchState }) => {
  const {t} = useTranslation()
  return (
    <div className="section section-recommended">
      <Typography variant="h2" className="section-title">
        <Highlighter
          searchWords={[searchState]}
          textToHighlight={t("RECOMMENDED")}
        />
      </Typography>
      <Grid container spacing={md_view ? 2 : xs_view ? 1 : 3}>
        {data.length > 0 && data.map((val) => {
          return (
            <Grid item xs={data.length === 3 ? 4 : 6} key={val._id}>
              <ImageWithHover 
                val={val}
                mobile_xs={md_view}
                searchState={searchState}
              />
            </Grid>
          )
        })}
      </Grid>
    </div>
  );
}

export default SectionRecommended;