import React, { useState, useRef } from "react";
import { Box, Button } from "@material-ui/core";
import HoverVideoPlayer from "react-hover-video-player";

import { ReactComponent as Play } from "../../assets/images/icons/on-hover-play.svg";
import { ReactComponent as Store } from "../../assets/images/icons/on-hover-store.svg";
import { ReactComponent as Star } from "../../assets/images/icons/on-hover-star.svg";
import { ReactComponent as FullStar } from "../../assets/images/icons/on-hover-full-star.svg";
import WhitePaper from "../../assets/images/icons/on-hover-white-paper.png";

import "./SectionSlider.scss";

import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  EffectCoverflow,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
SwiperCore.use([Navigation, Autoplay, Pagination, EffectCoverflow]);

const SliderDesktop = ({ data, imageLoaded, setImageLoaded }) => {
  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = (swiper) => {
    swiper.current.autoplay.stop();
  };
  const onMouseLeave = async (swiper) => {
    await swiper.current.autoplay.start();
    await onVideoStart();
  };

  const [video_start, set_video_start] = useState(false);
  const onVideoStart = () => {
    setTimeout(() => {
      set_video_start(true);
    }, 4000);
  };
  const onVideoEnd = () => {
    set_video_start(false);
  };

  const check_video_end = async (swiper) => {
    await setIsHover(false);
    await set_video_start(false);
    swiper.current.slideNext();
  };

  let myswiper = useRef(null);

  return (
    <Swiper
      ref={myswiper.current}
      className="swiper-area"
      loop={true}
      autoplay={{
        disableOnInteraction: false,
        delay: 20000,
      }}
      centeredSlides={true}
      slidesPerView={"auto"}
      navigation
      allowTouchMove={false}
      watchSlidesVisibility={true}
      pagination={{ clickable: true }}
      coverflowEffect={{
        rotate: 12,
        stretch: 800,
        depth: 500,
        modifier: 1,
        slideShadows: true,
      }}
      onInit={(swiper) => {
        myswiper.current = swiper;
      }}
      effect="coverflow"
      onSlideChange={() => {
        onVideoEnd();
        onVideoStart();
      }}
    >
      {data.map((val) => {
        return (
          <SwiperSlide
            key={val._id}
            className={`pc-view ${val.videoOnHover ? "has-video-hover" : ""}`}
          >
            {({ isVisible, isActive }) => (
              <div
                className={`slide-content-block ${val.className}`}
                onMouseEnter={() =>
                  isVisible && isActive ? onMouseEnter(myswiper) : null
                }
                onMouseLeave={() =>
                  isVisible && isActive ? onMouseLeave(myswiper) : null
                }
                style={{
                  opacity: imageLoaded ? 1 : 0,
                  transition: "opacity .4s ease-in-out",
                }}
                onClick={() => { }}
              >
                <a href={val.href} target="_blank" rel="noopener noreferrer">
                  <img
                    className="bg-image"
                    src={val.image}
                    alt={val.className}
                    onLoad={() => setImageLoaded(true)}
                  />
                </a>
                {val.videoOnHover && isVisible && isActive && (
                  <div
                    className="hover-video-container"
                    onMouseEnter={() =>
                      isVisible && isActive
                        ? (setIsHover(true), set_video_start(true))
                        : null
                    }
                    onMouseLeave={() =>
                      isVisible && isActive
                        ? (setIsHover(false), set_video_start(false))
                        : null
                    }
                  >
                    {video_start && (
                      <>
                        <div className="video-container">
                          <video
                            autoPlay
                            muted
                            onEnded={() => check_video_end(myswiper)}
                          >
                            <source src={val.videoOnHover} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </>
                    )}
                    {!video_start && (
                      <>
                        <HoverVideoPlayer
                          className="hover-video-player"
                          videoSrc={val.videoOnHover}
                          loop={false}
                          // muted={false}
                          onEnded={() => check_video_end(myswiper)}
                          pausedOverlay={
                            <img
                              src={val.image}
                              alt={val.className}
                              onLoad={() => setImageLoaded(true)}
                            />
                          }
                          loadingOverlay={
                            <div className="loading-spinner-overlay" />
                          }
                        />
                      </>
                    )}
                  </div>
                )}

                <Box
                  className={`slide-content-box 
  ${val.videoOnHover ? "has-video-hover" : ""} 
  ${isHover || video_start ? "hidden" : ""} `}
                >
                  {val.title && (
                    <h2 className="title" style={{ color: val.titleColor }}>
                      {val.title_is_image ? (
                        <img
                          className="img-fluid"
                          src={val.title}
                          alt={val.className}
                        />
                      ) : (
                        val.title
                      )}
                    </h2>
                  )}
                  {val.excerpt && (
                    <p className="excerpt" style={{ color: val.textColor }}>
                      {val.excerpt}
                    </p>
                  )}
                </Box>

                {isVisible && (
                  <div
                    className={`details ${val.commingSoon ? "coming-soon" : ""
                      }`}
                    onMouseEnter={() =>
                      isVisible && isActive
                        ? (setIsHover(true), set_video_start(true))
                        : null
                    }
                    onMouseLeave={() =>
                      isVisible && isActive
                        ? (setIsHover(false), set_video_start(false))
                        : null
                    }
                  >
                    {val.commingSoon ? (
                      <h4>Coming Soon</h4>
                    ) : (
                      <>
                        <div className="left">
                          {val.logo ? (
                            <h4 className="with-logo">
                              <img src={val.logo} alt={val.className} />
                            </h4>
                          ) : (
                            <h4>{val.className}</h4>
                          )}
                          {val.currency && (
                            <p className="currency">{val.currency}</p>
                          )}
                        </div>

                        <div className="right">
                          {val.white_paper && (
                            <Button
                              disableRipple
                              href={val.white_paper}
                              target="_blank"
                              className="white-paper-btn"
                            >
                              <img src={WhitePaper} alt="white paper" />
                              <label>WhitePaper</label>
                            </Button>
                          )}
                          {/* {val.buttons[0]?.href ?
                              <Button disableRipple href={val.buttons[0]?.href} target="_blank" className="play-btn">
                                <Play /><label>PLAY</label>
                              </Button>
                              : ''} */}


                          {val.buttons[0]?.onClick ? (
                            <Button
                              disableRipple
                              onClick={val.buttons[0]?.onClick}
                              target="_blank"
                              className="play-btn"
                            >
                              <Play />
                              <label>PLAY</label>
                            </Button>
                          ) : (
                            ""
                          )}

                          {val.buttons[0]?.text == "Sign up" ? (
                            <Button
                              disableRipple
                              href={val.buttons[0]?.href}
                              target="_blank"
                              className="play-btn signUp-btn"
                            >
                              <Play />
                              <label>Sign up</label>
                            </Button>
                          ) : (
                            ""
                          )}
                          {val.with_store_logo && (
                            <Button
                              disableRipple
                              href={val.store}
                              target="_blank"
                              className="store-btn"
                            >
                              <Store />
                              <label>NFT</label>
                            </Button>
                          )}
                          <Button disableRipple className="star-btn">
                            <FullStar />
                            <FullStar />
                            <FullStar />
                            <FullStar />
                            <FullStar />
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                )}

              </div>
            )}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default SliderDesktop;
