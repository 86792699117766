const blacklisted = [
    '0x2089fd5ae3871d09328967d2d7b1ebe119d14249',
    '0x2acb9719c174b7cf82e9431a992eda899a169330',
    '0xf03e574695f138668752d01e11473694e63d4b72',
    '0x38adfeac251885b3e5862cf1ddf0f92576699c37',
    '0x40dc770ad82a417f8f4adf5e11687b13def7044d',
    '0xc3f6e0f9a104878e7e61521025c561fdcb48552d',
    '0xfc495d275875f4c3b4f88e1f2440f3297ea90e69',
    '0x1e2b37abfb67598b15499db9d46a8065edf34fa5',
    '0x9a9bb62a84a9cb5e798e72f90db417b53b7fd95f'
  ];
  
  export default blacklisted;  