import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import { isMobile, isIOS } from 'react-device-detect';
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { IconButton, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@material-ui/core';
import {
  DAPPX_BASE_URL, CONTRACT_ADDRESS, TESTNET_CONTRACT_ADDRESS_ACE, TESTNET_CONTRACT_ADDRESS_DAPPX,
  MAINNET_CONTRACT_ADDRESS_ACE, MAINNET_CONTRACT_ADDRESS_DAPPX
} from '../../constant/config';
import { ReactComponent as UserIcon } from '../../assets/images/icons/user-avatar.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/icons/icon-copy.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/icons/dropdown-close.svg';
import WalletIcon from '../../assets/images/icons/icon-wallet.svg';
import ModalConnect from './ModalConnectWallet';
import ModalCreate from './ModalCreateAccount';
import ModalError from './ModalErrorPopup';
import ModalNotice from './ModalNotice';
import { AuthContext } from '../../context/AuthContext';
import { useWindowSize } from '../../constant/useWindowSize';
import plusIcon from "../../assets/images/icons/plus-circle.svg";
import './TopNav.scss';
import { useTranslation } from 'react-i18next';
import RestrictedModal from './RestrictedModal';
import useGeoLocation from "react-ipgeolocation";
import blacklisted from '../../utils/blacklisted'
import {useLogout} from '../../utils/cutom-hook'

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const ConnectWalletButton = ({justCreateModal, verifyAccount, setVerifyAccount}) => {
  let history = useHistory();
  const {t} = useTranslation()
  const { dispatch } = useContext(AuthContext);
  let session_id = localStorage.getItem('session-id');
  const [mobileView, setMobileView] = useState(false);
  const [window_width] = useWindowSize();
  useEffect(() => {
    if (window_width < 960) setMobileView(true)
    else setMobileView(false)
  }, [window_width]);
  // METAWALLET CONNECTION ==========
  // state inside function before modal
  const [connect_error, set_connect_error] = useState();
  const [is_s_korean, set_is_s_korean] = useState(false);
  const [public_address, set_public_address] = useState();
  // const [state_nonce, set_nonce] = useState();
  // const [sign_in_success, set_sign_in_success] = useState();
  const [sign_in_error, set_sign_in_error] = useState({
    icon: '',
    title: '',
    text: '',
    btn_label: '',
  });
  const [create_acc_error, set_create_acc_error] = useState();
  let query = useQuery();
  let isOsiris = navigator.osiris;
  let isWonderWallet = navigator.userAgent.match(/WonderWallet/i);
  let isDappstoreMobile = navigator.userAgent.match(/dAppstore/i);
  let isAlphaWallet = navigator.userAgent.match(/AlphaWallet/i);
  const location = useGeoLocation();

  useEffect(async () => {
    // const result = await axios.get('https://ipapi.co/json/')
    // console.log(result.data, 66)
    
    if(location.country === 'KR' || location.country === 'KOR') {
      set_is_s_korean(true)
    }

  }, [location.country])




  // MODAL ==========
  // connect modal
  const [openConnect, setOpenConnect] = useState(false);
  const onOpenConnect = () => {
    if (((isOsiris && !isMobile) || isWonderWallet || isDappstoreMobile || isAlphaWallet) && !isIOS) {
      if(!is_s_korean) {
        executeConnect();
      }else {
        setOpenConnect(true);
      }
     
    } else {
      setOpenConnect(true);
    }
  };
  const onCloseConnect = () => {
    setOpenConnect(false)
    if(!is_s_korean) {
      executeConnect()

    setTimeout(() => {
      set_connect_error()
      set_public_address()
    }, 400)
    }
   
  };
  // create modal
  const [openCreate, setOpenCreate] = useState(false);
  const onOpenCreate = (publicAddress) => {
    set_public_address(publicAddress)
    // setOpenLink(false)
    setOpenConnect(false)
    setTimeout(() => { setOpenCreate(true) }, 400)
  };
  const onCloseCreate = () => {
    set_connect_error()
    set_public_address()
    setOpenCreate(false)
    setVerifyAccount(false)
  };

  // useEffect(() => {
  //   if(query.get('ref')) {
  //     setOpenCreate(true)
  //   }
  // }, []);
  // sign in modal
  // const [openLink, setOpenLink] = useState(false);
  // const onOpenLink = (nonce, publicAddress) => {
  //   set_public_address(publicAddress)
  //   set_nonce(nonce)
  //   setOpenCreate(false)
  //   setOpenConnect(false)
  //   setTimeout(() => { setOpenLink(true) }, 400)
  // };
  // const onCloseLink = () => {
  //   setOpenLink(false)
  //   setTimeout(() => {
  //     set_sign_in_success()
  //     set_sign_in_error()
  //   }, 1000)
  // };
  // error modal
  const [openError, setOpenError] = useState(false);
  const onOpenError = (icon, title, message, label) => {
    setOpenError(true)
    set_sign_in_error({
      icon: icon,
      title: title,
      text: message,
      btn_label: label,
    })
  }
  const onCloseError = () => {
    setOpenError(false)
    setTimeout(() => {
      set_sign_in_error({
        icon: '',
        title: '',
        text: '',
        btn_label: '',
      })
    }, 600)
  }
  // notice modal
  const [openNotice, setOpenNotice] = useState(false);
  const onOpenNotice = () => {
    setOpenNotice(true)
    setTimeout(() => {
      onCloseConnect()
    }, 1600)
  };
  // METAWALLET CONNECTION ==========
  let APP_URL = DAPPX_BASE_URL;
  let web3 = new Web3(window.ethereum);
  const currentNetowrk = localStorage.getItem('current_network')
  const [is_init_contract_done, set_init_contract] = useState(false);
  const [deployed_contract, set_deployed_contract] = useState();
  const [deployed_contract_ace, set_deployed_contract_ace] = useState();
  const [deployed_contract_dappx, set_deployed_contract_dappx] = useState();
  const all_balances = [];
  let wallet_address = localStorage.getItem('wallet_address');
  let referral_code = localStorage.getItem('referral_code');
  let isTestnet = localStorage.getItem('current_network') === '0x2a' ? true : false;
  let CONTRACT_ADDRESS_ACE = isTestnet ? TESTNET_CONTRACT_ADDRESS_ACE : MAINNET_CONTRACT_ADDRESS_ACE;
  let CONTRACT_ADDRESS_DAPPX = isTestnet ? TESTNET_CONTRACT_ADDRESS_DAPPX : MAINNET_CONTRACT_ADDRESS_DAPPX;
  // let pathname = history.location.pathname;
  // const [accountsChanged, setAccountsChanged] = useState();
  const handleLogout = useLogout();
  const handleEventsAfterLoggingOut = useLogout()

  useEffect(() => {
    try {
      // dispatch({ type: 'INIT_WEB3', payload: new Web3(window.ethereum) })
      setupEvents();
    } catch (error) {
    }
    if (web3) initContract()
    // console.log(((isMobile && !isIOS && pathname !== '/terms' && pathname !=='disclaimer' ) || navigator.osiris) && window.ethereum)
    // if (((isMobile && !isIOS && pathname !== '/terms' && pathname !=='disclaimer' ) || navigator.osiris) && window.ethereum) {
    //   executeConnect()
    // }
    // check_account_interval()
  }, [])
  async function get_new_wallet() {
    let new_changed_wallet = await window.ethereum.request({ method: 'eth_requestAccounts' });
    // console.log('new_changed_wallet: ', new_changed_wallet[0]);
    // console.log('wallet_address', wallet_address)
    if (wallet_address) {
      if (wallet_address !== new_changed_wallet[0]) {
        await handleLogout();
        setOpen(false);
        dispatch({ type: 'LOGOUT' });
      }
      // else {
      //   console.log('is the wallet_address IS EQUAL to the new_change_wallet: ', wallet_address === new_changed_wallet[0]);
      // }
    }
  };
  const check_account_interval = async function () {
    const _await = await get_new_wallet();
    setTimeout(check_account_interval, 2000);
  };
  // useEffect(() => {
  //   if(accountsChanged) {
  //     executeAcountChange(accountsChanged)
  //   }
  // }, [accountsChanged])
  useEffect(() => {
    if (session_id && wallet_address && is_init_contract_done) {
      getAllBalances(wallet_address);
    }
    if (web3 && wallet_address && referral_code) {
      getUser(wallet_address, referral_code);
    }
  }, [session_id, wallet_address, is_init_contract_done, referral_code])
  // if (session_id && wallet_address){
  //   setTimeout(() => {
  //     getAllBalances(wallet_address);
  //   }, [300000])
  // }
  // Functions
  async function executeConnect() {
    if (!window.ethereum) {
      // set_connect_error('Please install Metawallet first.');
      onOpenNotice()
    } else {
      try {
        localStorage.setItem('signincalled', 'true');
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        dispatch({ type: 'INIT_WEB3', payload: new Web3(window.ethereum) })
        handleSignin(accounts[0]);
      } catch (error) {
        onOpenError('info', 'Pending wallet action', "Pending wallet action is required from your side. Please check your wallet and try again.", '')
        // set_connect_error('We have recorded an attempt to access your wallet. Please refresh the page and try again.')
        return;
      }
    }
  }
  async function handleSignin(publicAddress) {
    if(blacklisted.includes(publicAddress?.toLowerCase())) {
      history.push('/')
    } else {
      // Step-1 Try to fetch the nonce from the public address
      await dispatch({ type: 'INIT_WEB3', payload: new Web3(window.ethereum) })
      await axios.get(APP_URL + "/users?publicAddress=" + publicAddress)
        .then(function (data) {
          handleSignMessage(data.data.nonce, publicAddress);
        })
        .catch(function (data) {
          onOpenCreate(publicAddress)
        })
    }
  }

  useEffect(() => {
    if(localStorage.getItem('wallet_address')) {
      set_public_address(localStorage.getItem('wallet_address'))
    }
   
  }, [localStorage.getItem('wallet_address')])


  async function setupEvents() {
    await window.ethereum.on("disconnect", accounts => {
    });
    // // detect Metamask account change OR When User locks/unlocks the wallet
    // await window.ethereum.on('accountsChanged', async function (accounts) {
    //   if (!accounts.length) {
    //     localStorage.setItem('loggingOut', true);
    //     handleLogout();
    //     //logic to handle what happens once MetaMask is locked
    //   } else {
    //     setAccountsChanged(accounts[0])
    //     // if (localStorage.getItem('signincalled') === 'false') {
    //     //   // This is a patch because this method is called even when we connect to metawallet upon clicking on the login button. We want to avoid that
    //     //   if (localStorage.getItem('loggedin') === 'true') {
    //     //     await handleAccountChange(accounts[0]);
    //     //   } else {
    //     //     await handleSignin(accounts[0]);
    //     //   }
    //     // }
    //   }
    // });
    // this is just to check and listen for metamask lock and unlock events
    setInterval(function () {
      return web3 && web3.eth.getAccounts(function () {
        return null;
      });
    }, 2000);
    // detect Network change
    await window.ethereum.on('chainChanged', async function (networkId) {
      await localStorage.setItem('current_network', networkId);
      if (localStorage.getItem('wallet_address')) window.location.reload();
    });
    if (window.ethereum) localStorage.setItem('current_network', window.ethereum.chainId);
  }
  // async function executeAcountChange(publicAddress){
  //   if (localStorage.getItem('signincalled') === 'false') {
  //     if (localStorage.getItem('loggedin') === 'true') {
  //       await handleAccountChange(publicAddress);
  //     } else {
  //       // await handleSignin(publicAddress);
  //       handleLogout() // if on account change needs to logout
  //     }
  //   }
  // }
  function handleSignup(publicAddress, email_id, referral_by) {
    axios.post(APP_URL + "/users/createAccount", {
      publicAddress: publicAddress,
      email_id: email_id,
      referral_by: referral_by
    })
      .then(async function (data) {
        set_create_acc_error()
        // Step-2 Since account exist. Proceed with signing the message with provided nonce
        localStorage.removeItem('ref');
        // console.log('handleSignup nonce: ', data.data.nonce)
        if(!justCreateModal) {
          handleSignMessage(data.data.nonce, publicAddress);
        }else {
          if (blacklisted.includes(publicAddress?.toLowerCase())) {
            history.push('/');
            await handleLogout();
            setOpen(false);
            dispatch({ type: 'LOGOUT' });
          }
          history.push('/user-profile');
          window.location.reload();
        }
        // onOpenLink(data.data.nonce, publicAddress)
      })
      .catch(function (data) {
        set_create_acc_error(data?.response?.data?.message ? data?.response?.data?.message : 'Unexpected Error')
      });
  }
  async function handleSignMessage(nonce, publicAddress) {
    setOpenCreate(false)
    setOpenConnect(false)
    try {
      await web3.eth.personal.sign(`I am signing to DAPPX with my one-time random number: ${nonce}`,
        publicAddress,
        async function (error, signedMessage) {
          if (error) {
            // User denied the signing
            // set_sign_in_success();
          } else {
            // User signed the message Proceed with Authentication
            localStorage.setItem('signincalled', 'false');
            await handleAuthentication(publicAddress, signedMessage);
          }
        });
    } catch (error) {
      // set_sign_in_success();
      // let err_message = err.response ? err.response.data.message : ''
      // onOpenError('', '', err_message, '')
      if (error.code === 4001) {
        onOpenError('', 'Sign in error', 'Please approve the wallet signature to complete the transaction', 'DONE')
      } else {
        setTimeout(() => {
          handleEventsAfterLoggingOut();
          setOpen(false)
          dispatch({ type: 'LOGOUT' })
          
        }, 300)
      }
    }
  }
  async function handleAuthentication(publicAddress, signedMessage) {
    await axios.post(APP_URL + "/users/authentication", {
      publicAddress: publicAddress,
      signature: signedMessage,
    })
      .then(async function (data) {
        onCloseError();
        // set_sign_in_success("Signing successfull.");
        localStorage.setItem('session-id', data.data['session-id']);
        localStorage.setItem('loggedin', true);
        localStorage.setItem('wallet_address', publicAddress);
        localStorage.setItem('referral_code', data.data['referral_code']);
        getUser(publicAddress, data.data['referral_code'])
        if (blacklisted.includes(publicAddress?.toLowerCase())) {
          history.push('/');
          await handleLogout();
          setOpen(false);
          dispatch({ type: 'LOGOUT' });
        }
        if (deployed_contract && deployed_contract_ace && deployed_contract_dappx) {
          getAllBalances(publicAddress)
        }
        history.push('/user-profile');
        window.location.reload();
        //you will get the session id here which you can use for signup and include this in header session-id for restricted routes
        //data.session-id use this to set the user status as logged in
      })
      .catch(function (data) {
        //Something went wrong while signing the message
        // set_sign_in_success();
        onOpenError('', 'Sign in error', "Something went wrong.", '')
      });
  }
  function getUser(publicAddress, referral_code) {
    axios.get(APP_URL + "/users/userProfile", {
      headers: {
        'session-id': localStorage.getItem('session-id'),
      }
    })
      .then(function (data) {
        let user_data = {
          username: data.data.username,
          email: data.data.email_id,
          wallet_address: publicAddress,
          referral_code: referral_code,
        };
        dispatch({ type: 'LOGIN_SUCCESS', payload: user_data })
      })
      .catch(function (data) {
        // handle expired session ID
        if (data == 'Error: Network Error') {
          onOpenError('info', 'Session Expired.', 'Please login to continue.', '');
          setTimeout(async () => {
            await handleLogout();
            setOpen(false);
            dispatch({ type: 'LOGOUT' });
          }, 3000);
        }
      })
  }

  async function handleAccountChange(newPublicAddress) {
    set_public_address(newPublicAddress)
    await axios.post(APP_URL + "/users/accountChanged", {
      data: {
        publicAddress: newPublicAddress,
      }
    }, {
      headers: {
        'session-id': localStorage.getItem('session-id'),
        'Content-Type': 'application/json'
      }
    })
      .then(async function (data) {
        // await handleSignin(newPublicAddress);
        await handleLogout()
        setOpen(false); // if on account change needs to logout
        dispatch({ type: 'LOGOUT' });
      })
      .catch(async function (data) {
        // if (data.response) {
        //   if (data.response.data.message === 'Account already exists') {
        //     await handleSignin(newPublicAddress);
        //   } else if (data.response.data.message === "Account doesn't exists") {
        //     onOpenCreate(newPublicAddress)
        //   } else {
        //     handleEventsAfterLoggingOut()
        //     onOpenError('', 'Sign in error', "Something went wrong.", '')
        //   }
        // } else {
        // }
        await handleLogout()
        setOpen(false) // if on account change needs to logout
        dispatch({ type: 'LOGOUT' });
      });
  }

  async function initContract() {
    await axios.get(APP_URL + "/contract.json")
      .then(function (responseContract) {
        set_deployed_contract(new web3.eth.Contract(responseContract.data.abi, CONTRACT_ADDRESS));
      })
      .catch(function (data) {
      })
    await axios.get(APP_URL + "/acentToken.json")
      .then(function (responseContractAce) {
        set_deployed_contract_ace(new web3.eth.Contract(responseContractAce.data.abi, CONTRACT_ADDRESS_ACE));
      })
      .catch(function (data) {
      })
    await axios.get(APP_URL + "/dappx.json")
      .then(function (responseContractDappx) {
        set_deployed_contract_dappx(new web3.eth.Contract(responseContractDappx.data.abi, CONTRACT_ADDRESS_DAPPX));
      })
      .catch(function (data) {
      })
    set_init_contract(true)
  }
  async function getAllBalances(address) {
    await Promise.all([
      getAceBalance(address),
      getDappxBalance(address),
      getEthBalance(address),
    ]);
  }
  async function getAceBalance(account) {
    const result = await deployed_contract_ace.methods.balanceOf(account).call();

    var _balance = web3.utils.fromWei(result);

    await getCoinValue(_balance, 'ACE');
  }
  async function getDappxBalance(account) {
    const result = await deployed_contract_dappx.methods.balanceOf(account).call();

    var _balance = web3.utils.fromWei(result);

    await getCoinValue(_balance, 'DAPPX');
  }
  function getEthBalance(account) {
    getBalance(account, async function (balance) {
      if (currentNetowrk == '0x22b8' || currentNetowrk == '0x231d'){
        return await getCoinValue(balance, 'ACE');
      }
      await getCoinValue(balance, 'ETH');
    })
  }
  function getBalance(publicAddress, callback) {
    web3.eth.getBalance(publicAddress, function (error, result) {
      if (!error) {
        callback(web3.utils.fromWei(result, 'ether').toString(10));
      } else {
        console.log(publicAddress + ': ' + result);
      }
    });
  }
  async function getCoinValue(balance, tokenCode) {
    var currency = 'USD';
    await axios.get(APP_URL + '/users/getMarketPrices')
      .then(async function (prices) {

        var amount = balance;
        var fiat_currency = prices.data[tokenCode][currency] ? prices.data[tokenCode][currency] : 0;
        var fiat_value = parseFloat(amount) * parseFloat(fiat_currency);
        if (fiat_value > 0) {
          // Round of to nearest thousand
          var thousands = parseFloat(Number(fiat_value).toFixed(3));
          // Format fiat currency and display up to two decimal places
          fiat_value = formatCurrency(thousands.toFixed(2));
        }

        let new_balances = {
          token: tokenCode,
          value: parseFloat(balance).toFixed(6),
          currency: currency,
          fiat_value: fiat_value === 0 ? parseFloat(fiat_value).toFixed(2) : fiat_value,
        }

        let native_balance = {
          token: 'ACE',
          value: parseFloat(balance).toFixed(6),
          currency: currency,
          fiat_value: fiat_value === 0 ? parseFloat(fiat_value).toFixed(2) : fiat_value,
        }

        if (currentNetowrk == '0x22b8' || currentNetowrk == '0x231d'){
          await all_balances.push(native_balance);
          dispatch({ type: 'WALLET_BALANCE', payload: all_balances })
          return
        }
        await all_balances.push(new_balances);

        dispatch({ type: 'WALLET_BALANCE', payload: all_balances })
      })
      .catch(function (data) {
        console.log('getCoinValue error: ', data)
      })
  }
  function formatCurrency(n) {
    var parts = n.toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
  }
  // function handlePurchase() {
  //   var projectId = 1;
  //   var packageId = 1;
  //   console.log(accounts);
  //   //@todo before calling this method Call the create order api and pass the order_id to smart contract along with project and package id
  //   if (accounts != null) {
  //     deployed_contract.methods.purchasePackage(order_id, projectId, packageId).send({ from: accounts[0] }).then(function (tx) {
  //       console.log("tx", tx);
  //     })
  //       .catch(function (error) {
  //         //handle error here
  //         if (error.code == 4001) {
  //           console.log("Transaction Rejected") // @todo you need to handle when user rejects the transaction
  //         }
  //       });
  //   } else {
  //     console.log("Application is not connected to metawallet") //@todo you can write your own logic to inform user
  //     executeConnect();
  //   }
  // }
  // Dropdown =============================
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const ref = useRef(null);
  const handleToggle = async() => {
    const walletAddress = localStorage.getItem('wallet_address').toLowerCase();
    if(blacklisted.includes(localStorage.getItem('wallet_address').toLowerCase())) {
      await handleLogout();
      setOpen(false);
      dispatch({ type: 'LOGOUT' });
    }
    history.push("/user-profile")
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
};
useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
        document.removeEventListener('click', handleClickOutside, true);
    };
}, []);
  // const onMouseEnter = () => {
  //   setOpen(true)
  // }
  // const onMouseLeave = () => {
  //   setOpen(false)
  // }
  const handleClose = (event) => {
    let pathname = history.location.pathname;
    let target_pathname = event.target.href ? event.target.href.split("/").pop() : null;
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
    if (pathname.includes(target_pathname)) {
      history.push(`/${target_pathname}`)
      window.location.reload();
    }
  };
  const dropdown_menu = [
    {
      label: "My Assets",
      href: "/user-profile",
      icon: WalletIcon
    },
    // {
    //   label: "Logout",
    // },
  ]
  const walletAddressFormat = (wallet) => {
    return wallet?.substring(0, 4) + '....' + wallet?.substring(wallet.length - 4, wallet.length)
  }

  useEffect(() => {
    setOpenCreate(verifyAccount)

  }, [verifyAccount])

  if(justCreateModal) {
    return (
      <>
      <ModalCreate
          open={openCreate}
          onClose={onCloseCreate}
          public_address={public_address}
          handleSignup={handleSignup}
          create_acc_error={create_acc_error}
          set_create_acc_error={set_create_acc_error}
             onClickBtn={() => setOpenCreate(!openCreate)}
        />
      
      </>
    )
  }
  return (
    <>
      <div className="connect-wallet-component"
        // onMouseEnter={(session_id && !isMobile) && onMouseEnter}
        // onMouseLeave={(session_id && !isMobile) && onMouseLeave}
      >
        {!session_id && (
          <button className='connet_wallet_btn' onClick={onOpenConnect}><img src={plusIcon} /> {t("Connect Wallet")}</button>
        )}
        {session_id && (
          <button
            ref={session_id && anchorRef}
            onClick={session_id ? handleToggle : onOpenConnect}
            edge="end"
            id="connect-wallet-button"
            className={`${mobileView ? 'user-mobile' : 'user-btn'} ${session_id ? 'connected' : ''}`}>
            <UserIcon />
            <p>
              {localStorage.getItem('wallet_address') && walletAddressFormat(localStorage.getItem('wallet_address'))}
            </p>
          </button>
        )}
        {/* <Popper
          open={open}
          anchorEl={session_id && anchorRef.current}
          role={undefined}
          transition
          disablePortal
          className="connect-wallet-menu">
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper square={true}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {dropdown_menu.length > 0 && dropdown_menu.map((item) => (
                      <MenuItem
                        key={item.label.toLowerCase().replace(/ /g, "_")}
                        component={item.href ? RouterLink : "button"}
                        to={item.href}
                        target={item.target ? item.target : ''}
                        onClick={item.label === 'Logout' ? handleEventsAfterLoggingOut : handleClose}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper> */}
          {/* <div className={`user-dropdown-menu ${open ? 'open-menu' : ''}`} ref={ref}>
            <div className="user-dropdown-header">
              <div className="dropdown-header-keft">
                <UserIcon />
                <p>
                  {walletAddressFormat(localStorage.getItem('wallet_address'))}
                </p>
                <button className='transparent-btn'>
                  <CopyIcon />
                </button>
              </div>
              <button className='transparent-btn close-dropdown-btn' onClick={handleToggle}>
                <DownloadIcon />
              </button>
            </div>
            <MenuList>
              {dropdown_menu.length > 0 && dropdown_menu.map((item) => (
                <MenuItem
                  key={item.label.toLowerCase().replace(/ /g, "_")}
                  component={item.href ? RouterLink : "button"}
                  to={item.href}
                  target={item.target ? item.target : ''}
                  onClick={item.label === 'Logout' ? handleEventsAfterLoggingOut : handleClose}
                >
                  {item.icon && (
                    <img src={item.icon} alt="icon" />
                  )}
                  {item.label}
                </MenuItem>
              ))}
            </MenuList>
            <button className='dis-btn' onClick={handleEventsAfterLoggingOut}>Disconnect Wallet</button>
          </div> */}
      </div>
      <div className="d-none">

        {!is_s_korean && (
 <ModalConnect
 open={openConnect}
 onClose={onCloseConnect}
 onClickBtn={onOpenCreate}
 executeConnect={executeConnect}
 connect_error={connect_error}
/>
        )}

{is_s_korean && (
 <RestrictedModal
 open={openConnect}
 onClose={onCloseConnect}
 onClickBtn={onOpenCreate}
 executeConnect={executeConnect}
 connect_error={connect_error}
/>
        )}
       
        <ModalCreate
          open={openCreate}
          onClose={onCloseCreate}
          public_address={public_address}
          handleSignup={handleSignup}
          create_acc_error={create_acc_error}
          set_create_acc_error={set_create_acc_error}
             onClickBtn={() => setOpenCreate(!openCreate)}
        />
        {/* <ModalLink
      open={openLink}
      onClose={onCloseLink}
      handleSignMessage={handleSignMessage}
      public_address={public_address}
      nonce={state_nonce}
      sign_in_success={sign_in_success}
      sign_in_error={sign_in_error}
      set_sign_in_success={set_sign_in_success}
      set_sign_in_error={set_sign_in_error}
      /> */}
        <ModalError
          open={openError}
          onClose={onCloseError}
          modal_icon={sign_in_error.icon}
          modal_text={sign_in_error.text}
          modal_title={sign_in_error.title}
          modal_btn_label={sign_in_error.btn_label}
        />
        <ModalNotice
          open={openNotice}
          onClose={() => setOpenNotice(false)}
        />
      </div>
    </>
  );
}
export default ConnectWalletButton;