import { Button, styled } from "@material-ui/core";
import axios from "axios";
import { useContext, useState, useEffect } from "react";
import CustomDialog from "../../../../components/Modal/CustomDialog";
import { Config, DAPPX_BASE_URL } from "../../../../constant/config";
import { AuthContext } from "../../../../context/AuthContext";
import { convertValue } from "../../Profile";
import infoIcon from "../../../../assets/images/dappstore-info.png";
import { ReactComponent as SuccessIcon } from "../../../../assets/images/icons/success-check.svg";
import DappxWithdraw from "./dappxWithdraw";
import { useRef } from "react";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
const APP_URL = DAPPX_BASE_URL;
const defaultData = {
  total_dappx: "0.000000",
  pledged_dappx: "0.000000",
  unpledged_dappx: "0.000000",
};
const defaultFloatVal = "0.000000";

const DappxWithdrawWrapper = ({
  vaultAceBalance,
  vaultAceFiat,
  isVerified,
}) => {
  const displayDecimals = 6;
  const computeDecimals = 12;

  const [withdrawalOn, setWithdrawalOn] = useState(true);
  const [withdrawalMaitenance, setWithdrawalMatintenance] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);
  const [withdrawLimit, setWithdrawLimit] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const [nitroFee, setNitroFee] = useState(0);
  const [ubiFee, setUbiFee] = useState(0);
  const [coreFee, setCoreFee] = useState(0);
  const [vaultFiat, setVaultFiat] = useState(0);
  const [reloadData, setReloadData] = useState(true);
  const [open, setOpen] = useState(false);
  const [dataTotal, setDataTotal] = useState(defaultData);
  const { authState } = useContext(AuthContext);
  const { wallet_balance } = authState;
  const { pledged_dappx, unpledged_dappx, total_dappx } = dataTotal;
  const total = parseFloat(total_dappx).toFixed(6);


  const [data, setData] = useState({
    mainBal: defaultFloatVal,
    pocketBal: defaultFloatVal,
  });
  const [dataAce, setDataAce] = useState({
    mainBal: defaultFloatVal,
    pocketBal: defaultFloatVal,
  });
  const [digital_ace, set_digital_ace] = useState({
    coin_value: defaultFloatVal,
    fiat_value: "0.00",
  });

  const [pre_open_modal, set_pre_open_modal] = useState(false);

  const myRef = useRef();

  const handleClickOutside = e => {
    if (!myRef?.current?.contains(e.target)) {
      set_pre_open_modal(false);
    }
  };

  const handleClickInside = () => set_pre_open_modal(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });


  const { mainBal, pocketBal } = data;
  const balance = (parseFloat(mainBal) + parseFloat(pocketBal)).toFixed(
    computeDecimals
  );

  const sessionID = localStorage.getItem("session-id");

  const withdrawButton = () => {
    if (withdrawalOn) {
      setWithdrawModal(true);
    } else {
      setWithdrawalMatintenance(true);
    }
  };

  const transactionSuccess = () => {
    setSuccessModal(true);
  };

  const fetchWithdrawSettings = async () => {
    await axios
      .get(Config.DAPPX_BASE_URL + "/users/dappx/withdraw/settings", {
        headers: { "session-id": sessionID },
      })
      .then((res) => {
        setCoreFee(res.data.withdrawal_fee_core);
        setUbiFee(res.data.withdrawal_fee_ubi);
        setNitroFee(res.data.withdrawal_fee_nitro);
        setWithdrawalOn(res.data.allow_withdrawal);
      });
  };

  const fetchWithdrawaLimit = async () => {
    await axios
      .get(Config.DAPPX_BASE_URL + "/users/dappx/withdraw/limit-status", {
        headers: { "session-id": sessionID },
      })
      .then((res) => {
        if (
          res.data.remaining_daily_tx_amount >
          res.data.remaining_user_daily_tx_amount
        ) {
          setWithdrawLimit(res.data.remaining_user_daily_tx_amount);
        } else {
          setWithdrawLimit(res.data.remaining_daily_tx_amount);
        }
      });
  };
  const onCloseSuccessModal = () => {
    setSuccessModal(false);
    setWithdrawModal(false);
  };

  useEffect(() => {
    // getMyDappxBalance()
    fetchWithdrawSettings();
    fetchWithdrawaLimit();
  }, []);

  useEffect(async () => {
    if (reloadData) {
      const [vault, active] = await Promise.all([
        axios
          .get(APP_URL + "/users/dappx/vault/balance", {
            headers: {
              "session-id": sessionID,
            },
          })
          .catch((error) => error),
        axios
          .get(APP_URL + "/users/dappx/active/balance", {
            headers: {
              "session-id": sessionID,
            },
          })
          .catch((error) => error),
      ]);

      if (
        (vault && vault.status === 200 && vault.data) ||
        (active && active.status === 200 && active.data)
      ) {
        const mainBal =
          vault && vault.data ? vault.data.balance : defaultFloatVal;
        const pocketBal =
          active && active.data ? active.data.balance : defaultFloatVal;
        const total = (parseFloat(mainBal) + parseFloat(pocketBal)).toFixed(
          computeDecimals
        );
        const whole_num = total.split(".")[0];
        const decimal_num = total.split(".")[1].substr(0, displayDecimals);
        const balance = whole_num + "." + decimal_num;

        setData({
          ...data,
          mainBal:
            vault && vault.data
              ? vault.data.balance.toFixed(computeDecimals)
              : defaultFloatVal,
          pocketBal:
            active && active.data
              ? active.data.balance.toFixed(computeDecimals)
              : defaultFloatVal,
        });

        const convertVaultFiat = await convertValue(mainBal, "DAPPX");
        setVaultFiat(convertVaultFiat);

        const fiat_value = await convertValue(balance, "DAPPX");
        set_digital_ace({
          ...digital_ace,
          coin_value: balance,
          fiat_value: fiat_value,
        });
      }

      setReloadData(false);
    }
  }, [reloadData]);
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      padding: '10px 20px',
      fontSize: '14px'
    },
  }));
  return (
    <>


      {/* <div className="option-item-area-main option-item-area-main-f">

        <Button
          variant="outlined"
          color="primary"
          // fullWidth
          onClick={withdrawButton}
          // className={classes.btn}
          disabled={!isVerified}
        >
          Withdraw


        </Button>
        {!isVerified && (
          <>
            <button className="question-btn" onClick={() => set_pre_open_modal(true)}>
              <InfoIcon />
            </button>

            <div className={`option-item-area ${pre_open_modal ? 'option-item-area-active' : ''}`} ref={myRef}>
              <p>Verify your email to enable withdrawal.</p>
            </div>

          </>
        )}


      </div> */}

      {/* <Button
        variant="outlined"
        color="primary"
        // fullWidth
        onClick={withdrawButton}
        // className={classes.btn}
        disabled={!isVerified}
      >
        Withdraw
       
      </Button>
      <Tooltip title="asdfasdf asdf asdfa sdfasdfasdfasdf" arrow>
<p>asdfasdfasdf</p>
    
        </Tooltip> */}
      {isVerified && (
        <Button
          variant="outlined"
          color="primary"
          // fullWidth
          onClick={withdrawButton}
          className='disabled-with-info-btn'
          disabled={!isVerified}
        >
          Withdraw
        </Button>
      )}
      {!isVerified && (
        <HtmlTooltip title="Verify your email to enable withdrawal.">
          <span>
            <Button
              variant="outlined"
              color="primary"
              // fullWidth
              onClick={withdrawButton}
              className='disabled-with-info-btn'
              disabled={!isVerified}
            >
              Withdraw
              <InfoIcon />
            </Button>
          </span>
        </HtmlTooltip>
      )}



      <DappxWithdraw
        checkOpen={withdrawModal}
        vaultBalance={data.mainBal}
        aceVaultBalance={vaultAceBalance}
        withdrawLimit={withdrawLimit}
        nitroFee={nitroFee}
        ubiFee={ubiFee}
        coreFee={coreFee}
        usdValue={usdValue}
        vaultFiat={vaultFiat}
        vaultAceFiat={vaultAceFiat}
        onClose={() => setWithdrawModal(false)}
        onSuccess={transactionSuccess}
        refetch={fetchWithdrawSettings}
      />

      <CustomDialog
        open={policyModal}
        header={<p>Policy Update!</p>}
        body={
          <div className="mobile-modal-conatainer">
            <img src={infoIcon} alt="" className="mobile-modal-logo" />
            <p className="policy-modal-body">
              Daily withdraw policy was updated.
              <br />
              Kindly check and process your withdrawal again.
            </p>
          </div>
        }
        footer={
          <div className="policy-button-container">
            <button
              className="policy-button"
              onClick={() => setPolicyModal(false)}
            >
              Ok
            </button>
          </div>
        }
      />
      <CustomDialog
        open={withdrawalMaitenance}
        header={<p>Service Maintenance</p>}
        body={
          <div className="mobile-modal-conatainer">
            <img src={infoIcon} alt="" className="mobile-modal-logo" />
            <p className="policy-modal-body" style={{ marginRight: "-23%" }}>
              Service is not available at the moment.
              <br />
              Please try again later. Thank you.
            </p>
          </div>
        }
        footer={
          <div className="policy-button-container">
            <button
              className="policy-button"
              onClick={() => setWithdrawalMatintenance(false)}
            >
              Ok
            </button>
          </div>
        }
      />
      <CustomDialog
        open={successModal}
        className="deposit-dialog loading-dialog"
        onClose={onCloseSuccessModal}
        header="Your withdraw is on it's way!"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={
          <>
            <SuccessIcon className="icon" />
            <p>
              Please confirm the deposit action on your wallet to submit the
              request to the network.
            </p>

            <p>
              Updated status will be reflected on your wallet transaction
              history.
            </p>

            <p>
              For successful transaction, reflecting the balance on your
              dAppstore Balances may take longer than usual.
            </p>
          </>
        }
        footer={
          <>
            <div className="button-block">
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={onCloseSuccessModal}
                type="submit"
              >
                OK
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};

export default DappxWithdrawWrapper;
