const numeral = require("numeral");
module.exports = (val, decimal = 8) => {
  val = parseFloat(val);
  let decimalCount = val.toString().split(".")[1]?.length ?? 0;
  if (decimalCount < decimal) {
    decimal = decimalCount;
  }
  const decimalPattern = Array(decimal).fill("0").join("");
  let final = numeral(val).format(`0,00.${decimalPattern}`);
  return final;
};
