import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props
    // Code for before decimal input length and after decimal
    let max_length=10
    let value=(props.value).toString()
    if(value.includes('.')){
        max_length+=6

    }else{

        max_length=10
    }
//end

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      allowNegative={false}
      // decimalScale={6}
      maxLength={max_length}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default NumberFormatCustom
