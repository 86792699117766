import React, { useEffect, useState } from 'react';
import {
  Button
} from "@material-ui/core";
import { ReactComponent as HistoryBtn } from '../../../../assets/images/icons/history-btn.svg';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import HistoryItem from './HistoryItem';
import axios from 'axios';
import { Config } from '../../../../constant/config';
import Loader from '../../../../components/loader/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import fi from 'date-fns/esm/locale/fi/index.js';
import TransactionHistory from './TransactionHistory';
const TransactionHistoryDrawer = ({ type }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [transactions, setTransactions] = useState([])
  const [totalCount, setTotalCount] = useState()
  const sessionID = localStorage.getItem('session-id');
  const [page, setPage] = useState(1)
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }
  return (
    <>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction='right'
        className='bla bla bla'
        style={{
          zIndex: 9999,
          maxWidth: '387px',
          width: '100%'
        }}
      >

       {isOpen && <TransactionHistory toggleDrawer={toggleDrawer} />} 
      </Drawer>

      <div className='balance-history'>
        {/* <h3 className="title">dAppstore Balance</h3> */}
        <Button className="history-btn"
          variant="outlined"
          component="a"
          onClick={toggleDrawer}
        >
          <HistoryBtn />
          history
        </Button>
      </div>
    </>
  );
}

export default TransactionHistoryDrawer;
