import React, { useState } from 'react';
import { Box, ButtonBase } from '@material-ui/core';

import './SectionSlider.scss';

import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRef } from 'react';
import HoverVideoPlayer from 'react-hover-video-player';
SwiperCore.use([Navigation, Autoplay, Pagination]);


const SliderMobile = ({
  data,
  imageLoaded,
  setImageLoaded,
}) => {
  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = (swiper) => {
    swiper.current.autoplay.stop();
  }
  const onMouseLeave = async (swiper) => {
    await swiper.current.autoplay.start();
    await onVideoStart()
  }

  const [video_start, set_video_start] = useState(false);
  const onVideoStart = () => {
    setTimeout(() => {
      set_video_start(true)
    }, 4000)
  }
  const onVideoEnd = () => {
    set_video_start(false)
  }

  const check_video_end = async (swiper) => {
    await setIsHover(false);
    await set_video_start(false);
    swiper?.current?.slideNext();
  }
  let myswiper = useRef(null);
  return (
    <Swiper
      ref={myswiper.current}
      className="swiper-area"
      loop={true}
      autoplay={{
        disableOnInteraction: true,
        delay: 8000,
      }}
      centeredSlides={true}
      slidesPerView={'auto'}
      navigation
      watchSlidesVisibility={true}
      pagination={{ clickable: true }}
      effect="slide"
    >
      {data.map((val, el) => {
        return (
          <SwiperSlide key={val._id}>
            {({ isVisible, isActive }) => (
              <ButtonBase
                href={val.videoOnHoverMobile ? '#' : val.mobileLink ? val.mobileLink : val.buttons[0]?.href}
                target="_blank"
                className={`slide-content-block ${val.className}`}
                style={{
                  opacity: imageLoaded ? 1 : 0,
                  transition: 'opacity .4s ease-in-out'
                }}
                onMouseEnter={() => isVisible && isActive
                  ? (setIsHover(true), set_video_start(true))
                  : null
                }
                onMouseLeave={() => isVisible && isActive
                  ? (setIsHover(false), set_video_start(false))
                  : null
                }

                onClick={e => {
                  if(val.videoOnHoverMobile) {
                    e.preventDefault()
                  }
                 
                }}
              >
                {isActive && video_start && val.videoOnHoverMobile && <>

                  <div className="hover-video-container"
                      onMouseEnter={() => isVisible && isActive
                        ? (setIsHover(true), set_video_start(true))
                        : null
                      }
                      onMouseLeave={() => isVisible && isActive
                        ? (setIsHover(false), set_video_start(false))
                        : null
                      }
                    >

<div className="video-container">
                    <video autoPlay muted onEnded={() => check_video_end(myswiper)}>
                      <source src={val.videoOnHoverMobile} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                      </div>
                 
                </>}
                {!video_start && val.videoOnHoverMobile && <>
                  <HoverVideoPlayer
                    className="hover-video-player"
                    videoSrc={val.videoOnHoverMobile}
                    loop={false}
                    // muted={false}
                    onEnded={() => check_video_end(myswiper)}
                    pausedOverlay={<img src={val.image} alt={val.className} onLoad={() => setImageLoaded(true)} />}
                    loadingOverlay={<div className="loading-spinner-overlay" />}
                  />
                </>
                }
                <img className="bg-image" src={val.image} alt={val.className} onLoad={() => setImageLoaded(true)} />
                <Box className="slide-content-box">
                  {val.title &&
                    <h2 className="title" style={{ color: val.titleColor }}>
                      {val.title_is_image
                        ? <img className="img-fluid" src={val.title} alt={val.className} />
                        : val.title
                      }
                    </h2>
                  }
                  {val.excerpt &&
                    <p className="excerpt" style={{ color: val.textColor }}>
                      {val.excerpt}
                    </p>
                  }
                </Box>
              </ButtonBase>
            )}
          </SwiperSlide>
        );
      })}
    </Swiper>
  )
}

export default SliderMobile;