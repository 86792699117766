import React from 'react';
import { Container } from '@material-ui/core';
import './Terms.scss';

const Terms = () => {
  return (
    <div className="page-content terms-content">
      <Container>
        <h1>Terms of Service</h1>
        <h2>END-USER LICENSE AGREEMENT ("AGREEMENT") FOR DAPPX</h2>

        <p>Please read this End-User License Agreement (“Agreement”) carefully before downloading DAPPX or anything associated with using it.</p>
        <p>By using DAPPX, you are agreeing to be bound by the terms and conditions of this Agreement.</p>
        <p>This Agreement is a legal agreement between you (either an individual or a single entity) and Decenternet Association Limited., and it governs your use of the Application made available to you by Decenternet Association Limited.</p>
        <p>If you do not agree to the terms of this Agreement, do not continue to use DAPPX or any of its downloadable build or defer to use the Application.</p>
        <p>The Application is licensed, not sold to you by Decenternet Association Limited. for use strictly in accordance with the terms of this Agreement.</p>

        <h3>Authorized User of DAPPX</h3>
        <p>By using the Site, you represent and warrant that:</p>
        <ul>
          <li>(1) all registration information you submit will be true, accurate, current, and complete;</li>
          <li>(2) you will maintain the accuracy of such information and promptly update such registration information as necessary;</li>
          <li>(3) you have the legal capacity and you agree to comply with these Terms and Conditions;</li>
          <li>(4) you are not under the age of 13;</li>
          <li>(5) not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use the Site;</li>
          <li>(6) you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise; </li>
          <li>(7) you will not use the Site for any illegal or unauthorized purpose;</li>
          <li>(8) your use of the Site will not violate any applicable law or regulation.</li>
        </ul>
        <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse all current or future use of the Application (or any portion thereof).</p>

        <h3>License</h3>
        <p>Decenternet Association Limited. grants you a revocable, non-exclusive, non-transferable, limited license to download. Install and use the Application solely for your personal, non-commercial purposes, strictly in accordance with the terms of this Agreement. </p>

        <h3>Third-Party Services</h3>
        <p>The Application may display, include, or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services (“Third-Party Services”).</p>
        <p>You acknowledge and agree that Decenternet Association Limited. shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Decenternet Association Limited. does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.</p>
        <p>Third-Party Services and links thereto are provided solely as a convenience to you, and you access and use them entirely at your own risk and subject to such third parties’ terms and conditions.</p>

        <h3>Term and Termination</h3>
        <p>This Agreement shall remain in effect until terminated by you or Decenternet Association Limited.</p>
        <p>Decenternet Association Limited. may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.</p>
        <p>This Agreement will terminate immediately, without prior notice from Decenternet Association Limited. in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by discontinuing to use DAPPX and any of its content, and all copies of it for various devices thereof from your mobile device or from your computer.</p>
        <p>Upon termination of this Agreement, you shall cease all use of the Application and delete all copies of the Application from your mobile device and/ or from your computer.</p>
        <p>Termination of this Agreement will not limit any of Decenternet Association Limited. rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.</p>

        <h3>Amendments to this Agreement</h3>
        <p>Decenternet Association Limited. reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material, we will provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
        <p>By continuing to access or use our Application after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to access our licensed Application.</p>

        <h3>Governing Law</h3>
        <p>The laws of Hong Kong excluding its conflicts of law rules, shall govern this Agreement and your use of our Application. Your use of the Application may also be subject to other local, state, national, or international laws.</p>

        <h3>Contact Information</h3>
        <p>If you have any questions about this Agreement, please contact us.</p>

        <h3>Entire Agreement</h3>
        <p>The Agreement constitutes the entire agreement between you and Decenternet Association Limited. regarding your use of our Application and supersedes all prior and contemporaneous written or oral agreements between you and Decenternet Association Limited.</p>
        <p>You may be subject to additional terms and conditions that apply when you use or purchase other Decenternet Association Limited. services, which Decenternet Association Limited. will provide to you at the time of such use or purchase.</p>
      </Container>
    </div>
  );
}

export default Terms;
