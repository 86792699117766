import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import ReactPlayer from 'react-player/youtube';
import Highlighter from "react-highlight-words";
import logo_osiris from '../../assets/images/page-download/logo-osiris-browser.png';
import logo_wonder from '../../assets/images/page-download/logo-wonder-wallet.png';
import logo_wonder_sp from '../../assets/images/page-download/logo-wonder-wallet--sp.png';

const SectionMainVisual = ({ mobileView, download_btn_osiris, download_btn_wonder, searchState }) => {
  return (
    <div className="section section-mv">
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box className="heading-block">
              <h1>
                <Highlighter
                  searchWords={[searchState]}
                  textToHighlight="dAppstore is the best run by Osiris Browser Experience WEB3 with Osiris"
                />
              </h1>
              <p>
                <Highlighter
                  searchWords={[searchState]}
                  textToHighlight="Take advantage of the new dAppstore more diversely"
                />
              </p>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="btn-block">
              <div className="image-label">
                <img src={logo_osiris} alt="osiris browser" className="img-fluid" />
              </div>
              {download_btn_osiris}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="btn-block">
              <div className="image-label">
                <img src={ mobileView ? logo_wonder_sp : logo_wonder} alt="wonder wallet" className="img-fluid" />
              </div>
              {download_btn_wonder}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="video-block">
              <ReactPlayer
                className="react-player"
                controls={true}
                playing={false}
                muted
                loop={true}
                height="100%"
                width="100%"
                url="https://www.youtube.com/watch?v=JjTyYevke_M&ab_channel=dAppstore"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default SectionMainVisual;