import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Config } from '../../constant/config';
import {ReactComponent as ArrowDown} from '../../assets/images/icons/arrow-down.svg';
import './TransactionPreview.scss';

const TransactionPreview = ({
  balances,
  newBalances,
  error_message,
  open
}) => {
  const generateBalanceDisplay = (value) => {
    return {
      whole: value ? value.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
      decimal: value ? value.split('.')[1] : ''
    }
  }

  const [fiatValues, setFiatValues] = useState([])

  const getFiatValue = async () => {
    let convertion_currency = 'USD';

    await axios.get(Config.DAPPX_BASE_URL + '/users/getMarketPrices')
    .then((prices) => {
      let values = {};
      Object.keys(balances).map(token => {
        if (!prices.data[token]) {
          return;
        }

        let fiat_currency = prices.data[token][convertion_currency] ? prices.data[token][convertion_currency] : 0;
        values[token] = fiat_currency;
      })
      setFiatValues(values);
    })
    .catch((error) => {
      console.log(error)
      error_message("Can't pull conversion rate.")
      setFiatValues([])
    })
  }

  const convertToFiat = (token, bal) => {
    let fiat_value_value = parseFloat(bal) * parseFloat(fiatValues[token]);
    return parseFloat(fiat_value_value).toFixed(2);
  }

  useEffect(() => {
    getFiatValue()
  }, [open])

  return (
    <div className="transaction-preview">
      <div className="block current">
        <div className={`preview-header column-${Object.keys(balances).length}`}>
            <div></div>
          {Object.keys(balances).map(bal => (
            <div>{bal}</div>
          ))}
        </div>
        <div className={`preview-body column-${Object.keys(balances).length}`}>
            <div>Current</div>
            {Object.keys(balances).map(bal => {
              let amount = generateBalanceDisplay(balances[bal]);

              return (
                <div key={`${bal}-balance`}>
                  <div className="amount">{amount.whole}<span>{amount.decimal ? `.${amount.decimal}` : ''}</span></div>
                  {
                    Object.values(fiatValues).length > 0 ? 
                      fiatValues[bal] ? 
                        (<div className="currency">{convertToFiat(bal, balances[bal])} USD</div>)
                      : ''
                    : ''
                  }
                </div>
              )
            })}
        </div>
      </div>
      <div className="arrow-block">
        <ArrowDown/>
      </div>
      <div className="block after">

      <div className={`preview-header column-${Object.keys(balances).length}`}>
            <div></div>
          {Object.keys(newBalances).map(bal => (
            <div>{bal}</div>
          ))}
        </div>
        <div className={`preview-body column-${Object.keys(balances).length}`}>
            <div>After</div>
            {Object.keys(newBalances).map(bal => {
              let amount = generateBalanceDisplay(newBalances[bal]);

              return (
                <div key={`${bal}-balance`}>
                  <div className="amount">{amount.whole}<span>{amount.decimal ? `.${amount.decimal}` : ''}</span></div>
                  {
                    Object.values(fiatValues).length > 0 ? 
                      fiatValues[bal] ? 
                        (<div className="currency">{convertToFiat(bal, newBalances[bal])} USD</div>)
                      : ''
                    : ''
                  }
                </div>
              )
            })}
        </div>
      </div>
    </div>
  );
}

export default TransactionPreview;
