import React, { useRef, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close-circle.svg';
import './CustomDialog.scss';


const CustomDialog = ({ open, onClose, className, header, body, footer, scroll, disableEscapeKeyDown, disableBackdropClick, fullWidth = false, maxWidth = 'sm', closeBtn = false }) => {

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={scroll ? scroll : 'body'}
      aria-labelledby={`${className}-title`}
      aria-describedby={`${className}-description`}
      className={`custom-dialog ${className}`}
      disableEscapeKeyDown={disableEscapeKeyDown}
      disableBackdropClick={disableBackdropClick}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      PaperProps={{
        classes: {
          root: 'paper-root'
        }
      }}
    >
      {header &&
        <DialogTitle id={`${className}-title`}>
          {header}
        </DialogTitle>
      }

      {closeBtn && (
        <button
          onClick={onClose}
          className="close"
        >
        <CloseIcon />
        </button>
      )}
      <DialogContent dividers={scroll === 'paper'}>
        {body}
      </DialogContent>
      {footer &&
        <DialogActions disableSpacing={false}>
          {footer}
        </DialogActions>
      }
    </Dialog>
  );
}

export default CustomDialog;
