import { v4 as uuidv4 } from 'uuid';
import TopChartCommon from './TopChartCommon';
import image1 from '../../assets/images/section-top-charts/sandbox.jpg';
import image2 from '../../assets/images/section-top-charts/crypto-kitties.jpg';
import image3 from '../../assets/images/section-top-charts/crypto-heroes.jpg';
import image4 from '../../assets/images/section-top-charts/axie-infinity.jpg';
import image5 from '../../assets/images/section-top-charts/decentraland.jpg';
import image6 from '../../assets/images/section-top-charts/gods-unchained.jpg';
import image8 from '../../assets/images/section-top-charts/coba.jpg';
import image9 from '../../assets/images/section-top-charts/dragonereum.jpg';
import image10 from '../../assets/images/section-top-charts/league-of-kingdoms.jpg';

import './TopCharts.scss';

const GameCharts = ({ searchState }) => {
  const data = [
    {
      _id: uuidv4(),
      image: image1,
      name: 'sandbox',
      href: 'https://www.sandbox.game/login/?r=P~.UQTzO_nF.teQMC2owU',
    },
    {
      _id: uuidv4(),
      image: image2,
      name: 'crypto-kitties',
      href: 'https://www.cryptokitties.co/',
    },
    {
      _id: uuidv4(),
      image: image3,
      name: 'crypto-heroes',
      href: 'https://www.mycryptoheroes.net/',
    },
    {
      _id: uuidv4(),
      image: image4,
      name: 'axie-infinity',
      href: 'https://axieinfinity.com/',
    },
    {
      _id: uuidv4(),
      image: image5,
      name: 'decentraland',
      href: 'https://decentraland.org/',
    },
    {
      _id: uuidv4(),
      image: image6,
      name: 'gods-unchained',
      href: 'https://godsunchained.com/',
    },
    // {
    //   _id: uuidv4(),
    //   withLongImage: true,
    //   image: image7,
    //   image_long: image7_long,
    //   name: 'dapp-arcade',
    //   videoOnHover: video_arcade,
    //   href: process.env.REACT_APP_DAPPARCADE_URL,
    // },
    {
      _id: uuidv4(),
      image: image8,
      name: 'coba',
      href: 'https://coba.zone/',
    },
    {
      _id: uuidv4(),
      image: image9,
      name: 'dragonereum',
      href: 'https://dragonereum.io/',
    },
    {
      _id: uuidv4(),
      image: image10,
      name: 'league-of-kingdoms',
      href: 'https://www.leagueofkingdoms.com/',
    },
  ]

  const otherChartsData = [
    {
      _id: uuidv4(),
      name: 'NFT',
      href: '/top-nft-charts'
    },
    {
      _id: uuidv4(),
      name: 'DeFi',
      href: '/top-defi-charts'
    },
    {
      _id: uuidv4(),
      name: 'Exchange',
      href: '/exchange'
    }
  ]

  return (
    <TopChartCommon 
      searchState={searchState}
      data={data}
      mainChartTitle="Game"
      otherChartsData={otherChartsData} 
    />
  );
}

export default GameCharts;