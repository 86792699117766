import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Ticker from 'react-ticker';
import { API_BASE_URL } from '../../constant/config';
import { Container } from '@material-ui/core';
import { ReactComponent as LevelDown } from '../../assets/images/icons/level-down.svg';
import { ReactComponent as LevelUp } from '../../assets/images/icons/level-up.svg';
import { ReactComponent as Gas } from '../../assets/images/icons/gas-pump.svg';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import { motion, AnimatePresence } from 'framer-motion';
import PageVisibility from 'react-page-visibility';
import { useWindowSize } from '../../constant/useWindowSize';

const animate = {
  hidden: {
    opacity: 0,
    transition: { duration: .5 }
  },
  visible: {
    opacity: 1,
    transition: { duration: .5 }
  },
  exit: {
    opacity: 0,
    transition: { duration: .5 }
  }
}

const SectionTicker = ({ searchState, setApiLoader, apiLoading }) => {
  const [news_data, set_news_data] = useState();
  const [market_data, set_market_data] = useState();

  const fetch_news = () => {
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/crypto/news`,
    }).then(result => {
      const cointelegraphFeed = result.data.results.data.cointelegraphFeed;
      const mediumFeed = result.data.results.data.mediumFeed;
      const mergeFeeds = cointelegraphFeed.concat(mediumFeed);
      set_news_data(mergeFeeds);
      setApiLoader(false);
    }).catch(error => {
      console.log(error);
      console.log(error.response);
      set_news_data();
      setTimeout(() => {
        setApiLoader(false);
      }, 2000)
    });
  };

  const fetch_market = () => {
    axios({
      method: 'GET',
      url: `${API_BASE_URL}/crypto/market`,
    }).then(result => {
      const data = result.data.results.data;
      set_market_data(data);
      setApiLoader(false);
    }).catch(error => {
      console.log(error);
      console.log(error.response);
      set_market_data();
      setTimeout(() => {
        setApiLoader(false);
      }, 2000)
    });
  };

  useEffect(() => {
    async function fetch_ricker() {
      setApiLoader(true);
      fetch_market();
      fetch_news();
      setTimeout(() => {
        setApiLoader(false);
      }, 2000)
    }
    fetch_ricker();
  }, []);

  const [mobileView, setMobileView] = useState(false);

  const [ window_width ] = useWindowSize();
  useEffect(() => {
    if(window_width < 960) setMobileView(true)
    else setMobileView(false)
  }, [window_width]);

  const [move_news, set_move_news] = useState(false);
  const [move_market, set_move_market] = useState(false);

  useEffect(() => {
    if (apiLoading) {
      setTimeout(() => {
        set_move_news(true)
        set_move_market(true)
      }, 4000)
    }
  }, [apiLoading])

  // Check if user is focus on the page ===================================
  // // User has switched back to the tab
  // const onFocus = () => {
  //   console.log('Tab is in focus');
  //   setTimeout(() => {
  //     set_move_news(true)
  //     set_move_market(true)
  //   }, 500)
  // };

  // // User has switched away from the tab (AKA tab is hidden)
  // const onBlur = () => {
  //   console.log('Tab is blurred');
  //   set_move_news(false)
  //   set_move_market(false)
  // };

  // useEffect(() => {
  //   window.addEventListener('focus', onFocus);
  //   window.addEventListener('blur', onBlur);
  //   // Specify how to clean up after this effect:
  //   return () => {
  //     window.removeEventListener('focus', onFocus);
  //     window.removeEventListener('blur', onBlur);
  //   };
  // });

  // const handleResize = () => {
  //   setApiLoader(true);
  //   setTimeout(() => {
  //     setApiLoader(false);
  //   }, 2000)
  // }

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize, false);
  // }, []);

  const [pageIsVisible, setPageIsVisible] = useState(true)

  const handleVisibilityChange = (isVisible) => {
    setPageIsVisible(isVisible)
  }


  const displayDesktop = () => {
    return (
      <>
        <div className="ticker-content market-ticker">
          <Container>
            <label className="category"><a href={`${mobileView ? "https://m.kucoin.com/markets/symbol/DAPPX-USDT" : "https://trade.kucoin.com/DAPPX-USDT"}`} target="_blank" rel="noreferrer">Market</a></label>
            <AnimatePresence exitBeforeEnter>
              {market_data &&
                <motion.div
                  key={market_data ? "hidden-market" : ""}
                  className="fade-container"
                  variants={animate}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <PageVisibility onChange={handleVisibilityChange}>
                    {pageIsVisible && (

                      <Ticker move={move_market} mode="smooth" speed={4}>
                        {() => {
                          let coins = market_data.coins;
                          let overall = market_data.overall;
                          let marketCap_negative = overall && overall.marketUpdatePercentage.includes("-");
                          return (<>
                            <ul className="market-list"
                              onMouseEnter={() => set_move_market(false)}
                              onMouseLeave={() => set_move_market(true)}>
                              {overall &&
                                <>
                                  <li>
                                    <label>Market Cap: </label>
                                    <span className="blue">{overall.marketCap}</span>
                                    {marketCap_negative &&
                                      <span className={`percentage ${marketCap_negative ? "down" : "up"}`}>
                                        {overall.marketUpdatePercentage}
                                        <LevelDown className="icon-down" />
                                        <LevelUp className="icon-up" />
                                      </span>
                                    }
                                  </li>
                                  <li>
                                    <label>24h Vol: </label>
                                    <span className="blue">{overall.vol24h}</span>
                                  </li>
                                  <li>
                                    <label>Dominance: </label>
                                    <span className="mr-8">BTC {overall.dominance.btc}</span>
                                    <span>ETH {overall.dominance.eth}</span>
                                  </li>
                                  <li>
                                    <label><Gas className="icon-gas" />ETH Gas: </label>
                                    <span>{overall.ethGas.gasAverage}</span>
                                  </li>
                                </>
                              }

                              {coins.length > 0 &&
                                coins.map((item) => {
                                  let coin_negative = item.price_change_percentage_24h > 0
                                  let coin_long_name = item.name === 'btc'
                                    ? 'Bitcoin'
                                    : item.name === 'eth'
                                      ? 'Ethereum'
                                      : item.name === 'bnb'
                                        ? 'Binance Coin'
                                        : item.name === 'dappx'
                                          ? 'dAppstore'
                                          : item.name === 'ace'
                                            ? 'Acent'
                                            : ''
                                  return (
                                    <li key={item.name}>
                                      <img src={item.images.small} alt={coin_long_name} />
                                      <label>{coin_long_name}</label>
                                      <span className="abbrev">({item.name.toUpperCase()})</span>
                                      <span className={`price ${coin_negative ? 'up' : 'down'}`}>
                                        {item.current_price}
                                        <span className={`percentage ${coin_negative ? 'up' : 'down'}`}>
                                          ({item.price_change_percentage_24h}%)
                                          <ArrowDropUp className="icon-up" />
                                          <ArrowDropDown className="icon-down" />
                                        </span>
                                      </span>
                                    </li>
                                  )
                                })}
                            </ul>
                          </>)
                        }}
                      </Ticker>

                    )}
                  </PageVisibility>
                </motion.div>
              }
              {!market_data &&
                <motion.div
                  key={market_data ? "" : "show-market"}
                  className="fade-container"
                  variants={animate}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <span className="no-data">No available info</span>
                </motion.div>
              }
            </AnimatePresence>
          </Container>
        </div>
        {/* <div className="ticker-content news-ticker">
          <Container>
            <label className="category">NEWS</label>
            {news_data &&
              <motion.div
                key={market_data ? "hidden-news" : ""}
                className="fade-container"
                variants={animate}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <PageVisibility onChange={handleVisibilityChange}>
                  {pageIsVisible && (

                    <Ticker move={move_news} mode="smooth" speed={4}>
                      {() => {
                        return (<>
                          {news_data.map((item) => {
                            return (
                              <a href={item.link} key={uuidv4()} target="_blank" rel="noreferrer" className="news-link"
                                onMouseEnter={() => set_move_news(false)}
                                onMouseLeave={() => set_move_news(true)}>
                                <span className="icon-text">[News] </span> {item.title}
                              </a>
                            )
                          })}
                        </>)
                      }}
                    </Ticker>

                  )}
                </PageVisibility>
              </motion.div>
            }
            {!news_data &&
              <motion.div
                key={news_data ? "" : "show-news"}
                className="fade-container"
                variants={animate}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <span className="no-data">No available info</span>
              </motion.div>
            }
          </Container>
        </div> */}
      </>
    )
  }

  const displayMobile = () => {
    return (
      <h1>Mobile</h1>
    )
  }

  return (
    <div className="section section-ticker">
      {displayDesktop()}
    </div>
  );
}

export default SectionTicker;