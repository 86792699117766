import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { DAPPX_BASE_URL } from '../constant/config';

export const useLogout = () => {

  let APP_URL = DAPPX_BASE_URL;
  const history = useHistory();
  
  const handleLogout = () => {
    axios.get(APP_URL + "/users/logout", {
      headers: {
        'session-id': localStorage.getItem('session-id'),
        'Content-Type': 'application/json'
      }
    })
      .then(function (data) {
        console.log('handleLogout: ', data.data);
        localStorage.setItem('loggedin', false);
        handleEventsAfterLoggingOut(history);
      })
      .catch(function (data) {
        console.log("handleLogout error: ", data);
        handleEventsAfterLoggingOut(history);
      });
  };

  const handleEventsAfterLoggingOut = () => {
    history.push("/");
    window.location.reload();
  };

  return handleLogout;
};
