import React from 'react';
import DappStoreLogo from '../../assets/images/dappstore-logo.png';
import SiteMaintenanceImg from '../../assets/images/site-maintenance.png';
import './index.scss';

const Maintenance = () => {
  return (
    <div className="maintenance-wrapper">
      <div className="maintenance-container">
        <div>
          <img src={SiteMaintenanceImg} alt="Site Maintenance" className="maintenance-img" />
        </div>
        <h4 className="title">Maintenance & Upgrade</h4>
        <p className="content">
          dAppstore is undergoing maintenance and upgrades to
          provide you a better experience!
          We apologize for the inconvenience this has caused.
          We will be back shortly.
          Thank you for your understanding.
        </p>
        <img src={DappStoreLogo} alt="dAppStore" className="dappstore-logo" />
      </div>
    </div>
  )
};

export default Maintenance;