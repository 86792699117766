import React from "react";
import "./index.scss"

const SearchBox = () => {

    return (
        <>
        <div className="search-box" >
            <input className="search-box__input" placeholder="Search items, collections and accounts" />
            <div className="search-box__btn">
            <i className="icon-magnifier" />
            </div>
        </div>
        </>
    )
}

export default SearchBox