const MoralisReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_MARKETPLACE_ITEMS_REQUEST":
      return {
        ...state,
        fetching: true,
      };
    case "FETCH_MARKETPLACE_ITEMS_SUCCESS":
      return {
        ...state,
        fetching: false,
        marketplaceItems: action.payload,
      };
    case "FETCH_MARKETPLACE_ITEMS_FAILED":
      return {
        ...state,
        fetching: false,
        error: action.payload.error,
      };
    case "LOGOUT":
      localStorage.clear();
      return state
    default: return state;
  }
}

export default MoralisReducer;