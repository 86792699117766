import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import Web3 from "web3";
import { isMobile } from "react-device-detect";
import { useHistory, Link } from "react-router-dom";
import {
  Button,
  Snackbar,
  IconButton,
  ClickAwayListener,
  Tooltip,
  useMediaQuery,
  useTheme,
  styled,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import DP_Placeholder from "../../assets/images/dappxpr/dp-placeholder.png";
import eth from "../../assets/images/dappxpr/ethereum.png";
import zera from "../../assets/images/dappxpr/zera.png";
import dappx from "../../assets/images/dappxpr/dappstore.png";
import acent from "../../assets/images/icons/acent-logo.svg";
import gate from "../../assets/images/icons/gate.io.png";
import kucoin from "../../assets/images/icons/kucoin.png";
import infoIcon from "../../assets/images/dappstore-info.png";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { IS_UAT, IS_SAT } from "../../constant/config";
import { AuthContext } from "../../context/AuthContext";
import { useWindowSize } from "../../constant/useWindowSize";
import axios from "axios";
import { Config } from "../../constant/config";
import ModalError from "../../components/top-nav/ModalErrorPopup";
import "./UserProfile.scss";
import { Transfer, Deposit, Pledge, Unpledge, Withdraw } from "./common";
import CustomDialog from "../../components/Modal/CustomDialog";
import AceButtonPledge from "./common/Pledge/AcePledge";
import { Popover } from "react-bootstrap";
import GovernanceUnPledge from "./common/Pledge/GovernanceUnPledge";
import { DAPPX_BASE_URL } from "../../constant/config";
import DappxWithdrawWrapper from "./common/Withdraw/DappxWithdrawWrapper";
import edao_logo from "../../assets/images/icons/edao-logo.svg";
import { useStyles } from "./ace";
import numberFormat from "../../constant/numberFormat";
import SideBarData from "../../components/UserSidebar/SideBarData";
import { useRef } from "react";
import { tooltipClasses } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { ReactComponent as SuccessIcon } from "../../assets/images/icons/success-check.svg";

const defaultFloatVal = "0.000000";
const displayDecimals = 6;

export const convertValue = async (balance, tokenCode) => {
  let convertion_currency = "USD";
  let return_value = "0.00";

  await axios
    .get(Config.DAPPX_BASE_URL + "/users/getMarketPrices")
    .then((prices) => {
      let fiat_currency = prices.data[tokenCode][convertion_currency]
        ? prices.data[tokenCode][convertion_currency]
        : 0;
      let fiat_value_value = parseFloat(balance) * parseFloat(fiat_currency);
      let convertion_value;

      if (fiat_value_value === 0) {
        convertion_value = parseFloat(fiat_value_value);
      } else {
        convertion_value = parseFloat(fiat_value_value).toFixed(2);
      }

      return_value = convertion_value;
    })
    .catch((error) => {
      console.log("convertValue error: ", error);
    });

  return return_value;
};

const Profile = () => {
  const isNative = localStorage.getItem("current_network") === '0x22b8' || localStorage.getItem('current_network') === "0x231d" ? true : false;

  const handleClickOpen = () => {
    setOpen(true);
    axios
      .get(Config.DAPPX_BASE_URL + "/users/getQRCode", {
        headers: {
          "session-id": sessionID,
        },
      })
      .then((res) => {
        setQrCode(
          `http://api.qrserver.com/v1/create-qr-code/?data=${res.data.code}`
        );
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Responsive
  const session_Id = localStorage.getItem("session-id");
  const [tabletView, setTabletView] = useState(false);
  const [userHasPledges, setUserHasPledges] = useState(false);
  const [isShow, setShow] = useState(false);
  const [window_width] = useWindowSize();
  const [qrCode, setQrCode] = useState("");
  const [open, setOpen] = React.useState(false);
  const [usdValue, setUsdValue] = useState(0);
  const [withdrawalMaitenance, setWithdrawalMatintenance] = useState(false);
  const [withdrawalOn, setWithdrawalOn] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const [marketPriceData, setMarketPriceData] = useState({});
  const [pre_open_modal, set_pre_open_modal] = useState(false);

  const myRef = useRef();

  const handleClickOutside = e => {
      if (!myRef?.current?.contains(e.target)) {
        set_pre_open_modal(false);
      }
  };

  const handleClickInside = () => set_pre_open_modal(false);

  useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
  });


  const popoverAcent = (
    <Popover
      className="pop1"
      id="popover-positioned-bottom"
      title="Popover bottom"
    >
      <div className="buydrpdn">
        <div className="drpheader">
          <h1>BUY ACENT from</h1>
        </div>
        <div className="drpbody">
          <div className="drpbtn1">
            <Button
              href="https://www.kucoin.com/ucenter/signup?rcode=rJCP33Y"
              target="_blank"
            >
              <span>
                <img src={kucoin} />
              </span>
            </Button>
          </div>
          <div className="drpbtn2">
            <Button
              className="button"
              href="https://www.gate.io/signup/8166666 "
              target="_blank"
            >
              <span>
                <img src={gate} />
              </span>
            </Button>
          </div>
        </div>
        {/*<div className="drpbdr">*/}
        {/*  <p className="dot"> . . . . . . . . . . . .</p>*/}
        {/*  <div className="drpor"> <p>or</p> </div>*/}
        {/*  <p className="dot"> . . . . . . . . . . . .</p>*/}
        {/*</div>*/}
        {/*<div className="drpftr">*/}
        {/*  <p>Easily Exchange ETH to ACE in dAppstore!</p>*/}
        {/*  <Button>BUY in dAppstore</Button>*/}
        {/*</div>*/}
      </div>
    </Popover>
  );
  const popoverDAPPX = (
    <Popover
      className="pop1"
      id="popover-positioned-bottom"
      title="Popover bottom"
    >
      <div className="buydrpdn">
        <div className="drpheader">
          <h1>BUY DAPPX from</h1>
        </div>
        <div className="drpbody">
          <div className="drpbtn1">
            <Button
              href="https://www.kucoin.com/ucenter/signup?rcode=rJCP33Y"
              target="_blank"
            >
              <span>
                <img src={kucoin} />
              </span>
            </Button>
          </div>
        </div>
        {/*<div className="drpbdr">*/}
        {/*  <p className="dot"> . . . . . . . . . . . .</p>*/}
        {/*  <div className="drpor"> <p>or</p> </div>*/}
        {/*  <p className="dot"> . . . . . . . . . . . .</p>*/}
        {/*</div>*/}
        {/*<div className="drpftr">*/}
        {/*  <p>Easily Exchange ETH to ACE in dAppstore!</p>*/}
        {/*  <Button>BUY in dAppstore</Button>*/}
        {/*</div>*/}
      </div>
    </Popover>
  );

  const convertCurrency = async () => {

    // setLoadData(true);
    await axios
      .get(DAPPX_BASE_URL + "/users/getMarketPrices")
      .then((res) => {
        // setLoadData(true);

        setMarketPriceData(res.data)
        setUsdValue(res.data.ACE.USD);
        // setUsdValue(res.data.ACE.USD);
        // setLoadData(false);
      })
      .catch((error) => {
        console.log("ERROR", error);
        // set_input_fiat_val(0)
        // setError("Can't pull conversion rate.")
      });
  };

  const nativeConverter = async (balance, tokenCode) => {

    if (!marketPriceData[tokenCode]) {
      return 0;
    }
    let fiat_currency = marketPriceData[tokenCode]["USD"]?? 0;
    let fiat_value_value = parseFloat(balance) * parseFloat(fiat_currency);

    let convertion_value;

    if (fiat_value_value === 0) {
      convertion_value = parseFloat(fiat_value_value);
    } else {
      convertion_value = parseFloat(fiat_value_value).toFixed(2);
    }
    return convertion_value;
  }

  useEffect(() => {
    convertCurrency();
    fetchWithdrawSettings();
    fetchWithdrawaLimit();
  }, []);

  const fetchQRCode = () => {
    axios
      .get(Config.DAPPX_BASE_URL + "/users/getQRCode", {
        headers: {
          "session-id": sessionID,
        },
      })
      .then((res) => {
        setQrCode(
          `http://api.qrserver.com/v1/create-qr-code/?data=${res.data.code}`
        );
      });
  };

  useEffect(() => {
    if (window_width < 1214) setTabletView(true);
    else setTabletView(false);
  }, [window_width]);

  const withdrawButton = () => {
    if (withdrawalOn) {
      setWithdrawModal(true);
    } else {
      setWithdrawalMatintenance(true);
    }
  };

  // Error modal
  const [sign_in_error, set_sign_in_error] = useState({
    icon: "",
    title: "",
    text: "",
    btn_label: "",
  });
  const [openError, setOpenError] = useState(false);
  const onOpenError = (icon, title, message, label) => {
    setOpenError(true);
    set_sign_in_error({
      icon: icon,
      title: title,
      text: message,
      btn_label: label,
    });
  };
  const onCloseError = () => {
    setOpenError(false);
    setTimeout(() => {
      set_sign_in_error({
        icon: "",
        title: "",
        text: "",
        btn_label: "",
      });
    }, 600);
  };
  // User Profile Info

  const isUserVerified = async () => {
    // console.log("check if data is verified");
    await axios
      .get(Config.DAPPX_BASE_URL + "/users/userProfile", {
        headers: {
          "session-id": sessionID,
        },
      })
      .then((res) => {
        setIsVerified(res.data.is_verified);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // State
  const { authState, dispatch } = useContext(AuthContext);
  let history = useHistory();
  const { wallet_balance, user_details } = authState;
  let user_username = user_details.username;
  let user_email = user_details.email;
  let user_ace = wallet_balance.find((x) => x.token === "ACE");
  let user_eth = wallet_balance.find((x) => x.token === "ETH");
  let user_dappx = wallet_balance.find((x) => x.token === "DAPPX");
  const [digital_dappx, set_digital_dappx] = useState({
    coin_value: "0.000000",
    fiat_value: "0.00",
  });

  // Wallet balance Loading
  const [loading, set_loading] = useState(true);
  useEffect(() => {
    if (wallet_balance.length > 0) {
      set_loading(false);
    }
  }, [wallet_balance.length]);

  // Functions
  const logout = () => {
    dispatch({ type: "LOGOUT" });
    history.push("/");
  };

  const getDappxTotalBalance = () => {
    axios
      .get(Config.DAPPX_BASE_URL + "/users/getMyDappxBalance", {
        headers: {
          "session-id": localStorage.getItem("session-id"),
        },
      })
      .then(async (data) => {
        let balance_wei = Web3.utils.fromWei(data.data.total_dappx, "ether");
        let bal = parseFloat(balance_wei).toFixed(6);
        let num_to_str = bal.toString();
        let _fiat_value = await nativeConverter(bal, "DAPPX");
        set_digital_dappx({ coin_value: num_to_str, fiat_value: _fiat_value });
      })
      .catch((error) => {
        set_digital_dappx({ coin_value: "0.000000", fiat_value: "0.00" });
      });
  };

  const getUserPledges = async () => {
    await axios
      .get(Config.DAPPX_BASE_URL + "/users/getPledgeBalance?token_type=DAPPX", {
        headers: { "session-id": session_Id },
      })
      .then((res) => {
        let balance_wei = Web3.utils.fromWei(res.data.dappx_balance, "ether");
        let num2 = Number(balance_wei.toString().match(/^\d+(?:\.\d{0,6})?/));
        Number(num2).toFixed(6) <= 0
          ? setUserHasPledges(false)
          : setUserHasPledges(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTransactions = () => {
    axios
      .get(Config.DAPPX_BASE_URL + "/users/getTransactions", {
        headers: {
          "session-id": localStorage.getItem("session-id"),
        },
      })
      .then(async (data) => {
      })
      .catch((error) => {
        console.log("ERROR getTransactions: ", error);
      });
  };

  useEffect(() => {
    isUserVerified();
    getDappxTotalBalance();
    getTransactions();
    getUserPledges();
    // check_pendingTransactions_interval()
    if (localStorage.getItem("current_network") === "null" && window.ethereum) {
      localStorage.setItem("current_network", window.ethereum.chainId);
      onOpenError(
        "",
        "Wallet Network Error",
        "Unable to detect what network you are in! Please reload the page.",
        "OK"
      );
    }
  }, []);

  // Data
  let data_wallet_bal = [
    user_ace && {
      _id: uuidv4(),
      icon: acent,
      coin: "ACE",
      coin_value: user_ace.value ? user_ace.value : "NaN",
      convertion_currency: user_ace.currency === "USD" ? "USD" : "",
      coin_convertion: user_ace.fiat_value ? user_ace.fiat_value : "NaN",
    },
    user_dappx && {
      _id: uuidv4(),
      icon: dappx,
      coin: "DAPPX",
      coin_value: user_dappx.value ? user_dappx.value : "NaN",
      convertion_currency: user_dappx.currency === "USD" ? "USD" : "",
      coin_convertion: user_dappx.fiat_value ? user_dappx.fiat_value : "NaN",
    },
    user_eth && {
      _id: uuidv4(),
      icon: eth,
      coin: "ETH",
      coin_value: user_eth.value ? user_eth.value : "NaN",
      convertion_currency: user_eth.currency === "USD" ? "USD" : "",
      coin_convertion: user_eth.fiat_value ? user_eth.fiat_value : "NaN",
    },
  ];
  let format_wallet_bal = data_wallet_bal.filter((item) => item !== undefined);

  // Copy Function
  const [copySuccess, setCopySuccess] = useState();
  const copyToClipboard = (text) => {
    setCopySuccess("Copied to clipboard!");
  };
  let URL_BASE = IS_SAT
    ? "https://play-sat.dappstore.me"
    : IS_UAT
      ? "https://play-uat.dappstore.me"
      : "https://dappstore.me";

  function wallet_ellipsis(str) {
    return str.substr(0, 6) + "..." + str.substr(str.length - 4, str.length);
  }

  // Zera Section
  const sessionID = localStorage.getItem("session-id");

  const computeDecimals = 12;
  const defaultDecimalVal = "0.000000";
  const [loadData, setLoadData] = useState(true);
  const [openZeraTransfer, setOpenZeraTransfer] = useState(false);
  const [zeraData, setZeraData] = useState({
    mainBal: defaultDecimalVal,
    pocketBal: defaultDecimalVal,
  });
  const [digital_zera, set_digital_zera] = useState({
    coin_value: defaultDecimalVal,
    fiat_value: "0.00",
  });
  const handleOpenZeraTransfer = () => {
    setLoadData(true);
    setOpenZeraTransfer(true);
  };
  const handleCloseZeraTransfer = () => {
    setOpenZeraTransfer(false);
  };

  const getZeraBalance = async () => {
    const [vault, ingame] = await Promise.all([
      axios
        .get(Config.DAPPX_BASE_URL + "/users/zera/main/balance", {
          headers: {
            "session-id": sessionID,
          },
        })
        .catch((error) => error),
      axios
        .get(Config.DAPPX_BASE_URL + "/users/zera/pocket/balance", {
          headers: {
            "session-id": sessionID,
          },
        })
        .catch((error) => error),
    ]);

    if (
      (vault && vault.status === 200 && vault.data) ||
      (ingame && ingame.status === 200 && ingame.data)
    ) {
      const mainBal =
        vault && vault.data ? vault.data.zera_balance : defaultDecimalVal;
      const pocketBal =
        ingame && ingame.data ? ingame.data.zera_balance : defaultDecimalVal;
      const total = (parseFloat(mainBal) + parseFloat(pocketBal)).toFixed(
        computeDecimals
      );
      const whole_num = total.split(".")[0];
      const decimal_num = total.split(".")[1].substr(0, displayDecimals);
      const balance = whole_num + "." + decimal_num;

      setZeraData({
        ...zeraData,
        mainBal:
          vault && vault.data
            ? vault.data.zera_balance.toFixed(computeDecimals)
            : defaultDecimalVal,
        pocketBal:
          ingame && ingame.data
            ? ingame.data.zera_balance.toFixed(computeDecimals)
            : defaultDecimalVal,
      });

      set_digital_zera({
        ...digital_zera,
        coin_value: balance,
      });
    }

    setLoadData(false);
  };

  // Acent Section
  const [openAcentTransfer, setOpenAcentTransfer] = useState(false);
  const [openDappxTransfer, setOpenDappxTransfer] = useState(false);
  const [withdrawLimit, setWithdrawLimit] = useState(0);
  const [vaultFiat, setVaultFiat] = useState(0);
  const [vaultDappxFiat, setVaultDappxFiat] = useState(0);
  const [nitroFee, setNitroFee] = useState(0);
  const [ubiFee, setUbiFee] = useState(0);
  const [coreFee, setCoreFee] = useState(0);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [acentData, setAcentData] = useState({
    mainBal: defaultDecimalVal,
    pocketBal: defaultDecimalVal,
    mainBal_fiat_value: defaultDecimalVal,
    pocketBal_fiat_value: defaultDecimalVal,
  });
  const [dappxData, setDappxData] = useState({
    mainBal: defaultDecimalVal,
    pocketBal: defaultDecimalVal,
    mainBal_fiat_value: defaultDecimalVal,
    pocketBal_fiat_value: defaultDecimalVal,
  });
  const [edaoData, setEdaoData] = useState({
    mainBal: defaultDecimalVal,
    pocketBal: defaultDecimalVal,
    mainBal_fiat_value: defaultDecimalVal,
    pocketBal_fiat_value: defaultDecimalVal,
  });
  const [digital_ace, set_digital_ace] = useState({
    coin_value: defaultDecimalVal,
    fiat_value: "0.00",
  });

  const handleDappxTransferClose = () => {
    setOpenDappxTransfer(false);
  };

  const handleOpenAcentTransfer = () => {
    setOpenAcentTransfer(true);
  };
  const handleCloseAcentTransfer = () => {
    setLoadData(true);
    setOpenAcentTransfer(false);
  };

  const getAceBalance = async () => {
    const [vault, active] = await Promise.all([
      axios
        .get(Config.DAPPX_BASE_URL + "/users/acent/vault/balance", {
          headers: {
            "session-id": sessionID,
          },
        })
        .catch((error) => error),
      axios
        .get(Config.DAPPX_BASE_URL + "/users/acent/active/balance", {
          headers: {
            "session-id": sessionID,
          },
        })
        .catch((error) => error),
    ]);

    if (
      (vault && vault.status === 200 && vault.data) ||
      (active && active.status === 200 && active.data)
    ) {
      const mainBal =
        vault && vault.data ? vault.data.balance : defaultDecimalVal;
      const pocketBal =
        active && active.data ? active.data.balance : defaultDecimalVal;
      const total = (parseFloat(mainBal) + parseFloat(pocketBal)).toFixed(
        computeDecimals
      );
      const whole_num = total.split(".")[0];
      const decimal_num = total.split(".")[1].substr(0, displayDecimals);
      const balance = whole_num + "." + decimal_num;
      const mainBal_fiat_value = await nativeConverter(mainBal, "ACE");
      const pocketBal_fiat_value = await nativeConverter(pocketBal, "ACE");
      setAcentData({
        ...acentData,
        mainBal_fiat_value,
        pocketBal_fiat_value,
        mainBal:
          vault && vault.data
            ? vault.data.balance.toFixed(computeDecimals)
            : defaultDecimalVal,
        pocketBal:
          active && active.data
            ? active.data.balance.toFixed(computeDecimals)
            : defaultDecimalVal,
      });

      const convertVaultFiat = await nativeConverter(mainBal, "ACE");
      setVaultFiat(convertVaultFiat);

      const fiat_value = await nativeConverter(balance, "ACE");
      set_digital_ace({
        ...digital_ace,
        coin_value: balance,
        fiat_value: fiat_value,
      });
    }

    setLoadData(false);
  };

  const getDappxBalance = async () => {
    const [vault, active] = await Promise.all([
      axios
        .get(Config.DAPPX_BASE_URL + "/users/dappx/vault/balance", {
          headers: {
            "session-id": sessionID,
          },
        })
        .catch((error) => error),
      axios
        .get(Config.DAPPX_BASE_URL + "/users/dappx/active/balance", {
          headers: {
            "session-id": sessionID,
          },
        })
        .catch((error) => error),
    ]);

    if (
      (vault && vault.status === 200 && vault.data) ||
      (active && active.status === 200 && active.data)
    ) {
      const mainBal =
        vault && vault.data ? vault.data.balance : defaultFloatVal;
      const pocketBal =
        active && active.data ? active.data.balance : defaultFloatVal;
      const total = (parseFloat(mainBal) + parseFloat(pocketBal)).toFixed(
        computeDecimals
      );
      const whole_num = total.split(".")[0];
      const decimal_num = total.split(".")[1].substr(0, displayDecimals);
      const balance = whole_num + "." + decimal_num;
      const mainBal_fiat_value = await nativeConverter(mainBal, "DAPPX");
      const pocketBal_fiat_value = await nativeConverter(pocketBal, "DAPPX");
      setDappxData({
        ...dappxData,
        mainBal:
          vault && vault.data
            ? vault.data.balance.toFixed(computeDecimals)
            : defaultFloatVal,
        pocketBal:
          active && active.data
            ? active.data.balance.toFixed(computeDecimals)
            : defaultFloatVal,
        mainBal_fiat_value,
        pocketBal_fiat_value,
      });

      const convertVaultFiat = await nativeConverter(mainBal, "DAPPX");
      setVaultDappxFiat(convertVaultFiat);

      const fiat_value = await nativeConverter(balance, "DAPPX");
      set_digital_dappx({
        ...digital_dappx,
        coin_value: balance,
        fiat_value: fiat_value,
      });
    }
  };

  const getEdaoBalance = async () => {
    const [vault, active] = await Promise.all([
      axios
        .get(Config.DAPPX_BASE_URL + "/users/edao/vault/balance", {
          headers: {
            "session-id": sessionID,
          },
        })
        .catch((error) => error),
      axios
        .get(Config.DAPPX_BASE_URL + "/users/edao/active/balance", {
          headers: {
            "session-id": sessionID,
          },
        })
        .catch((error) => error),
    ]);

    if (
      (vault && vault.status === 200 && vault.data) ||
      (active && active.status === 200 && active.data)
    ) {
      const mainBal =
        vault && vault.data ? vault.data.balance : defaultFloatVal;
      const pocketBal =
        active && active.data ? active.data.balance : defaultFloatVal;
      const total = (parseFloat(mainBal) + parseFloat(pocketBal)).toFixed(
        computeDecimals
      );
      const whole_num = total.split(".")[0];
      const decimal_num = total.split(".")[1].substr(0, displayDecimals);
      const balance = whole_num + "." + decimal_num;

      setEdaoData({
        ...data,
        mainBal:
          vault && vault.data
            ? vault.data.balance.toFixed(computeDecimals)
            : defaultFloatVal,
        pocketBal:
          active && active.data
            ? active.data.balance.toFixed(computeDecimals)
            : defaultFloatVal,
      });
    }
  };

  const fetchWithdrawaLimit = async () => {
    // const tokenType = isNative ? 'native' : 'token'
    await axios
      .get(Config.DAPPX_BASE_URL + `/users/acent/withdraw/limit-status/`, {
        headers: { "session-id": sessionID },
      })
      .then((res) => {
        console.log('LIMITs', res.data)
        if (
          res.data.remaining_daily_tx_amount >
          res.data.remaining_user_daily_tx_amount
        ) {
          setWithdrawLimit(res.data.remaining_user_daily_tx_amount);
        } else {
          setWithdrawLimit(res.data.remaining_daily_tx_amount);
        }
      });
  };

  const fetchWithdrawSettings = async () => {
    // const tokenType = isNative ? 'native' : 'token'
    await axios
      .get(Config.DAPPX_BASE_URL + `/users/acent/withdraw/settings/`, {
        headers: { "session-id": sessionID },
      })
      .then((res) => {
        setCoreFee(res.data.withdrawal_fee_core);
        setUbiFee(res.data.withdrawal_fee_ubi);
        setNitroFee(res.data.withdrawal_fee_nitro);
        setWithdrawalOn(res.data.allow_withdrawal);
      });
  };

  const showDropdown = (e) => {
    setShow(!isShow);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  const builtInTheme = useTheme();
  const isSM = useMediaQuery(builtInTheme.breakpoints.down("sm"));

  useEffect(() => {
    if (Object.keys(marketPriceData).length >0) {
      getZeraBalance();
      getAceBalance();
      getDappxBalance();
      getEdaoBalance();
    }
  }, [marketPriceData]);
  // }, [loadData]);

  const data = {
    username: user_username ? user_username : "Unnamed",
    image: "",
    wallet_address: localStorage.getItem("wallet_address")
      ? localStorage.getItem("wallet_address")
      : logout(),
    referral_code: localStorage.getItem("referral_code")
      ? localStorage.getItem("referral_code")
      : logout(),
    acent_balance: {
      _id: uuidv4(),
      icon: acent,
      coin: "ACE",
      coin_value: digital_ace.coin_value,
      convertion_currency: "USD",
      coin_convertion: digital_ace.fiat_value,
    },
    zera_balance: {
      _id: uuidv4(),
      icon: zera,
      coin: "Zera",
      coin_value: digital_zera.coin_value,
      convertion_currency: "DAPPX",
      coin_convertion: "—",
    },
    dappx_balance: {
      _id: uuidv4(),
      icon: dappx,
      coin: "DAPPX",
      coin_value: digital_dappx.coin_value,
      convertion_currency: "USD",
      coin_convertion: digital_dappx.fiat_value,
      // other_values: [
      //   {
      //     _id: uuidv4(),
      //     label: "Pledged",
      //     amount: "0.00000000"
      //   },
      //   {
      //     _id: uuidv4(),
      //     label: "Unpledged/Withdrawable",
      //     amount: "0.00000000"
      //   }
      // ]
    },
    eth_balance: {
      _id: uuidv4(),
      icon: eth,
      coin: "ETH",
      coin_value: "0.000000",
      convertion_currency: "USD",
      coin_convertion: "0.00",
    },
    wallet_balance: format_wallet_bal,
  };
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      padding: '10px 20px',
      fontSize: '14px'
    },
  }));

  const onCloseSuccessModal = () => {
    setSuccessModal(false);
    setWithdrawModal(false);
  };
  return (
    <div className=" user-sidebar-wrapper user-sidebar-wrapper-v2">
      <div className="content content-v2">
        <>
          <div className="profile-content">
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              autoHideDuration={2000}
              open={copySuccess ? true : false}
              onClose={() => setCopySuccess()}
              // message={copySuccess}
              key={uuidv4()}
            >
              <Alert severity="success">{copySuccess}</Alert>
            </Snackbar>

            <div className="section s1">
              <div className="img-container">
                <img src={DP_Placeholder} alt="place your cool face here" />
                <h3 className="title">{data.username}</h3>
              </div>
              <div className="details">
                <div className="name">
                  <div className="detail-top"></div>
                </div>
                <div>
                </div>
              </div>
            </div>
            <div className="section s2">
              <div className="balance-transaction-history">
                <h3 className="title">My Digital Assets</h3>
                {/* <TransactionHistoryDrawer /> */}
              </div>

              {data.dappx_balance && (
                <div
                  className="balance-block"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CoinBlock
                    icon={data.dappx_balance.icon}
                    name={data.dappx_balance.coin}
                    value={data.dappx_balance.coin_value}
                    convertion={data.dappx_balance.coin_convertion}
                    convertion_currency={data.dappx_balance.convertion_currency}
                    other_values={data.dappx_balance.other_values}
                    data={dappxData}
                  // isTooltipActive={has_pending_dappx}
                  />
                  <div className="buttons-block buttons-block-v2">
               
                    {!isMobile && (
                      <>
                        <Deposit
                          tokenType="DAPPX"
                          balance={user_dappx}
                          digital_balance={digital_dappx}
                          notDisabled={true}
                        />
                        <Button
                          variant="outlined"
                          onClick={() => setOpenDappxTransfer(true)}
                        // disabled
                        >
                          Transfer
                        </Button>
                        <DappxWithdrawWrapper
                          vaultAceBalance={acentData.mainBal}
                          vaultAceFiat={vaultFiat}
                          isVerified={isVerified}
                        />
                        <Pledge balance={user_dappx} />
                        <Unpledge
                          balance={user_dappx}
                          disableButton={!userHasPledges}
                        />
                      </>
                    )}
                  </div>
                </div>
              )}

              {data.acent_balance && (
                <div
                  className="balance-block"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CoinBlock
                    icon={data.acent_balance.icon}
                    name={data.acent_balance.coin}
                    value={data.acent_balance.coin_value}
                    convertion={data.acent_balance.coin_convertion}
                    convertion_currency={data.acent_balance.convertion_currency}
                    other_values={data.acent_balance.other_values}
                    // isTooltipActive={has_pending_dappx}
                    data={acentData}
                  />
                  <div
                    className="buttons-block buttons-block-v2"
                    style={{ display: "flex" }}
                  >
                    {/* <OverlayTrigger trigger={[ 'click']} rootClose placement="bottom" overlay={popoverAcent}>
                    <Button className="buybutton"
                            variant="outlined"
                            component="a">
                      <img src={plusCircle} alt='' style={{marginRight: '3.5px'}}/>
                      Top Up
                    </Button>
                  </OverlayTrigger> */}
                    <Deposit
                      tokenType="ACE"
                      balance={user_ace}
                      digital_balance={digital_ace}
                      notDisabled={true}
                    />
                    <Button variant="outlined" onClick={handleOpenAcentTransfer}>
                      Transfer
                    </Button>
                    <div className="option-item-area-main option-item-area-main-f"> 
                    {isVerified && (
       <Button
       variant="outlined"
       // onClick={() => setWithdrawModal(true)}
       onClick={withdrawButton}
       disabled={!isVerified}
     >
       Withdraw
     </Button>
      )}
      {!isVerified && (
        <HtmlTooltip title="Verify your email to enable withdrawal.">
          <span>
            <Button
              variant="outlined"
              // onClick={() => setWithdrawModal(true)}
              onClick={withdrawButton}
              disabled={!isVerified}
              className='disabled-with-info-btn'
            >
              Withdraw
              <InfoIcon />
            </Button>
          </span>
        </HtmlTooltip>
      )}


                    
{/* <Button
                      variant="outlined"
                      // onClick={() => setWithdrawModal(true)}
                      onClick={withdrawButton}
                      disabled={!isVerified}
                    >
                      Withdraw
                    </Button>
                    {!isVerified && (
  <>
  <button className="question-btn" onClick={() => set_pre_open_modal(true)}>
        !
      </button>

      <div className={`option-item-area ${pre_open_modal ? 'option-item-area-active' : ''}`} ref={myRef}>
                   <p>Verify your email to enable withdrawal.</p>
                  </div>
  
  </>
)} */}
                     </div>
                   
                    <AceButtonPledge balance={user_dappx} check={false} />
                    <GovernanceUnPledge check={false} />
                  </div>
                </div>
              )}
              {data.zera_balance && (
                <div
                  className="balance-block"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CoinBlock
                    icon={data.zera_balance.icon}
                    name={data.zera_balance.coin}
                    value={data.zera_balance.coin_value}
                    convertion={"0.00"}
                    data={zeraData}
                  />
                  {!isSM && (
                    <div className="buttons-block buttons-block-v2 zera-btns">
                      <Button variant="outlined" onClick={handleOpenZeraTransfer}>
                        Transfer
                      </Button>
                      {/* <Button variant="outlined" color="primary" disabled>
                      Swap
                    </Button> */}
                    </div>
                  )}
                </div>
              )}

              {data.eth_balance && (
                <div
                  className="balance-block"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CoinBlock
                    icon={edao_logo}
                    name={"EDAO"}
                    value={"0.00"}
                    convertion={"0.00"}
                    data={edaoData}
                  />
                  <div
                    className="buttons-block buttons-block-v2"
                    style={{ display: "flex" }}
                  >
                    <Pledge disableButton />
                    <Unpledge disableButton />
                  </div>
                </div>
              )}
            </div>
            
          </div>
          <ModalError
            open={openError}
            onClose={onCloseError}
            modal_icon={sign_in_error.icon}
            modal_text={sign_in_error.text}
            modal_title={sign_in_error.title}
            modal_btn_label={sign_in_error.btn_label}
            className="wallet-network-error-modal"
          />

       

          <Transfer
            token="zera"
            open={openZeraTransfer}
            handleClose={handleCloseZeraTransfer}
            data={zeraData}
            decimals={displayDecimals}
          />

          <Transfer
            token="acent"
            open={openAcentTransfer}
            handleClose={handleCloseAcentTransfer}
            data={acentData}
            decimals={displayDecimals}
          />

          <Transfer
            token="dappx"
            open={openDappxTransfer}
            handleClose={handleDappxTransferClose}
            data={dappxData}
            decimals={displayDecimals}
          />
          <Withdraw
            vaultBalance={acentData.mainBal}
            withdrawLimit={withdrawLimit}
            nitroFee={nitroFee}
            ubiFee={ubiFee}
            coreFee={coreFee}
            usdValue={usdValue}
            vaultFiat={vaultFiat}
            checkOpen={withdrawModal}
            onSuccess={() => setSuccessModal(true)}
            onClose={() => setWithdrawModal(false)}
          />
          <CustomDialog
            open={withdrawalMaitenance}
            header={<p>Service Maintenance</p>}
            body={
              <div className="mobile-modal-conatainer">
                <img src={infoIcon} alt="" className="mobile-modal-logo" />
                <p className="policy-modal-body" style={{ marginRight: "-23%" }}>
                  Service is not available at the moment.
                  <br />
                  Please try again later. Thank you.
                </p>
              </div>
            }
            footer={
              <div className="policy-button-container">
                <button
                  className="policy-button"
                  onClick={() => setWithdrawalMatintenance(false)}
                >
                  Ok
                </button>
              </div>
            }
          />
          <CustomDialog
          open={successModal}
          className="deposit-dialog loading-dialog"
          onClose={onCloseSuccessModal}
          header="Your withdraw is on it's way!"
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          body={
            <>
              <SuccessIcon className="icon" />
              <p>
                Updated status will be reflected on your wallet transaction
                history.
              </p>

              <p>
                For successful transaction, reflecting the balance on your
                dAppstore Balances may take longer than usual.
              </p>
            </>
          }
          footer={
            <>
              <div className="button-block">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={onCloseSuccessModal}
                  type="submit"
                >
                  OK
                </Button>
              </div>
            </>
          }
        />
        </>
      </div>
      <SideBarData/>
    </div>

  );
};

const CoinBlock = ({
  icon,
  name,
  value,
  convertion,
  convertion_currency,
  other_values,
  isTooltipActive,
  data,
}) => {
  const classes = useStyles();
  let whole_num_1 = value
    ? value.split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "";
  let decimal_num_1 = value ? value.split(".")[1] : "";

  // Tooltip
  const [open_tooltip, set_tooltip] = useState(false);
  const onOpen_tooltip = () => set_tooltip(true);
  const onClose_tooltip = () => set_tooltip(false);
  const formatIntegers = (val) => {
    return new Intl.NumberFormat().format(val);
  };

  const dappxAce =
    name === "DAPPX" || name === "ACE" || name === "Zera" || name === "EDAO";

  return (
    <div className="coin-block">
      <div className="block block-01">
        <div className="coin-name">
          <div className="coin-logo">
            <Link to={`/user-profile/${name.toLowerCase()}`}>
              <img src={icon} alt={name} />
            </Link>
          </div>
          <Link to={`/user-profile/${name.toLowerCase()}`}>
            <span
              style={{
                display: "block",
                textAlign: "start",
                fontWeight: 700,
                fontSize: "1.25rem",
                textDecoration: "underline",
                color: "#212121",
              }}
            >
              {name}
            </span>
          </Link>

          <div className="coin-label">
            {isTooltipActive && (
              <ClickAwayListener onClickAway={onClose_tooltip}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={onClose_tooltip}
                  open={open_tooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                  placement="right"
                  title={
                    <div className="tooltip-content">
                      <p>You have pending transaction.</p>
                    </div>
                  }
                >
                  <IconButton
                    onClick={onOpen_tooltip}
                    className={`info-btn ${open_tooltip ? "open" : ""}`}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            )}
          </div>
        </div>

        {dappxAce && (
          <>
            <div className="coin-value">
              <span
                style={{
                  display: "block",
                  textAlign: "start",
                  fontWeight: 700,
                }}
              >
                Active
              </span>
              <span>{numberFormat(data.pocketBal, displayDecimals)}</span>
              <span className="convertion">
                {convertion_currency == "USD" ? "$ " : ""}
                {/* {data.pocketBal_fiat_value} */}
                {data.hasOwnProperty("pocketBal_fiat_value") &&
                  Number(data.pocketBal_fiat_value).toFixed(2)}
                {/* {convertion_currency} */}
              </span>
            </div>

            <div className="coin-value">
              <span
                style={{
                  display: "block",
                  textAlign: "start",
                  fontWeight: 700,
                }}
              >
                Vault
              </span>

              <span>{numberFormat(data.mainBal, displayDecimals)}</span>
              <span className="convertion">
                {convertion_currency == "USD" ? "$ " : ""}
                {data.hasOwnProperty("mainBal_fiat_value") &&
                  Number(data.mainBal_fiat_value).toFixed(2)}
                {/* {convertion_currency} */}
              </span>
            </div>
          </>
        )}

        {!dappxAce && (
          <div className="coin-value">
            <span
              style={{ display: "block", textAlign: "start", fontWeight: 400 }}
            >
              {name}
            </span>
            <span>{whole_num_1}</span>
            {decimal_num_1 == 0 ? (
              ""
            ) : (
              <>
                <b>.</b>
                {decimal_num_1}
              </>
            )}
            <span className="convertion">
              {convertion} {convertion_currency}
            </span>
          </div>
        )}
      </div>
      
    </div>
  );
};

export default Profile;
