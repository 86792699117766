import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Config } from '../../constant/config';
import {ReactComponent as ArrowDown} from '../../assets/images/icons/arrow-down.svg';
import './TransactionPreview.scss';

const TransactionPreview = ({
  bal_1_title,
  bal_2_title,
  current_bal_1,
  current_bal_2,
  after_bal_1,
  after_bal_2,
  error_message,
  token_code,
  open
}) => {
  let current_whole_num_bal_1 = current_bal_1 ? current_bal_1.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
  let current_decimal_num_bal_1 = current_bal_1 ? current_bal_1.split('.')[1] : '';
  let current_whole_num_bal_2 = current_bal_2 ? current_bal_2.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
  let current_decimal_num_bal_2 = current_bal_2 ? current_bal_2.split('.')[1] : '';
  let after_whole_num_bal_1 = after_bal_1 ? after_bal_1.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
  let after_decimal_num_bal_1 = after_bal_1 ? after_bal_1.split('.')[1] : '';
  let after_whole_num_bal_2 = after_bal_2 ? after_bal_2.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
  let after_decimal_num_bal_2 = after_bal_2 ? after_bal_2.split('.')[1] : '';
  const [currencies, set_currencies] = useState([])

  const convertValue = async (balance, tokenCode) => {
    let convertion_currency = 'USD';

    await axios.get(Config.DAPPX_BASE_URL + '/users/getMarketPrices')
    .then((prices) => {
      let array_of_currency = [];
      balance.map(i => {
        let fiat_currency = prices.data[tokenCode][convertion_currency] ? prices.data[tokenCode][convertion_currency] : 0;
        let fiat_value_value = parseFloat(i) * parseFloat(fiat_currency);
        let convertion_value = parseFloat(fiat_value_value).toFixed(2);

        // if (fiat_value_value === 0) {
        //   convertion_value = parseFloat(fiat_value_value)
        // } else {
        //   convertion_value = parseFloat(fiat_value_value).toFixed(2)
        // }

        array_of_currency.push(convertion_value)
      })

      set_currencies(array_of_currency)
    })
    .catch((error) => {
      console.log(error)
      error_message("Can't pull conversion rate.")
      set_currencies([])
    })
  }

  useEffect(() => {
    let array_of_balances = [current_bal_1, current_bal_2, after_bal_1, after_bal_2]
    if(open){
      convertValue(array_of_balances, token_code)
    }
  }, [open])

  return (
    <div className="transaction-preview">
      <div className="block current">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{bal_1_title}</th>
              <th>{bal_2_title}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-left">Current</td>
              <td>
                <div className="amount">{current_whole_num_bal_1}<span>{current_decimal_num_bal_1 ?<span><b>.</b>{current_decimal_num_bal_1}</span> : ''}</span></div>
                <div className="currency">{currencies.length ? currencies[0] : '0.00'} USD</div>
              </td>
              <td>
                <div className="amount">{current_whole_num_bal_2}<span>{current_decimal_num_bal_2 ? <span><b>.</b>{current_decimal_num_bal_2}</span> : ''}</span></div>
                <div className="currency">{currencies.length ? currencies[1] : '0.00'} USD</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="arrow-block">
        <ArrowDown/>
      </div>
      <div className="block after">
      <table>
          <thead>
            <tr>
              <th></th>
              <th>{bal_1_title}</th>
              <th>{bal_2_title}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-left">After</td>
              <td>
                <div className='amount'>{after_whole_num_bal_1}<span>{after_decimal_num_bal_1 ? <span><b>.</b>{after_decimal_num_bal_1}</span> : ''}</span></div>
                <div className="currency">{currencies.length ? currencies[2] : '0.00'} USD</div>
              </td>
              <td>
                <div className="amount">{after_whole_num_bal_2}<span>{after_decimal_num_bal_2 ? <span><b>.</b>{after_decimal_num_bal_2}</span> : ''}</span></div>
                <div className="currency">{currencies.length ? currencies[3] : '0.00'} USD</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TransactionPreview;
