import React, { useState, useEffect } from 'react';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { useWindowSize } from '../../constant/useWindowSize';
import './UserProfile.scss';

const UserProfile = ({ match }) => {
  const [mobileView, setMobileView] = useState(false);

  const [ window_width ] = useWindowSize();
  useEffect(() => {
    if(window_width < 960) setMobileView(true)
    else setMobileView(false)
  }, [window_width]);

  return (
    <div className="page-content user-content">
      <>
      {mobileView 
      ? <Mobile 
        match={match}
      /> 
      : <Desktop 
        match={match}
      />}
      </>
     
    </div>
  );
}

export default UserProfile;