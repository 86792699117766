import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

const Spinner = ({size}) => {
  return (
    <Box sx={{ display: 'flex'}} >
      <CircularProgress size={size}/>
    </Box>
  )
}

export default Spinner
