import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Modal,
  Backdrop,
  Zoom,
  FormGroup,
  OutlinedInput,
  Button,
  Fade,
  IconButton,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Loader from "../../components/loader/Loader";
import { ReactComponent as Icon } from "../../assets/images/icons/functional.svg";
import { ReactComponent as Warning } from "../../assets/images/icons/warning.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close.svg";
import "./ModalDappxPR.scss";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const ModalCreateAccount = ({
  open,
  onClose,
  public_address,
  handleSignup,
  create_acc_error,
  set_create_acc_error,
  disableButton,
}) => {
  // Loading
  const [loading_alert, set_loading_alert] = useState(false);
  const onClose_loading_alert = () => set_loading_alert(false);
  const onOpen_loading_alert = () => set_loading_alert(true);

  // Error
  const [disable_btn, set_disable_btn] = useState(false);
  const [error_alert, set_error_alert] = useState(false);
  const [outline_error, set_outline_error] = useState([]);
  const [error_message, set_error_message] = useState();
  const onClose_error_alert = () => {
    set_error_alert(false);
    set_error_message();
  };
  const onOpen_error_alert = () => set_error_alert(true);

  const [values, setValues] = useState({
    email_id: "",
    referral_by: "",
  });

  let query = useQuery();

  // useEffect(() => {
  //   if(query.get('ref')) {
  //     setValues({
  //       ...values,
  //       referral_by: query.get('ref')
  //     })
  //   }
  // }, []);

  const onChangeEmail = () => (event) => {
    // comment out email validations

    // let regex = /^(([^<>()\[\]\\.,;:\s@�"+]+(\.[^<>()\[\]\\.,;:\s@�"+]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // let inputVal = event.target.value;
    // let at_dash = inputVal.includes('@-')
    // let double_quote = inputVal.includes(`"`);
    // let single_quote = inputVal.includes(`'`);

    onClose_error_alert();
    set_error_message();

    onClose_error_alert();
    setValues({ ...values, email_id: event.target.value });

    let update_outline_error = outline_error.filter((e) => e !== "email");
    set_outline_error(update_outline_error);

    // if (regex.test(inputVal) && !at_dash && !double_quote && !single_quote) {
    //   set_disable_btn(false)
    // } else if (inputVal === '') {
    //   set_disable_btn(false)
    // } else{
    //   set_disable_btn(true)
    // }
  };

  const onChangeReferral = () => (event) => {
    onClose_error_alert();
    set_error_message();

    onClose_error_alert();
    setValues({ ...values, referral_by: event.target.value });

    let update_outline_error = outline_error.filter((e) => e !== "referral");
    set_outline_error(update_outline_error);
  };

  useEffect(() => {
    if (create_acc_error) {
      execute_error();
      set_create_acc_error();

      let ref_err = create_acc_error.toLowerCase().includes("referral");
      let email_err = create_acc_error.toLowerCase().includes("email");
      let error_arr = [];
      if (ref_err) error_arr.push("referral");
      if (email_err) error_arr.push("email");
      set_outline_error(error_arr);
    }
  }, [create_acc_error]);
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const onSubmit = (e) => {
    e.preventDefault();
   

    console.log("values: ", values.email_id, validateEmail(values.email_id));
    if (!values.email_id) {
      set_loading_alert(false);
      set_error_alert(true);
      set_error_message("Please enter your email");
    } else if (validateEmail(values.email_id) === null) {
      set_loading_alert(false);
      set_error_alert(true);
      set_error_message("Please enter a valid email.");
    }  else {
      onOpen_loading_alert();
      handleSignup(public_address, values.email_id, values.referral_by);
    }
  };

  // function handleSignup(publicAddress, email_id, referral_by) {
  //   const walletAddress = localStorage.getItem("wallet_address");
  //   axios.post(DAPPX_BASE_URL + "/users/createAccount", {
  //     publicAddress: publicAddress ? publicAddress : walletAddress,
  //     email_id: email_id,
  //     referral_by: referral_by
  //   })
  //     .then(function (data) {
  //       console.log('createAccount: ', data);
  //       set_loading_alert(false)
  //       set_error_alert(false)
  //       onClose()
  //     })
  //     .catch(function (data) {
  //       console.log('handleSignup error.response: ', data.response);
  //       set_loading_alert(false)
  //       set_error_alert(true)
  //       set_error_message(data.response.data.message)
  //     });
  // }

  const execute_error = () => {
    onOpen_error_alert();
    set_disable_btn(true);
    set_error_message(create_acc_error);
    onClose_loading_alert();

    setTimeout(() => {
      set_disable_btn(false);
    }, 1000);
  };

  // Auto populate referral code
  let ref_code = localStorage.getItem("ref");

  // useEffect(() => {
  //   if(ref_code){
  //     setValues({ ...values, referral_by: ref_code });
  //   }
  // }, [ref_code])

  return (
    <div>
      <Modal
        aria-labelledby="create-account-title"
        aria-describedby="create-account-desc"
        className="create-account-modal custom-scroll dappxPR-modal"
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Zoom in={open}>
          <div className="paper-wrapper">
            <div className="paper">
              <div className="mdl-header">
                <h2>Register Account</h2>
                <IconButton
                  onClick={() => {
                    onClose();
                    onClose_loading_alert();
                  }}
                  className="close-btn"
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="mdl-body">
                <form onSubmit={onSubmit}>
                  <p
                    className="desc desc-1"
                    style={{
                      width: "309px",
                      textAlign: "center",
                      margin: "auto",
                      marginBottom: "10px",
                    }}
                  >
                    Create your DAPPX account to get more security and exciting
                    features.
                  </p>
                  <div className="img-container">
                    <Icon />
                  </div>
                  <FormGroup>
                    <OutlinedInput
                      className="form-input"
                      id="email-field"
                      type="text"
                      error={outline_error.includes("email")}
                      placeholder="Email Address (Required)"
                      value={values.email_id}
                      onChange={onChangeEmail()}
                      labelWidth={0}
                 
                    />
                  </FormGroup>
                  <FormGroup>
                    <OutlinedInput
                      className="form-input"
                      id="referral-field"
                      type="text"
                      error={outline_error.includes("referral")}
                      placeholder="Referral Code (Optional)"
                      value={values.referral_by}
                      onChange={onChangeReferral()}
                      labelWidth={0}
                    />
                  </FormGroup>

                  {error_alert && (
                    <Fade
                      className="error custom-error"
                      in={error_alert ? true : false}
                      timeout={500}
                    >
                      <Alert severity="error">
                        <Warning className="icon" />
                        {error_message}
                      </Alert>
                    </Fade>
                  )}

                  <Button
                    className={`btn-submit ${
                      disable_btn ? "incorrect-email" : ""
                    }`}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disableElevation
                    fullWidth
                    disabled={
                      loading_alert || disable_btn || disableButton
                        ? true
                        : false
                    }
                  >
                    <span className="text">Verify</span>
                    <Loader
                      appear={loading_alert}
                      timeout={1000}
                      width="22"
                      coloredBg
                    />
                  </Button>
                  {/* <Button
                    className={`btn-submit ${disable_btn ? 'incorrect-email' : ''}`}
                    variant="contained"
                    color="primary"
                    disableElevation
                    fullWidth
                    disabled={loading_alert || disable_btn ? true : false}
                    onClick={onClose}
                    >
                    <span className="text">Skip, I'll do it later</span>
                  </Button> */}
                  <p className="desc desc-2">
                    By connecting a wallet, you agree to our{" "}
                    <Link to="/terms" target="_blank">
                      Terms of Service
                    </Link>{" "}
                    and acknowledge that you have read and understand the{" "}
                    <Link to="/disclaimer" target="_blank">
                      DAPPX protocol disclaimer
                    </Link>
                    .
                  </p>
                </form>
              </div>
            </div>
          </div>
        </Zoom>
      </Modal>
    </div>
  );
};

export default ModalCreateAccount;
