import React, { useContext } from 'react';
import DefiCharts from './DefiCharts';
import GameCharts from './GameCharts';
import NftCharts from './NftCharts';
import ExchangeCharts from './ExchangeCharts';
import './TopCharts.scss';
import { SearchContext } from '../../context/SearchContext';

const TopCharts = (props) => {
  let path = props.location.pathname;
  const currentPath = path.substring(path.lastIndexOf('/') + 1);
  const { searchState } = useContext(SearchContext);
  return (

    <div className="page-content top-charts-content">
      {currentPath === 'top-defi-charts' &&
          <DefiCharts searchState={searchState.searchValue} />
      }
      {currentPath === 'top-game-charts' &&
          <GameCharts searchState={searchState.searchValue} />
      }
      {currentPath === 'top-nft-charts' &&
          <NftCharts searchState={searchState.searchValue} />
      }
      {currentPath === 'exchange' &&
          <ExchangeCharts searchState={searchState.searchValue} />
      }
    </div>
  );
}

export default TopCharts;