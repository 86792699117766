import React, { createContext, useReducer } from 'react';
import AuthReducer from './reducers/AuthReducer';

export const AuthContext = createContext();

const initialState = {
  web3: null,
  isAddressRegistered: null,
  user_details: {
    email: '',
    wallet_address: '',
    referral_code: '',
  },
  wallet_balance: [],
  imx_balance: {},
  // changes based on events login, signup, signmessage and connect to web3.
  error: false,
  loading: false,
  errorMessage: '',
  nonce: null
}

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState)

  return (
    <AuthContext.Provider value={{
      authState: state,
      dispatch
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;