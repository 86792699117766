import React, { useContext, useState, useEffect } from 'react';
import { SearchContext } from '../../context/SearchContext';
import { ButtonBase } from '@material-ui/core';
import Highlighter from "react-highlight-words";
import { ReactComponent as Polygon } from '../../assets/images/icons/polygon.svg';
import { useWindowSize } from '../../constant/useWindowSize';
import './DownloadBtn.scss';


const GetButton = ({ className, href, icon, text, small_text, disabled }) => {
  const { searchState } = useContext(SearchContext);

  const [mobileView, setMobileView] = useState(false);

  const [ window_width ] = useWindowSize();
  useEffect(() => {
    if(window_width < 960) setMobileView(true)
    else setMobileView(false)
  }, [window_width]);


  return (
    <ButtonBase className={`get-it-btn ${className}`} href={href} target="_blank" disabled={disabled}>
      {mobileView && <Polygon className="bg-icon"/>}
      <div className="content-wrapper">
        {icon}
        <div className="text">
          <div className="text-small">
            <Highlighter
              searchWords={[searchState.searchValue]}
              textToHighlight={small_text}
            />
          </div>
          <div className="text-big">
            <Highlighter
              searchWords={[searchState.searchValue]}
              textToHighlight={text}
            />
          </div>
        </div>
      </div>
    </ButtonBase>
  )
}

export default GetButton;