import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Tab, Paper, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Profile from './Profile';
import Package from './package';
import ACE from './ace';
import Zera from './zera';
import Dappx from './dappx';
import './UserProfile.scss';

const getSideMenuValue = (val) => {
  switch (val) {
    case 'zera':
      return 1;

    case 'dappx':
      return 2;

    // case 'package':
    //   return 3;

    default:
      return 0;
  }
};

const Mobile = ({ match }) => {
  let history = useHistory();
  let pathname = history.location.pathname ? history.location.pathname : '/404';
  let last_segment = pathname.substring(pathname.lastIndexOf('/') + 1);
  let active_sidemenu;

  if (last_segment.includes('-')) {
    let return_value = last_segment.split('-')[0]
    active_sidemenu = getSideMenuValue(return_value);

    // active_sidemenu = return_value === 'package' ? 1 : 0
    // active_sidemenu = return_value === 'zera' ? 2 : 0
  } else {
    active_sidemenu = getSideMenuValue(last_segment);

    // active_sidemenu = last_segment === 'package' ? 1 : 0
    // active_sidemenu = last_segment === 'zera' ? 2 : 0
  }

  const [tab_value, set_tab_value] = useState(active_sidemenu);

  const onChangeTabs = (event, newValue) => {
    set_tab_value(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          children
        )}
      </div>
    );
  }

  const menu = [
    {
      id: "assets",
      label: "My Assets",
      icon: '',
      href: `${match.url}`,
    },
    {
      id: "ace",
      label: "My ACE",
      href: `${match.url}/ace`
    },
    {
      id: "zera",
      label: "My Zera",
      href: `${match.url}/zera`
    },
    {
      id: "dappx",
      label: "My DAPPX",
      href: `${match.url}/dappx`
    },
    // {
    //   id: "package",
    //   label: "My Package",
    //   href: `${match.url}/package`
    // },
    // {
    //   id: "profile",
    //   label: "My Profile",
    //   href: `${match.url}/profile`,
    //   disable: true
    // },
  ]

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('434'));

  return (
    <div className="mobile-view">
      <Paper square elevation={0} style={{ position: 'fixed', width: '100%', zIndex: 10 }}>
        <Tabs
          value={tab_value}
          onChange={onChangeTabs}
          aria-label="user profile tabs"
          variant={isXS ? "scrollable" : "fullWidth"}
          scrollButtons={isXS ? "auto" : "off"}
          indicatorColor="primary"
        >
          {menu.map((val) => {
            const isBetaLabel = val.id == 'zera'
              ? (
                <span>
                  {val.label} <span
                    className="beta-chip"
                  >BETA</span>
                </span>
              ) : val.label

            return (
              <Tab
                key={val.id}
                label={isBetaLabel}
                id={`tab-${val.id}`}
                aria-controls={`tabpanel-${val.id}`}
                disabled={val.disable}
                onClick={() => {
                  history.push(val.href)
                }}
              />
            )
          }
          )}
        </Tabs>
      </Paper>
      <TabPanel value={tab_value} index={0}>
        <Profile />
      </TabPanel>
      <TabPanel value={tab_value} index={1}>
        <ACE />
      </TabPanel>
      <TabPanel value={tab_value} index={2}>
        <Zera />
      </TabPanel>
      <TabPanel value={tab_value} index={3}>
        <Dappx />
      </TabPanel>
      {/* <TabPanel value={tab_value} index={3}>
        <Package />
      </TabPanel> */}
    </div>
  );
}

export default Mobile;