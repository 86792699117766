/* TODO:
    - Each reducer should have loadin=>request, failure=>onerror, success=>onsuccess
*/
const AuthReducer = (state, action) => {
  switch (action.type) {
    case "INIT_WEB3":
      return {
        ...state,
        web3: action.payload,
      };
    case "CONFIRM_ACCOUNT_FAILURE":
      return {
        ...state,
        isAddressRegistered: false,
        error: action.payload.error,
        errorMessage: action.payload.data.message
      };
    case "CONFIRM_ACCOUNT_SUCCESS":
      return {
        ...state,
        isAddressRegistered: true,
        error: false,
        errorMessage: '',
        nonce: action.payload
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        isAddressRegistered: false,
        error: action.payload.error,
        errorMessage: action.payload.data.message
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        user_details: {
          username: action.payload.username,
          email: action.payload.email,
          wallet_address: action.payload.wallet_address,
          referral_code: action.payload.referral_code,
        },
      };
    case "WALLET_BALANCE":
      return {
        ...state,
        wallet_balance: action.payload
      };
    case "IMX_BALANCE":
      return {
        ...state,
        imx_balance: action.payload
      };
    case "LOGOUT":
      localStorage.clear();
      return state
    default: return state;
  }
}

export default AuthReducer;