import { useState, useEffect, useContext } from "react";
import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import axios from "axios";
import Web3 from "web3";
import { Config, DAPPX_BASE_URL } from "../../../constant/config";
import infoIcon from "../../../assets/images/dappstore-info.png";
import PledgeIcon from "../../../assets/images/icons/padlock-lock.png";
import { ReactComponent as UnpledgeIcon } from "../../../assets/images/icons/padlock-unlock.svg";
import { ReactComponent as AcentActiveIcon } from "../../../assets/images/icons/acent-active.svg";
import { ReactComponent as VaultIcon } from "../../../assets/images/icons/vault.svg";
import DP_Placeholder from "../../../assets/images/dappxpr/dp-placeholder.png";
import { AuthContext } from "../../../context/AuthContext";
import { DappxWithdraw, Deposit, Pledge, Transfer, Unpledge } from "../common";
import { convertValue } from "../Profile";
import "./index.scss";
import kucoin from "../../../assets/images/icons/kucoin.png";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { Ticker } from "react-flip-ticker";
import CustomDialog from "../../../components/Modal/CustomDialog";
import { ReactComponent as SuccessIcon } from "../../../assets/images/icons/success-check.svg";
import { useStyles } from "../ace";
import "../../../components/IMX/index.scss";
import SideBarData from "../../../components/UserSidebar/SideBarData";

const APP_URL = DAPPX_BASE_URL;
const defaultData = {
  total_dappx: "0.000000",
  pledged_dappx: "0.000000",
  unpledged_dappx: "0.000000",
};
const defaultFloatVal = "0.000000";

const Dappx = () => {
  const classes = useStyles();
  const displayDecimals = 6;
  const computeDecimals = 12;

  const [withdrawalOn, setWithdrawalOn] = useState(true);
  const [withdrawalMaitenance, setWithdrawalMatintenance] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);
  const [withdrawLimit, setWithdrawLimit] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const [nitroFee, setNitroFee] = useState(0);
  const [ubiFee, setUbiFee] = useState(0);
  const [coreFee, setCoreFee] = useState(0);
  const [vaultFiat, setVaultFiat] = useState(0);
  const [reloadData, setReloadData] = useState(true);
  const [open, setOpen] = useState(false);
  const [dataTotal, setDataTotal] = useState(defaultData);
  const { authState } = useContext(AuthContext);
  const { wallet_balance, user_details } = authState;
  let user_username = user_details.username;
  const { pledged_dappx, unpledged_dappx, total_dappx } = dataTotal;
  const total = parseFloat(total_dappx).toFixed(6);
  const [data, setData] = useState({
    mainBal: defaultFloatVal,
    pocketBal: defaultFloatVal,
  });
  const [dataAce, setDataAce] = useState({
    mainBal: defaultFloatVal,
    pocketBal: defaultFloatVal,
  });
  const [digital_ace, set_digital_ace] = useState({
    coin_value: defaultFloatVal,
    fiat_value: "0.00",
  });
  const { mainBal, pocketBal } = data;
  const balance = (parseFloat(mainBal) + parseFloat(pocketBal)).toFixed(
    computeDecimals
  );

  const sessionID = localStorage.getItem("session-id");

  useEffect(async () => {
    if (reloadData) {
      const [vault, active] = await Promise.all([
        axios
          .get(APP_URL + "/users/dappx/vault/balance", {
            headers: {
              "session-id": sessionID,
            },
          })
          .catch((error) => error),
        axios
          .get(APP_URL + "/users/dappx/active/balance", {
            headers: {
              "session-id": sessionID,
            },
          })
          .catch((error) => error),
      ]);

      if (
        (vault && vault.status === 200 && vault.data) ||
        (active && active.status === 200 && active.data)
      ) {
        const mainBal =
          vault && vault.data ? vault.data.balance : defaultFloatVal;
        const pocketBal =
          active && active.data ? active.data.balance : defaultFloatVal;
        const total = (parseFloat(mainBal) + parseFloat(pocketBal)).toFixed(
          computeDecimals
        );
        const whole_num = total.split(".")[0];
        const decimal_num = total.split(".")[1].substr(0, displayDecimals);
        const balance = whole_num + "." + decimal_num;

        setData({
          ...data,
          mainBal:
            vault && vault.data
              ? vault.data.balance.toFixed(computeDecimals)
              : defaultFloatVal,
          pocketBal:
            active && active.data
              ? active.data.balance.toFixed(computeDecimals)
              : defaultFloatVal,
        });

        const convertVaultFiat = await convertValue(mainBal, "DAPPX");
        setVaultFiat(convertVaultFiat);

        const fiat_value = await convertValue(balance, "DAPPX");
        set_digital_ace({
          ...digital_ace,
          coin_value: balance,
          fiat_value: fiat_value,
        });
      }

      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(async () => {
    if (reloadData) {
      const [vault, active] = await Promise.all([
        axios
          .get(APP_URL + "/users/acent/vault/balance", {
            headers: {
              "session-id": sessionID,
            },
          })
          .catch((error) => error),
        axios
          .get(APP_URL + "/users/acent/active/balance", {
            headers: {
              "session-id": sessionID,
            },
          })
          .catch((error) => error),
      ]);

      if (
        (vault && vault.status === 200 && vault.data) ||
        (active && active.status === 200 && active.data)
      ) {
        const mainBal =
          vault && vault.data ? vault.data.balance : defaultFloatVal;
        const pocketBal =
          active && active.data ? active.data.balance : defaultFloatVal;
        const total = (parseFloat(mainBal) + parseFloat(pocketBal)).toFixed(
          computeDecimals
        );
        const whole_num = total.split(".")[0];
        const decimal_num = total.split(".")[1].substr(0, displayDecimals);
        const balance = whole_num + "." + decimal_num;

        setDataAce({
          ...data,
          mainBal:
            vault && vault.data
              ? vault.data.balance.toFixed(computeDecimals)
              : defaultFloatVal,
          pocketBal:
            active && active.data
              ? active.data.balance.toFixed(computeDecimals)
              : defaultFloatVal,
        });

        const convertVaultFiat = await convertValue(mainBal, "ACE");
        // setVaultFiat(convertVaultFiat)

        const fiat_value = await convertValue(balance, "ACE");
        // set_digital_ace({
        //   ...digital_ace,
        //   coin_value: balance,
        //   fiat_value: fiat_value,
        // })
      }

      setReloadData(false);
    }
  }, [reloadData]);

  const fetchWithdrawSettings = async () => {
    await axios
      .get(Config.DAPPX_BASE_URL + "/users/dappx/withdraw/settings", {
        headers: { "session-id": sessionID },
      })
      .then((res) => {
        setCoreFee(res.data.withdrawal_fee_core);
        setUbiFee(res.data.withdrawal_fee_ubi);
        setNitroFee(res.data.withdrawal_fee_nitro);
        setWithdrawalOn(res.data.allow_withdrawal);
      });
  };
  let user_dappx = wallet_balance.find((x) => x.token === "DAPPX");
  const [digital_dappx, set_digital_dappx] = useState({
    coin_value: "0.000000",
    fiat_value: "0.00",
  });
  const onCloseSuccessModal = () => {
    setSuccessModal(false);
    setWithdrawModal(false);
  };

  const withdrawButton = () => {
    if (withdrawalOn) {
      setWithdrawModal(true);
    } else {
      setWithdrawalMatintenance(true);
    }
  };

  const transactionSuccess = () => {
    setSuccessModal(true);
  };

  const getMyDappxBalance = () => {
    axios
      .get(Config.DAPPX_BASE_URL + "/users/getMyDappxBalance", {
        headers: { "session-id": localStorage.getItem("session-id") },
      })
      .then(async (result) => {
        console.log(result);
        let pledge = parseFloat(
          Web3.utils.fromWei(result.data.pledged_dappx.toString(), "ether")
        )
          .toFixed(6)
          .toString();
        let resp = Web3.utils.fromWei(result.data.pledged_dappx.toString());
        let num2 = Number(resp.toString().match(/^\d+(?:\.\d{0,6})?/))
          .toFixed(6)
          .toString(); // limiting the digits to 6 decimals
        let unpledge = parseFloat(
          Web3.utils.fromWei(result.data.unpledged_dappx.toString(), "ether")
        )
          .toFixed(6)
          .toString();
        let total = parseFloat(
          Web3.utils.fromWei(result.data.total_dappx.toString(), "ether")
        )
          .toFixed(6)
          .toString();
        let _fiat_value = await convertValue(parseFloat(total), "DAPPX");

        if (pledge || unpledge || total) {
          setDataTotal({
            ...data,
            pledged_dappx: num2,
            unpledged_dappx: unpledge,
            total_dappx: total,
          });
          set_digital_dappx({ coin_value: total, fiat_value: _fiat_value });
        } else {
          setDataTotal(defaultData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchWithdrawaLimit = async () => {
    await axios
      .get(Config.DAPPX_BASE_URL + "/users/dappx/withdraw/limit-status", {
        headers: { "session-id": sessionID },
      })
      .then((res) => {
        if (
          res.data.remaining_daily_tx_amount >
          res.data.remaining_user_daily_tx_amount
        ) {
          setWithdrawLimit(res.data.remaining_user_daily_tx_amount);
        } else {
          setWithdrawLimit(res.data.remaining_daily_tx_amount);
        }
      });
  };

  useEffect(() => {
    getMyDappxBalance();
    fetchWithdrawSettings();
    fetchWithdrawaLimit();
  }, []);

  const formatIntegers = (val) => {
    return new Intl.NumberFormat().format(val);
  };
  const handleOpen = () => {
    setReloadData(true);
    setOpen(true);
  };
  const handleClose = () => {
    setReloadData(true);
    setOpen(false);
  };
  const builtInTheme = useTheme();
  const isXS = useMediaQuery(builtInTheme.breakpoints.down("xs"));
  const isSM = useMediaQuery(builtInTheme.breakpoints.down("sm"));
  const popoverDAPPX = (
    <Popover
      className="pop1"
      id="popover-positioned-bottom"
      title="Popover bottom"
    >
      <div className="buydrpdn">
        <div className="drpheader">
          <h1>BUY DAPPX from</h1>
        </div>
        <div className="drpbody">
          <div className="drpbtn1">
            <Button
              href="https://www.kucoin.com/ucenter/signup?rcode=rJCP33Y"
              target="_blank"
            >
              <span>
                <img src={kucoin} />
              </span>
            </Button>
          </div>
        </div>
        {/*<div className="drpbdr">*/}
        {/*  <p className="dot"> . . . . . . . . . . . .</p>*/}
        {/*  <div className="drpor"> <p>or</p> </div>*/}
        {/*  <p className="dot"> . . . . . . . . . . . .</p>*/}
        {/*</div>*/}
        {/*<div className="drpftr">*/}
        {/*  <p>Easily Exchange ETH to ACE in dAppstore!</p>*/}
        {/*  <Button>BUY in dAppstore</Button>*/}
        {/*</div>*/}
      </div>
    </Popover>
  );

  let acTotal = Number(mainBal) + Number(pocketBal);
  acTotal = String(acTotal);

  const [isVerified, setIsVerified] = useState(false);

  const isUserVerified = async () => {
    // console.log("check if data is verified");
    await axios
      .get(Config.DAPPX_BASE_URL + "/users/userProfile", {
        headers: {
          "session-id": sessionID,
        },
      })
      .then((res) => {
        setIsVerified(res.data.is_verified);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    isUserVerified();
  }, []);
  return (

    <div className=" user-sidebar-wrapper user-sidebar-wrapper-v2">

<div className="content-wrapper-v2">
      <div className="content">
        <div
          className="section s1"
          style={{ display: "flex", borderBottom: "1px solid #dedede" }}
        >
          <div
            className="img-container"
            style={{ display: "flex", margin: "15px", alignItems: "center" }}
          >
            <img
              src={DP_Placeholder}
              alt="place your cool face here"
              style={{ height: 60, width: 60, margin: 10 }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p
                style={{ fontSize: "1.5rem", color: "#21212", fontWeight: 700 }}
              >
                {user_username ? user_username : "Unnamed"}
              </p>
              <a href="/user-profile" style={{ textDecoration: "underline" }}>
                My Digital Assets
              </a>
            </div>
          </div>
        </div>
        <div className="my-dappx-page">
          <Grid container spacing={isXS ? 1 : 2}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h5"
                gutterBottom
                className="bold total-balance-label"
              >
                Total DAPPX
              </Typography>
              <div className="amount">
                <Typography variant="h2" className="whole-num-large">
                  {formatIntegers(acTotal?.split(".")[0])}
                </Typography>
                <Typography variant="h5" className="decimal-num-large">
                  {acTotal?.split(".")[1] == 0
                    ? ""
                    : `${
                        acTotal?.split(".")[1]
                          ? "." + acTotal?.split(".")[1]
                          : ""
                      }`}
                </Typography>
              </div>
            </Grid>

            {/* <Grid item xs={6} md={6}>
    <div className="detail blue growth-detail">
      <div className="details-text-container">
        <div className="bold">
          APR
        </div>
        <div className="amount">
          <Typography variant="h4" color="primary">
            {'- '}
            <span>%</span>
          </Typography>
        </div>
      </div>
      <div className="dappx-details-icon growth-container">
        <img src={GrowthIcon} alt="" className="growth-img" />
      </div>
    </div>
  </Grid> */}

            {/* {isSM && <Grid item xs={6}></Grid>} */}

            <Grid item xs={6}>
              <div className="detail gray">
                <div className="details-text-container">
                  <Typography
                    variant="h6"
                    className="details-title"
                    gutterBottom
                  >
                    Staked
                  </Typography>
                  <div className="amount">
                    <Typography variant="h4" className="whole-num">
                      {formatIntegers(pledged_dappx.split(".")[0])}
                    </Typography>
                    <Typography variant="h5" className="decimal-num">
                      {pledged_dappx.split(".")[1] == 0
                        ? ""
                        : `.${pledged_dappx.split(".")[1]}`}
                    </Typography>
                  </div>
                </div>
                <img src={PledgeIcon} alt="" className="dappx-details-icon" />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="detail gray">
                <div className="details-text-container">
                  <Typography
                    variant="h6"
                    className="details-title"
                    gutterBottom
                  >
                    Unstaked/Withdrawable
                  </Typography>
                  <div className="amount">
                    <Typography variant="h4" className="whole-num">
                      {formatIntegers(unpledged_dappx.split(".")[0])}
                    </Typography>
                    <Typography variant="h5" className="decimal-num">
                      {unpledged_dappx.split(".")[1] == 0
                        ? ""
                        : `.${unpledged_dappx.split(".")[1]}`}
                    </Typography>
                  </div>
                </div>
                <UnpledgeIcon className="dappx-details-icon" />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="token-details">
                <div className="details-text-container">
                  <Typography
                    variant="h6"
                    className={classes.detailsTitle}
                    gutterBottom
                  >
                    DAPPX Vault
                  </Typography>
                  <div className="amount">
                    <Ticker textClassName="whole-num">
                      {formatIntegers(mainBal.split(".")[0])}
                    </Ticker>
                    <Ticker
                      textClassName={`decimal-num ${
                        mainBal.split(".")[1].substr(0, displayDecimals) == 0 &&
                        "hidden"
                      }`}
                    >
                      <span className={classes.dot}></span>
                      {`.${mainBal.split(".")[1].substr(0, displayDecimals)}`}
                    </Ticker>
                  </div>
                </div>
                <VaultIcon className="details-icon" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="token-details">
                <div className="details-text-container">
                  <Typography
                    variant="h6"
                    className={classes.detailsTitle}
                    gutterBottom
                  >
                    Active DAPPX
                  </Typography>
                  <div className="amount">
                    <Ticker textClassName="whole-num">
                      {formatIntegers(pocketBal.split(".")[0])}
                    </Ticker>
                    <Ticker
                      textClassName={`decimal-num ${
                        pocketBal.split(".")[1].substr(0, displayDecimals) ==
                          0 && "hidden"
                      }`}
                    >
                      <span className={classes.dot}></span>
                      {`.${pocketBal.split(".")[1].substr(0, displayDecimals)}`}
                    </Ticker>
                  </div>
                </div>
                <AcentActiveIcon className="details-icon" />
              </div>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <div className="buttons-block">
                {/*<Button*/}
                {/*  variant="outlined"*/}
                {/*  component="a"*/}
                {/*  href="https://www.kucoin.com/ucenter/signup?rcode=rJCP33Y"*/}
                {/*  target="_blank">*/}
                {/*  Buy*/}
                {/*</Button>*/}
                <OverlayTrigger
                  trigger={["click"]}
                  rootClose
                  placement="bottom"
                  overlay={popoverDAPPX}
                >
                  <Button
                    className="buybutton"
                    variant="outlined"
                    component="a"
                  >
                    Buy
                  </Button>
                </OverlayTrigger>
                {!isMobile && (
                  <>
                    <Deposit
                      tokenType="DAPPX"
                      balance={user_dappx}
                      digital_balance={digital_dappx}
                      notDisabled={total > 0 ? true : false}
                    />
                    <Pledge balance={user_dappx} />
                    <Unpledge
                      disableButton={Number(pledged_dappx) <= 0}
                      balance={user_dappx}
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={handleOpen}
                      className={classes.btn}
                    >
                      Transfer
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={withdrawButton}
                      // className={classes.btn}
                      disabled={!isVerified}
                    >
                      Withdraw
                    </Button>

                    <Transfer
                      token="dappx"
                      open={open}
                      handleClose={handleClose}
                      data={data}
                      decimals={displayDecimals}
                    />
                    <DappxWithdraw
                      checkOpen={withdrawModal}
                      vaultBalance={data.mainBal}
                      aceVaultBalance={dataAce.mainBal}
                      withdrawLimit={withdrawLimit}
                      nitroFee={nitroFee}
                      ubiFee={ubiFee}
                      coreFee={coreFee}
                      usdValue={usdValue}
                      vaultFiat={vaultFiat}
                      onClose={() => setWithdrawModal(false)}
                      onSuccess={transactionSuccess}
                      refetch={fetchWithdrawSettings}
                    />

                    <CustomDialog
                      open={policyModal}
                      header={<p>Policy Update!</p>}
                      body={
                        <div className="mobile-modal-conatainer">
                          <img
                            src={infoIcon}
                            alt=""
                            className="mobile-modal-logo"
                          />
                          <p className="policy-modal-body">
                            Daily withdraw policy was updated.
                            <br />
                            Kindly check and process your withdrawal again.
                          </p>
                        </div>
                      }
                      footer={
                        <div className="policy-button-container">
                          <button
                            className="policy-button"
                            onClick={() => setPolicyModal(false)}
                          >
                            Ok
                          </button>
                        </div>
                      }
                    />
                    <CustomDialog
                      open={withdrawalMaitenance}
                      header={<p>Service Maintenance</p>}
                      body={
                        <div className="mobile-modal-conatainer">
                          <img
                            src={infoIcon}
                            alt=""
                            className="mobile-modal-logo"
                          />
                          <p
                            className="policy-modal-body"
                            style={{ marginRight: "-23%" }}
                          >
                            Service is not available at the moment.
                            <br />
                            Please try again later. Thank you.
                          </p>
                        </div>
                      }
                      footer={
                        <div className="policy-button-container">
                          <button
                            className="policy-button"
                            onClick={() => setWithdrawalMatintenance(false)}
                          >
                            Ok
                          </button>
                        </div>
                      }
                    />
                    <CustomDialog
                      open={successModal}
                      className="deposit-dialog loading-dialog"
                      onClose={onCloseSuccessModal}
                      header="Your withdraw is on it's way!"
                      disableEscapeKeyDown={true}
                      disableBackdropClick={true}
                      body={
                        <>
                          <SuccessIcon className="icon" />
                          <p>
                            Please confirm the deposit action on your wallet to
                            submit the request to the network.
                          </p>

                          <p>
                            Updated status will be reflected on your wallet
                            transaction history.
                          </p>

                          <p>
                            For successful transaction, reflecting the balance
                            on your dAppstore Balances may take longer than
                            usual.
                          </p>
                        </>
                      }
                      footer={
                        <>
                          <div className="button-block">
                            <Button
                              variant="contained"
                              color="primary"
                              disableElevation
                              onClick={onCloseSuccessModal}
                              type="submit"
                            >
                              OK
                            </Button>
                          </div>
                        </>
                      }
                    />
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>

    <SideBarData />

    </div>
  
  );
};

export default Dappx;
