import React from 'react';

const Login = () => {
  return (
    <div>
      <h1>Login page</h1>
      <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
        Launch demo modal
      </button>

    </div>
  );
}
 
export default Login;