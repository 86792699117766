import React, { useContext, useState, useEffect } from 'react';
import SectionMainVisual from './SectionMainVisual';
import SectionHowTo from './SectionHowTo';
// import SectionBigButton from './SectionBigButton';
import { ReactComponent as Windows } from '../../assets/images/icons/windows.svg';
import { ReactComponent as Google } from '../../assets/images/icons/google-play.svg';
import { ReactComponent as Apple } from '../../assets/images/icons/apple.svg';
import { SearchContext } from '../../context/SearchContext';
import GetButton from '../../components/download-btn/GetButton';
import { useWindowSize } from '../../constant/useWindowSize';
import './Download.scss';

const Download = () => {
  const { searchState } = useContext(SearchContext);

  const [mobileView, setMobileView] = useState(false);

  const [ window_width ] = useWindowSize();
  useEffect(() => {
    if(window_width < 960) setMobileView(true)
    else setMobileView(false)
  }, [window_width]);

  return (
    <div className="page-content download-content">
      <SectionMainVisual
        searchState={searchState.searchValue}
        mobileView={mobileView}
        download_btn_osiris={
          <>
            <GetButton
              className="windows-btn"
              href="https://browseosiris.com/"
              icon={<Windows className="icon" />}
              text="Windows PC"
              small_text="GET IT ON"
            />
            <GetButton
              className="google-btn"
              href="https://play.google.com/store/apps/details?id=com.osiris.browser"
              icon={<Google className="icon" />}
              text="Google Play"
              small_text="GET IT ON"
            />
            <GetButton
              className="apple-btn"
              href="https://apps.apple.com/us/app/osiris-browser/id1487907355"
              icon={<Apple className="icon" />}
              text="App Store"
              small_text="Download on the"
            />
          </>
        }
        download_btn_wonder={
          <>
            <GetButton
              className="google-btn"
              href="https://play.google.com/store/apps/details?id=com.wonder.wallet"
              icon={<Google className="icon" />}
              text="Google Play"
              small_text="GET IT ON"
            />
            <GetButton
              className="apple-btn"
              icon={<Apple className="icon" />}
              text="App Store"
              small_text="Download on the"
              disabled={true}
            />
          </>
        }
      />
      <SectionHowTo
        searchState={searchState.searchValue}
      />
      {/* <SectionBigButton
        searchState={searchState.searchValue}
        addDownloadBtn={
          <>
            <GetButton
              className="windows-btn"
              href="https://browseosiris.com/"
              icon={<Windows className="icon" />}
              text="Windows PC"
            />
            <GetButton
              className="google-btn"
              href="https://play.google.com/store/apps/details?id=com.osiris.browser"
              icon={<Google className="icon" />}
              text="Google Play"
            />
          </>
        }
      /> */}
    </div>
  );
}

export default Download;