import { useContext, useEffect } from 'react';
import { Container } from '@material-ui/core';
import SectionTicker from './SectionTicker';
import SectionSlider from './SectionSlider';
import SectionMedia from './SectionMedia';
import SectionTopChart from './SectionTopCharts';
import SectionWeeklyTop from './SectionWeeklyTop';
import SectionRecommended from './SectionRecommended';
import { SearchContext } from '../../context/SearchContext';
import { useMoralis, useMoralisCloudFunction } from "react-moralis";
import { MoralisContext } from '../../context/MoralisContext';
import './Home.scss';

const Home = ({setApiLoader, apiLoading}) => {
  const { searchState } = useContext(SearchContext);
  const { marketplaceState, dispatch } = useContext(MoralisContext);
  const {
    enableWeb3,
    isWeb3Enabled,
  } = useMoralis();
  
  const {  data, error, isLoading } = useMoralisCloudFunction(
    "getItems",
    
    { autoFetch: true }
  );
  useEffect(() => {
    console.log('API', process.env.REACT_APP_ENV)
    // if (!isWeb3Enabled) {
    //   enableWeb3(); 
    // }
   
    if(data) {
      dispatch({ type: 'FETCH_MARKETPLACE_ITEMS_SUCCESS', payload: data })
    }
    
  }, [data]);

  // const testHandler = async () => {
  //   const payload = {
  //     "value": true
  //   }
  //   const result = await axios.post('http://localhost:8546/api/setflag', payload, {
  //     headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //     }
  // })
  //   console.log(result)
  // }

  return (
    <div className="page-content home-content">
      <SectionTicker searchState={searchState.searchValue} setApiLoader={setApiLoader} apiLoading={apiLoading} />
      <SectionSlider searchState={searchState.searchValue} />

      {/* <button onClick={testHandler}>
        Testing
      </button> */}
      <Container>
        <SectionTopChart searchState={searchState.searchValue} />
      </Container>
      <Container>
        <SectionRecommended searchState={searchState.searchValue} />
      </Container>
      {/* <Container>
        <SectionAnalytics searchState={searchState.searchValue} />
      </Container> */}
      <Container>
        <SectionWeeklyTop searchState={searchState.searchValue} />
      </Container>
      {/* <Container>
        <SectionNew searchState={searchState.searchValue} />
      </Container> */}
      <Container>
        <SectionMedia searchState={searchState.searchValue} setApiLoader={setApiLoader}/>
      </Container>
    </div>
  );
}

export default Home;