import React, { useState, useEffect } from "react";
import mailIcon from "../../assets/images/icons/mail.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ModalCreateAccount from "../top-nav/ModalCreateAccount";
import "./UserSidebar.scss";
import { useRef } from "react";
import { use } from "i18next";
import Web3 from 'web3';
import { DAPPX_BASE_URL } from "../../constant/config";
import axios from 'axios'
import ConnectWalletButton from "../top-nav/ConnectWalletButton";
const EmailAndReferral = ({isVerified}) => {
  let web3 = new Web3(window.ethereum);
  let APP_URL = DAPPX_BASE_URL;
  const [referralCode, setRefrralCode] = useState("");
  const [copySuccess, setCopySuccess] = useState();
  const [verifyAccount, setVerifyAccount] = useState(false);
  const [pre_open_modal, set_pre_open_modal] = useState(false);
  const [copyReferral, setCopyReferral] = useState('')
  const [public_address, set_public_address] = useState('');
  const [create_acc_error, set_create_acc_error] = useState();
  const myRef = useRef();

  useEffect(() => {
    set_public_address(localStorage.getItem('wallet_address'))
  }, [])

  const copyToClipboard = (text) => {
    setCopySuccess("Copied to clipboard!");
  };

  const handleClickOutside = e => {
    if (!myRef?.current?.contains(e.target)) {
      set_pre_open_modal(false);
    }
};

const handleClickInside = () => set_pre_open_modal(false);

useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
});
  console.log(referralCode);

  useEffect(() => {
    setRefrralCode(localStorage.getItem("referral_code"))
    setCopyReferral('https://dappstore.me/?ref='+localStorage.getItem("referral_code"))
  }, []);


  return (
    <>
    {!isVerified ? 
      <div className="verify-box">
      <div className="verify-header">
        <img className="verify-header__icon" src={mailIcon} alt="" />
        <p className="verify-header__text">Add Your Email</p>
      </div>
      <p className="verify-body">
        Verify your email to be secured and to get more features on DAPPX
      </p>
      <button
        className="verify-button"
        onClick={() => setVerifyAccount(true)}
      >
        Verify
      </button>
    </div>
    :''}
      <div className="referral-container">
        <div class="form-group">
          <p style={{textAlign: 'left'}}>Generate passive income with DAPPX!</p>
          <div class="input-group referral-input-container">
            <input
              type="text"
              name="search"
              className="referral-input"
              placeholder="Placeholder"
              onChange={(e) => setRefrralCode(e.target.value)}
              disabled
              value={referralCode}
            />
            
              <div className="share-area">
                <button type="submit" className="referral-button" onClick={() => set_pre_open_modal(true)}>
                  {" "}
                  Share
                </button>
                <div className={`option-item-area ${pre_open_modal ? 'option-item-area-active' : ''}`} ref={myRef}>
                <CopyToClipboard
              text={copyReferral}
              // value={referralCode}
              onCopy={() => copyToClipboard()}
            >
                    <button className="option-item" onClick={e => {
                      e.preventDefault()
                      set_pre_open_modal(false)
                    }}>
                      <p>Copy Link</p>
                    </button>
                    </CopyToClipboard>
                  </div>
            
              </div>
         
          </div>
        </div>
      </div>
      {verifyAccount && (
        <ConnectWalletButton justCreateModal={true} verifyAccount={verifyAccount} setVerifyAccount={setVerifyAccount}  />
      )}

      
    </>
  );
};

export default EmailAndReferral;
