import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import { Button, Fade, TextField, Link, ClickAwayListener, Tooltip, IconButton } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import { Config } from '../../../../constant/config';
import CustomDialog from '../../../../components/Modal/CustomDialog';
import dappx_logo from '../../../../assets/images/dappstore-icon.svg';
import edao_logo from '../../../../assets/images/icons/edao-logo.svg';
import acent_logo from '../../../../assets/images/icons/acent-logo.svg';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ReactComponent as WarningIcon } from '../../../../assets/images/icons/warning.svg';
import { ReactComponent as LoaderIcon } from '../../../../assets/images/icons/loader-2.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/images/icons/error-x.svg';
import { ReactComponent as SuccessIcon } from '../../../../assets/images/icons/success-check.svg';
import TransactionPreviewCurrentScalable from '../../../../components/Modal/TransactionPreviewCurrentScalable';
import TransactionPreviewScalable from '../../../../components/Modal/TransactionPreviewScalable';
import { NumberFormatCustom } from '../../../../components/CustomInput';

import moment from "moment";
import '../Pledge/index.scss';

const logos = {
  edao: edao_logo,
  ace: acent_logo,
  dappx: dappx_logo,
}

const apiEndpoint = {
  edao: {
    feeTokenBalance: '/users/acent/active/balance',
    tokenBalance: '/users/edao/active/balance',
    config: '/users/edao/governance-pledging/settings',
    unpledge: '/users/edao/governance-pledging/unpledge',
    pledgedBalance: '/users/edao/governance-pledging/balance'
  },
   dappx: {
    feeTokenBalance: '/users/getDappxDepositBalance',
    tokenBalance: '/users/getDappxDepositBalance',
    config: '/users/getPledgingConfig',
    unpledge: '/users/createUnpledgeTx',
    pledgedBalance: '/users/getPledgeBalance?token_type=DAPPX'
  }
}

const ButtonUnpledge = ({ balance, disableButton, token = 'dappx' }) => {

  const [input_val, set_input_val] = useState('')
  const [input_fiat_val, set_input_fiat_val] = useState(0);
  const [wallet_action, set_wallet_action] = useState(false);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [feeTokenBalance, setFeeTokenBalance] = useState(0);
  const [pledgedBalance, setPledgedBalance] = useState(0);
  const [newFeeTokenBalance, setNewFeeTokenBalance] = useState(0);
  const [newTokenBalance, setNewTokenBalance] = useState(0);
  const [newPledgeBal, setNewPledgeBal] = useState(0);
  const [penaltyFee, setPenaltyFee] = useState(0);
  const [holdPeriod, setHoldPeriod] = useState(0);
  const [holdingPeriod, setHoldingPeriod] = useState(0);
  const [penaltyInNumbers, setPenaltyInNumbers] = useState(0);
  const [dateCreated, setDateCreated] = useState('');
  const [isUnderPenalty, setIsUnderPenalty] = useState(true);
  const [serviceFee, setServiceFee] = useState(0);
  const [unpledgeFeeNitro, setUnpledgeFeeNitro] = useState(0);
  const [unpledgeFeeUBI, setUnpledgeFeeUBI] = useState(0);
  const [unpledgeFeeCore, setUnpledgeFeeCore] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const [isMarketPriceReady, setIsMarketPriceReady] = useState(true);
  const [marketPrice, setMarketPrice] = useState(undefined);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [unpledgeFee, setUnpledgeFee] = useState(0)

  const [hourlyCountDown, setHourlyCountDown] = useState(0);
  const [minutesCountDown, setMinutesCountDown] = useState(0);
  const [secondCountDown, setSecondsCountDown] = useState(0);

  useEffect(()=>{
    if (holdingPeriod && holdPeriod && isUnderPenalty) {
      setInterval(() => {
        getTimeRemaining(holdPeriod)
      }, 1000);
    }
  },[holdPeriod,holdingPeriod]);

  const getTimeRemaining = (endtime) => {

    // Add Number of days to the createdAt date
    // var myDate = new Date(endtime);
    // myDate.setDate(myDate.getDate()  parseInt(3));

    let myDate = moment(endtime).add(holdingPeriod, 'hours');

    const total = Date.parse(myDate) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % holdingPeriod)

    setHourlyCountDown(hours < 10 ? `0${hours}` : hours)
    setMinutesCountDown(minutes < 10 ? `0${minutes}` : minutes)
    setSecondsCountDown(seconds < 10 ? `0${seconds}` : seconds)
  
    if (hours <= 0 && minutes <= 0 && seconds <= 0) {
      setIsUnderPenalty(false)
    }
  }

  const session_Id = localStorage.getItem('session-id')

  const fetchTokenBalance = async () => {
    await axios.get(Config.DAPPX_BASE_URL + apiEndpoint[token].tokenBalance, {headers: {'session-id': session_Id}})
    .then(async(result) => {
      if (token === 'dappx') {
        let balance_wei = Web3.utils.fromWei(result.data.dappx_balance, 'ether');
        let hasNoDecimals = balance_wei.split('.')[1]
        hasNoDecimals == 0 || hasNoDecimals === undefined ? setTokenBalance(Number(Math.trunc(balance_wei))) : setTokenBalance(Number(balance_wei).toFixed(6))
        return;
      }

      let hasNoDecimals = result.data.balance.toFixed(6).split('.')[1]
      hasNoDecimals == 0 || hasNoDecimals === undefined ? setTokenBalance(Number(Math.trunc(Number(result.data.balance)))) : setTokenBalance(Number(result.data.balance).toFixed(6))
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const fetchFeeTokenBalance = async () => {
    if (token === 'dappx') {
      if (feeTokenBalance > 0) {
        setTokenBalance(setTokenBalance(Number(feeTokenBalance).toFixed(6)));
      }
    }

    await axios.get(Config.DAPPX_BASE_URL + apiEndpoint[token].feeTokenBalance, {headers: {'session-id': session_Id}})
    .then(async(result) => {
      if (token === 'dappx') {
        let balance_wei = Web3.utils.fromWei(result.data.dappx_balance, 'ether');
        let hasNoDecimals = balance_wei.split('.')[1]
        hasNoDecimals == 0 || hasNoDecimals === undefined ? setFeeTokenBalance(Number(Math.trunc(balance_wei))) : setFeeTokenBalance(Number(balance_wei).toFixed(6))
        return;
      }

      let hasNoDecimals = result.data.balance.toFixed(6).split('.')[1]
      hasNoDecimals == 0 || hasNoDecimals === undefined ? setFeeTokenBalance(Number(Math.trunc(Number(result.data.balance)))) : setFeeTokenBalance(Number(result.data.balance).toFixed(6))
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const fetchPledgeBalance = async () => {
    await axios.get(Config.DAPPX_BASE_URL + apiEndpoint[token].pledgedBalance, {headers: {'session-id': session_Id}})
    .then((result) => {
      if (token === 'dappx') {
        let balance_wei = Web3.utils.fromWei(result.data.dappx_balance.toString(), 'ether');
        let num2 = Number(balance_wei.toString().match(/^\d+(?:\.\d{0,6})?/));
        let hasNoDecimals = balance_wei.split('.')[1]
        hasNoDecimals == 0 || hasNoDecimals === undefined ? setPledgedBalance(Number(Math.trunc(num2))) : setPledgedBalance(Number(num2).toFixed(6))
        return;
      }

      var date1 = new Date();
      var date2 = new Date(result.data.hold_until);
      //Penalty Period
      if(date1.getTime() <= date2.getTime()){
        setIsUnderPenalty(true)
        setHoldPeriod(result.data.hold_until)
        //date 1 is newer
      } else {
        setIsUnderPenalty(false)
        setHoldPeriod()
      }
      setPledgedBalance(result.data.balance)
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const fetchPledgeRules = async () => {
    let headers = {
      headers: token === 'dappx' ? {'custom-token-id': "H00R7LPQSY"} : {'session-id': session_Id}
    }
    axios.get(Config.DAPPX_BASE_URL  + apiEndpoint[token].config, headers)
      .then((res) => {
        setPenaltyFee(token === 'dappx' ? Number(res.data.data.penalty_fee) : 0);
        setHoldingPeriod(token === 'dappx' ? Number(res.data.data.holding_period) : Number(res.data.penalty_duration));

        setUnpledgeFeeCore(res.data.unpledge_fee_core && Number(res.data.unpledge_fee_core));
        setUnpledgeFeeNitro(res.data.unpledge_fee_nitro && Number(res.data.unpledge_fee_nitro));
        setUnpledgeFeeUBI(res.data.unpledge_fee_ubi && Number(res.data.unpledge_fee_ubi));
        setUnpledgeFee(res.data.unpledge_fee_core + res.data.unpledge_fee_nitro + res.data.unpledge_fee_ubi)
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const isUserUnderPenalty = async () => {
    if (token !== 'dappx') {
      return;
    }

    let data = {
      tokenType: `${token.toUpperCase()}`
    }
    await axios.post(Config.DAPPX_BASE_URL + '/users/isUnderPenalty', data, { headers: { "session-id": session_Id } })
      .then((res) => {
        setIsUnderPenalty(res.data.data.isUnderPenalty)
        setDateCreated(res.data.data.pleding_date)
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const calculatePenaltyFee = () => {
    setPenaltyInNumbers((Number(input_val) / 100) * penaltyFee)
  }

  const convertCurrency = async () => {
    await axios.get(Config.DAPPX_BASE_URL + '/users/getMarketPrices')
    .then((res) => {
      setMarketPrice(res.data)

      if (token.toUpperCase() === "DAPPX") {
        setUsdValue(res.data.DAPPX.USD);
        return;
      }

      setUsdValue(res.data.ACE.USD);
    })
    .catch((error) => {
      set_input_fiat_val(0);
      set_err_message("Can't pull conversion rate.")
    })
  }

  useEffect(() => {
    fetchTokenBalance()
    fetchFeeTokenBalance()
    fetchPledgeBalance()
    fetchPledgeRules()
    isUserUnderPenalty()
    convertCurrency()
  }, [])

  useEffect(() => {
    calculatePenaltyFee()
  }, [input_val])

  useEffect(() => {
    if (!isUnderPenalty) {
      setNewTokenBalance(Number(tokenBalance) + Number(input_val))
    }
  }, [isUnderPenalty])

  // Input Error
  const [err_message, set_err_message] = useState('');
  const [outline_error, set_outline_error] = useState([]);

  // Modal Deposit
  const [open_modal, set_open_modal] = useState(false);
  const onOpenModal = () => {
    set_open_modal(true)
  }
  const onCloseModal = () => {
    if (wallet_action) {
      set_wallet_action(false)
      set_err_message('')
    } else {
      onClear()
    }
  }

  // Modal Loading
  const [open_modal_loading, set_open_modal_loading] = useState(false);
  const onOpenModalLoading = () => {
    set_open_modal_loading(true)
    set_wallet_action(false)
    set_open_modal(false)
  }
  const onCloseModalLoading = () => {
    set_open_modal_loading(false)
  }

  // Modal Success
  const [open_modal_success, set_open_modal_success] = useState(false);
  const onOpenModalSuccess = () => {
    set_open_modal_success(true)
    set_open_modal(false)
  }
  const onCloseModalSuccess = () => {
    set_open_modal_success(false)
    window.location.reload()
  }

  // Modal Error
  const [open_modal_error, set_open_modal_error] = useState(false);
  const onOpenModalError = () => {
    set_open_modal_error(true)
    onClear()
  }
  const onCloseModalError = () => {
    set_open_modal_error(false)
    window.location.reload()
  }

  // Functions
  const remove_errors = (target) => {
    set_err_message('')
    let update_outline_error = outline_error.filter(id => id !== target)
    set_outline_error(update_outline_error)
  }


  const convertValue = (balance, tokenCode) => {
    let convertion_currency = 'USD';

    if (isMarketPriceReady) {
      axios.get(Config.DAPPX_BASE_URL + '/users/getMarketPrices')
        .then((prices) => {
          if (!prices.data[tokenCode]) {
            return;
          }
          let fiat_currency = prices.data[tokenCode][convertion_currency] ? prices.data[tokenCode][convertion_currency] : 0;
          let fiat_value_value = parseFloat(balance) * parseFloat(fiat_currency);
          let convertion_value;

          if (fiat_value_value === 0) {
            convertion_value = parseFloat(fiat_value_value)
          } else {
            convertion_value = parseFloat(fiat_value_value).toFixed(2)
          }

          set_input_fiat_val(convertion_value);
          setMarketPrice(prices.data)
          setIsMarketPriceReady(false)
          if (!isTimerRunning) marketPriceTimer()
        })
        .catch((error) => {
          console.log('convertValue error: ', error)
          set_input_fiat_val(0)
          set_err_message("Can't pull conversion rate.")
        })
    } else {
      if (!marketPrice[tokenCode]) {
        return;
      }

      let fiat_currency = marketPrice[tokenCode][convertion_currency] ? marketPrice[tokenCode][convertion_currency] : 0;
      let fiat_value_value = parseFloat(balance) * parseFloat(fiat_currency);
      let convertion_value;

      if (fiat_value_value === 0) {
        convertion_value = parseFloat(fiat_value_value)
      } else {
        convertion_value = parseFloat(fiat_value_value).toFixed(2)
      }

      set_input_fiat_val(convertion_value);
      if (!isTimerRunning) marketPriceTimer()
    }
  }

  const marketPriceTimer = () => {
    setIsTimerRunning(true)

    setTimeout(() => {
      setIsMarketPriceReady(true)
      setIsTimerRunning(false)
    }, 60000)
  }

  const onChange_num = (e) => {
    const target_val = e.target.value;
    const decimals = target_val.split('.')[1] || '';
    remove_errors(e.target.id)

    if (target_val !== '' && decimals.length <= 6) {
      set_input_val(target_val)
      let convertion_value = (target_val * usdValue).toFixed(2)
      set_input_fiat_val(convertion_value)
      convertCurrency()
    }

    if (target_val === '') {
      set_input_val(target_val)
      // convertValue(0, 'DAPPX')
      convertValue(0, `${token.toUpperCase()}`)
      convertCurrency()
    }
  }

  const maxVal = () => {
    set_input_val(pledgedBalance)
    // convertValue(pledgedBalance, 'DAPPX')
    convertValue(pledgedBalance, `${token.toUpperCase()}`)
  }

  const onSubmit = () => {
    setNewPledgeBal(Number(pledgedBalance - Number(input_val)))

    if (isUnderPenalty) {
      setNewTokenBalance((Number(tokenBalance) + Number(input_val) - penaltyInNumbers))
      setNewFeeTokenBalance(Number(feeTokenBalance - getUnpledgeFee()));
    } else {
      setNewTokenBalance(Number(tokenBalance) + Number(input_val))
      setNewFeeTokenBalance(Number(feeTokenBalance));
    }

    if (Number(input_val) > pledgedBalance) {
      set_err_message('Unstake amount is greater than the current stake!')
    } else if (Number(input_val) <= 0) {
      set_err_message('Amount should be greater than zero!')
    } else if(Number(unpledgeFee) > feeTokenBalance) {
      set_err_message('Insufficient balance!')
    }else {
      set_err_message('')

      if (wallet_action) {

        let data = {
          amount: parseFloat(input_val)
        }

        if(token === 'dappx') {
          data = {
            unpledgeAmount: input_val,
            tokenType: token.toUpperCase()
          }
        }

        onOpenModalLoading()

        axios.post(Config.DAPPX_BASE_URL + apiEndpoint[token].unpledge, data, {headers: {'session-id': session_Id}})
        .then((res) => {
          setTokenBalance(newTokenBalance);
          setPledgedBalance(newPledgeBal);
          onCloseModalLoading();
          onOpenModalSuccess();
        })
        .catch((error) => {
          console.log(error);
          onCloseModalLoading();
          onOpenModalError();
        })
      } else {
        set_wallet_action(true);
      }
    }
  }

  const onClear = () => {
    setTimeout(() => {
      set_wallet_action(false)
      set_open_modal(false)
      set_input_val('')
      set_input_fiat_val(0)
      set_err_message('')
      set_outline_error([])
      set_wallet_action(false)
    }, 400)
  }

  // Tooltip
  const [pledgeTooltip, setPledgeTooltip] = useState(false)
  const [serviceFeeTooltip, setServiceFeeTooltip] = useState(false)

  const getUnpledgeFee = () => {
    return unpledgeFeeCore + unpledgeFeeNitro + unpledgeFeeUBI;
  }

  return (
    <>
      <Button
        className="pledge-btn"
        variant="outlined"
        disabled={disableButton}
        // fullWidth
        onClick={onOpenModal}>
        Unstake
      </Button>

      <CustomDialog
        open={open_modal}
        className={`pledge-dialog ${token}-modal`}
        onClose={onCloseModal}
        header={`Unstake ${token.toUpperCase()}`}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={<>
          {!wallet_action &&
            <div className="container-balance">
              <TransactionPreviewCurrentScalable
                balances= {
                  token === 'dappx' ? {
                    "DAPPX": tokenBalance.toString(),
                    "Stake": pledgedBalance.toString(),
                  } :
                  {
                    "ACE": feeTokenBalance.toString(),
                    "EDAO": tokenBalance.toString(),
                    "Stake": pledgedBalance.toString(),
                  }
                }
                error_message={set_err_message}
                token_code={token.toUpperCase()}
                open={wallet_action}
              />
            </div>
          }

          <div className={`container-textfield ${wallet_action ? 'wallet-action' : ''}`}>
            <div className="number-label">
              <label>Unstake</label>
              <div className="right">
                <Button disableRipple onClick={maxVal}>Max</Button>
              </div>
            </div>
            <div className="textfield-block">
              <TextField
                fullWidth
                className="number-field"
                error={outline_error.includes('amount')}
                value={input_val}
                onChange={onChange_num}
                autoComplete="off"
                variant="outlined"
                id='amount'
                placeholder="0.0"
                disabled={wallet_action ? true : false}
                name="number-field"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              <img className="coin-logo" src={logos[token]} alt={"logo"} />
            </div>
            {!getUnpledgeFee() ? (<div className="convertion">≈ {input_fiat_val} USD</div>) : ''}

            {
              (isUnderPenalty && getUnpledgeFee() && input_val) ? (
              <div className="number-label">
                <label className="service_label">
                  Unstaking Fee:<div className="convertion">{unpledgeFee.toFixed(6).replace(/\.?0+$/, '')} ACE</div>
                  <ClickAwayListener onClickAway={()=> setServiceFeeTooltip(false)}>
                    <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={()=> setServiceFeeTooltip(false)}
                        open={serviceFeeTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                        placement="right"
                        title={
                          <div className="tooltip-content">
                            <h5>Unstaking Fee</h5>
                            <ul>
                              <li><span>{unpledgeFeeNitro}</span> Nitro fees.</li>
                              <li><span>{unpledgeFeeUBI} ACE</span> UBI protocol fee will be used to compensate token holders, including land owners.</li>
                              <li><span>{unpledgeFeeCore} ACE</span> Core Ecosystem development fee to deploy a self-sufficient, robust, decentralized immersive web service.</li>
                            </ul>
                          </div>
                        }
                    >
                      <IconButton onClick={()=> setServiceFeeTooltip(true)} className={`info-btn ${serviceFeeTooltip ? 'open' : ''}`}><InfoOutlinedIcon/></IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </label>
              </div>
              ) : ''
            }
          </div>

          {!wallet_action && isUnderPenalty &&
            <div className="container-info">
              <p className="text">
                {
                  token === 'dappx' ?
                    `Unstaking ${token.toUpperCase()} within ${holdingPeriod} hour/s will result in a penalty fee.` :
                    `Unstaking now will incur penalty fee.`
                }
              </p>
              <ClickAwayListener onClickAway={()=> setPledgeTooltip(false)}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={()=> setPledgeTooltip(false)}
                  open={pledgeTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                  placement={token === 'dappx' ? 'right' : 'top'}
                  title={
                    <div className={`tooltip-content ${token}-timer`}>
                      {
                        token === 'dappx' ?
                          (
                            <>
                            <h5>Stake Limit</h5>
                            <p>A penalty of <span>{penaltyFee}% ({penaltyInNumbers} {token.toUpperCase()})</span>
                              &nbsp;will be applied if you unstake within <span> {hourlyCountDown}:{minutesCountDown}:{secondCountDown}</span> </p>
                            </>
                          ) : (
                            <>
                              <p>Penalty will be applied if your unstake within <span> {hourlyCountDown}:{minutesCountDown}:{secondCountDown}</span> </p>
                            </>
                          )
                      }

                    </div>
                  }>
                  <Button onClick={()=> setPledgeTooltip(true)} className={`info-btn ${pledgeTooltip ? 'open' : ''}`} disableRipple >See More</Button>
                </Tooltip>
              </ClickAwayListener>
            </div>
          }

          {wallet_action &&
            <div className="container-preview">
              <TransactionPreviewScalable
                balances = {
                  token === 'dappx' ? {
                    "DAPPX": Number(tokenBalance).toFixed(6).toString(),
                    "Stake": Number(pledgedBalance).toFixed(6).toString(),
                  } :
                  {
                    "ACE": Number(feeTokenBalance).toFixed(6).toString(),
                    "EDAO": Number(tokenBalance).toFixed(6).toString(),
                    "Stake": Number(pledgedBalance).toFixed(6).toString(),
                  }
                }
                newBalances = {
                  token === 'dappx' ? {
                    "DAPPX": Number(newTokenBalance).toFixed(6).toString(),
                    "Stake": Number(newPledgeBal).toFixed(6).toString(),
                  } :
                  {
                    "ACE": Number(newFeeTokenBalance).toFixed(6).replace(/\.?0+$/, '').toString(),
                    "EDAO": Number(newTokenBalance.toFixed(6).replace(/\.?0+$/, '')).toString(),
                    "Stake": Number(newPledgeBal).toFixed(6).replace(/\.?0+$/, '').toString(),
                  }
                }
                error_message={set_err_message}
                token_code={token.toUpperCase()}
                open={wallet_action}
              />
            </div>
          }
        </>}
        footer={<>
          {err_message &&
            <Fade className="error custom-error" in={err_message ? true : false} timeout={500} >
              <Alert severity="error"><WarningIcon className="icon" />{err_message}</Alert>
            </Fade>
          }
          <div className="button-block">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disableElevation
              onClick={onSubmit}
              type="submit">{wallet_action ? 'Submit' : 'Continue'}</Button>
            <Button variant="contained" color="default" fullWidth disableElevation onClick={onCloseModal}>Cancel</Button>
          </div>
        </>}
      />

      <CustomDialog
        open={open_modal_loading}
        className="pledge-dialog loading-dialog"
        onClose={onCloseModalLoading}
        header="Processing your request"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={<>
          <LoaderIcon className="icon" />
          <p>Almost there!</p>
          <p>Please wait a moment.</p>
        </>}
      />

      <CustomDialog
        open={open_modal_success}
        className="pledge-dialog success-dialog"
        onClose={onCloseModalSuccess}
        header="Unstake Successful!"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={<>
          <SuccessIcon className="icon" />
          <p>You have successfully unstaked</p>
          <p className="amount-preview">{input_val} {token.toUpperCase()}</p>
        </>}
        footer={<>
          <div className="button-block">
            <Button variant="contained" color="primary" disableElevation onClick={onCloseModalSuccess} type="submit">OK</Button>
          </div>
        </>}
      />

      <CustomDialog
        open={open_modal_error}
        className="pledge-dialog error-dialog"
        onClose={onCloseModalError}
        header="Unstake Failed"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={<>
          <ErrorIcon className="icon" />
          <p>Your unstake transaction didn't push through.</p>
          <p>Amount of {token.toUpperCase()} has been reverted to your wallet.</p>
        </>}
        footer={<>
          <div className="button-block">
            <Button variant="contained" color="primary" disableElevation onClick={onCloseModalError} type="submit">OK</Button>
          </div>
        </>}
      />
    </>
  )
}

export default ButtonUnpledge;
