import React, { useEffect, useState } from "react";
import { Toolbar, IconButton, Button } from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "../../assets/images/icons/download.svg";
// import { ReactComponent as Logo } from "../../assets/images/dappx-logo-2.svg";
import Logo  from "../../assets/images/dappx-logo-2.png";
import { ReactComponent as DownloadLogo } from "../../assets/images/download-logo.svg";
import { ReactComponent as EsDownloadLogo } from "../../assets/images/es-download-logo.svg";
// import LanguageIcon from '@material-ui/icons/Language';
import { Link as RouterLink } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ConnectWalletButton from "./ConnectWalletButton";
import MenuDropdown from "./MenuDropdown";
import { IS_SAT, IS_UAT } from "../../constant/config";
import "./TopNav.scss";
import { useTranslation } from "react-i18next";

const TopnavDesktop = ({ mobileView, menu_data, search_bar }) => {
  const { i18n, t } = useTranslation();
  const [lang, setLand] = useState(i18n.language);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLand(lng);
  };

  console.log(i18n.language)
  const languageHandler = (e) => {
    changeLanguage(e.target.value);
  };

  useEffect(() => {
    const langT = i18n.language.slice(0, 2).toLowerCase()
    if(langT !== "en" && langT !== 'es' && langT !== 'pt') {
      i18n.changeLanguage('en');
      setLand('en');
      return 
    }
    i18n.changeLanguage(i18n.language);
    setLand(i18n.language);
    console.log(i18n.language, i18n.language, i18n.language, i18n.language)
  }, [i18n.language])
  const downBtnLink = IS_SAT
    ? "https://sat.dappstore.me/"
    : IS_UAT
    ? "https://uat.dappstore.me/"
    : "https://dappstore.me/intro";
  return (
    <Toolbar className="custom-toolbar">
      <RouterLink to="/" className="logo-block">
        {/* <Logo /> */}
        <img src={Logo} alt="Logo" />
      </RouterLink>
      <div>
        {menu_data.length > 0 &&
          menu_data.map((val) => {
            if (val.dropdown) {
              return (
                <MenuDropdown
                  key={val.label.toLowerCase().replace(/ /g, "_")}
                  value={val}
                />
              );
            } else {
              return (
                <Button
                  {...{
                    key: val.label.toLowerCase().replace(/ /g, "_"),
                    to: val.href,
                    href: val.href,
                    component: val.target ? "button" : RouterLink,
                    target: val.target,
                  }}
                  className={
                    val.pc_hidden && !mobileView
                      ? "d-none"
                      : val.sp_hidden && isMobile
                      ? "d-none"
                      : ""
                  }
                  disabled={val.disabled}
                >
                  {t(val.label)}
                </Button>
              );
            }
          })}
      </div>

      <div className="right-block">
        {/* 
          <IconButton
            className="language"
            aria-label="language"
            aria-controls="language-dd"
            aria-haspopup="true"
            onClick={openDropdown}
            color="inherit"
          >
            <LanguageIcon />
          </IconButton>
          <Menu
            id="language-dd"
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={closeDropdown}
          >
            <MenuItem onClick={closeDropdown}>English</MenuItem>
            <MenuItem onClick={closeDropdown}>Korean</MenuItem>
          </Menu> 
        */}

        <a
          href={downBtnLink}
          edge="end"
          component={RouterLink}
          target={"_blank"}
          className="update-download-btn"
        >
          {/* <DownloadIcon color='#fff' />
          Install DAPPX */}
          {lang?.slice(0, 2).toLowerCase() === "en" && (
 <DownloadLogo />
          )}

{lang?.slice(0, 2).toLowerCase() !== "en" && (
 <EsDownloadLogo />
          )}
         
        </a>
        <ConnectWalletButton />
        <div className="lang">
          <div className="lang-active">
            <img
              src="https://dappstore.me/intro/wp-content/themes/dappstore/assets/img/globelang.png"
              alt=""
            />
            <span> {lang?.slice(0, 2).toUpperCase()}</span>
           
          </div>

          <ul>
            <li onClick={() => changeLanguage("en")}>English</li>
            <li onClick={() => changeLanguage("es")}>Spanish</li>
            <li onClick={() => changeLanguage("pt")}>Portuguese</li>
          </ul>
        </div>

        {/* {search_bar()} */}
      </div>
    </Toolbar>
  );
};

export default TopnavDesktop;
