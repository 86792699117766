import { useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Container } from '@material-ui/core';
import SectionSlider from './SectionSlider';
import SectionRecommended from './SectionRecommended';
import SectionSpecial from './SectionSpecial';
import SectionPopular from './SectionPopular';
import { SearchContext } from '../../context/SearchContext';
import { useWindowSize } from '../../constant/useWindowSize';
import './Games.scss';

import recommended_sandbox from '../../assets/images/page-games/recommended/sandbox.jpg';
import recommended_chainz_arena from '../../assets/images/page-games/recommended/chainz-arena.jpg';
import recommended_my_crypto_heroes from '../../assets/images/page-games/recommended/my-crypto-heroes.jpg';
import recommended_0xgames from '../../assets/images/page-games/recommended/0xgames.jpg';

import special_gods_unchained from '../../assets/images/page-games/special/gods-unchained.jpg';

import video_sandbox from '../../assets/videos/sandbox.mp4';
import video_chainz_arena from '../../assets/videos/chainz-arena.mp4';
// import video_axie_infinity from '../../assets/videos/axie-infinity.mp4';
// import video_dragonereum from '../../assets/videos/dragonereum.mp4';

import { ReactComponent as ETH } from '../../assets/images/icons/eth-icon.svg';

import logo_xgames from '../../assets/images/page-games/recommended/logo-0xgames.png';
import logo_heroes from '../../assets/images/page-games/recommended/logo-my-crypto-heroes.png';
import logo_unchained from '../../assets/images/page-games/special/logo-gods-unchained.png';

const Games = () => {
  const { searchState } = useContext(SearchContext);

  let recomended_data_1 = [
    // {
    //   _id: uuidv4(),
    //   image: recommended_crypto_arcade,
    //   name: 'Dapp Arcade',
    //   currency: <ETH/>,
    //   href: process.env.REACT_APP_DAPPARCADE_URL,
    //   videoOnHover: video_arcade,
    //   store: '',
    // },
    {
      _id: uuidv4(),
      image: recommended_sandbox,
      name: 'Sandbox',
      currency: <ETH/>,
      href: 'https://www.sandbox.game/login/?r=P~.UQTzO_nF.teQMC2owU',
      videoOnHover: video_sandbox,
      store: 'https://opensea.io/assets/sandbox',
    },
    {
      _id: uuidv4(),
      image: recommended_chainz_arena,
      name: 'Chainz Arena',
      currency: <ETH/>,
      href: 'https://www.chainzarena.com/',
      videoOnHover: video_chainz_arena,
      store: 'https://opensea.io/assets?search[query]=chainz%20arena',
    }
  ]

  let recomended_data_2 = [
    // {
    //   _id: uuidv4(),
    //   image: recommended_axie,
    //   name: 'Axie Infinity',
    //   logo: logo_axie,
    //   currency: <ETH/>,
    //   href: 'https://axieinfinity.com/',
    //   videoOnHover: video_axie_infinity,
    //   store: 'https://opensea.io/assets/axie',
    // },
    // {
    //   _id: uuidv4(),
    //   image: recommended_dragonereum,
    //   name: 'Dragonereum',
    //   logo: logo_dragonereum,
    //   currency: <ETH/>,
    //   href: 'https://dragonereum.io/',
    //   videoOnHover: video_dragonereum,
    //   store: 'https://opensea.io/assets/dragonereum',
    // }
  ]

  let recomended_data_3 = [
    {
      _id: uuidv4(),
      image: recommended_my_crypto_heroes,
      name: 'My Crypto Heroes',
      logo: logo_heroes,
      currency: <ETH/>,
      href: 'https://www.mycryptoheroes.net/',
      store: 'https://opensea.io/assets/mycryptoheroes',
    },
    {
      _id: uuidv4(),
      image: recommended_0xgames,
      name: '0xGames',
      logo: logo_xgames,
      currency: <ETH/>,
      href: 'https://0x.games/',
      store: 'https://opensea.io/assets?search[query]=0xgames',
    }
  ]

  let special_data_1 = [
    // {
    //   _id: uuidv4(),
    //   image: special_crypto_run,
    //   currency: <ETH/>,
    //   name: 'Crypto Run',
    //   logo: logo_crypto_un,
    //   href: process.env.REACT_APP_DAPPARCADE_URL,
    //   hashtag: ['Action', 'Adventure', 'Casual'],
    //   store: '',
    // },
  ]

  let special_data_2 = [
    {
      _id: uuidv4(),
      image: special_gods_unchained,
      name: 'Gods Unchained',
      logo: logo_unchained,
      currency: <ETH/>,
      href: 'https://godsunchained.com/',
      hashtag: ['Indie', 'Racing', 'Simulation'],
      store: 'https://opensea.io/assets/gods-unchained',
    },
  ]

  const [md_view, set_md_view] = useState(false);
  const [xs_view, set_xs_view] = useState(false);

  const [ window_width ] = useWindowSize();
  useEffect(() => {
    if(window_width < 960 && window_width > 600) set_md_view(true)
    else set_md_view(false)

    if(window_width <= 600) set_xs_view(true)
    else set_xs_view(false)
  }, [window_width]);

  return (
    <div className="page-content game-content">
      <SectionSlider 
        searchState={searchState.searchValue}
      />
      <Container>
        <SectionRecommended
          data={recomended_data_1}
          searchState={searchState.searchValue}
          md_view={md_view}
          xs_view={xs_view}
        />
      </Container>
      <Container style={{display:"none"}}>
        <SectionSpecial
          data={special_data_1}
          searchState={searchState.searchValue}
          md_view={md_view}
          xs_view={xs_view}
        />
      </Container>
      <Container>
        <SectionRecommended
          data={recomended_data_2}
          searchState={searchState.searchValue}
          md_view={md_view}
          xs_view={xs_view}
        />
      </Container>
      <Container>
        <SectionPopular
          searchState={searchState.searchValue}
          md_view={md_view}
          xs_view={xs_view}
        />
      </Container>
      <Container>
        <SectionSpecial
          data={special_data_2}
          searchState={searchState.searchValue}
          md_view={md_view}
          xs_view={xs_view}
        />
      </Container>
      <Container className="pb-80">
        <SectionRecommended
          data={recomended_data_3}
          searchState={searchState.searchValue}
          md_view={md_view}
          xs_view={xs_view}
        />
      </Container>
    </div>
  );
}

export default Games;