import React from 'react';
import { ReactComponent as LoaderIcon } from '../../assets/images/icons/loader.svg';
import Fade from '@material-ui/core/Fade';
import './Loader.scss'

const Loader = ({ appear, timeout, className, coloredBg, width, id }) => {

  return (
    <Fade in={appear} timeout={timeout ? timeout : 500}>
      <div className={`loader ${coloredBg ? 'colored-bg' : ''} ${className ? className : ''} ${appear ? '': 'appear'}`} >
        <LoaderIcon
          className="loader-icon"
          id={id ? id : null}
          width={width}
        />
      </div>
    </Fade>
  );
}

export default Loader;