import React, { useEffect, useState } from 'react';
import {
  Button
} from "@material-ui/core";
import { ReactComponent as HistoryBtn } from '../../../../assets/images/icons/history-btn.svg';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import HistoryItem from './HistoryItem';
import axios from 'axios';
import { Config } from '../../../../constant/config';
import Loader from '../../../../components/loader/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import fi from 'date-fns/esm/locale/fi/index.js';
const TransactionHistory = ({ toggleDrawer }) => {
  const [loading, setLoading] = useState(true)
  const [transactions, setTransactions] = useState([])
  const [totalCount, setTotalCount] = useState()
  const sessionID = localStorage.getItem('session-id');
  const [page, setPage] = useState(0)
  const getTransactionHistory = async (moreFetch) => {
    try {
      if (!moreFetch) {
        setLoading(true)
      }

      const result = await axios.get(
        Config.DAPPX_BASE_URL + `/users/acent/transactionsHistory?limit=10&offset=${page * 10}`,
        {
          headers: {
            'session-id': sessionID,
          },
        },
      )
      if (moreFetch) {
        setTransactions([...transactions, ...result.data.txs])
      } else {
        setTransactions(result.data.txs)
      }

      setLoading(false)
      setTotalCount(result.data.total_rows)
      setPage(page + 1)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  useEffect(() => {
    getTransactionHistory()
  }, []);

  console.log(totalCount, transactions.length, 'totalCount !== transactions.length')
  return (
    <>
      <div className='history-drawer'>
        <h2>
          History
        </h2>
        <div className="history-wrapper">
          <div className="history-item-area" id="scrollableDiv">
            <InfiniteScroll
              dataLength={transactions.length} //This is important field to render the next data
              next={() => getTransactionHistory(true)}
              hasMore={totalCount !== transactions.length}
              loader={<span>Loading...</span>}
              endMessage={
                <p className='end-of'>End of transactions</p>
              }
              scrollableTarget="scrollableDiv"
            >
              {!loading && transactions.length > 0 && (
                <>
                  {transactions.map((transaction, index) => {
                    return (
                      <HistoryItem transaction={transaction} key={index} />
                    )
                  })}
                 
                </>
              )}

              {!loading && transactions.length < 1 && (
                <>

                  <p className='end-of'>Transactions not found</p>
                </>
              )}


              {loading &&
                <Loader
                  appear={true}
                  timeout={1000}
                  width="50"
                />
              }
            </InfiniteScroll>

          </div>
          <Button className="close-btn"
            component="a"
            onClick={toggleDrawer}
          >
            Close
          </Button>
        </div>
      </div>
    </>
  );
}

export default TransactionHistory;
