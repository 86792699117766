import React, { useEffect, useState, useContext } from "react";
import "react-modern-drawer/dist/index.css";
import SideBarItem from "./SideBarItem";
import { useHistory } from "react-router-dom";
import Loader from "../loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { AuthContext } from "../../context/AuthContext";
import EmailAndReferral from "./EmailAndReferral";
import DP_Placeholder from "../../assets/images/dappxpr/dp-placeholder.png";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ethIcon from "../../assets/images/dappxpr/ethereum.png";
import aceIcon from "../../assets/images/dappxpr/acent.png";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import axios from "axios";
import { Config } from "../../constant/config";
import { IconButton } from "@material-ui/core";
import "./UserSidebar.scss";
import CopyToClipboard from "react-copy-to-clipboard";
import LanguageIcon from "@material-ui/icons/FileCopy";
import { CircularProgress } from "@mui/material";

const SideBarData = ({ toggleDrawer }) => {
  const currentNetowrk = localStorage.getItem('current_network')
  const sessionID = localStorage.getItem("session-id");
  const history = useHistory();
  const { authState, dispatch } = useContext(AuthContext);
  const { wallet_balance } = authState;
  const [loading, setLoading] = useState(true);
  const [qrCode, setQrCode] = useState("");
  const [open, setOpen] = React.useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isLoadingUserData, setIsLoadingUserData] = useState(true);
  const [network, setNetwork] = useState('ETH')

  function handleEventsAfterLoggingOut() {
    dispatch({ type: "LOGOUT" });
    history.push("/");
    window.location.reload();
  }

  const isUserVerified = async () => {
    await axios
      .get(Config.DAPPX_BASE_URL + "/users/userProfile", {
        headers: {
          "session-id": sessionID,
        },
      })
      .then((res) => {
        setIsVerified(res.data.is_verified);
        setIsLoadingUserData(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingUserData(false);
      });
  };

  useEffect(() => {
    if (currentNetowrk == '0x22b8' || currentNetowrk == '0x231d'){
      setNetwork('ACE')
    }
  },[])

  useEffect(() => {
    if (wallet_balance) {
      setLoading(false);
    }
    isUserVerified();
  }, [wallet_balance]);

  const handleClickOpen = () => {
    setOpen(true);
    axios
      .get(Config.DAPPX_BASE_URL + "/users/getQRCode", {
        headers: {
          "session-id": sessionID,
        },
      })
      .then((res) => {
        setQrCode(
          `http://api.qrserver.com/v1/create-qr-code/?data=${res.data.code}`
        );
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log('CHEK',wallet_balance);
  let update_wallet_balance = wallet_balance;

  if (wallet_balance.length > 2) {
    update_wallet_balance = [
      wallet_balance.find((wb) => wb.token === "DAPPX"),
      wallet_balance.find((wb) => wb.token === "ACE"),
      wallet_balance.find((wb) => wb.token === "ETH"),
    ];
  }

  const walletAddressFormat = (wallet) => {
    return (
      wallet?.substring(0, 4) +
      "...." +
      wallet?.substring(wallet.length - 4, wallet.length)
    );
  };

  return (
    <>
      <div className="history-drawer update-sidebar">
        <div className="section s1">
          {/* <div className="img-container">
            <img src={ DP_Placeholder} alt="place your cool face here" />
          </div> */}
          <div className="details">
            <div className="name">
              <div className="detail-top">
                <Dialog className="qr_dialog" open={open} onClose={handleClose}>
                  <div className="qr_main_dialog">
                    <span>
                      <CloseIcon onClick={handleClose} />
                    </span>
                    <p>
                      Scan this QR Code to link your DAPPX account to your
                      mobile browser.
                    </p>
                    <div>
                      <img
                        src={qrCode ? qrCode : DP_Placeholder}
                        alt="place your cool face here"
                      />
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div className="qr-container">
          <CopyToClipboard text={localStorage.getItem("wallet_address")}>
            <span>
              <IconButton
                className="language"
                aria-label="language"
                aria-controls="language-dd"
                aria-haspopup="true"
                color="inherit"
              >
                <LanguageIcon />
              </IconButton>
            </span>
          </CopyToClipboard>
          <p className="verify-header__tex">
            {localStorage.getItem("wallet_address") &&
              walletAddressFormat(localStorage.getItem("wallet_address"))}
          </p>

          <a className="qr_diag_btn" onClick={handleClickOpen}>
            <span>
              <QrCodeIcon />
            </span>
          </a>
        </div>

        <div className="history-wrapper">
          <div
            className="history-item-area"
            id="scrollableDiv"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "17px",
                  gap: "10px",
                }}
              >
                <img
                  src={network === 'ACE' ? aceIcon : ethIcon}
                  alt=""
                  style={{ width: "14px", height: "15px" }}
                />
                <p>{network === 'ACE' ? 'Acent' : 'Ethereum'} Network</p>
              </div>
              <InfiniteScroll
                dataLength={update_wallet_balance.length}
                loader={<span>Loading...</span>}
                scrollableTarget="scrollableDiv"
              >
                {!loading && wallet_balance.length > 0 && (
                  <>
                    {update_wallet_balance.map((token, index) => {
                      return <SideBarItem token={token} key={index} />;
                    })}
                  </>
                )}

                {!loading && update_wallet_balance.length < 1 && (
                  <>
                    <p className="end-of">Tokens not found</p>
                  </>
                )}

                {loading && <Loader appear={true} timeout={1000} width="50" />}
              </InfiniteScroll>
            </div>
            <div>
              {/* {isLoadingUserData && <Skeleton height="40vh"></Skeleton>} */}
              {isLoadingUserData && (
                <CircularProgress className="mb-5 sidebar-loader" />
              )}
              {!isLoadingUserData && (
                <EmailAndReferral isVerified={isVerified} />
              )}
              <button
                className="disconnect-button"
                onClick={handleEventsAfterLoggingOut}
              >
                Disconnect Wallet
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBarData;
