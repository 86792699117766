import { useState } from "react";
import MinusImg from "../../assets/images/icons/minus-2.svg";
import PlusImg from "../../assets/images/icons/plus.svg";
import TrashImg from "../../assets/images/icons/trash.svg";
const CartItem = ({item, deleteCardItem, plusHandler, minusHandler}) => {
  console.log({item})
  const {pack, quantity} = item
  const [active, setActive] = useState(97)  

  // const minusHandler = () => {
//   if(active < 1) {
  //       return 
  //   }
  //   setActive(active - 1)
  // }
  // const plusHandler = () => {
  //   if(active > 98) return

  //   setActive(active + 1)
  // }

  return (
    <div className="cart-item">
      {/* <img src={DappxImg} className="currency-style2" alt="" /> */}
      <p>{pack.token_count} DAPPX</p>

      <span style={{width:"75px",overflowWrap:"break-word"}}>${ (pack.price * quantity).toFixed(2)}</span>
      {/* <span>{ (pack.actual_price * quantity).toFixed(2)}</span> */}
      {/* <span>{ (pack.price_with_markup * quantity).toFixed(2)}</span> */}
      <div className="pricing">
        <button onClick={() => minusHandler(pack._id)} disabled={quantity <= 1}>
          <img src={MinusImg} className="currency-style2" alt="" />
        </button>

        <div style={{width:"20px"}}>

        <span>{quantity}</span>
        </div>

        <button onClick={() => plusHandler(pack._id)}>
          <img src={PlusImg} className="currency-style2" alt="" />
        </button>
      </div>

      <button onClick={() => deleteCardItem(pack._id)}>
        <img src={TrashImg} className="currency-style2" alt="" />
      </button>
    </div>
  );
};

export default CartItem;
