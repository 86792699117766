import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import { Button, Fade, TextField, Link, FormGroup, FormControlLabel, Checkbox, ClickAwayListener, Tooltip, IconButton } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import { Config } from '../../../../constant/config';
import CustomDialog from '../../../../components/Modal/CustomDialog';
import acent_logo from '../../../../assets/images/icons/acent-logo.svg';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ReactComponent as WarningIcon } from '../../../../assets/images/icons/warning.svg';
import { ReactComponent as LoaderIcon } from '../../../../assets/images/icons/loader-2.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/images/icons/error-x.svg';
import { ReactComponent as SuccessIcon } from '../../../../assets/images/icons/success-check.svg';
import TransactionPreviewCurrent from '../../../../components/Modal/TransactionPreviewCurrent';
import TransactionPreview from '../../../../components/Modal/TransactionPreview';
import { NumberFormatCustom } from '../../../../components/CustomInput';
import './index.scss';
import Countdown, {zeroPad} from "react-countdown";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
const useStyles = makeStyles(theme => ({

  btn: {
    fontSize: '1rem',
    lineHeight: 1.6,
    borderRadius: '5px',
    borderColor: '#016ce9',
    color: '#016ce9',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
}));

const GovernanceUnPledge = (props) => {
const classes=useStyles()
  const [input_val, set_input_val] = useState('')
  const [input_fiat_val, set_input_fiat_val] = useState(0)
  const [wallet_action, set_wallet_action] = useState(false)
  const [check_box, set_check_box] = useState(false)
  const [minPledge, setMinPledge] = useState(0)
  const [maxPledge, setMaxPledge] = useState(0)
  const [maxAccPledge, setMaxAccPledge] = useState(0)
  const [holdingPeriod, setHoldingPeriod] = useState(0)
  const [activationFee, setActivationFee] = useState(0)
  const [aceBalance, setAceBalance] = useState(0)

  const [pledgedBalance, setPledgedBalance] = useState('')
  const [fiat_val, setFiat_val] = useState(0)
  const [newDappXBal, setNewDappXBal] = useState(0)
  const [newPledgeBal, setNewPledgeBal] = useState(0)
  const [newMaxAccPledge, setNewMaxAccPledge] = useState(0)
  const [activatedUser, setActivatedUser] = useState(false)
  const [usdValue, setUsdValue] = useState(0)
  const [isMarketPriceReady, setIsMarketPriceReady] = useState(true)
  const [marketPrice, setMarketPrice] = useState(undefined)
  const [isTimerRunning, setIsTimerRunning] = useState(false)
  const [isPenalty,setIsPenalty]=useState(false)
  const [penaltyFee,setPenaltyFee]=useState(0)
  const [penaltyPercentage,setPenaltyPercentage]=useState(0)
  const [holdPeriod,setHoldPeriod]=useState(new Date())
  const [isEligible,setIsEligible]=useState(false)
  const [show, setShow] = useState(false)
  const [coreFee, setCoreFee] = useState(0)
  const [nitroFee, setNitroFee] = useState(0)
  const [ubiFee, setUbiFee] = useState(0)
  const session_Id = localStorage.getItem('session-id')
  let whole_num = aceBalance ? aceBalance.toString().split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
  let decimal_num = aceBalance ? aceBalance.toString().split('.')[1] : '';
  const [hourlyCountDown, setHourlyCountDown] = useState(0)
  const [minutesCountDown, setMinutesCountDown] = useState(0)
  const [secondCountDown, setSecondsCountDown] = useState(0)


  // Input Error
  const [err_message, set_err_message] = useState('');
  const [outline_error, set_outline_error] = useState([]);


  const [open_service_tooltip, set_service_tooltip] = useState(false)
  const onOpen_service_tooltip = () => set_service_tooltip(true)
  const onClose_service_tooltip = () => set_service_tooltip(false)
  // Modal Deposit
  const [open_modal, set_open_modal] = useState(false);
  const onOpenModal = () => {
    set_open_modal(true)
  }
  const onCloseModal = (e) => {
    e.preventDefault()
    if (wallet_action) {
      set_wallet_action(false)
      set_err_message('')
    } else {
      onClear()
    }
  }

  const hasUserPledged = async () => {
    await axios.get(Config.DAPPX_BASE_URL + '/users/acent/governance-pledging/status', {headers: {'session-id': session_Id}})
    .then((result) => {
      setActivatedUser(result.data.activated)
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const fetchAceBalance = async () => {
    await axios.get(Config.DAPPX_BASE_URL + '/users/acent/active/balance', {headers: {'session-id': session_Id}})
        .then(async(result) => {
          let hasNoDecimals = result.data.balance.toFixed(6).split('.')[1]
          hasNoDecimals == 0 || hasNoDecimals === undefined ? setAceBalance(Number(Math.trunc(Number(result.data.balance)))) : setAceBalance(Number(result.data.balance).toFixed(6))
          // setAceBalance(Number(result.data.balance))
        })
        .catch((error) => {
          console.log(error)
        })
  }

  const fetchPledgeBalance = async () => {
    await axios.get(Config.DAPPX_BASE_URL + '/users/acent/governance-pledging/balance', {headers: {'session-id': session_Id}})
    .then((result) => {
      // let balance_wei = Web3.utils.fromWei(result.data.dappx_balance.toString(), 'ether');
      // let num2 = Number(balance_wei.toString().match(/^\d+(?:\.\d{0,6})?/));
      // let hasNoDecimals = balance_wei.split('.')[1]
      // hasNoDecimals == 0 || hasNoDecimals === undefined ? setPledgedBalance(Number(Math.trunc(num2))) : setPledgedBalance(Number(num2).toFixed(6))
      var date1 = new Date();
      var date2 = new Date(result.data.hold_until);
      //Penalty Period
      if(date1.getTime() <= date2.getTime()){
        // const fee=result.data.balance*(result.data.penalty_fee/100)
        // setPenaltyFee(fee)

        setIsPenalty(true)
        setHoldPeriod(result.data.hold_until)
        //date 1 is newer
      }
      if(result.data.balance >0){
        setIsEligible(true)
      }
      setPledgedBalance(result.data.balance)

    })
    .catch((error) => {
      console.log(error)
    })
  }
useEffect(()=>{
  if (holdingPeriod && holdPeriod && isPenalty) {
    setInterval(() => {
      getTimeRemaining(holdPeriod)
    }, 1000);
  }
},[holdPeriod,holdingPeriod])
  const getTimeRemaining = (endtime) => {

    // Add Number of days to the createdAt date
    // var myDate = new Date(endtime);
    // myDate.setDate(myDate.getDate() + parseInt(3));

    let myDate = moment(endtime).add(holdingPeriod, 'hours');

    const total = Date.parse(myDate) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % holdingPeriod)

    setHourlyCountDown(hours < 10 ? `0${hours}` : hours)
    setMinutesCountDown(minutes < 10 ? `0${minutes}` : minutes)
    setSecondsCountDown(seconds < 10 ? `0${seconds}` : seconds)

    if (hours <= 0 && minutes <= 0 && seconds <= 0) {
      setIsPenalty(false)
    }
  }
  const fetchPledgeRules = async () => {
    axios.get(Config.DAPPX_BASE_URL + '/users/acent/governance-pledging/settings', {headers: {'session-id': session_Id}})
    .then((res) => {
      // console.log('nn',res.data)
      // let min_pledge = Web3.utils.fromWei(res.data.min_pledge_per_tx.toString(), 'ether');
      // let max_pledge = Web3.utils.fromWei(res.data.max_pledge_per_tx.toString(), 'ether');
      // let max_account_pledge = Web3.utils.fromWei(res.data.max_pledge_per_user.toString(), 'ether');
      // let activation_fee = Web3.utils.fromWei(res.data.activation_fee.toString(), 'ether')
      // console.log(activation_fee)
      setMinPledge(Number(res.data.min_pledge_per_tx))
      setMaxPledge(Number(res.data.max_pledge_per_tx));
      setMaxAccPledge(Number(res.data.max_pledge_per_user))
      setActivationFee(Number(res.data.activation_fee))
      setHoldingPeriod(Number(res.data.penalty_duration))
      let unpledge_fee_core=res.data.unpledge_fee_core
      let unpledge_fee_nitro=res.data.unpledge_fee_nitro
      let unpledge_fee_ubi=res.data.unpledge_fee_ubi
      let totalFee=unpledge_fee_core+unpledge_fee_nitro+unpledge_fee_ubi
      setCoreFee(unpledge_fee_core)
      setNitroFee(unpledge_fee_nitro)
      setUbiFee(unpledge_fee_ubi)
      setPenaltyPercentage(totalFee)
    })
    .catch((error) => {
      console.log(error);
    })
  }


  // Modal Loading
  const [open_modal_loading, set_open_modal_loading] = useState(false);
  const onOpenModalLoading = () => {
    set_open_modal_loading(true)
    set_wallet_action(false)
    set_open_modal(false)
  }
  const onCloseModalLoading = () => {
    set_open_modal_loading(false)
  }

  // Modal Success
  const [open_modal_success, set_open_modal_success] = useState(false);
  const onOpenModalSuccess = () => {
    set_open_modal_success(true)
    set_open_modal(false)
  }
  const onCloseModalSuccess = () => {
    set_open_modal_success(false)
    window.location.reload()
  }

  // Modal Error
  const [open_modal_error, set_open_modal_error] = useState(false);
  const onOpenModalError = () => {
    set_open_modal_error(true)
    onClear()
  }
  const onCloseModalError = () => {
    set_open_modal_error(false)
    window.location.reload()
  }

  // Functions
  const remove_errors = (target) => {
    set_err_message('')
    let update_outline_error = outline_error.filter(id => id !== target)
    set_outline_error(update_outline_error)
  }

  const convertCurrency = async () => {
    await axios.get(Config.DAPPX_BASE_URL + '/users/getMarketPrices')
    .then((res) => {
      setUsdValue(res.data.DAPPX.USD)
    })
    .catch((error) => {
      set_input_fiat_val(0)
      set_err_message("Can't pull conversion rate.")
    })
  }

  const convertValue = (balance, tokenCode) => {
    let convertion_currency = 'USD';

    if (isMarketPriceReady) {
      axios.get(Config.DAPPX_BASE_URL + '/users/getMarketPrices')
        .then((prices) => {
          let fiat_currency = prices.data[tokenCode][convertion_currency] ? prices.data[tokenCode][convertion_currency] : 0;
          let fiat_value_value = parseFloat(balance) * parseFloat(fiat_currency);
          let convertion_value;

          if (fiat_value_value === 0) {
            convertion_value = parseFloat(fiat_value_value)
          } else {
            convertion_value = parseFloat(fiat_value_value).toFixed(2)
          }

          set_input_fiat_val(convertion_value);
          setMarketPrice(prices.data)
          setIsMarketPriceReady(false)
          if (!isTimerRunning) marketPriceTimer()
        })
        .catch((error) => {
          console.log('convertValue error: ', error)
          set_input_fiat_val(0)
          set_err_message("Can't pull conversion rate.")
        })
    } else {
      let fiat_currency = marketPrice[tokenCode][convertion_currency] ? marketPrice[tokenCode][convertion_currency] : 0;
      let fiat_value_value = parseFloat(balance) * parseFloat(fiat_currency);
      let convertion_value;

      if (fiat_value_value === 0) {
        convertion_value = parseFloat(fiat_value_value)
      } else {
        convertion_value = parseFloat(fiat_value_value).toFixed(2)
      }

      set_input_fiat_val(convertion_value);
      if (!isTimerRunning) marketPriceTimer()
    }
  }

  const marketPriceTimer = () => {
    setIsTimerRunning(true)

    setTimeout(() => {
      setIsMarketPriceReady(true)
      setIsTimerRunning(false)
    }, 60000)
  }
  const maxVal = () => {
    set_input_val(pledgedBalance)
    convertValue(pledgedBalance, 'ACE')
  }
  useEffect(() => {
    hasUserPledged()
    fetchPledgeRules()
    fetchAceBalance()
    fetchPledgeBalance()
    convertCurrency()
  },[])
useEffect(()=>{
  set_open_modal(props.open)

},[props.open])
  useEffect(() => {
    if(maxAccPledge>=pledgedBalance){
      setNewMaxAccPledge(maxAccPledge - pledgedBalance)

    }else if(maxAccPledge<=pledgedBalance){
      setNewMaxAccPledge(pledgedBalance-maxAccPledge  )

    }
  },[input_val, pledgedBalance,open_modal])

  const onChange_num = (e) => {
    const target_val = e.target.value;
    const decimals = target_val.split('.')[1] || '';
    remove_errors(e.target.id)
    if(target_val !== ''){
      setShow(true)
      let penalty_fee=target_val*(penaltyPercentage/100)
      setPenaltyFee(penalty_fee)
    }

    if (target_val !== '' && decimals.length <= 6) {
      setShow(true)
      set_input_val(target_val)
      let convertion_value = (target_val * usdValue).toFixed(2)
      // convertValue(target_val, 'DAPPX')
      set_input_fiat_val(convertion_value)
      convertCurrency()
    }

    if (target_val === '') {
      setShow(false)
      set_input_val(target_val)
      setPenaltyFee(0)
      set_input_fiat_val(target_val * usdValue)
      convertCurrency()
      // convertValue(0, 'DAPPX')
    }
  }


  const onSubmit = () => {
    setNewDappXBal(Number(aceBalance) + Number(input_val))
    setNewPledgeBal(Number(pledgedBalance) - Number(input_val) )
      if(Number(input_val) > pledgedBalance ) {
        set_err_message('Unpledge amount is greater than the current pledge!')

      }   else if(Number(input_val) === 0 || !input_val) {
        set_err_message('Amount should be greater than zero!')

      } else {
        set_err_message('')
        if (wallet_action) {

          const data = {
            amount: parseFloat(input_val),
          }
          onOpenModalLoading()

          axios.post(Config.DAPPX_BASE_URL + '/users/acent/governance-pledging/unpledge', data, {headers: {'session-id': session_Id}})
          .then((res) => {
            setAceBalance(newDappXBal)
            setPledgedBalance(newPledgeBal)
            onCloseModalLoading()
            onOpenModalSuccess()

          })
          .catch((error) => {
            onCloseModalLoading()
            onOpenModalError()

            console.log(error);
          })
        } else {
          set_wallet_action(true)
        }
  }
  }

  const onClear = () => {
    setTimeout(() => {
      set_wallet_action(false)
      set_open_modal(false)
      set_input_val('')
      set_input_fiat_val(0)
      set_err_message('')
      set_outline_error([])
      set_wallet_action(false)
      set_check_box(false)
    }, 400)
  }

  // Tooltip
  const [open_tooltip, set_tooltip] = useState(false)
  const onOpen_tooltip = () => set_tooltip(true)
  const onClose_tooltip = () => set_tooltip(false)
  // Tooltip for Service fee
  const [open_pledge_tooltip, set_pledge_tooltip] = useState(false)
  const onOpen_pledge_tooltip = () => set_pledge_tooltip(true)
  const onClose_pledge_tooltip = () => set_pledge_tooltip(false)

  return (
    <>
      {
        props.check?    <Button
            className={classes.btn}
                style={{ "width": "100%"}}
                variant="outlined"
                color="primary"
                disabled={isEligible?false:true}
                onClick={()=>{
                  onOpenModal()
                }}>
              Unstake
            </Button>
            :    <Button
                variant="outlined"
                color="primary"
                disabled={isEligible?false:true}
                onClick={()=>{
                  onOpenModal()
                }}>
              Unstake
            </Button>
      }


      {
    <CustomDialog
        open={open_modal}
        className="pledge-dialog-governance"
        onClose={onCloseModal}
        header="Unstake ACE Governance"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={
        <>
          {!wallet_action &&
            <div className="container-balance">
              <TransactionPreviewCurrent
                bal_1_title="Balance"
                bal_2_title="Stake"
                current_bal_1={aceBalance.toString()}
                current_bal_2={pledgedBalance.toString()}
                error_message={set_err_message}
                token_code="ACE"
                open={wallet_action}
              />
            </div>
          }

          <div className={`container-textfield ${wallet_action ? 'wallet-action' : ''}`}>
            <div className="number-label">
              <label>
                Unstake

              </label>
              <div className="right">
                <Button disableRipple onClick={maxVal}>Max</Button>
              </div>
            </div>
            <div className="textfield-block">
              <TextField
                fullWidth
                className="number-field"
                error={outline_error.includes('amount')}
                value={input_val}
                onChange={onChange_num}
                autoComplete="off"
                variant="outlined"
                id='amount'
                maxlength="9"
                placeholder="0.0"
                disabled={wallet_action ? true : false}
                name="number-field"
                // onInput = {(e) =>{
                //   e.target.value = Math.max(0, parseFloat(e.target.value) ).toString().slice(0,9)
                // }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              {
                show &&
                <div className="convet_in_input">(≈ {input_fiat_val} USD)</div>

              }
              <img className="coin-logo" src={acent_logo} alt={"logo"} />
            </div>
            {
              (isPenalty && show) &&
              <div className="number-label">
                <label className="service_label">
                  Unstaking Fee:<div className="convertion">{Number((input_val*(penaltyPercentage/100)).toFixed(2))} ACE</div>
                  <ClickAwayListener onClickAway={onClose_pledge_tooltip}>
                    <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={onClose_pledge_tooltip}
                        open={open_pledge_tooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow

                        placement="right"
                        title={
                          <div className="tooltip-content">
                            <h5>Unstaking Fee</h5>
                            {/*<p>*/}
                            {/*  {serviceFee}% of the pledged amount will be used to develop the core metaweb ecosystem; compensate the EDAO governance token holders, including landowners; and push for a decentralized immersive web service.*/}
                            {/*</p>*/}
                            <ul>
                              <li>
                                <span>{nitroFee}%</span> Nitro Fees
                              </li>
                              <li>
                                <span>{ubiFee}%</span> UBI protocol fee will be used to compensate token holders, including land owners.
                              </li>
                              <li>
                                <span>{coreFee}%</span> Core Ecosystem development fee to deploy a self-sufficient, robust, decentralized immersive web service.
                              </li>
                            </ul>
                          </div>
                        }
                    >
                      <IconButton onClick={onOpen_pledge_tooltip} className={`info-btn ${open_service_tooltip ? 'open' : ''}`}><InfoOutlinedIcon/></IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </label>
              </div>
            }


          </div>
          {
            (isPenalty && !wallet_action ) &&
          <ClickAwayListener onClickAway={onClose_service_tooltip}>
            <div className="tlp-set">
            <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={onClose_service_tooltip}
                open={open_service_tooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                arrow
                placement="top"
                title={
                  <div className="tooltip-content">
                    <p >
                      Penalty will be applied if you unstake within <span >{hourlyCountDown}:{minutesCountDown}:{secondCountDown}</span>

                    </p>
                  </div>
                }
            >

              <div className="uplg-text">
                <p>Unstaking now will incur penalty fee. <span onClick={onOpen_service_tooltip}>See more</span></p>
              </div>

            </Tooltip>
              </div></ClickAwayListener>
          }


          {wallet_action &&
            <div className="container-preview">
              <TransactionPreview
                bal_1_title="Balance"
                bal_2_title="Stake"
                current_bal_1={aceBalance.toString()}
                current_bal_2={pledgedBalance.toString()}
                after_bal_1={Number((newDappXBal-penaltyFee).toFixed(6)).toString()}
                after_bal_2={Number(newPledgeBal.toFixed(6)).toString()}
                error_message={set_err_message}
                token_code="ACE"
                open={wallet_action}
              />
            </div>
          }
        </>
        }
        footer={<>
          {err_message &&
            <Fade className="error custom-error" in={err_message ? true : false} timeout={500} >
              <Alert severity="error"><WarningIcon className="icon" />{err_message}</Alert>
            </Fade>
          }
          <div className="button-block">
            <Button
              variant="contained"
              fullWidth
              disableElevation
              onClick={onSubmit}
              type="submit">{wallet_action ? 'Submit' : 'Continue'}</Button>
            <Button className="cancel-btn" variant="contained" color="default" fullWidth disableElevation onClick={onCloseModal}>Cancel</Button>
          </div>
        </>}
      />
    }

      <CustomDialog
        open={open_modal_loading}
        className="pledge-dialog loading-dialog"
        onClose={onCloseModalLoading}
        header="Processing your request"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={<>
          <LoaderIcon className="icon"/>
          <p>Almost there!</p>
          <p>Please wait a moment.</p>
        </>}
      />

      <CustomDialog
        open={open_modal_success}
        className="pledge-dialog success-dialog"
        onClose={onCloseModalSuccess}
        header= {"Unstake Successful!"}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={<>
          <SuccessIcon className="icon"/>
          <>
          <p>You have successfully Unstaked</p>
          <p className="amount-preview">{input_val} ACE</p>
          </>
        </>}
        footer={<>
          <div className="button-block">
            <Button variant="contained" color="primary" disableElevation onClick={onCloseModalSuccess} type="submit">OK</Button>
          </div>
        </>}
      />

      <CustomDialog
        open={open_modal_error}
        className="pledge-dialog error-dialog"
        onClose={onCloseModalError}
        header="Stake Failed"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={<>
          <ErrorIcon className="icon"/>
          <p>Your unstake transaction didn't push through.</p>
          <p>Amount of ACE has been reverted to your wallet.</p>
        </>}
        footer={<>
          <div className="button-block">
            <Button variant="contained" color="primary" disableElevation onClick={onCloseModalError} type="submit">OK</Button>
          </div>
        </>}
      />
    </>
  )
}

export default GovernanceUnPledge;
