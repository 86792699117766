import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TopChartCommon from './TopChartCommon';
import image1 from '../../assets/images/section-top-charts/kucoin.jpg';
import image2 from '../../assets/images/section-top-charts/binance.jpg';
import image3 from '../../assets/images/section-top-charts/coinbase.jpg';
import image4 from '../../assets/images/section-top-charts/okex.jpg';
import image5 from '../../assets/images/section-top-charts/huobi.jpg';
import image6 from '../../assets/images/section-top-charts/gate-io.jpg';
import image7 from '../../assets/images/section-top-charts/acent-wallet.jpg';
import image7_long from '../../assets/images/section-top-charts/acent-wallet--long.jpg';
import image8 from '../../assets/images/section-top-charts/foblgate.jpg';
import image9 from '../../assets/images/section-top-charts/upbit.jpg';
import image10 from '../../assets/images/section-top-charts/bithumb.jpg';
import image11 from '../../assets/images/section-top-charts/bitstamp.jpg';
import image12 from '../../assets/images/section-top-charts/crypto-com.jpg';
import { useWindowSize } from '../../constant/useWindowSize';
import './TopCharts.scss'

const ExchangeCharts = ({ searchState }) => {

  const [mobileView, setMobileView] = useState(false);

  const [ window_width ] = useWindowSize();
  useEffect(() => {
    if(window_width < 960) setMobileView(true)
    else setMobileView(false)
  }, [window_width]);

  const data = [
    {
      _id: uuidv4(),
      image: image1,
      name: 'kucoin',
      href: 'https://www.kucoin.com/ucenter/signup?rcode=rJGHYZZ',
    },
    {
      _id: uuidv4(),
      image: image2,
      name: 'binance',
      href: 'https://www.binance.com/en',
    },
    {
      _id: uuidv4(),
      image: image3,
      name: 'coinbase',
      href: 'https://www.coinbase.com/',
    },
    {
      _id: uuidv4(),
      image: image4,
      name: 'okex',
      href: 'https://www.okex.com/',
    },
    {
      _id: uuidv4(),
      image: image5,
      name: 'huobi',
      href: 'https://www.huobi.com/',
    },
    {
      _id: uuidv4(),
      image: image6,
      name: 'gate-io',
      href: 'https://www.gate.io/',
    },
    {
      _id: uuidv4(),
      withLongImage: true,
      image: image7,
      image_long: image7_long,
      name: 'acent-wallet',
      href: process.env.REACT_APP_ACENT_WALLET_URL,
    },
    {
      _id: uuidv4(),
      image: image8,
      name: 'foblgate',
      href: 'https://www.foblgate.com/',
    },
    {
      _id: uuidv4(),
      image: image9,
      name: 'upbit',
      href: 'https://upbit.com/home',
    },
    {
      _id: uuidv4(),
      image: image10,
      name: 'bithumb',
      href: 'https://www.bithumb.com/',
    },
    // {
    //   _id: uuidv4(),
    //   image: image11,
    //   name: 'bitstamp',
    //   href: 'https://www.bitstamp.net/',
    //   hidden: mobileView ? false : true 
    // },
    // {
    //   _id: uuidv4(),
    //   image: image12,
    //   name: 'crypto-com',
    //   href: 'https://crypto.com/',
    //   hidden: mobileView ? false : true 
    // },
  ]

  const otherChartsData = [
    {
      _id: uuidv4(),
      name: 'Game',
      href: '/top-game-charts'
    },
    {
      _id: uuidv4(),
      name: 'NFT',
      href: '/top-nft-charts'
    }, 
    {
      _id: uuidv4(),
      name: 'DeFi',
      href: '/top-defi-charts'
    }
  ]

  return (
    <TopChartCommon 
      searchState={searchState}
      data={data}
      mainChartTitle="Exchange"
      otherChartsData={otherChartsData} 
    />
  );
}

export default ExchangeCharts;