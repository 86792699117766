import { useState, useEffect, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  ClickAwayListener,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { ReactComponent as AcentActiveIcon } from "../../../assets/images/icons/acent-active.svg";
import { ReactComponent as VaultIcon } from "../../../assets/images/icons/vault.svg";
import acent_logo from "../../../../src/assets/images/icons/acent-logo.svg";
import edao_logo from "../../../../src/assets/images/icons/edao-logo.svg";
import DP_Placeholder from "../../../assets/images/dappxpr/dp-placeholder.png";
import { zeroPad } from "react-countdown";
import "./index.scss";
import { Config, DAPPX_BASE_URL } from "../../../constant/config";
import { Ticker } from "react-flip-ticker";
import { Pledge, Transfer } from "../common";
import { AuthContext } from "../../../context/AuthContext";
import { convertValue } from "../Profile";
import { format } from "date-fns";
import { Unpledge } from "../common";
import Pagination from "@mui/material/Pagination";
import SideBarData from "../../../components/UserSidebar/SideBarData";

const APP_URL = DAPPX_BASE_URL;
const sessionID = localStorage.getItem("session-id");

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#111111",
    padding: "2rem",
    "& .amount": {
      "& > *": {
        display: "inline-block",
      },
    },
    "& button": {
      textTransform: "none",
      fontSize: "16px",

      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },

    [theme.breakpoints.down("xs")]: {
      padding: "2rem .5rem",
    },

    [theme.breakpoints.down("sm")]: {
      paddingTop: "5rem",
    },
  },
  divider: {
    width: "1rem",
    visibility: "hidden",
  },
  center: {
    textAlign: "center",
  },
  brdr: {
    borderBottom: "1px solid #000 !important",
    borderColor: "red",
  },
  dot: {
    fontWeight: "bold",
    lineHeight: 2,
  },
  balanceLabel: {
    fontSize: "24px",
    fontWeight: "bold",
    lineHeight: 1,
    color: "#111111",
    borderBottom: "1px solid #dddddd",
    height: "50px",

    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },

  bgLightGray: {
    backgroundColor: "#F7F7F7",
  },
  comingSoonContainer: {
    width: "100%",
    borderRadius: "0.6rem",
    backgroundColor: "rgba(0,0,0,.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1rem",
  },
  comingSoonText: {
    position: "absolute",
    fontWeight: "bold",
    color: "#FFF",
    lineHeight: 1,

    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  detailsTitle: {
    fontSize: "20px",
    lineHeight: "1.2",
    color: "#626262",
    fontWeight: "normal",

    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  btn: {
    fontSize: "1rem",
    lineHeight: 1.6,
    borderRadius: "5px",
    borderColor: "#016ce9",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
}));

const defaultFloatVal = "0.000000";

const EDAO = () => {
  const classes = useStyles();
  const displayDecimals = 6;
  const computeDecimals = 12;

  const [reloadData, setReloadData] = useState(true);
  const [open, setOpen] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [data, setData] = useState({
    mainBal: defaultFloatVal,
    pocketBal: defaultFloatVal,
  });
  const [pledgedBalance, setPledgedBalance] = useState(0);
  const [aceEarned, setAceEarned] = useState(0);
  const [aceFiatValue, setAceFiatValue] = useState(0.0);
  const [governancePledgeHistory, setGovernancePledgeHistory] = useState([]);
  const { mainBal, pocketBal } = data;
  const balance = (parseFloat(mainBal) + parseFloat(pocketBal)).toFixed(
    computeDecimals
  );
  const { authState } = useContext(AuthContext);
  const { wallet_balance, user_details } = authState;
  let user_username = user_details.username;
  const user_edao = wallet_balance.find((x) => x.token === "EDAO");
  const handleOpen = () => {
    setReloadData(true);
    setOpen(true);
  };

  const handleClose = () => {
    setReloadData(true);
    setOpen(false);
  };

  const [isTooltip, setIsTooltip] = useState(false);
  const handleTooltip = () => setIsTooltip(!isTooltip);

  useEffect(() => {
    fetchPledgeBalance();
    fetchtotalAceEarned();
    fetchGovernancePledgeHistory(0);
  }, []);
  // const fetchActivePledgeSeason=async ()=>{
  //   axios.get(Config.DAPPX_BASE_URL + '/users/edao/currentPledgeSeason')
  //       .then((res) => {
  //         const result=res.data.season
  //         setActiveSeasonId(result._id)

  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       })
  // }

  const fetchPledgeBalance = async () => {
    await axios
      .get(Config.DAPPX_BASE_URL + "/users/edao/governance-pledging/balance", {
        headers: { "session-id": sessionID },
      })
      .then((result) => {
        // let balance_wei = Web3.utils.fromWei(result.data.dappx_balance.toString(), 'ether');
        // let num2 = Number(balance_wei.toString().match(/^\d+(?:\.\d{0,6})?/));
        // let hasNoDecimals = balance_wei.split('.')[1]
        // hasNoDecimals == 0 || hasNoDecimals === undefined ? setPledgedBalance(Number(Math.trunc(num2))) : setPledgedBalance(Number(num2).toFixed(6))
        setPledgedBalance(result.data.balance);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchtotalAceEarned = async () => {
    await axios
      .get(
        Config.DAPPX_BASE_URL + "/users/edao/governance-pledging/ace-earned",
        { headers: { "session-id": sessionID } }
      )
      .then((result) => {
        // let balance_wei = Web3.utils.fromWei(result.data.dappx_balance.toString(), 'ether');
        // let num2 = Number(balance_wei.toString().match(/^\d+(?:\.\d{0,6})?/));
        // let hasNoDecimals = balance_wei.split('.')[1]
        // hasNoDecimals == 0 || hasNoDecimals === undefined ? setPledgedBalance(Number(Math.trunc(num2))) : setPledgedBalance(Number(num2).toFixed(6))
        try {
          if (result.data.ace_earned) {
            setAceEarned(result.data.ace_earned);
          }
        } catch (e) {
          setAceEarned(0);

          console.log(e);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchGovernancePledgeHistory = async (page) => {
    await axios
      .get(
        Config.DAPPX_BASE_URL +
        "/users/edao/governance-pledging/history?offset=" +
        page * limit +
        "&limit=" +
        limit,
        { headers: { "session-id": sessionID } }
      )
      .then((result) => {
        let check = parseInt(result.data.total_rows) % limit;
        let nc = parseInt(result.data.total_rows / limit);
        if (nc <= 0) {
          setCount(1);
        } else if (check !== 0) {
          setCount(nc + 1);
        } else {
          setCount(nc);
        }
        // let balance_wei = Web3.utils.fromWei(result.data.dappx_balance.toString(), 'ether');
        // let num2 = Number(balance_wei.toString().match(/^\d+(?:\.\d{0,6})?/));
        // let hasNoDecimals = balance_wei.split('.')[1]
        // hasNoDecimals == 0 || hasNoDecimals === undefined ? setPledgedBalance(Number(Math.trunc(num2))) : setPledgedBalance(Number(num2).toFixed(6))
        setGovernancePledgeHistory(result.data.transactions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const paginationChange = (event, value) => {
    if (value !== offset) {
      setOffset(value);
      let page = value - 1;
      fetchGovernancePledgeHistory(page);
    }
  };
  useEffect(async () => {
    if (reloadData) {
      const [vault, active] = await Promise.all([
        axios
          .get(APP_URL + "/users/edao/vault/balance", {
            headers: {
              "session-id": sessionID,
            },
          })
          .catch((error) => error),
        axios
          .get(APP_URL + "/users/edao/active/balance", {
            headers: {
              "session-id": sessionID,
            },
          })
          .catch((error) => error),
      ]);

      if (
        (vault && vault.status === 200 && vault.data) ||
        (active && active.status === 200 && active.data)
      ) {
        const mainBal =
          vault && vault.data ? vault.data.balance : defaultFloatVal;
        const pocketBal =
          active && active.data ? active.data.balance : defaultFloatVal;
        const total = (parseFloat(mainBal) + parseFloat(pocketBal)).toFixed(
          computeDecimals
        );
        const whole_num = total.split(".")[0];
        const decimal_num = total.split(".")[1].substr(0, displayDecimals);
        const balance = whole_num + "." + decimal_num;

        setData({
          ...data,
          mainBal:
            vault && vault.data
              ? vault.data.balance.toFixed(computeDecimals)
              : defaultFloatVal,
          pocketBal:
            active && active.data
              ? active.data.balance.toFixed(computeDecimals)
              : defaultFloatVal,
        });
      }

      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(async () => {
    if (!aceEarned) {
      return;
    }

    const fiat_value = await convertValue(aceEarned, "ACE");
    setAceFiatValue(fiat_value);
  }, [aceEarned]);

  const formatIntegers = (val) => {
    return new Intl.NumberFormat().format(val);
  };

  const builtInTheme = useTheme();
  const isXS = useMediaQuery(builtInTheme.breakpoints.down("xs"));
  const isSM = useMediaQuery(builtInTheme.breakpoints.down("sm"));
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <h6>completed</h6>;
    } else {
      // Render a countdown
      return (
        <span>
          {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m{" "}
          {zeroPad(seconds)}s
        </span>
      );
    }
  };
  const depositComponent = ({ onClick, disabled }) => (
    <Button
      variant="outlined"
      color="primary"
      fullWidth
      className={classes.btn}
      onClick={onClick}
      disabled={disabled}
    >
      Add
    </Button>
  );
  let acTotal = Number(mainBal) + Number(pocketBal);
  acTotal = String(acTotal);
  console.log(acTotal, typeof mainBal, typeof pocketBal);
  return (

    <div className=" user-sidebar-wrapper user-sidebar-wrapper-v2">


      <div className="content-wrapper-v2">
        <div className="content marginbot" id="edao-wrapper">
          
          <div
            className="section s1"
            style={{ display: "flex", borderBottom: "1px solid #dedede" }}
          >
            <div
              className="img-container"
              style={{ display: "flex", margin: "15px", alignItems: "center" }}
            >
              <img
                src={DP_Placeholder}
                alt="place your cool face here"
                style={{ height: 60, width: 60, margin: 10 }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  style={{ fontSize: "1.5rem", color: "#21212", fontWeight: 700 }}
                >
                  {user_username ? user_username : "Unnamed"}
                </p>
                <a href="/user-profile" style={{ textDecoration: "underline" }}>
                  My Digital Assets
                </a>
              </div>
            </div>
          </div>
          <div className={classes.root}>
            <Typography
              variant="h5"
              gutterBottom
              className={classes.balanceLabel}
            >
              Current EDAO Balance
              <ClickAwayListener onClickAway={() => setIsTooltip(false)}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltip}
                  open={isTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                  placement="right"
                  title={
                    <div className="tooltip-content">
                      <h5>EDAO</h5>
                      <p>
                        EDAO tokens are ARC-20 governance and ecosystem revenue
                        share tokens in the YED metaverse.
                      </p>
                    </div>
                  }
                >
                  <IconButton
                    onClick={handleTooltip}
                    className={`info-btn ${isTooltip ? "open" : ""}`}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            </Typography>
            <Grid container spacing={isXS ? 1 : 2}>
              <Grid item xs={12} md={6}>
                {acTotal && (
                  <div className="amount">
                    <Ticker textClassName="whole-num-large">
                      {formatIntegers(acTotal.split(".")[0])}
                    </Ticker>
                    <Ticker textClassName={`decimal-num-large `}>
                      {acTotal?.split(".")[1] == 0
                        ? ""
                        : `${acTotal?.split(".")[1]
                          ? "." + acTotal?.split(".")[1]
                          : ""
                        }`}
                    </Ticker>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={6}>
                <div className="token-details">
                  <div className="details-text-container">
                    <Typography
                      variant="h6"
                      className={classes.detailsTitle}
                      gutterBottom
                    >
                      EDAO Vault
                    </Typography>
                    <div className="amount">
                      <Ticker textClassName="whole-num">
                        {formatIntegers(mainBal.split(".")[0])}
                      </Ticker>
                      <Ticker
                        textClassName={`decimal-num ${mainBal.split(".")[1].substr(0, displayDecimals) == 0 &&
                          "hidden"
                          }`}
                      >
                        <span className={classes.dot}></span>
                        {`.${mainBal.split(".")[1].substr(0, displayDecimals)}`}
                      </Ticker>
                    </div>
                  </div>
                  <VaultIcon className="details-icon" />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="token-details">
                  <div className="details-text-container">
                    <Typography
                      variant="h6"
                      className={classes.detailsTitle}
                      gutterBottom
                    >
                      Active EDAO
                    </Typography>
                    <div className="amount">
                      <Ticker textClassName="whole-num">
                        {formatIntegers(pocketBal.split(".")[0])}
                      </Ticker>
                      <Ticker
                        textClassName={`decimal-num ${pocketBal.split(".")[1].substr(0, displayDecimals) ==
                          0 && "hidden"
                          }`}
                      >
                        <span className={classes.dot}></span>
                        {`.${pocketBal.split(".")[1].substr(0, displayDecimals)}`}
                      </Ticker>
                    </div>
                  </div>
                  <AcentActiveIcon className="details-icon" />
                </div>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={6} sm={6} lg={6}>
                  <div className="buttons-block">
                    <Pledge
                      balance={user_edao}
                      token="edao"
                      isGovernance={true}
                      isFullWidth={true}
                      isConvertable={false}
                    />
                    <Unpledge
                      disableButton={Number(pledgedBalance) <= 0}
                      balance={user_edao}
                      token="edao"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        {/*Governance Pledge*/}
        <div className="content marginbot" id="edao-gov-wrapper">
          <div className={classes.root}>
            <Typography
              variant="h5"
              gutterBottom
              className={classes.balanceLabel}
            >
              Governance Stake
            </Typography>
            <Grid container spacing={isXS ? 1 : 2}>
              <Grid item xs={6}>
                <div className="token-details">
                  <div className="details-text-container">
                    <Typography
                      variant="h6"
                      className={classes.detailsTitle}
                      gutterBottom
                    >
                      Stake
                      <img src={edao_logo} alt={"logo"} />
                    </Typography>
                    <div className="amount">
                      <Ticker textClassName="whole-num">
                        {/*{formatIntegers(activeSeasonPledgedAmount.split('.')[0])}*/}
                        {pledgedBalance.toFixed(6).replace(/\.?0+$/, "")}
                      </Ticker>
                      {/*<Ticker textClassName={`decimal-num ${(activeSeasonPledgedAmount.split('.')[1]).substr(0, displayDecimals) == 0 && 'hidden'}`}>*/}
                      {/*  <span className={classes.dot}></span>{`.${(activeSeasonPledgedAmount.split('.')[1]).substr(0, displayDecimals)}`}*/}
                      {/*</Ticker>*/}
                    </div>
                  </div>
                  {/*<TodoIcon className="details-icon" />*/}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="token-details earned-balance">
                  <div className="details-text-container">
                    <Typography
                      variant="h6"
                      className={classes.detailsTitle}
                      gutterBottom
                    >
                      Total ACE Earned
                      <img src={acent_logo} alt={"logo"} />
                    </Typography>
                    <div className="amount">
                      <Ticker textClassName="whole-num">
                        {formatIntegers(aceEarned)}
                        {/*{formatIntegers(pocketBal.split('.')[0])}*/}
                      </Ticker>
                      <div className="convertion-container">
                        <span className="convertion">
                          (≈{" "}
                          {aceFiatValue ? formatIntegers(aceFiatValue) : "0.00"}{" "}
                          USD)
                        </span>
                      </div>
                      {/*<Ticker textClassName={`decimal-num ${(pocketBal.split('.')[1]).substr(0, displayDecimals) == 0 && 'hidden'}`}>*/}
                      {/*  <span className={classes.dot}></span>{`.${(pocketBal.split('.')[1]).substr(0, displayDecimals)}`}*/}
                      {/*</Ticker>*/}
                    </div>
                  </div>
                  {/*<HistoryIcon className="details-icon" />*/}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.root}>
            <Typography
              variant="h5"
              gutterBottom
              className={classes.balanceLabel}
            >
              History
            </Typography>
            <table className="history-table">
              <thead>
                <tr>
                  <th>Activity</th>
                  <th>Date/Time </th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {governancePledgeHistory.map((row) => {
                  let date = new Date(row.end_date);
                  date.setDate(date.getDate() + row.duration);

                  return (
                    <tr key={`edao-history-${row._id}`}>
                      <td>
                        {row.type == "pledge" || "stake" ? "Stake" : "Unstake"}
                      </td>
                      <td>
                        {format(new Date(row.start), "yyyy/MM/dd-kk:mm:ss")}
                      </td>
                      <td>
                        {row.amount}
                        <img
                          style={{ width: "22px", paddingBottom: "8px" }}
                          src={edao_logo}
                          alt={"logo"}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              className="pagination"
              siblingCount={1}
              boundaryCount={1}
              onChange={paginationChange}
              count={count}
              page={offset}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
        <Transfer
          token="acent"
          open={open}
          handleClose={handleClose}
          data={data}
          decimals={displayDecimals}
        />
      </div>

      <SideBarData />
    </div>

  );
};

export default EDAO;
