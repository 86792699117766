import React, {useEffect, useState, useContext} from 'react';
import Web3 from "web3";
import axios from "axios";
import { Link, ImmutableXClient, ETHTokenType } from '@imtbl/imx-sdk';
import { Button, Fade, TextField } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import { AuthContext } from '../../context/AuthContext';
import { SnackbarContext } from "../../context/SnackBarContext";
import { IMX_LINK_BASE_URL, IMX_API_BASE_URL, DAPPX_BASE_URL } from '../../constant/config';
import { Popover,OverlayTrigger  } from 'react-bootstrap';

import { ReactComponent as WarningIcon } from '../../assets/images/icons/warning.svg';
import { ReactComponent as SuccessIcon } from '../../assets/images/icons/check-filled.svg';
import { ReactComponent as EthLightIcon } from '../../assets/images/icons/icon-eth-light.svg';
import { ReactComponent as EthIcon } from '../../assets/images/icons/icon-eth.svg';
import CustomDialog from '../Modal/CustomDialog';
import ConnectWalletButton from '../top-nav/ConnectWalletButton';
import Loader from '../loader/Loader';
import { NumberFormatCustom } from '../CustomInput';
import {isMobile} from 'react-device-detect';
import './index.scss'

const IMXButton = ({btnLabel = false, callback = false}) => {
  const { authState, dispatch } = useContext(AuthContext);
  const { showSnack } = useContext(SnackbarContext);
  const { wallet_balance } = authState;
  let user_eth = wallet_balance.find(x => x.token === 'ETH');
  let user_wallet_eth = user_eth ? user_eth.value : 0;
  let user_eth_fiat = user_eth ? user_eth.fiat_value : 0;
  let currency = user_eth ? user_eth.currency : 'USD';

  let web3 = new Web3(window.ethereum);
  const [connectModal, setConnectModal] = useState(false);
  const [depositModal, setDepositModal] = useState(false);
  const [balanceDialog, setBalanceDialog] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [outline_error, set_outline_error] = useState([]);

  const [input_val, set_input_val] = useState('');
  const [input_fiat_val, set_input_fiat_val] = useState(0);
  const [usdValue, setUsdValue] = useState(0);

  const session_id = localStorage.getItem('session-id');

  // initialise Immutable X Link SDK
  const link = new Link(IMX_LINK_BASE_URL);
  const [wallet, setWallet] = useState('');
  const [balance, setBalance] = useState(0);
  const [client, setClient] = useState(null);
  
  const [imxBalance, setImxBalance] = useState(null);
  const [imxLoading, setImxLoading] = useState(true)

  useEffect(() => {
    setWallet(localStorage.getItem('wallet-imx'));
    buildIMX();
    convertCurrency();
  }, []);

  useEffect(() => {
    if(wallet && client) {
      getWalletBalance(wallet);
      setIsConnected(true);
    }
  }, [wallet, client]);

  useEffect(() => {
    if(balance && usdValue) {
      setImxBalance(Number(parseFloat(web3.utils.fromWei((balance ? balance.balance.toString() : 0).toString(), 'ether')).toFixed(6)));
      setImxLoading(false)
    }
  }, [balance, usdValue]);

  useEffect(() => {
    if(imxBalance) {
      console.log('Updateds IMX Balance', imxBalance);
      let fiat_value = (imxBalance * usdValue).toFixed(2);
      let new_balances = {
        token: 'IMX',
        value: imxBalance,
        currency: currency,
        fiat_value: fiat_value === 0 ? parseFloat(fiat_value).toFixed(2) : fiat_value,
      };
      dispatch({ type: 'IMX_BALANCE', payload: new_balances });
    }
  }, [imxBalance]);

  async function getWalletBalance(walletAddress) {
    setBalance(await client.getBalance({ user: walletAddress, tokenAddress: 'ETH' }));
  }

  // initialise an Immutable X Client to interact with apis more easily
  async function buildIMX() {
    const publicApiUrl = IMX_API_BASE_URL;
    setClient(await ImmutableXClient.build({ publicApiUrl }));
  }

  // register and/or setup a user
  async function linkSetup() {
    setLoading(true);
    setError(false);
    
    link.setup({}).then((res) => {
      console.log(res);

      let walletAddress = localStorage.getItem('wallet_address');
      if (res.address !== walletAddress) {
        setError("Please use the same address.");
        setLoading(false);
        return;
      }

      setWallet(res.address);
      getWalletBalance(res.address);
      localStorage.setItem('wallet-imx', res.address);
      setIsConnected(true);
      setLoading(false);
      setConnectModal(false);
    }).catch((err) => {
      console.log('Link error: ', err);
      setLoading(false);
      setError('Please complete the action needed.');
    })
  };

  const onSubmit = async () => {
    let to_num_input_val = parseFloat(input_val);
    let to_num_balance_val = parseFloat(user_wallet_eth);
    let acceptedNumberToAdd = to_num_input_val - 0.000001
    let err_arr = [];


    let imxAddress = localStorage.getItem('wallet-imx');
    let walletAddress = localStorage.getItem('wallet_address');

    if (to_num_balance_val < to_num_input_val) {
      err_arr.push('amount')
      set_outline_error(err_arr)
      setError("Insufficient balance.")
    } else if (to_num_input_val === 0 || isNaN(to_num_input_val)) {
      err_arr.push('amount')
      set_outline_error(err_arr)
      setError("Amount should be greater than zero.")
    } else {
      if (imxAddress !== walletAddress) {
        setError("Something went wrong. Please try again later.")
        return;
      }
      handleDeposit(to_num_input_val)
    }
  }

  async function handleDeposit(to_num_input_val) {
    setLoading(true);
    setError(false);

    await link.deposit({
      type: ETHTokenType.ETH,
      amount: to_num_input_val,
    }).then((res) => {
      setLoading(false);
      setDepositModal(false);
      showSnack('Deposit is on its way');
    }).catch((err) => {
      console.log('Link error: ', err)
      setLoading(false);
      setError('Please complete the action needed.');
    })
  };

  const convertCurrency = async () => {
    await axios.get(DAPPX_BASE_URL + '/users/getMarketPrices')
        .then((res) => {
            setUsdValue(res.data.ETH.USD)
        })
        .catch((error) => {
            set_input_fiat_val(0)
            setError("Can't pull conversion rate.")
        })
}

  const onChange_num = (e) => {
    const target_val = e.target.value;
    const decimals = target_val.split('.')[1] || '';
    remove_errors(e.target.id)

    if (target_val !== '' && decimals.length <= 6) {
      let convertion_value = (target_val * usdValue).toFixed(2)
      set_input_val(target_val);
      set_input_fiat_val(convertion_value);
      convertCurrency();
    }

    if (target_val === '') {
      set_input_val(target_val);
      set_input_fiat_val(target_val * usdValue);
      convertCurrency();
    }
  }

  // Functions
  const remove_errors = (target) => {
    setError('')
    let update_outline_error = outline_error.filter(id => id !== target)
    set_outline_error(update_outline_error)
  }

  const formatTxnHash = (hash) => {
    if (!hash) {
        return false;
    }
    return hash.substring(0, 6) + "..." + hash.slice(hash.length - 4);
  };

  const handleDisconnect = () => {
    setLoading(true);

    setTimeout(() => {
      setIsConnected(false);
      localStorage.removeItem('wallet-imx');
      setLoading(false);
    }, 1000);
  }

  const getWholeNumber = (value) => {
    return value ? value.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
  }
  const getDecimalNumber = (value) => {
    const first = value.split('.')[1]
    return value > 0 ? `.${first}` : '';
  }

  const handleModal = (modal) => {
    setError(false);
    set_outline_error([]);
    set_input_val(0);

    switch (modal) {
      case 'connect':
        setConnectModal(!connectModal)
        break;
      case 'deposit':
        setDepositModal(!depositModal)
        break;
      default:
        break;
    }
  }

  const checkIfConnected = () => {
    let session = localStorage.getItem('session-id');
    return session && true
}
  
  const handleConnectToIMX = () => {
    if (!checkIfConnected()) {
      document.getElementById("connect-wallet-button").click();
      return;
    }

    handleModal('connect');
  }


  const popoverBalance = (
    <Popover className="pop1" id="popover-positioned-bottom" title="Popover bottom">
      <div className="imx-balance-dialog">
        <div className="header">
          IMX Balance
        </div>

        <div className="content">
          <div className="amount-block">
            {imxLoading || imxBalance == null ?
             <>
              <p className="amount"><span>Fetching ...</span></p>
            </>
            :
              <>
                <p className="amount">{getWholeNumber(imxBalance.toString())}<span>{getDecimalNumber(imxBalance.toString())}</span></p>
              </>
            }
          </div>
          <div className="icon-block">
            <EthLightIcon />
          </div>
        </div>
        <div className="footer">
          <div className="button-block">
            <Button variant="contained" color="primary" fullWidth disableElevation onClick={() => handleModal('deposit')}
            >
              <span className="text">{'Deposit ETH'}</span>
            </Button>
            <Button variant="text" color="default" fullWidth disableElevation
              onClick={handleDisconnect}
              disabled={loading}
            >
              <span className="text">{'Disconnect from IMX'}</span>
            </Button>
          </div>
        </div>
      </div>
    </Popover>
  );

  const getBalance = () => {
      if (!balanceDialog) {
        getWalletBalance(wallet)
      }

      setBalanceDialog(!balanceDialog);
  }

  return (
    <>
    {!isMobile ?
    <div className={`imx-button-container`}>
    {!isConnected ? 
      <Fade in={isConnected ? false : true} timeout={500} >
        <Button variant="contained" color="primary" id="connect-to-imx-button" disableElevation onClick={handleConnectToIMX}>{btnLabel ? btnLabel : 'Connect to IMX'}</Button>
      </Fade> : 
      <Fade in={isConnected ? true : false} timeout={500} >
        <OverlayTrigger trigger={[ 'click']} rootClose placement="bottom" overlay={popoverBalance}>
          <Button className="balance-button"
                  variant="outlined"
                  component="a"
                  onClick={getBalance}
                  >
           <span> {formatTxnHash(wallet)}</span> <span> IMX <SuccessIcon /></span>
          </Button>
        </OverlayTrigger>
      </Fade>
    }
  </div>
    : ''}

      <CustomDialog
        open={connectModal}
        className="imx-modal connext-to-imx-dialog"
        onClose={() => handleModal('connect')}
        header="Connect to IMX"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={
          <>
            <p>Please ensure you have access to your Metawallet. You’ll also be connecting to IMX.</p>
          </>
        }
        footer={<>
          {error &&
            <Fade className="error custom-error" in={error ? true : false} timeout={500} >
              <Alert severity="error"><WarningIcon className="icon" />{error}</Alert>
            </Fade>
          }
          <div className="button-block">
            <Button variant="contained" color="primary" fullWidth disableElevation
              onClick={linkSetup}
              disabled={loading}
            >
              <span className="text">{'Connect'}</span>
              <Loader
                appear={loading}
                timeout={1000}
                width="22"
                coloredBg
                svgProps={{
                  direction: "right",
                  negative: true,
                }}
              />
            </Button>
            <Button variant="contained" color="default" fullWidth disableElevation disabled={loading} onClick={() => handleModal('connect')}>Cancel</Button>
          </div>
        </>}
      />

      <CustomDialog
        open={depositModal}
        className="imx-modal imx-deposit-dialog "
        onClose={() => handleModal('deposit')}
        header={`Deposit`}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={
        <>
          <div className="container-balance">
            <label>Available ETH Balance</label>
            <div className="block">
              <div className="icon-block">
                <EthIcon />
              </div>
              <div className="amount-block">
                {user_wallet_eth &&
                  <>
                    <p className="amount">{getWholeNumber(user_wallet_eth)}.<span>{getDecimalNumber(user_wallet_eth)}</span></p>
                    <p className="convertion">{user_eth_fiat} {currency}</p>
                  </>
                }
              </div>
            </div>
          </div>
          <div className={`container-textfield`}>
            <div className="number-label">
              <label>Deposit Amount</label>
              {/* <Button disableRipple onClick={maxVal}>Max</Button> */}
            </div>
            <div className="textfield-block">
              <TextField
                fullWidth
                className="number-field"
                error={outline_error.includes('amount')}
                value={input_val}
                onChange={onChange_num}
                autoComplete="off"
                inputProps={{
                  min: 0
                }}
                variant="outlined"
                id='amount'
                placeholder="0"
                name="number-field"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              <div className="coin-logo"><EthIcon /></div>
              {input_fiat_val ? (<div className="convertion">(≈ {input_fiat_val} USD )</div>) : ''}
            </div>
          </div>
        </>}
        footer={<>
          {error &&
            <Fade className="error custom-error" in={error ? true : false} timeout={500} >
              <Alert severity="error"><WarningIcon className="icon" />{error}</Alert>
            </Fade>
          }
          <div className="button-block">
            <Button variant="contained" color="primary" fullWidth disableElevation
              onClick={onSubmit}
              type="submit"
              disabled={loading}
            >
              <span className="text">{'Submit'}</span>
              <Loader
                appear={loading}
                timeout={1000}
                width="22"
                coloredBg
                svgProps={{
                  direction: "right",
                  negative: true,
                }}
              />
            </Button>
            <Button variant="contained" color="default" disabled={loading} fullWidth disableElevation onClick={() => handleModal('deposit')}>Cancel</Button>
          </div>
        </>}
      />
    </>
  )
}

export default IMXButton
