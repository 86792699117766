import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Container,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Divider,
  Button,
  Paper,
  useMediaQuery,
  MenuItem,
  Input,
  Chip,
  Checkbox,
  ListItemText,
  Typography,
  FormControl,
  InputLabel,
  Select,
  useScrollTrigger,
} from '@material-ui/core';

import GradientBG from '../../../assets/images/user-packages/rectangle-gradient@2x.png';
import EarthLogo from '../../../assets/images/user-packages/planet-earth-logo.svg';
import ArcadeLogo from '../../../assets/images/user-packages/dapp-arcade-logo.svg';
import StoreLogo from '../../../assets/images/user-packages/dapp-store-logo.svg';
import Loader from '../../../assets/images/user-packages/loader.png';

import './index.scss';
import { DAPPX_BASE_URL } from '../../../constant/config';

const APP_URL = DAPPX_BASE_URL;
const imageBaseURL = 'https://dappx-adminapi-sat.dappstore.me/';

const baseFilters = {
  earth: 'Planet Earth',
  arcade: 'dApp Arcade',
  store: 'dApp Store',
  ticket: 'Tickets',
};

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    borderRadius: '1.25rem',
    minHeight: '639px',
  },
  media: {
    height: 75,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headImg: {
    height: 29,
    width: 'auto',
  },
  cardTitle: {
    fontSize: '18px',
  },
  cardSubTitle: {
    fontSize: '14px',
  },
  ul: {
    textAlign: 'left',
  },
  ulPaddingXS: {
    textAlign: 'left',
    padding: '0 3rem',
  },
  ulPaddingLG: {
    textAlign: 'left',
    padding: '0 1rem',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem 1.5rem',
  },
  divider: {
    margin: '1rem 0',
  },
  placeholder: {
    height: '73px',
    width: 'auto',
    marginTop: '1rem',
    marginBottom: '2rem',
    borderRadius: '50%',
  },
  placeholderContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardBtn: {
    width: '100%',
    textTransform: 'uppercase',
    fontSize: '14px',

    '&:disabled': {
      color: '#dddddd',
      backgroundColor: '#747474',
    },
  },
  formControl: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(1),
    marginTop: '1rem',
    minWidth: 256,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputSelect: {
    height: '40px'
  },
  inputSelectLabel: {
    transform: 'translate(14px, 13px) scale(1)'
  },
  ownedText: {
    color: '#016ce9',
    fontSize: '14px',
    fontWeight: 500,
  },
  menuitem: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  chips: {
    display: 'flex',
    alignSelf: 'start',
    flexWrap: 'wrap',
    margin: '.3rem 0',
  },
  chip: {
    margin: 2,
    borderRadius: '.5rem',
    backgroundColor: '#E5F5FC',
    color: '#181818',
    '&:hover': {
      backgroundColor: '#43ADDC',
    }
  },
  floatFilterSM: {
    top: '7.4rem',
    left: 0,
    right: 0,
    position: 'fixed',
    backgroundColor: '#FFF',
    width: 'auto',
    zIndex: 99,
    padding: '0 1.5rem',
  },
  floatFilterXS: {
    top: '6.4rem',
    left: 0,
    right: 0,
    position: 'fixed',
    backgroundColor: '#FFF',
    width: 'auto',
    zIndex: 99,
    padding: '0 1rem',
  },
  descContainer: {
    minHeight: '200px',
    padding: '1rem 0',
    fontSize: '12px',

    [theme.breakpoints.down('xs')]: {
      padding: '1rem 4rem',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '1rem 3rem',
    },

    [theme.breakpoints.down('md')]: {
      padding: '1rem 2rem',
    },
  },
}));

const Package = () => {
  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const [userPackages, setUserPackages] = useState([]);
  const [userTickets, setUserTickets] = useState([]);
  const [loading, setLoading] = useState(false);

  const GetCardHeadImg = (tag) => {
    switch (tag) {
      case 'Your Earth':
        return EarthLogo;

      case 'store':
      case 'ticket':
        return StoreLogo;

      case 'arcade':
        return ArcadeLogo;

      default:
        return '';
    }
  };
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.up('sm'));
  const isMD = useMediaQuery(theme.breakpoints.up('md'));

  const PackageCard = ({
    userPackage: {
      package: packageName,
      name: tag,
      description,
      amount,
      image,
    },
    count = 1,
  }) => {
    return (
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={GradientBG}
          >
            <img src={GetCardHeadImg(tag)} className={classes.headImg} />
          </CardMedia>
          <CardContent>
            <Typography variant="h6" className={classes.cardTitle}>
              {packageName}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" component="p" className={classes.cardSubTitle}>
              {amount.length && `${amount[0].amount} ${amount[0].currency}`}
            </Typography>
            <Divider component="hr" className={classes.divider} />
            <Container className={classes.placeholderContainer}>
              {
                image.length && <img className={classes.placeholder} src={imageBaseURL + image[0].path} />
              }
            </Container>
            <Container className={classes.descContainer} dangerouslySetInnerHTML={{ __html: description }}></Container>
            <Divider component="hr" className={classes.divider} />
            <Typography variant="p" className={classes.ownedText}>
              Owned: {count} {count >= 2 ? 'packages' : 'package'}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.cardActions}>
          <Button className={classes.cardBtn} variant="contained" color="primary" size="large" disabled>
            Coming Soon
          </Button>
        </CardActions>
      </Card>
    )
  };

  const TicketCard = ({
    userTicket: {
      name,
      description,
      image,
      tag = 'ticket',
    },
    count,
  }) => {
    return (
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={GradientBG}
          >
            <img src={GetCardHeadImg(tag)} className={classes.headImg} />
          </CardMedia>
          <CardContent>
            <Typography variant="h6" className={classes.cardTitle}>
              {name}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary" component="p" className={classes.cardSubTitle}>
              Common Ticket
            </Typography>
            <Divider component="hr" className={classes.divider} />
            <Container className={classes.placeholderContainer}>
              {
                image && <img className={classes.placeholder} src={imageBaseURL + image} />
              }
            </Container>
            <Container className={classes.descContainer} dangerouslySetInnerHTML={{ __html: description }}></Container>
            <Divider component="hr" className={classes.divider} />
            <Typography variant="p" className={classes.ownedText}>
              Owned: {count} {count >= 2 ? 'tickets' : 'ticket'}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.cardActions}>
          <Button className={classes.cardBtn} variant="contained" color="primary" size="large" disabled>
            Coming Soon
          </Button>
        </CardActions>
      </Card>
    )
  };

  const classes = useStyles();
  const [filterTags, setFilterTags] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(async () => {
    setLoading(true);
    const sessionID = localStorage.getItem('session-id');
    const startTime = new Date().getTime();
    const minLoadTime = 1000;

    function hideLoader() {
      const endTime = new Date().getTime();
      const waitTime = minLoadTime - (endTime - startTime);

      setTimeout(() => {
        setLoading(false);
      }, waitTime);
    }

    const [packages, tickets] = await Promise.all([
      axios.post(
        APP_URL + "/users/getOrders",
        {},
        {
          headers: {
            'session-id': sessionID,
          },
        },
      ).catch(error => error),
      axios.get(
        APP_URL + "/users/getTickets",
        {
          headers: {
            'session-id': sessionID,
          },
        },
      ).catch(error => error),
    ])

    if (packages.data.data.length) {
      const withDetails = packages.data.data.filter(x => x.packageDetails.length);
      const newArr = [];

      if (withDetails.length) {
        withDetails.forEach(x => x.packageDetails.forEach(y => newArr.push(y)));
        setUserPackages(newArr);
      }
    }

    if (tickets.data.tickets.length) {
      setUserTickets(tickets.data.tickets);
    }

    hideLoader();

    // .catch((err) => {
    //   console.error(err);
    //   hideLoader();
    // })

  }, []);

  const Filter = ({ filterTags, setFilterTags, baseFilters }) => {
    const handleChange = (event) => {
      // scroll to top when adding new filters
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

      setFilterTags(event.target.value);
    };

    const handleDelete = (val) => {
      const newFilter = filterTags.filter(x => x !== val);
      setFilterTags(newFilter);
    };

    return (
      <>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="mutiple-select-label" className={classes.inputSelectLabel}>Filter</InputLabel>
          <Select
            labelId="mutiple-select-label"
            id="mutiple-select"
            label="Filter"
            multiple
            value={filterTags}
            onChange={handleChange}
            renderValue={() => ''}
            className={classes.inputSelect}
          >
            <MenuItem disabled className={classes.menuitem}>
              <em>Filter</em>
            </MenuItem>
            {
              Object.keys(baseFilters).map((key, i) => {
                const val = baseFilters[key];

                return (
                  <MenuItem key={i} value={key} className={classes.menuitem}>
                    <ListItemText primary={val} />
                    <Checkbox checked={filterTags.indexOf(key) > -1} color="primary" />
                  </MenuItem>
                );
              })
            }
          </Select>
        </FormControl>

        <div className={classes.chips}>
          {
            filterTags.map((f, i) =>
              <Chip
                key={i}
                label={baseFilters[f]}
                className={classes.chip}
                color="primary"
                value={f}
                onClick={() => handleDelete(f)}
                onDelete={() => handleDelete(f)}
              />
            )
          }
        </div>
      </>
    );
  };

  const filteredPackage = (filter) => {
    return userPackages.filter(t => t.name.toLowerCase().includes(filter));
  };

  const renderFilter = () => {
    if (isMD) {
      return <Filter filterTags={filterTags} setFilterTags={setFilterTags} baseFilters={baseFilters} />
    } else if (isSM) {
      return <Paper elevation={scrollTrigger ? 4 : 0} className={classes.floatFilterSM}>
        <Filter filterTags={filterTags} setFilterTags={setFilterTags} baseFilters={baseFilters} />
      </Paper>
    } else {
      return <Paper elevation={scrollTrigger ? 4 : 0} className={classes.floatFilterXS}>
        <Filter filterTags={filterTags} setFilterTags={setFilterTags} baseFilters={baseFilters} />
      </Paper>
    }
  }

  return (
    <div className="package-content">
      {
        loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <img src={Loader} className="loader" />
          </div>
        ) : (
          userPackages.length || userTickets.length ? (
            <>
              {
                renderFilter()
              }
              {
                filterTags.length ? filterTags.map(f =>
                  <div style={{ margin: '2rem 0' }}>
                    <div style={{ display: 'flex', alignItems: 'center', position: 'sticky', top: '-1px' }}>
                      <h6 style={{ textTransform: 'uppercase' }}>{baseFilters[f]}</h6>
                      <div style={{ width: '54px', height: '4px', backgroundImage: 'linear-gradient(to right, #41d1e8 7%, #4c11ac)', marginLeft: '1rem' }}></div>
                    </div>
                    {
                      f !== 'ticket' && !filteredPackage(f).length && <div>You have not purchased any {baseFilters[f]} packages yet.</div>
                    }
                    {
                      f === 'ticket' && !userTickets.length && <div>No tickets owned yet.</div>
                    }
                    <Grid container spacing={3}>
                      {
                        f === 'ticket' ? userTickets.map(t =>
                          <Grid item xs={12} sm={6} lg={4} key={t.ticket_details['_id']}>
                            <TicketCard userTicket={t.ticket_details} count={t.num_tickets} />
                          </Grid>
                        ) : filteredPackage(f).map(p =>
                          <Grid item xs={12} sm={6} lg={4} key={p.id}>
                            <PackageCard userPackage={p} />
                          </Grid>
                        )
                      }
                    </Grid>
                  </div>
                ) : (
                  <Grid container spacing={3}>
                    {
                      userPackages.map(p =>
                        <Grid item xs={12} sm={6} lg={4} key={p['_id']}>
                          <PackageCard userPackage={p} />
                        </Grid>
                      )
                    }
                    {
                      userTickets.map(t =>
                        <Grid item xs={12} sm={6} lg={4} key={t.ticket_details['_id']}>
                          <TicketCard userTicket={t.ticket_details} count={t.num_tickets} />
                        </Grid>
                      )
                    }
                  </Grid>
                )
              }
            </>
          ) : (
            <p className="empty-package">You have not purchased any packages yet.</p>
          )
        )
      }
    </div >
  )
}

export default Package;