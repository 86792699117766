// export const PRODUCTION_API = 'https://dappstore.me';
// export const UAT_API = 'https://play-uat.dappstore.me';
// export const SAT_API = 'https://play-sat.dappstore.me';

// export const IS_UAT = window.location.href.includes('-uat');
// export const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');

// export const API_BASE_URL = `
//   ${IS_SAT
//       ? SAT_API
//       : IS_UAT
//           ? UAT_API
//           : PRODUCTION_API
//   }
// `;

// WHEN TICKET IS ON GOING ====================================================================

export const PRODUCTION_API = 'https://apiadmin.dappstore.me';
export const UAT_API = 'https://apiadmin-uat.dappstore.me';
export const SAT_API = 'https://apiadmin-sat.dappstore.me';

export const IS_UAT = window.location.href.includes('-uat');
export const IS_SAT = window.location.href.includes('localhost') || window.location.href.includes('-sat');

export const API_BASE_URL = `
  ${IS_SAT
      ? SAT_API
      : IS_UAT
          ? UAT_API
          : PRODUCTION_API
  }
`;

export const DAPPX_BASE_URL = IS_SAT
? 'https://dappx-api-sat.dappstore.me'
: IS_UAT
  ? 'https://dappx-api-uat.dappstore.me'
  : 'https://dappx-api.dappstore.me';


export const NFT_IMX_BASE_URL = IS_SAT
? 'https://nft-imx-api-sat.yourearth.io'
: IS_UAT
  ? 'https://nft-imx-api-uat.yourearth.io'
  : 'https://nft-imx-api.yourearth.io';


// IMX URLS
export const IMX_LINK_BASE_URL = IS_SAT
  ? 'https://link.ropsten.x.immutable.com'
  : IS_UAT
    ? 'https://link.x.immutable.com'
    : 'https://link.x.immutable.com';

export const IMX_API_BASE_URL = IS_SAT
  ? 'https://api.ropsten.x.immutable.com/v1'
  : IS_UAT
    ? 'https://api.x.immutable.com/v1'
    : 'https://api.x.immutable.com/v1';


export  let CONTRACT_ADDRESS = '0xd1d709babD041E942Abb616fa2fe7b12E48B534d';

export  let TESTNET_CONTRACT_ADDRESS_ACE = '0x700967D2Ec0ECbCD80E46c3facA8D77bDebF5488';
export  let TESTNET_CONTRACT_ADDRESS_DAPPX = '0xBFd2A85f89a9962E155d7dF7b23789b8312B1EE1';
export  let TESTNET__CONTRACT_ADDRESS_WITHDRAW_ACE = '0x0Cc84772D14096285c36A3ac183C2a5CF87190fD';
export  let MAINNET_CONTRACT_ADDRESS_ACE = '0xeC5483804e637D45cDe22Fa0869656B64b5AB1ab';
export  let MAINNET_CONTRACT_ADDRESS_DAPPX = '0x00d8318E44780EdEEFcF3020A5448F636788883C';
export  let MAINNET_CONTRACT_ADDRESS_WITHDRAW_ACE = '0x313d516a370bf0240913a370b94a8640ce47500e';
export  let MAINNET_CONTRACT_ADDRESS_WITHDRAW_ACE_PROD = '0xf3f6450D751DEa9bE16E7079F587D30803f72F44';

/* new config checking the environment variables for all environments SAT, UAT and PROD */
 /* __TODO: 
        - add the above contract address and change change na
        - make a comment between each different type/catrgory of envs
  */

export const Config = {
  
  /* 
    The main environment of the current app, always use this instead of
    process.NODE.ENV 
  */
  
  APP_ENV: process.env.REACT_APP_ENV,
  
  // main/base apis urls

  APP_API_URL: process.env.REACT_APP_API_URL,
  APP_ADMIN_API_URL: process.env.REACT_APP_DAPPX_ADMIN_URL,
  DAPPX_BASE_URL: process.env.REACT_APP_DAPPX_URL,

  MAILCHIMP_URL: process.env.REACT_APP_MAILCHIMP_URL,
  
  MORALIS_APP_ID: process.env.REACT_APP_MORALIS_APP_ID,
  MORALIS_MARKETPLACE_CONTRACT: process.env.REACT_APP_MARKETPLACE_CONTRACT,
  MORALIS_SERVER_URL: process.env.REACT_APP_MORALIS_SERVER_URL,
  MORALIS_TOKEN_CONTRACT_ADDRESS: process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS,
  
  CONTRACT_ADDRESS: '0xd1d709babD041E942Abb616fa2fe7b12E48B534d',

  TESTNET_CONTRACT_ADDRESS_ACE: '0x700967D2Ec0ECbCD80E46c3facA8D77bDebF5488',
  MAINNET_CONTRACT_ADDRESS_ACE: '0xeC5483804e637D45cDe22Fa0869656B64b5AB1ab',

  TESTNET_CONTRACT_ADDRESS_DAPPX: '0xBFd2A85f89a9962E155d7dF7b23789b8312B1EE1',
  MAINNET_CONTRACT_ADDRESS_DAPPX: '0x00d8318E44780EdEEFcF3020A5448F636788883C',

  DEPOSIT_TESTNET_CONTRACT_ADDRESS_DAPPX: '0x9eB5Ffe0a5b4F74E1E598cD0a682A5148Ca41E85', // dappstore-contract.json
  DEPOSIT_UAT_MAINNET_CONTRACT_ADDRESS_DAPPX: '0xAB2ca70C2A718deD1dfda472D67F8bbb62d88435',
  DEPOSIT_PROD_MAINNET_CONTRACT_ADDRESS_DAPPX: '0xd688775397c2e8bf2f5943468d234b1119769d6d',

  DEPOSIT_TESTNET_CONTRACT_ADDRESS_ACE: '0xab2ca70c2a718ded1dfda472d67f8bbb62d88435',
  DEPOSIT_UAT_MAINNET_CONTRACT_ADDRESS_ACE: '0x6383eaaB94B1116B607a73aA3cbC739A76c422B6',
  DEPOSIT_PROD_MAINNET_CONTRACT_ADDRESS_ACE: '0x0FE4D8976267ddBADD4ceB6Ae9f6197fD8566284',
  MAINNET_CONTRACT_ADDRESS_SAT_ACE_NATIVE: '0x7412cD0C178Aa317A945B7701369b31075C323bb',
  MAINNET_CONTRACT_ADDRESS_UAT_ACE_NATIVE: '0x9A963bf00C8974ab1283505fBee1217BDdA09806',

  DEPOSIT_TESTNET_CONTRACT_ADDRESS_PLANET_EARTH: '0xc726e2a97e8904f27983f1acd4d3753e843a3e6c', // planetearth-contract.json
}
