import { useState } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { ListItemIcon, ListItemText } from "@material-ui/core";
import { ReactComponent as Assets } from '../../assets/images/icons/assets.svg';
import { ReactComponent as AceLogo } from '../../assets/images/icons/acent-logo.svg';
import { ReactComponent as ZeraLogo } from '../../assets/images/icons/zera-logo.svg';
import { ReactComponent as DappxLogo } from '../../assets/images/dappstore-icon.svg';
import { ReactComponent as EdaoLogo } from '../../assets/images/icons/edao-logo.svg';
import Profile from './Profile';
import ACE from './ace';
import Zera from './zera';
import Dappx from './dappx';
import EDAO from './edao';
import DappxPaypal from './paypal-topup'
import './UserProfile.scss';
import PaypalPaymentSuccess from './paypal-topup/paypal-payment-success';


const Desktop = ({ match }) => {
  let history = useHistory();
  const location = useLocation()
  let pathname = history.location.pathname ? history.location.pathname : '/404';
  let last_segment = pathname.substring(pathname.lastIndexOf('/') + 1);
  let active_sidemenu;

  if (last_segment.includes('-')) {
    active_sidemenu = last_segment.split('-')[0]
  } else {
    active_sidemenu = last_segment
  }

  const menu = [
    {
      id: "assets",
      label: "Assets",
      icon: <Assets />,
      href: `${match.url}`,
    },
    {
      id: "ace",
      label: "Acent",
      icon: <AceLogo />,
      href: `${match.url}/ace`,
    },
    {
      id: "zera",
      label: "Zera",
      icon: <ZeraLogo />,
      href: `${match.url}/zera`,
    },
    {
      id: "dappx",
      label: "DAPPX",
      icon: <DappxLogo />,
      href: `${match.url}/dappx`,
    },
    {
      id: "edao",
      label: "Governance/UBI Earning",
      icon: <EdaoLogo />,
      href: `${match.url}/edao`,
    },
    // {
    //   id: "package",
    //   label: "My Package",
    //   icon: <Thumbnail />,
    //   href: `${match.url}/package`,
    // },
    // {
    //   id: "profile",
    //   label: "My Profile",
    //   icon: <User />,
    //   href: `${match.url}/profile`,
    //   disabled: true
    // },
  ];

  const [open, setOpen] = useState(active_sidemenu);
  const [navVisible, showNavbar] = useState(true);

  const onToggleOpen = (id) => {
    if (id === open) {
      setOpen()
    } else {
      setOpen(id)
    }
  };

  const IconLabel = ({ icon, label, id }) => {
    const isBetaLabel = id == 'zera'
      ? (
        <>
          {label} <span
            className="beta-chip"
          >BETA</span>
        </>
      ) : label

    return (
      <>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        {label && <ListItemText primary={isBetaLabel} />}
      </>
    )
  }

  return (
    <div className="desktop-view">
      <Switch>
        <Route exact path={`${match.path}`} component={Profile} />
        <Route exact path={`${match.path}/ace`} component={ACE} />
        <Route exact path={`${match.path}/zera`} component={Zera} />
        <Route exact path={`${match.path}/dappx`} component={Dappx} />
        <Route exact path={`${match.path}/edao`} component={EDAO} />
        {/* <Route exact path={`${match.path}/dappx-paypal`} component={DappxPaypal} />
        <Route exact path={`${match.path}/dappx-paypal/success/:id`} component={PaypalPaymentSuccess} /> */}
      </Switch>

    </div>
  );
}

export default Desktop;
