import React from 'react';
import { Box, ButtonBase, Button } from '@material-ui/core';
import Highlighter from "react-highlight-words";
import HoverVideoPlayer from 'react-hover-video-player';
import './ImageWithHover.scss';

import { useDimensions } from "../../constant/useDimensions";
import { ReactComponent as Play } from '../../assets/images/icons/on-hover-play.svg';
import { ReactComponent as Store } from '../../assets/images/icons/on-hover-store.svg';
import { ReactComponent as Star } from '../../assets/images/icons/on-hover-star.svg';
import { ReactComponent as FullStar } from '../../assets/images/icons/on-hover-full-star.svg';

const ImageWithHover = ({ val, mobile_xs, searchState }) => {
  const [{ width }, ref] = useDimensions();
  let isImageSmall = width >= 587;

  return (
    <Box className="box-with-hover">
      <ButtonBase className="link" href={val.href} target="_blank">
        <div className="img-container" ref={ref}>
          <img className="w-100" src={val.image} alt={val.name} />
          {(val.videoOnHover && !mobile_xs) &&
            <div className="hover-video-container">
              <HoverVideoPlayer
                className="hover-video-player"
                videoSrc={val.videoOnHover}
                pausedOverlay={
                  <img className="w-100" src={val.image} alt={val.name} />
                }
                loadingOverlay={
                  <div className="loading-spinner-overlay" />
                }
              />
            </div>
          }
        </div>
      </ButtonBase>
      {!mobile_xs &&
        <div className={`details ${val.commingSoon ? 'coming-soon' : ''}`}>
          {val.commingSoon
            ? <h4>Coming Soon</h4>
            : <>
              {isImageSmall &&
                <div className="left">
                  {val.logo
                    ? <h4 className="with-logo">
                      <img src={val.logo} alt={val.name} />
                    </h4>
                    : <h4>
                      <Highlighter
                        searchWords={[searchState]}
                        textToHighlight={val.name}
                      />
                    </h4>
                  }
                  {val.currency && <p className="currency">{val.currency}</p>}
                </div>
              }

              <div className="right">
                <Button disableRipple href={val.href} target="_blank" className="play-btn"><Play />{isImageSmall && <label>PLAY</label>}</Button>
                {val.store && <Button disableRipple href={val.store} target="_blank" className="store-btn"><Store />{isImageSmall && <label>NFT</label>}</Button>}
                <Button disableRipple className="star-btn"><FullStar /><FullStar /><FullStar /><FullStar /><FullStar /></Button>
              </div>
            </>
          }
        </div>
      }
    </Box>
  );
}

export default ImageWithHover;