import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import { Button, Fade, TextField, Link, FormGroup, FormControlLabel, Checkbox, ClickAwayListener, Tooltip, IconButton } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import { Config } from '../../../../constant/config';
import CustomDialog from '../../../../components/Modal/CustomDialog';
import acent_logo from '../../../../assets/images/icons/acent-logo.svg';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ReactComponent as WarningIcon } from '../../../../assets/images/icons/warning.svg';
import { ReactComponent as LoaderIcon } from '../../../../assets/images/icons/loader-2.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/images/icons/error-x.svg';
import { ReactComponent as SuccessIcon } from '../../../../assets/images/icons/success-check.svg';
import TransactionPreviewCurrent from '../../../../components/Modal/TransactionPreviewCurrent';
import TransactionPreview from '../../../../components/Modal/TransactionPreview';
import { NumberFormatCustom } from '../../../../components/CustomInput';
import './index.scss';
import {Overlay,OverlayTrigger, Popover} from "react-bootstrap";
import kucoin from "../../../../assets/images/icons/kucoin.png";
import gate from "../../../../assets/images/icons/gate.io.png";
import GovernancePledge from "./GovernancePledge";
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({

    btn: {
        fontSize: '1rem',
        lineHeight: 1.6,
        borderRadius: '5px',
        borderColor: '#016ce9',
        color: '#016ce9',
        fontWeight: 500,
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
    },
}));


const AceButtonPledge = ({ balance,check }) => {
    const classes = useStyles();

    const [input_val, set_input_val] = useState('')
    const [input_fiat_val, set_input_fiat_val] = useState(0)
    const [wallet_action, set_wallet_action] = useState(false)
    const [check_box, set_check_box] = useState(false)
    const [minPledge, setMinPledge] = useState(0)
    const [maxPledge, setMaxPledge] = useState(0)
    const [maxAccPledge, setMaxAccPledge] = useState(0)
    const [holdingPeriod, setHoldingPeriod] = useState(0)
    const [activationFee, setActivationFee] = useState(0)
    const [aceBalance, setAceBalance] = useState(0)
    const [pledgedBalance, setPledgedBalance] = useState('')
    const [fiat_val, setFiat_val] = useState(0)
    const [newDappXBal, setNewDappXBal] = useState(0)
    const [newPledgeBal, setNewPledgeBal] = useState(0)
    const [newMaxAccPledge, setNewMaxAccPledge] = useState(0)
    const [activeSeason, setActiveSeason] = useState()
    const [usdValue, setUsdValue] = useState(0)
    const [isMarketPriceReady, setIsMarketPriceReady] = useState(true)
    const [marketPrice, setMarketPrice] = useState(undefined)
    const [isTimerRunning, setIsTimerRunning] = useState(false)
    const [governanceModal, setGovernanaceModal] = useState(false)
    const [show, setShow] = useState(false);
    const [seasons, setSeasons] = useState([])
    const [newState, setNewState] = useState({objects: []})
    const [sendDuration, setSendDuration] = useState('')
    const [inputVal, setInputVal] = useState('')
    const [id, setId] = useState('')
    const target = useRef(null);
    const session_Id = localStorage.getItem('session-id')
    const wallet = localStorage.getItem('wallet_address')
    let whole_num = aceBalance ? aceBalance.toString().split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
    let decimal_num = aceBalance ? aceBalance.toString().split('.')[1] : '';

    // Input Error
    const [err_message, set_err_message] = useState('');
    const [outline_error, set_outline_error] = useState([]);

    // Modal Deposit
    const [open_modal, set_open_modal] = useState(false);
    const onOpenModal = () => {
        set_open_modal(true)
    }
    const onCloseModal = (e) => {
        e.preventDefault()
        if (wallet_action) {
            set_wallet_action(false)
            set_err_message('')
        } else {
            onClear()
        }
    }
useEffect(()=>{
    setMaxAccPledge(maxPledge-pledgedBalance)
},[pledgedBalance,maxPledge])
    const fetchAceBalance = async () => {
        await axios.get(Config.DAPPX_BASE_URL + '/users/acent/active/balance', {headers: {'session-id': session_Id}})
            .then(async(result) => {
                let hasNoDecimals = result.data.balance.toFixed(6).split('.')[1]
                hasNoDecimals == 0 || hasNoDecimals === undefined ? setAceBalance(Number(Math.trunc(Number(result.data.balance)))) : setAceBalance(Number(result.data.balance).toFixed(6))
                // setAceBalance(Number(result.data.balance))
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const popoverAcent = (

        // <Overlay target={target.current} show={show} placement="right">
            <Popover className="pop1" id="popover-positioned-bottom" title="Popover bottom">
            <div className="pledge_drpdn">
                <div className="pledge_header">
                    <h1>Select Stake Type</h1>
                </div>
                <div className="pledge_body">
                    <div className="pledge_btn">
                        <Button  className="button" onClick={()=>{
                            // document.body.click()
                            setGovernanaceModal(true)
                            setShow(false)

                        }}>Metaweb Governance Stake</Button>
                    </div>

                    <div  className="pledge_btn">
                        <Button className="button" 
                        // disabled={activeSeason ? false:true}
                         onClick={onOpenModal}>Season Stake</Button>

                    </div>
                </div>
            </div>
        </Popover>
         // </Overlay>
    );

    const fetchPledgeBalance = async (id) => {
        await axios.get(Config.DAPPX_BASE_URL + '/users/acent/pledgedAcentPerSeason?season_ids='+id ,{headers: {'session-id': session_Id}},)
            .then((result) => {
                // let balance_wei = Web3.utils.fromWei(result.data.toString(), 'ether');
                // alert(balance_wei);
                // let num2 = Number(balance_wei.toString().match(/^\d+(?:\.\d{0,6})?/));
                // alert(num2);
                //
                // let hasNoDecimals = balance_wei.split('.')[1]
                // hasNoDecimals == 0 || hasNoDecimals === undefined ? setPledgedBalance(Number(Math.trunc(num2))) : setPledgedBalance(Number(num2).toFixed(10))
                //
                setPledgedBalance(result.data.pledge_amount)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const fetchActivePledgeSeason=async ()=>{
            axios.get(Config.DAPPX_BASE_URL + '/users/acent/currentPledgeSeason')
                .then((res) => {
                    const active_season = res.data.season.map(({ _id }) => _id).join(',')
                  const result=res.data.season
                  setSeasons(res.data)
                  fetchPledgeBalance(active_season)
                    setActiveSeason(result.data)
                    setMinPledge(Number(result.min_amount))
                    setMaxPledge(Number(result.max_amount));
                })
                .catch((error) => {
                    console.log(error);
                })
    }

    // Modal Loading
    const [open_modal_loading, set_open_modal_loading] = useState(false);
    const onOpenModalLoading = () => {
        set_open_modal_loading(true)
        set_wallet_action(false)
        set_open_modal(false)
    }
    const onCloseModalLoading = () => {
        set_open_modal_loading(false)
    }

    // Modal Success
    const [open_modal_success, set_open_modal_success] = useState(false);
    const onOpenModalSuccess = () => {
        set_open_modal_success(true)
        set_open_modal(false)
    }
    const onCloseModalSuccess = () => {
        set_open_modal_success(false)
        window.location.reload()
    }

    // Modal Error
    const [open_modal_error, set_open_modal_error] = useState(false);
    const onOpenModalError = () => {
        set_open_modal_error(true)
        onClear()
    }
    const onCloseModalError = () => {
        set_open_modal_error(false)
        window.location.reload()
    }

    // Functions
    const remove_errors = (target) => {
        set_err_message('')
        let update_outline_error = outline_error.filter(id => id !== target)
        set_outline_error(update_outline_error)
    }

    const convertCurrency = async () => {
        await axios.get(Config.DAPPX_BASE_URL + '/users/getMarketPrices')
            .then((res) => {
                setUsdValue(res.data.ACE.USD)
            })
            .catch((error) => {
                set_input_fiat_val(0)
                set_err_message("Can't pull conversion rate.")
            })
    }

    const convertValue = (balance, tokenCode) => {
        let convertion_currency = 'USD';

        if (isMarketPriceReady) {
            axios.get(Config.DAPPX_BASE_URL + '/users/getMarketPrices')
                .then((prices) => {
                    let fiat_currency = prices.data[tokenCode][convertion_currency] ? prices.data[tokenCode][convertion_currency] : 0;
                    let fiat_value_value = parseFloat(balance) * parseFloat(fiat_currency);
                    let convertion_value;

                    if (fiat_value_value === 0) {
                        convertion_value = parseFloat(fiat_value_value)
                    } else {
                        convertion_value = parseFloat(fiat_value_value).toFixed(2)
                    }

                    set_input_fiat_val(convertion_value);
                    setMarketPrice(prices.data)
                    setIsMarketPriceReady(false)
                    if (!isTimerRunning) marketPriceTimer()
                })
                .catch((error) => {
                    console.log('convertValue error: ', error)
                    set_input_fiat_val(0)
                    set_err_message("Can't pull conversion rate.")
                })
        } else {
            let fiat_currency = marketPrice[tokenCode][convertion_currency] ? marketPrice[tokenCode][convertion_currency] : 0;
            let fiat_value_value = parseFloat(balance) * parseFloat(fiat_currency);
            let convertion_value;

            if (fiat_value_value === 0) {
                convertion_value = parseFloat(fiat_value_value)
            } else {
                convertion_value = parseFloat(fiat_value_value).toFixed(2)
            }

            set_input_fiat_val(convertion_value);
            if (!isTimerRunning) marketPriceTimer()
        }
    }

    const marketPriceTimer = () => {
        setIsTimerRunning(true)

        setTimeout(() => {
            setIsMarketPriceReady(true)
            setIsTimerRunning(false)
        }, 60000)
    }

    useEffect(() => {
        fetchActivePledgeSeason()
        fetchAceBalance()
        convertCurrency()
    },[])

    useEffect(() => {
        setNewMaxAccPledge(maxAccPledge - pledgedBalance)
    },[input_val, pledgedBalance,open_modal])

    const onChange_num = (e, i, id) => {
        setNewState((state) => {
            const newObject = {...state.objects};
            newObject[`${i}`] = {value: e.target.value, key: id}
            return {objects: newObject }
           });


        // const seasonId = e.target.name._id
        // console.log(seasonId)
        const target_val = e.target.value;
        const decimals = target_val.split('.')[1] || '';
        remove_errors(e.target.id)

        if (target_val !== '' && decimals.length <= 6) {
            set_input_val(target_val)
            let convertion_value = (target_val * usdValue).toFixed(2)
            // convertValue(target_val, 'DAPPX')
            set_input_fiat_val(convertion_value)
            convertCurrency()
        }

        if (target_val === '') {
            set_input_val(target_val)
            set_input_fiat_val(target_val * usdValue)
            convertCurrency()
            // convertValue(0, 'DAPPX')
        }
    }

    const onCheckDisclaimer = (event) => {
        set_check_box(event.target.checked);
    };

    const maxVal = () => {
        set_input_val(Math.min(aceBalance, maxPledge, newMaxAccPledge))
        convertValue(Math.min(aceBalance, maxPledge, newMaxAccPledge), 'DAPPX')
    }

    const minVal = () => {
        set_input_val(minPledge)
        convertValue(minPledge, 'DAPPX')
    }

    const submitModal = (id) => {
        const finalObject = Object.values(newState.objects).filter(x => x.key == id)
        const findData = Object.values(seasons.season).filter(x => x._id == id)

        const finalMinPledge = findData[0].min_amount
        const finalMaxPledge = findData[0].max_amount
        const finalDuration = findData[0].duration
        let finalValue
        if(finalMinPledge == finalMaxPledge){
            finalValue = finalMinPledge
        } else {
            finalValue = finalObject[0]? finalObject[0].value : ''
        }
        
        // await setInputVal(finalValue)
        set_input_val(finalValue)
        setNewDappXBal(aceBalance - finalValue)
        setNewPledgeBal(Number(pledgedBalance) + Number(finalValue))
        setSendDuration(finalDuration)
        setId(id)

        if (finalMinPledge == finalMaxPledge) {
            if(Number(finalMinPledge) > aceBalance) {
                set_err_message('Insufficient balance!')
            } else {
                set_err_message('')
                onSubmit()
            }
        } else {
            if (Number(input_val) > aceBalance) {
                set_err_message('Insufficient balance!')
            } else if(Number(input_val) < finalMinPledge) {
                set_err_message('Your Stake amount is below the minimum limit!')
    
            } else if( Number(input_val) > finalMaxPledge ) {
                set_err_message('Stake amount is greater than the maximum limit!')
    
            } else if(Number(input_val) == 0 || !input_val) {
                set_err_message('Invalid input!')
            } else {
                set_err_message('')
                onSubmit(finalMinPledge)
            }
        }
    }


    const onSubmit = (stakeAmount) => {
        
            if (wallet_action) {

                const data = {
                    pledgeAmount: input_val ? input_val : stakeAmount,
                    wallet,
                    season_id:id,
                    pledge_date:new Date(),
                    duration:sendDuration
                }

                axios.post(Config.DAPPX_BASE_URL + '/users/acent/createPledge', data, {headers: {'session-id': session_Id}})
                    .then((res) => {
                        setAceBalance(newDappXBal)
                        setPledgedBalance(newPledgeBal)
                    })
                    .catch((error) => {
                        console.log(error);
                    })

                onOpenModalLoading()
                setTimeout(() => { // for UI only
                    onCloseModalLoading()
                    setTimeout(() => {
                        onOpenModalSuccess()
                    }, 300)
                    // onOpenModalError()
                }, [2000])
            } else {
                set_wallet_action(true)
            }
        }
    

    const onClear = () => {
        setTimeout(() => {
            set_wallet_action(false)
            set_open_modal(false)
            set_input_val('')
            set_input_fiat_val(0)
            set_err_message('')
            set_outline_error([])
            set_wallet_action(false)
            set_check_box(false)
            setSendDuration('')
            setId('')
            setInputVal('')
        }, 400)
    }

    // Tooltip
    const [open_tooltip, set_tooltip] = useState(false)
    const onOpen_tooltip = () => set_tooltip(true)
    const onClose_tooltip = () => set_tooltip(false)

    return (
        <>
            {
                governanceModal &&
                <GovernancePledge closeHandler={()=>setGovernanaceModal(false)} open={governanceModal} />


            }
            {/*<Overlay target={target.current} show={show}  rootClose placement="bottom">*/}
            {/*    <Popover className="pop1" id="popover-positioned-bottom" title="Popover bottom">*/}
            {/*        <div className="pledge_drpdn">*/}
            {/*            <div className="pledge_header">*/}
            {/*                <h1>Select Pledge Type</h1>*/}
            {/*            </div>*/}
            {/*            <div className="pledge_body">*/}
            {/*                <div className="pledge_btn">*/}
            {/*                    <Button  className="button" onClick={()=>{*/}
            {/*                        // document.body.click()*/}
            {/*                        setGovernanaceModal(true)*/}
            {/*                        setShow(false)*/}

            {/*                    }}>Metaweb Governance Pledge</Button>*/}
            {/*                </div>*/}

            {/*                <div  className="pledge_btn">*/}
            {/*                    <Button className="button" disabled={activeSeason ? false:true} onClick={onOpenModal}>Season Pledge</Button>*/}

            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Popover>*/}
            {/*</Overlay>*/}
            {
                check ?(
                    // activeSeason? <Button
                    //     className="pledge-btn pldgebtn"
                    //     style={{"color":"#016ce9", "border":"1px solid #016ce9", "width": "100%", "font-size":"16px",
                    //         "font-weight":"500","line-height":"1.6"}}
                    //     variant="outlined"
                    //     color="primary"
                    //     // onClick={onOpenModal}
                    //     // disabled={activeSeason ? false:true}
                    //
                    // >
                    //
                    //
                    //     Pledge
                    // </Button>
                    //     :
                    //     <OverlayTrigger trigger={[ 'click']} rootClose placement="bottom" overlay={popoverAcent}>
                   <>
                       <OverlayTrigger  rootCloseEvent="mousedown" trigger="click"  rootClose placement="bottom" overlay={popoverAcent}>
                           <Button   placement="bottom"
                               // className="pledge-btn pldgebtn"
                                   style={{ "width": "100%",}}
                                   variant="outlined"
                                   color="primary"
                                   className={classes.btn}
                           >


                               Stake
                           </Button>

                       </OverlayTrigger>




                   </>
                    // </OverlayTrigger>
                    )
                   :
                    <OverlayTrigger  rootCloseEvent="mousedown" trigger="click"  rootClose placement="bottom" overlay={popoverAcent}>

                    <Button
                        className="pledge-btn"
                        variant="outlined"
                        color="primary"
                        // onClick={onOpenModal}
                        // disabled={activeSeason ? false:true}

                    >


                        Stake
                    </Button>
                    </OverlayTrigger>
            }

                <CustomDialog
                    open={open_modal}
                    className="pledge-dialog"
                    onClose={onCloseModal}
                    header="Stake ACE"
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    body={
                        <>
                            {!wallet_action &&
                            <div className="container-balance">
                                <TransactionPreviewCurrent
                                    bal_1_title="Balance"
                                    bal_2_title="Stake"
                                    current_bal_1={aceBalance.toString()}
                                    current_bal_2={pledgedBalance.toString()}
                                    error_message={set_err_message}
                                    token_code="ACE"
                                    open={wallet_action}
                                />
                            </div>
                            }

                            <div className={`container-textfield ${wallet_action ? 'wallet-action' : ''}`}>
                                <div className="number-label">
                                    <label>
                                        Stake
                                        <ClickAwayListener onClickAway={onClose_tooltip}>
                                            <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={onClose_tooltip}
                                                open={open_tooltip}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                arrow
                                                placement="right"
                                                title={
                                                    <div className="tooltip-content">
                                                        <h5>Stake Limit</h5>
                                                        <p>Minimum stake per transaction: <span>{minPledge} ACE</span></p>
                                                        <p style={{whiteSpace: 'nowrap'}}>Maximum stake per transaction: <span>{maxPledge} ACE</span></p>
                                                        {/* <p style={{whiteSpace: 'nowrap'}}>Remaining amount for staking: <span>{maxAccPledge.toString().includes('.') ? maxAccPledge.toFixed(6) : maxAccPledge} ACE</span></p> */}
                                                    </div>
                                                }
                                            >
                                                <IconButton onClick={onOpen_tooltip} className={`info-btn ${open_tooltip ? 'open' : ''}`}><InfoOutlinedIcon/></IconButton>
                                            </Tooltip>
                                        </ClickAwayListener>
                                    </label>
                                    {/*<div className="right">*/}
                                    {/*    <Button disableRipple onClick={minVal}>Min</Button>*/}
                                    {/*    <Button disableRipple onClick={maxVal}>Max</Button>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="textfield-block">
                                {seasons.season && <table>
                                    <tr>
                                        <th>Season</th>
                                        <th>Price</th>
                                        <th>Stake</th>
                                    </tr>
                                    
                                    {seasons.season.map((season, i) => (
                                        <tr style={{lineHeight: '16px'}}>
                                            <td>{season.name}</td>
                                            {/* <td>{season.min_amount}</td> */}
                                            <td>{season.min_amount == season.max_amount ? 
                                            <p style={{width: '70%', textAlign: 'center', fontSize: '18px', fontWeight: 700}}>{season.min_amount}</p>
                                            : 
                                            <TextField 
                                            // fullWidth
                                            key={season}
                                            className="number-field"
                                            style={{width: '170px', transform: 'translateY(-10px)'}}
                                            error={outline_error.includes('amount')}
                                            value={newState.objects[`${i}`]?.value || ''}
                                            // value={input_val}
                                            // onChange={(e) => onChange_num(e)}
                                            onChange={(e) => onChange_num(e, i, season._id)}
                                            autoComplete="off"
                                            variant="outlined"
                                            id='amount'
                                            placeholder= {season.min_amount + '-' + season.max_amount}
                                            disabled={wallet_action ? true : false}
                                            name={season}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            />}</td>
                                            <td><Button  variant="contained" color="primary" onClick={() => submitModal(season._id)}>Stake</Button></td>
                                        </tr>
                                    ))}
                                    </table> }
                                    {/* <TextField
                                        fullWidth
                                        className="number-field"
                                        error={outline_error.includes('amount')}
                                        value={input_val}
                                        onChange={onChange_num}
                                        autoComplete="off"
                                        variant="outlined"
                                        id='amount'
                                        placeholder="0.0"
                                        disabled={wallet_action ? true : false}
                                        name="number-field"
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                        }}
                                    />
                                    <img className="coin-logo" src={acent_logo} alt={"logo"} /> */}
                                </div>
                                {/* <div className="convertion">≈ {input_fiat_val} USD</div> */}
                            </div>

                            {/*{!wallet_action &&*/}
                            {/*<FormGroup className="container-check">*/}
                            {/*    <FormControlLabel*/}
                            {/*        control={*/}
                            {/*            <Checkbox*/}
                            {/*                checked={check_box}*/}
                            {/*                onChange={onCheckDisclaimer}*/}
                            {/*                name="remember_me"*/}
                            {/*                color="primary" />*/}
                            {/*        }*/}
                            {/*        label={`I understand that changing or decreasing my most recent pledged amount within ${holdingPeriod} hour/s will incur a penalty.`}*/}
                            {/*    />*/}
                            {/*</FormGroup>*/}
                            {/*}*/}

                            {wallet_action &&
                            <div className="container-preview">
                                <TransactionPreview
                                    bal_1_title="Balance"
                                    bal_2_title="Stake"
                                    current_bal_1={aceBalance.toString()}
                                    current_bal_2={pledgedBalance.toString()}
                                    after_bal_1={newDappXBal.toFixed(6).toString()}
                                    after_bal_2={newPledgeBal.toFixed(6).toString()}
                                    error_message={set_err_message}
                                    token_code="DAPPX"
                                    open={wallet_action}
                                />
                            </div>
                            }
                        </>
                    }
                    footer={<>
                        {err_message &&
                        <Fade className="error custom-error" in={err_message ? true : false} timeout={500} >
                            <Alert severity="error"><WarningIcon className="icon" />{err_message}</Alert>
                        </Fade>
                        }
                        <div className="button-block">
                            {wallet_action ?  <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disableElevation
                                disabled={err_message}
                                onClick={onSubmit}
                                type="submit">{'Submit'}</Button>
                            : ''}
                            <Button variant="contained" color="default" fullWidth disableElevation onClick={onCloseModal}>Cancel</Button>
                        </div>
                    </>}
                />
            <CustomDialog
                open={open_modal_loading}
                className="pledge-dialog loading-dialog"
                onClose={onCloseModalLoading}
                header="Processing your request"
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                body={<>
                    <LoaderIcon className="icon"/>
                    <p>Almost there!</p>
                    <p>Please wait a moment.</p>
                </>}
            />

            <CustomDialog
                open={open_modal_success}
                className="pledge-dialog success-dialog"
                onClose={onCloseModalSuccess}
                header= {"Stake Successful!"}
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                body={<>
                    <SuccessIcon className="icon"/>
                    <>
                        <p>You have successfully staked</p>
                        <p className="amount-preview">{input_val} ACE</p>
                    </>
                </>}
                footer={<>
                    <div className="button-block">
                        <Button variant="contained" color="primary" disableElevation onClick={onCloseModalSuccess} type="submit">OK</Button>
                    </div>
                </>}
            />

            <CustomDialog
                open={open_modal_error}
                className="pledge-dialog error-dialog"
                onClose={onCloseModalError}
                header="Stake Failed"
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                body={<>
                    <ErrorIcon className="icon"/>
                    <p>Your stake transaction didn't push through.</p>
                    <p>Amount of ACE has been reverted to your wallet.</p>
                </>}
                footer={<>
                    <div className="button-block">
                        <Button variant="contained" color="primary" disableElevation onClick={onCloseModalError} type="submit">OK</Button>
                    </div>
                </>}
            />
        </>
    )
}

export default AceButtonPledge;
