import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, ButtonBase } from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { useWindowSize } from '../../constant/useWindowSize';
import { useTranslation } from 'react-i18next';

const SectionTopChart = ({ searchState }) => {

  const [xs_view, set_xs_view] = useState(false);

  const [ window_width ] = useWindowSize();
  useEffect(() => {
    if(window_width < 600) set_xs_view(true)
    else set_xs_view(false)
  }, [window_width]);
  const {t} = useTranslation()
  const displayDesktop = () => {
   
    return (
      <>
        <Grid container spacing={xs_view ? 1 : 3}>
          <Grid item xs={6} md={3}>
            <ButtonBase
            // {...{
            //   to: "/top-defi-charts",
            //   component: RouterLink,
            // }}
            >
              <Box
                className="box box-defi"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`https://ai.dappstore.me/`, "_blank");
                }}
              ></Box>
            </ButtonBase>
          </Grid>
          <Grid item xs={6} md={3}>
            <ButtonBase
              {...{
                to: "/top-nft-charts",
                component: RouterLink,
              }}
            >
              <Box className="box box-nft"></Box>
            </ButtonBase>
          </Grid>
          <Grid item xs={6} md={3}>
            <ButtonBase
              {...{
                to: "/top-game-charts",
                component: RouterLink,
              }}
            >
              <Box className="box box-game"></Box>
            </ButtonBase>
          </Grid>
          <Grid item xs={6} md={3}>
            <ButtonBase
              {...{
                to: "/exchange",
                component: RouterLink,
              }}
            >
              <Box className="box box-exchange"></Box>
            </ButtonBase>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <div className="section section-top-chart">
      {/* <div className="dapparcade-btn-sat">
        <Button
          variant="contained"
          color="primary"
          component="a"
          disableElevation
          href="https://sat.dapparcade.io/">
          Dapparcade SAT
        </Button>
      </div> */}
      <Typography variant="h2" className="section-title">
        <Highlighter
          searchWords={[searchState]}
          textToHighlight={t("WHAT'S HOT")}
        />
      </Typography>
      {displayDesktop()}
    </div>
  );
}

export default SectionTopChart;