/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios'
import {Config} from '../constant/config'
import { getLocalStorageItem } from '../utils/localStorage';

const API_BASE_URL = Config.APP_API_URL;
const DAPPX_BASE_URL = Config.DAPPX_BASE_URL;
let headers

// handle errors
const handleErrors = async (error) => {
	let result = {}
	const data = error && error.response && error.response.data
	const status = error && error.response && error.response.status
	
	result = {
		status: status,
		data: data,
		error: true
	}
	return result
	
}
// handle response
const handleResponse = res => {
	let result = {}
	const data = res && res.data
	const status = res && res.status
	
	result = {
		status: status,
		data: data,
		error: false
	}
	return result
}
// create axios api
const createApi = (baseUrl) => {
	const sessionId = getLocalStorageItem('session-id')
	if (sessionId) {
		headers = {
			'Accept': '*',
		  	"Content-Type": "application/json",
		  	"session-id": sessionId
		};
	} else {
		headers = {
			'Accept': '*',
			'Content-Type': 'application/json'
		}
	}
	
    const api = axios.create({
		baseURL: baseUrl,
		responseType: 'json',
		headers
	})

	api.interceptors.response.use(function (response) {
        return response
    }, function (error) {
        // const { status } = error.response	
        console.log(error,'response')
        return Promise.reject(error)
    })
    return api
}
// handle requests
const requests = {
	get: (baseUrl, endPoint, data, externalUrl) =>
		createApi(baseUrl)
			.get(`${!externalUrl ? `${baseUrl}${endPoint}` : externalUrl}`, data)
			.then(handleResponse)
			.catch(handleErrors),
    post: (baseUrl, endPoint, data, externalUrl = false) =>
        createApi(baseUrl)
            .post(`${!externalUrl ? `${baseUrl}${endPoint}` : externalUrl}`, data)
            .then(handleResponse)
            .catch(handleErrors),
	patch: (baseUrl, endPoint, data, externalUrl) =>
		createApi(baseUrl)
			.patch(`${!externalUrl ? `${baseUrl}${endPoint}` : externalUrl}`, data)
			.then(handleResponse)
			.catch(handleErrors),
	delete: (baseUrl, endPoint) =>
		createApi(baseUrl)
			.delete(`${baseUrl}${endPoint}`)
			.then(handleResponse)
			.catch(handleErrors),
}

const Auth = {
    login: (data) => requests.post(DAPPX_BASE_URL, "/users/authentication", data),
}
const User = {
	getNonce: (data) => requests.get(DAPPX_BASE_URL ,`/users?publicAddress=${data}`),
	getUser: (data) => requests.get(API_BASE_URL ,`/users/userProfile`, data)
}
const Transaction = {
	getDepositContract: () => requests.get(DAPPX_BASE_URL , 'dappstore-contract.json'),
	createTransaction: (data) => requests.post(DAPPX_BASE_URL ,"/users/createTransaction", data)
	// withdraw: (data) => requests.get(DAPPX_BASE_URL ,``)
}
const IPFS = {
	upload: (data, url) => requests.post(undefined, undefined ,data, url),
	fetchItemDetails: (url) => requests.get(undefined, undefined, undefined, url),
}

export default {
	Auth,
	User,
	Transaction,
	IPFS
}
