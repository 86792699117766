import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NFT_IMX_BASE_URL } from '../../constant/config';
import ItemsList from '../../components/Collections/Items';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { useParams } from 'react-router-dom';

const Collection = () => {

    return (
        <div className="main">
            
            <Breadcrumb title={`NFT Marketplace`} subpage={`NFT Collection`} page={`NFT Collection Page`} imxbutton={true} />
            <ItemsList />
            
        </div>
    );
    
}

export default Collection;

