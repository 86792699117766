import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3542d8',
    }
  },
  typography: {
    fontFamily: "'Roboto', sans-serif;",
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "'Roboto', sans-serif;",
        fontWeight: 400,
        textTransform: 'none'
      },
    }, 
  }, 
});

export default theme;