import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import { Button, Fade, TextField, Link, FormGroup, FormControlLabel, Checkbox, ClickAwayListener, Tooltip, IconButton } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import { Config } from '../../../../constant/config';
import CustomDialog from '../../../../components/Modal/CustomDialog';
import dappx_logo from '../../../../assets/images/dappstore-icon.svg';
import edao_logo from '../../../../assets/images/icons/edao-logo.svg';
import acent_logo from '../../../../assets/images/icons/acent-logo.svg';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ReactComponent as WarningIcon } from '../../../../assets/images/icons/warning.svg';
import { ReactComponent as LoaderIcon } from '../../../../assets/images/icons/loader-2.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/images/icons/error-x.svg';
import { ReactComponent as SuccessIcon } from '../../../../assets/images/icons/success-check.svg';
import TransactionPreviewCurrentScalable from '../../../../components/Modal/TransactionPreviewCurrentScalable';
import TransactionPreviewScalable from '../../../../components/Modal/TransactionPreviewScalable';
import { NumberFormatCustom } from '../../../../components/CustomInput';
import './index.scss';

const logos = {
  edao: edao_logo,
  dappx: dappx_logo,
  ace: acent_logo
}

const content = {
  edao: {
    activation : {
      notes: 'EDAO Staking allows you to earn more ACE.',
      checkboxLabel: 'I understand that the one-time activation fee of %s ACE is almost negligible compared to the rewards I can obtain from EDAO Staking.',
      token: 'ACE'
    },
    pledging: {
      checkboxLabel: 'I understand that minimizing and/or withdrawing my staked amount within %s hour/s of the last stake will incur a penalty fee if I proceed.'
    }
  },
   dappx: {
    activation : {
      notes: 'DAPPX Staking allows you to earn more Zera. FREE!',
      checkboxLabel: 'I know that %s DAPPX one-time activation fee is minimum compared to the benefits I can earn from DAPPX staking.',
      token: 'DAPPX'
    },
    pledging: {
      checkboxLabel: 'I understand that changing or decreasing my most recent staked amount within %s hour/s will incur a penalty.'
    }
  }
}

const apiEndpoint = {
  edao: {
    status: '/users/edao/governance-pledging/status',
    feeTokenBalance: '/users/acent/active/balance',
    tokenBalance: '/users/edao/active/balance',
    config: '/users/edao/governance-pledging/settings',
    activate: '/users/edao/governance-pledging/activate',
    pledge: '/users/edao/governance-pledging/pledge',
    pledgedBalance: '/users/edao/governance-pledging/balance'
  },
   dappx: {
    status: '/users/isPledgingActivated',
    feeTokenBalance: '/users/getDappxDepositBalance',
    tokenBalance: '/users/getDappxDepositBalance',
    config: '/users/getPledgingConfig',
    activate: '/users/createPledgingActivationTransaction',
    pledge: '/users/createPledging',
    pledgedBalance: '/users/getPledgeBalance?token_type=DAPPX'
  }
}

const ButtonPledge = ({ balance, token = "dappx", isGovernance = false, isFullWidth = false, isConvertable = true, disableButton}) => {

  const [input_val, set_input_val] = useState('');
  const [input_fiat_val, set_input_fiat_val] = useState(0);
  const [wallet_action, set_wallet_action] = useState(false);
  const [check_box, set_check_box] = useState(false);
  const [minPledge, setMinPledge] = useState(0);
  const [maxPledge, setMaxPledge] = useState(0);
  const [maxAccPledge, setMaxAccPledge] = useState(0);
  const [holdingPeriod, setHoldingPeriod] = useState(0);
  const [activationFee, setActivationFee] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [feeTokenBalance, setFeeTokenBalance] = useState(0);
  const [pledgedBalance, setPledgedBalance] = useState('');
  const [fiat_val, setFiat_val] = useState(0);
  const [newFeeTokenBalance, setNewFeeTokenBalance] = useState(0);
  const [newTokenBalance, setNewTokenBalance] = useState(0);
  const [newPledgeBal, setNewPledgeBal] = useState(0);
  const [newMaxAccPledge, setNewMaxAccPledge] = useState(0);
  const [activatedUser, setActivatedUser] = useState(false);
  const [pledgeFeeNitro, setPledgeFeeNitro] = useState(0);
  const [pledgeFeeUBI, setPledgeFeeUBI] = useState(0);
  const [pledgeFeeCore, setPledgeFeeCore] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const [isMarketPriceReady, setIsMarketPriceReady] = useState(true);
  const [marketPrice, setMarketPrice] = useState(undefined);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [pledgeFee, setPledgeFee] = useState(0)

  const session_Id = localStorage.getItem('session-id')
  let feeWholeNum = feeTokenBalance ? feeTokenBalance.toString().split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
  let feeDecimalNum = feeTokenBalance ? feeTokenBalance.toString().split('.')[1] : '';

  // Input Error
  const [err_message, set_err_message] = useState('');
  const [outline_error, set_outline_error] = useState([]);

  // Modal Deposit
  const [open_modal, set_open_modal] = useState(false);
  const onOpenModal = () => {
    set_open_modal(true)
  }
  const onCloseModal = (e) => {
    e.preventDefault()

    if (wallet_action) {
      set_wallet_action(false)
      set_err_message('')
    } else {
      onClear()
    }
  }


  const hasUserPledged = async () => {
    await axios.get(Config.DAPPX_BASE_URL + apiEndpoint[token].status, {headers: {'session-id': session_Id}})
    .then((result) => {
      if (token === 'dappx') {
        setActivatedUser(result.data.data)
        return;
      }
      setActivatedUser(result.data.activated)
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const fetchFeeTokenBalance = async () => {
    if (token === 'dappx') {
      if (feeTokenBalance > 0) {
        setTokenBalance(feeTokenBalance);
      }
    }

    await axios.get(Config.DAPPX_BASE_URL + apiEndpoint[token].feeTokenBalance, {headers: {'session-id': session_Id}})
    .then(async(result) => {
      if (token === 'dappx') {
        let balance_wei = Web3.utils.fromWei(result.data.dappx_balance, 'ether');
        let hasNoDecimals = balance_wei.split('.')[1]
        hasNoDecimals == 0 || hasNoDecimals === undefined ? setFeeTokenBalance(Number(Math.trunc(balance_wei))) : setFeeTokenBalance(Number(balance_wei).toFixed(6))
        return;
      }

      let hasNoDecimals = result.data.balance.toFixed(6).split('.')[1]
      hasNoDecimals == 0 || hasNoDecimals === undefined ? setFeeTokenBalance(Number(Math.trunc(Number(result.data.balance)))) : setFeeTokenBalance(Number((result.data.balance).toFixed(6)))
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const fetchTokenBalance = async () => {
    await axios.get(Config.DAPPX_BASE_URL + apiEndpoint[token].tokenBalance, {headers: {'session-id': session_Id}})
    .then(async(result) => {
      if (token === 'dappx') {
        let balance_wei = Web3.utils.fromWei(result.data.dappx_balance, 'ether');
        let hasNoDecimals = balance_wei.split('.')[1]
        hasNoDecimals == 0 || hasNoDecimals === undefined ? setTokenBalance(Number(Math.trunc(balance_wei))) : setTokenBalance(Number(balance_wei).toFixed(6))
        return;
      }

      let hasNoDecimals = result.data.balance.toFixed(6).split('.')[1]
      hasNoDecimals == 0 || hasNoDecimals === undefined ? setTokenBalance(Number(Math.trunc(Number(result.data.balance)))) : setTokenBalance(Number(result.data.balance).toFixed(6))
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const fetchPledgeBalance = async () => {
    await axios.get(Config.DAPPX_BASE_URL + apiEndpoint[token].pledgedBalance, {headers: {'session-id': session_Id}})
    .then((result) => {
      if (token === 'dappx') {
        let balance_wei = Web3.utils.fromWei(result.data.dappx_balance.toString(), 'ether');
        let num2 = Number(balance_wei.toString().match(/^\d+(?:\.\d{0,6})?/));
        let hasNoDecimals = balance_wei.split('.')[1]
        hasNoDecimals == 0 || hasNoDecimals === undefined ? setPledgedBalance(Number(Math.trunc(num2))) : setPledgedBalance(Number(num2).toFixed(6))
        return;
      }

      setPledgedBalance(result.data.balance)
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const fetchPledgeRules = async () => {
    let headers = {
      headers: token === 'dappx' ? {'custom-token-id': "H00R7LPQSY"} : {'session-id': session_Id}
    }

    axios.get(Config.DAPPX_BASE_URL + apiEndpoint[token].config, headers)
    .then((res) => {
      if (token === 'dappx') {
        let min_pledge = Web3.utils.fromWei(res.data.data.minimum_pledge_limit_per_transaction.toString(), 'ether');
        let max_pledge = Web3.utils.fromWei(res.data.data.maximum_pledge_limit_per_transaction.toString(), 'ether');
        let max_account_pledge = Web3.utils.fromWei(res.data.data.maximum_pledge_per_account.toString(), 'ether');
        let activation_fee = Web3.utils.fromWei(res.data.data.activation_fee.toString(), 'ether');

        setMinPledge(Number(min_pledge));
        setMaxPledge(Number(max_pledge));
        setMaxAccPledge(Number(max_account_pledge));
        setActivationFee(Number(activation_fee));
        setHoldingPeriod(Number(res.data.data.holding_period));
        return;
      }

      setMinPledge(Number(res.data.min_pledge_per_tx));
      setMaxPledge(Number(res.data.max_pledge_per_tx));
      setMaxAccPledge(Number(res.data.max_pledge_per_user));
      setActivationFee(Number(res.data.activation_fee));
      setHoldingPeriod(Number(res.data.penalty_duration));
      setPledgeFeeCore(Number(res.data.pledge_fee_core));
      setPledgeFeeNitro(Number(res.data.pledge_fee_nitro));
      setPledgeFeeUBI(Number(res.data.pledge_fee_ubi));
      setPledgeFee(res.data.pledge_fee_core + res.data.pledge_fee_nitro + res.data.pledge_fee_ubi)
    })
    .catch((error) => {
      console.log(error);
    })
  }


  // Modal Loading
  const [open_modal_loading, set_open_modal_loading] = useState(false);
  const onOpenModalLoading = () => {
    set_open_modal_loading(true)
    set_wallet_action(false)
    set_open_modal(false)
  }
  const onCloseModalLoading = () => {
    set_open_modal_loading(false)
  }

  // Modal Success
  const [open_modal_success, set_open_modal_success] = useState(false);
  const onOpenModalSuccess = () => {
    set_open_modal_success(true)
    set_open_modal(false)
  }
  const onCloseModalSuccess = () => {
    set_open_modal_success(false)
    window.location.reload()
  }

  // Modal Error
  const [open_modal_error, set_open_modal_error] = useState(false);
  const onOpenModalError = () => {
    set_open_modal_error(true)
    onClear()
  }
  const onCloseModalError = () => {
    set_open_modal_error(false)
    window.location.reload()
  }

  // Functions
  const remove_errors = (target) => {
    set_err_message('')
    let update_outline_error = outline_error.filter(id => id !== target)
    set_outline_error(update_outline_error)
  }

  const convertCurrency = async () => {
    await axios.get(Config.DAPPX_BASE_URL + '/users/getMarketPrices')
    .then((res) => {
      setMarketPrice(res.data)

      if (token.toUpperCase() === "DAPPX") {
        setUsdValue(res.data.DAPPX.USD);
        return;
      }

      setUsdValue(res.data.ACE.USD);
    })
    .catch((error) => {
      set_input_fiat_val(0);
      set_err_message("Can't pull conversion rate.")
    })
  }

  const convertValue = (balance, tokenCode) => {
    let convertion_currency = 'USD';

    if (isMarketPriceReady) {
      axios.get(Config.DAPPX_BASE_URL + '/users/getMarketPrices')
        .then((prices) => {
          if (!prices.data[tokenCode]) {
            return;
          }
          let fiat_currency = prices.data[tokenCode][convertion_currency] ? prices.data[tokenCode][convertion_currency] : 0;
          let fiat_value_value = parseFloat(balance) * parseFloat(fiat_currency);
          let convertion_value;

          if (fiat_value_value === 0) {
            convertion_value = parseFloat(fiat_value_value)
          } else {
            convertion_value = parseFloat(fiat_value_value).toFixed(2)
          }

          set_input_fiat_val(convertion_value);
          setMarketPrice(prices.data)
          setIsMarketPriceReady(false)
          if (!isTimerRunning) marketPriceTimer()
        })
        .catch((error) => {
          console.log('convertValue error: ', error)
          set_input_fiat_val(0)
          set_err_message("Can't pull conversion rate.")
        })
    } else {
      if (!marketPrice[tokenCode]) {
        return;
      }

      let fiat_currency = marketPrice[tokenCode][convertion_currency] ? marketPrice[tokenCode][convertion_currency] : 0;
      let fiat_value_value = parseFloat(balance) * parseFloat(fiat_currency);
      let convertion_value;

      if (fiat_value_value === 0) {
        convertion_value = parseFloat(fiat_value_value)
      } else {
        convertion_value = parseFloat(fiat_value_value).toFixed(2)
      }

      set_input_fiat_val(convertion_value);
      if (!isTimerRunning) marketPriceTimer()
    }
  }

  const marketPriceTimer = () => {
    setIsTimerRunning(true)

    setTimeout(() => {
      setIsMarketPriceReady(true)
      setIsTimerRunning(false)
    }, 60000)
  }

  useEffect(() => {
    hasUserPledged()
    fetchPledgeRules()
    fetchTokenBalance()
    fetchFeeTokenBalance()
    fetchPledgeBalance()
    convertCurrency()
  },[])

  useEffect(() => {
    setNewMaxAccPledge(maxAccPledge - pledgedBalance)
  },[input_val, pledgedBalance,open_modal])

  const onChange_num = (e) => {
    const target_val = e.target.value;
    const decimals = target_val.split('.')[1] || '';
    remove_errors(e.target.id)

    if (target_val !== '' && decimals.length <= 6) {
      set_input_val(target_val)
      let convertion_value = (target_val * usdValue).toFixed(2)
      // convertValue(target_val, 'DAPPX')
      set_input_fiat_val(convertion_value)
      convertCurrency()
    }

    if (target_val === '') {
      set_input_val(target_val)
      set_input_fiat_val(target_val * usdValue)
      convertCurrency()
      // convertValue(0, 'DAPPX')
    }
  }

  const onCheckDisclaimer = (event) => {
    set_check_box(event.target.checked);
  };

  const maxVal = () => {
    set_input_val(Math.min(tokenBalance, maxPledge, newMaxAccPledge))
    convertValue(Math.min(tokenBalance, maxPledge, newMaxAccPledge), token.toUpperCase())
  }

  const minVal = () => {
    set_input_val(minPledge)
    convertValue(minPledge, token.toUpperCase())
  }

  const newUserSubmit =() => {    
    if(!check_box) {
      set_err_message('Please read labels and check the box before we proceed.')
    } else if(feeTokenBalance < activationFee) {
      set_err_message('Insufficient balance!')
    } else {
      let data ={
        tokenType: token.toUpperCase()
      }
      axios.post(Config.DAPPX_BASE_URL + apiEndpoint[token].activate, data, {headers: {'session-id': session_Id}})
      .then((res) => {
        set_err_message('')
        setActivatedUser(true)
        fetchTokenBalance()
        set_check_box(false)
        onOpenModal()
      })
      .catch((error) => {
        console.log(error);
        set_err_message("Could not process Activation!")
      })
    }
  }

  const onSubmit = () => {
    setNewTokenBalance(Number(tokenBalance - input_val).toFixed(6).replace(/\.?0+$/, ''));
    setNewFeeTokenBalance((feeTokenBalance - getPledgeFee()).toFixed(6).replace(/\.?0+$/, ''));
    setNewPledgeBal(Number((Number(pledgedBalance) + Number(input_val)).toFixed(6).replace(/\.?0+$/, '')) );
    if(!check_box) {
      set_err_message('Please read labels and check the box before we proceed.')
    }else if(Number(input_val) > tokenBalance || Number(pledgeFee) > feeTokenBalance) {
      set_err_message('Insufficient balance!')

    } else if (Number(input_val) > newMaxAccPledge) {
      set_err_message('Stake amount will exceed the allowable stake per account.')

    } else if(Number(input_val) < minPledge) {
      set_err_message('Your stake amount is below the minimum limit!')

    } else if(Number(input_val) > maxPledge){
      set_err_message('Stake amount is greater than the remaining amount for staking!')

    } else if(Number(input_val) === 0 || !input_val) {
      set_err_message('Invalid input!')

    } else {
      set_err_message('')
      if (wallet_action) {

        let data = {
          amount: parseFloat(input_val)
        }

        if(token === 'dappx') {
          data = {
            pledgeAmount: input_val,
            tokenType: token.toUpperCase()
          }
        }

        onOpenModalLoading()

        axios.post(Config.DAPPX_BASE_URL + apiEndpoint[token].pledge, data, {headers: {'session-id': session_Id}})
        .then((res) => {
          setTokenBalance(newTokenBalance);
          setPledgedBalance(newPledgeBal);
          onCloseModalLoading();
          onOpenModalSuccess();
        })
        .catch((error) => {
          console.log(error);
          onCloseModalLoading();
          onOpenModalError();
        })
      } else {
        set_wallet_action(true);
      }
    }
  }

  const onClear = () => {
    setTimeout(() => {
      set_wallet_action(false)
      set_open_modal(false)
      set_input_val('')
      set_input_fiat_val(0)
      set_err_message('')
      set_outline_error([])
      set_wallet_action(false)
      set_check_box(false)
    }, 400)
  }

  // Tooltip
  const [pledgeTooltip, setPledgeTooltip] = useState(false)
  const [pledgeFeeTooltip, setPledgeFeeTooltip] = useState(false)

  const getFiatValue = (value) => {
    return (parseFloat(value) * parseFloat(usdValue)).toFixed(2);
  }

  const getPledgeFee = () => {
    return pledgeFeeCore + pledgeFeeNitro + pledgeFeeUBI;
  }

  return (
    <>
      <Button
        className="pledge-btn"
        variant="outlined"
        onClick={onOpenModal}
        fullWidth={isFullWidth}
        disabled={disableButton}>
        Stake
      </Button>

      {!activatedUser ?
      <CustomDialog
      open={open_modal}
      className="deposit-dialog"
      onClose={onCloseModal}
      header="Stake Activation"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      body={
      <>
        {!wallet_action &&
          <div className="container-balance">
            <label>Available {content[token].activation.token} Balance</label>
            <div className="block">
              <div className="icon-block">
                <img src={logos[content[token].activation.token.toLowerCase()]} alt=""/>
              </div>
              <div className="amount-block">

                  <>
                    <p className="amount">{feeWholeNum ? feeWholeNum : "0"}.<span>{feeDecimalNum ? feeDecimalNum : "000000"}</span></p>
                    {feeTokenBalance ? (<p className="convertion">{getFiatValue(feeTokenBalance)} USD</p>) : null}
                    {/* <p className="convertion">{fiat_val} USD</p> */}
                  </>

              </div>
            </div>
          </div>
        }
        <div className={`container-textfield ${wallet_action ? 'wallet-action' : ''}`}>
          <div className='activation-message'>
            <p className="activation-text">{content[token].activation.notes}</p>
          </div>
          <FormGroup className="container-check">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={check_box}
                    onChange={onCheckDisclaimer}
                    name="remember_me"
                    color="primary" />
                }
                label={content[token].activation.checkboxLabel.replace(/%s/g, activationFee)}
              />
            </FormGroup>
        </div>
        </>
      }
      footer={
      <>
        {err_message &&
          <Fade className="error custom-error" in={err_message ? true : false} timeout={500} >
            <Alert severity="error"><WarningIcon className="icon" />{err_message}</Alert>
          </Fade>
        }
        <div className="button-block">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            onClick={!activatedUser ? newUserSubmit : onSubmit}
            type="submit">{wallet_action ? 'Submit' : 'Continue'}</Button>
          <Button variant="contained" color="default" fullWidth disableElevation onClick={onCloseModal}>Cancel</Button>
        </div>
      </>}
        />
    :
    <CustomDialog
        open={open_modal}
        className={`pledge-dialog ${token}-modal`}
        onClose={onCloseModal}
        header={`Stake ${token.toUpperCase()}${isGovernance ? ' Governance' : ''}`}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={
        <>
          {!wallet_action &&
            <div className="container-balance">
              <TransactionPreviewCurrentScalable
                balances= {
                  token === 'dappx' ? {
                    "DAPPX": tokenBalance.toString(),
                    "Stake": pledgedBalance.toString(),
                  } :
                  {
                    "ACE": feeTokenBalance.toString(),
                    "EDAO": tokenBalance.toString(),
                    "Stake": pledgedBalance.toString(),
                  }
                }
                error_message={set_err_message}
                token_code={token.toUpperCase()}
                open={wallet_action}
              />
            </div>
          }

          <div className={`container-textfield ${wallet_action ? 'wallet-action' : ''}`}>
            <div className="number-label">
              <label>
                Stake
                <ClickAwayListener onClickAway={()=> setPledgeTooltip(false)}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={()=> setPledgeTooltip(false)}
                    open={pledgeTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                    placement="right"
                    title={
                      <div className="tooltip-content">
                        <h5>Stake Limit</h5>
                        <p>Minimum per transaction: <span>{minPledge} {token.toUpperCase()}</span></p>
                        <p>Maximum per transaction: <span>{maxPledge} {token.toUpperCase()}</span></p>
                        <p>Remaining amount for staking: <span>{newMaxAccPledge} {token.toUpperCase()}</span></p>
                      </div>
                    }
                  >
                    <IconButton onClick={()=> setPledgeTooltip(true)} className={`info-btn ${pledgeTooltip ? 'open' : ''}`}><InfoOutlinedIcon/></IconButton>
                  </Tooltip>
                </ClickAwayListener>
              </label>
              <div className="right">
                <Button disableRipple onClick={minVal}>Min</Button>
                <Button disableRipple onClick={maxVal}>Max</Button>
              </div>
            </div>
            <div className="textfield-block">
              <TextField
                fullWidth
                className="number-field"
                error={outline_error.includes('amount')}
                value={input_val}
                onChange={onChange_num}
                autoComplete="off"
                variant="outlined"
                id='amount'
                placeholder="0.0"
                disabled={wallet_action ? true : false}
                name="number-field"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              <img className="coin-logo" src={logos[token]} alt={"logo"} />
            </div>
            {marketPrice ? marketPrice[token.toUpperCase()] && (<div className="convertion">≈ {input_fiat_val} USD</div>) : ''}

            {
              (getPledgeFee() && input_val) ? (
              <div className="number-label">
                <label className="service_label">
                  Staking Fee:<div className="convertion">{pledgeFee.toFixed(13).replace(/\.?0+$/, '')} ACE</div>
                  <ClickAwayListener onClickAway={()=> setPledgeFeeTooltip(false)}>
                    <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={()=> setPledgeFeeTooltip(false)}
                        open={pledgeFeeTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                        placement="right"
                        title={
                          <div className="tooltip-content">
                            <h5>Staking Fee</h5>
                            <ul>
                              <li><span>{pledgeFeeNitro}</span> Nitro fees.</li>
                              <li><span>{pledgeFeeUBI} ACE</span> UBI protocol fee will be used to compensate token holders, including land owners.</li>
                              <li><span>{pledgeFeeCore} ACE</span> Core Ecosystem development fee to deploy a self-sufficient, robust, decentralized immersive web service.</li>
                            </ul>
                          </div>
                        }
                    >
                      <IconButton onClick={()=> setPledgeFeeTooltip(true)} className={`info-btn ${pledgeFeeTooltip ? 'open' : ''}`}><InfoOutlinedIcon/></IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </label>
              </div>
              ) : ''
            }
          </div>

          {!wallet_action &&
            <FormGroup className="container-check">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={check_box}
                    onChange={onCheckDisclaimer}
                    name="remember_me"
                    color="primary" />
                }
                label={content[token].pledging.checkboxLabel.replace(/%s/g, holdingPeriod)}
              />
            </FormGroup>
          }

          {wallet_action &&
            <div className="container-preview">
              <TransactionPreviewScalable
                balances = {
                  token === 'dappx' ? {
                    "DAPPX": tokenBalance.toString(),
                    "Stake": pledgedBalance.toString(),
                  } :
                  {
                    "ACE": feeTokenBalance.toString(),
                    "EDAO": tokenBalance.toString(),
                    "Stake": pledgedBalance.toString(),
                  }
                }
                newBalances = {
                  token === 'dappx' ? {
                    "DAPPX": newTokenBalance.toString(),
                    "Stake": newPledgeBal.toString(),
                  } :
                  {
                    "ACE": newFeeTokenBalance.toString(),
                    "EDAO": newTokenBalance.toString(),
                    "Stake": newPledgeBal.toString(),
                  }
                }
                error_message={set_err_message}
                token_code={token.toUpperCase()}
                open={wallet_action}
              />
            </div>
          }
        </>
        }
        footer={<>
          {err_message &&
            <Fade className="error custom-error" in={err_message ? true : false} timeout={500} >
              <Alert severity="error"><WarningIcon className="icon" />{err_message}</Alert>
            </Fade>
          }
          <div className="button-block">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disableElevation
              onClick={onSubmit}
              type="submit">{wallet_action ? 'Submit' : 'Continue'}</Button>
            <Button variant="contained" color="default" fullWidth disableElevation onClick={onCloseModal}>Cancel</Button>
          </div>
        </>}
      />
    }

      <CustomDialog
        open={open_modal_loading}
        className="pledge-dialog loading-dialog"
        onClose={onCloseModalLoading}
        header="Processing your request"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={<>
          <LoaderIcon className="icon"/>
          <p>Almost there!</p>
          <p>Please wait a moment.</p>
        </>}
      />

      <CustomDialog
        open={open_modal_success}
        className="pledge-dialog success-dialog"
        onClose={onCloseModalSuccess}
        header= {"Staking Successful!"}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={<>
          <SuccessIcon className="icon"/>
          <>
          <p>You have successfully Staked</p>
          <p className="amount-preview">{input_val} {token.toUpperCase()}</p>
          </>
        </>}
        footer={<>
          <div className="button-block">
            <Button variant="contained" color="primary" disableElevation onClick={onCloseModalSuccess} type="submit">OK</Button>
          </div>
        </>}
      />

      <CustomDialog
        open={open_modal_error}
        className="pledge-dialog error-dialog"
        onClose={onCloseModalError}
        header="Staking Failed"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={<>
          <ErrorIcon className="icon"/>
          <p>Your stake transaction didn't pushed through.</p>
          <p>Amount of {token.toUpperCase()} has been reverted to your wallet.</p>
        </>}
        footer={<>
          <div className="button-block">
            <Button variant="contained" color="primary" disableElevation onClick={onCloseModalError} type="submit">OK</Button>
          </div>
        </>}
      />
    </>
  )
}

export default ButtonPledge;
