import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { NFT_IMX_BASE_URL, IMX_LINK_BASE_URL } from '../../constant/config';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Button, Fade } from "@material-ui/core";
import { useParams, useHistory } from 'react-router-dom';
import { SnackbarContext } from "../../context/SnackBarContext";
import { AuthContext } from "../../context/AuthContext";

import Pagination from '@mui/material/Pagination';

import ImageWithFallback from './imageWithFallback';

import { Link, ETHTokenType } from '@imtbl/imx-sdk';

import { ReactComponent as WarningIcon } from '../../assets/images/icons/warning.svg';
import { ReactComponent as EthIcon } from '../../assets/images/icons/icon-eth-light.svg';
import CustomDialog from '../Modal/CustomDialog';
import Loader from '../loader/Loader';
import './index.scss'

const errorCodes = {
    'default': "Something went wrong. Please try again later.",
    'missing-key-pair': "Something went wrong. Please try again later.",
    'invalid-request': "Something went wrong. Please try again later.",
    'invalid-tx-id': "Transaction did not proceed. Please try again.",
    'invalid-session-id': "Session expired. Please connect your wallet again.",
    'invalid-order-id': "Something went wrong. Please try again later.",
    'action-not-allowed': "Transaction did not proceed. Please try again.",
    'insufficient': "Insufficient IMX Fund.",
    'cancel': "Please complete the action needed.",
}

const Items = () => {
    const { showSnack } = useContext(SnackbarContext);
    const { authState } = useContext(AuthContext);
    const { imx_balance } = authState;

    const [modal, setModal] = useState(false);
    const [value, setValue] = useState(false);
    const [error, setError] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConnectToIMXModal, setShowConnectToIMXModal] = useState(false);
    const { id } = useParams();
    const [items, setItems ] = useState([]);
    const [collection, setCollection ] = useState(false);

    const [limit, setLimit]=useState(36);
    const [offset, setOffset]=useState(1);
    const [count, setCount]=useState(0);

    const link = new Link(IMX_LINK_BASE_URL);

    const history = useHistory();

    useEffect(() => {
        fetchTokens(0);
    }, []);

    const fetchTokens = async(page) => {
        await axios.get(NFT_IMX_BASE_URL + `/v1/marketplace/collections/${id}/tokens?offset=${page*limit}&limit=${limit}`)
        .then((res) => {
            setItems(res.data.data.tokens);
            setCollection(res.data.data.collection);
            setLoading(false)

            let check=parseInt(res.data.data.total_tokens)%limit;
            let nc=parseInt(res.data.data.total_tokens/limit);
            if(nc<=0){
                setCount(1)
            }else if(check !==0){
                setCount(nc+1)

            }else{
                setCount(nc)
            }
        })
        .catch((error) => {
            setItems([]);
            if (error.response) {
                if (error.response.data.message === 'invalid-collection-id') {
                    history.goBack();
                }
            }
        })
    }

    const showPreview = (item) => {
        let wallet = localStorage.getItem('wallet-imx');
        setIsConnected(wallet && true);
        setValue(item);
        setError(false);
        setModal(true);
    }

    const checkIfConnected = () => {
        let wallet = localStorage.getItem('wallet-imx');
        return wallet && true
    }

    const checkIfBalanceSufficient = () => {
        return imx_balance.value > value.price
    }

    const handleBuy = (id) => {
        if (!checkIfConnected()) {
            document.getElementById("connect-to-imx-button").click();
            return;
        }

        if (!checkIfBalanceSufficient()) {
            setError(errorCodes.insufficient)
            return;
        }

        console.log(id);

        setLoading(true);
        let data = {
            'session_id': localStorage.getItem('session-id'),
            'asset_id': id,
        }

        axios.post(NFT_IMX_BASE_URL + `/v1/marketplace/buy-token`, data)
        .then((res) => {
            console.log(res);

            transferETH(res.data.data);
        })
        .catch((error) => {
            console.log(error);
            console.log('Response', error.response);
            setLoading(false);
            if (error.response) {
                if (errorCodes[error.response.message]) {
                    setError(errorCodes[error.response.message])
                } else {
                    setError(errorCodes.default);
                }
            }
        })
    }
    
    const transferETH = async (value) => {
		try {
		    const res = await link.transfer([
		      {
		        amount: value.price,
		        type: ETHTokenType.ETH,
		        toAddress: value.admin_wallet_address,
		      },
		    ])

            if(res && res.result[0]) {
                console.log(res.result[0].txId);
                fulfilledTransaction(value.order_id, res.result[0].txId);
            }
		} catch (error) {
			console.log('Wallet action cancelled or denied', error);
            cancelTransaction(value.order_id);
		}
    }
    
    const cancelTransaction = (orderId) => {
        let data = {
            'session_id': localStorage.getItem('session-id'),
            'order_id': orderId,
        }

        console.log('cancelTransaction', data);
		axios.post(NFT_IMX_BASE_URL + `/v1/marketplace/cancel-order`, data)
        .then((res) => {
            console.log(res);
            setLoading(false);
            setError(errorCodes.cancel);
        })
        .catch((error) => {
            console.log(error);
            console.log('Response', error.response);
            setLoading(false);
            if (error.response) {
                if (errorCodes[error.response.message]) {
                    setError(errorCodes[error.response.message])
                } else {
                    setError(errorCodes.default);
                }
            }
        })
    }
    
    const fulfilledTransaction = (orderId, txId) => {
        let data = {
            'session_id': localStorage.getItem('session-id'),
            'order_id': orderId,
            'tx_id': txId.toString(),
        }

        let maxTry = 5;
        let delayPerTry = 5000;
        let errorMsg = '';
        let attempts = 0;
        let isSuccess = false;

        console.log('fulfilledTransaction', data);

        // for transaction id to be available on list of transfer from imx data
        let fulfillIntervals = setInterval(() => {
            if (isSuccess) {
                clearInterval(fulfillIntervals);
                return;
            }

            if (attempts <= maxTry) {
                axios.post(NFT_IMX_BASE_URL + `/v1/marketplace/fulfill-order`, data)
                .then((res) => {
                    console.log(res);
                    isSuccess = true;
                    setLoading(false);
                    setModal(false);
                    showSnack('NFT successfully purchased');
                    fetchTokens(0);
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.data.message === 'invalid-tx-id') {
                            attempts++;
                            errorMsg = errorCodes[error.response.data.message];
                            return;
                        } else {
                            isSuccess = true 
                        }

                        setLoading(false);
                        if (errorCodes[error.response.data.message]) {
                            setError(errorCodes[error.response.data.message])
                        } else {
                            setError(errorCodes.default);
                        }
                        
                    }
                })
            } else {
                setError(errorMsg);
                clearInterval(fulfillIntervals);
                return;
            }
        }, delayPerTry);
    }

    const handleCloseModal = () => {
        setError(false);
        setModal(false);
    }


    const paginationChange=(event,value)=>{
        setLoading(true)
        if(value!==offset){
            setOffset(value)
            let page=value-1
            fetchTokens(page)
        }
    }

    return (
        <section className="nft-collection-area p-0">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* Intro */}
                        <div className="intro d-flex justify-content-between flex-column m-0">
                            <div className="intro-btn">
                                <a className="btn content-btn back-btn text-left" href="/">Back</a>
                            </div>
                            <div className="intro-content">
                                <h3 className="mt-3 mb-0">{collection.name}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                {loading ?
                    <Loader
                      appear={loading}
                      timeout={1000}
                      width="22"
                      coloredBg
                      svgProps={{
                      direction: "right",
                      negative: true,
                      }}
                    />     
                :
                <div className="row items">
                    {items.length > 0 ? 
                        items.map((item, idx) => {
                            return (
                                <div key={`cd_${idx}`} className="col-12 col-sm-6 col-md-4 col-lg-2 item" onClick={() => showPreview(item)}>
                                    <div className="card no-hover">
                                        <div className="image-over">
                                            <ImageWithFallback className="card-img-top" src={item.metadata.image_url} alt={`Preview ${item.metadata.name}`} />
                                            <div className={`availablity ${item.status == 0 ? 'available' : 'sold'}`}>
                                                {item.status == 0 ? 'available' : 'sold'}
                                            </div>
                                        </div>
                                        {/* Card Caption */}
                                        <div className="card-caption">
                                            {/* Card Body */}
                                            <div className="card-body">
                                                <h5 className="mb-2">{item.metadata.name}</h5>
                                                <p>{item.metadata.asset_id}</p>
                                                <p>{item.price} <EthIcon /></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }) : (
                            <div className="no-items-container">
                                <Alert severity="info">
                                    <AlertTitle>Info</AlertTitle>
                                    No <strong>tokens</strong> in this collection
                                </Alert>
                            </div>
                        )
                    }
                </div>
                }
            </div>

            <Pagination  className="pagination" siblingCount={1}  boundaryCount={1} onChange={paginationChange} count={count} page={offset} variant="outlined" shape="rounded"/>

            {value ? (<CustomDialog
                open={modal}
                className="imx-modal buy-nft-dialog"
                onClose={handleCloseModal}
                header={false}
                closeBtn={true}
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                body={
                    <>
                        <div className="nft-item-container">
                            <div className="card no-hover p-0 mt-5">
                                <div className="d-flex flex-row mb-3">
                                    <div className="image-over">
                                        <ImageWithFallback className="card-img-top" src={value.metadata.image_url}  alt={`Preview ${value.metadata.name}`} />
                                        <div className={`availablity ${value.status === 0 ? 'available' : 'sold'}`}>
                                            {value.status === 0 ? 'available' : 'sold'}
                                        </div>
                                    </div>
                                    {/* Card Caption */}
                                    <div className="card-caption p-0">
                                        {/* Card Body */}
                                        <div className="card-body">
                                            <h5 className="nft-name mb-2">{value.metadata.name}</h5>
                                            <p className="collection-name">{collection.name}</p>
                                            <p className="nft-price">{value.price} <EthIcon /></p>
                                            <p className="label">Description</p>
                                            {value.metadata.description ? (
                                                <div className="nft-description">
                                                    <p>{value.metadata.description}</p>
                                                </div>
                                            ): ''}
                                        </div>
                                    </div>
                                </div>
                                {value.metadata && Object.keys(value.metadata).length > 2 ? (
                                    <>
                                        <p className="label mb-4">
                                            Properties
                                        </p>
                                        <div className="properties-container">
                                            {Object.keys(value.metadata).map((key) => {
                                                if (key === 'name' || key === 'description' || key === 'image_url') {
                                                    return;
                                                }
                                                
                                                if (key === 'ZEP') {
                                                    if(!value.metadata.ZEP) {
                                                      return;
                                                    } else {
                                                        return (
                                                            <div key={`property_${key}`} className="d-flex flex-row justify-content-between mb-3">
                                                            <span onClick={() => console.log(value.metadata)}>
                                                                {key}
                                                            </span>
                                                            <span>
                                                                {value.status === 0 ? (<i>Mint Generated</i>) : (value.metadata[key] ? value.metadata[key] : 0)}
                                                            </span>
                                                        </div>
                                                        )
                                                    }
                                                }

                                                // if (!value.metadata[key]) {
                                                //     return;
                                                // }
                                                
                                                return (
                                                    <div key={`property_${key}`} className="d-flex flex-row justify-content-between mb-3">
                                                        <span onClick={() => console.log(value.metadata)}>
                                                            {key}
                                                        </span>
                                                        <span>
                                                            {value.metadata[key]}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </>
                                ) : (<></>)}
                            </div>
                        </div>
                    </>
                }
                footer={<>
                {error &&
                    <Fade className="error custom-error" in={error ? true : false} timeout={500} >
                        <Alert severity="error"><WarningIcon className="icon" />{error}</Alert>
                    </Fade>
                }

                <div className={`button-block`}>
                    <Button variant="contained" color="primary" fullWidth disableElevation
                    onClick={() => handleBuy(value._id)}
                    disabled={loading || (value.status === 0 ? false : true)}
                    >
                    <span className="text">{value.status === 0 ? 'MINT' : 'SOLD'}</span>
                    <Loader
                        appear={loading}
                        timeout={1000}
                        width="22"
                        coloredBg
                        svgProps={{
                        direction: "right",
                        negative: true,
                        }}
                    />
                    </Button>
                    {/* <ConnectToIMX btnLabel="Connect to IMX" showModal={showConnectToIMXModal} hideBtn={true} callback={afterConnectToImx}/> */}
                </div>

                </>}
            />) : ''}
            
        </section>
    );
}

export default Items;