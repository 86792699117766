import React, { createContext, useReducer, useEffect } from 'react';
import MoralisReducer from './reducers/MoralisReducer';
import { Moralis } from "moralis";
import API from '../api'

export const MoralisContext = createContext();

const initialState = {
  marketplaceItems: [],
  isAuthenticated: false,
  user: null,
  user_items: []
}

const MoralisContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MoralisReducer, initialState)
  useEffect(() => {
    
    if(state.marketplaceItems){
      getItemDetails()
    }

  }, [state]);
  

  const getItemDetails = async () => {
    // map over all items 
    state.marketplaceItems.map( (item) => {
      // GET request to fetch IPFS details
        API.IPFS.fetchItemDetails(item.tokenUri)
        .then( async (res) => {
          // get the username by the owner wallet address
        const itemOwner =  await Moralis.Cloud.run("getUsername", {userAddress: item.ownerOf});
         res.owner = itemOwner && itemOwner
        // get item details by nftId and nftContractAddress
        const itemDetails =  await Moralis.Cloud.run("getItemDetails", {nftContractAddress: item.tokenAddress, nftId: item.tokenId});
         res.price = itemDetails.price
         res.status = itemDetails.status
         res.category = itemDetails.category
         res.itemId = itemDetails.itemId
        // append all details to the initial state
          item.details = res
        }).catch((err) => console.log('ERROR FETCHING DETAILS', err));
    });
  };
  return (
    <MoralisContext.Provider value={{
      marketplaceState: state,
      dispatch
    }}>
      {children}
    </MoralisContext.Provider>
  );
}

export default MoralisContextProvider;