import { useEffect, useState, useRef } from 'react';
// import { useDimensions } from "../../constant/useDimensions";
import Highlighter from "react-highlight-words";
import Slider from "react-slick";
import { v4 as uuidv4 } from 'uuid';
import { ButtonBase } from '@material-ui/core';
import { useWindowSize } from '../../constant/useWindowSize';
import image1 from '../../assets/images/page-games/slider/dappstore.png';
import title1 from '../../assets/images/page-games/slider/dappstore-logo.png';
import bg_image1 from '../../assets/images/page-games/slider/dappstore--bg.jpg';
import image3 from '../../assets/images/page-games/slider/coba.png';
import title3 from '../../assets/images/page-games/slider/coba-logo.png';
import bg_image3 from '../../assets/images/page-games/slider/coba--bg.jpg';

const SectionSlider = ({ searchState }) => {
  const [imageHeight, setImageHeight] = useState();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const [ window_width ] = useWindowSize();
  useEffect(() => {
    if(window_width < 960) setMobileView(true)
    else setMobileView(false)
  }, [window_width]);

  const onLoadImage = ({ target: img }) => {
    // console.log(img)
    setImageLoaded(true)
    let image = img.offsetHeight - (mobileView ? 0 : 100);
    setImageHeight(image)
  }

  // const [{ height }, ref] = useDimensions();

  let sliderData = [
    {
      _id: uuidv4(),
      className: 'dappstore',
      image: image1,
      title: title1,
      excerpt: 'dAppstore is Steam of Blockchain',
      bg_image: bg_image1,
      link: 'https://dappstore.me/intro/',
      link_color: '#ed1f24',
    },
    // {
    //   _id: uuidv4(),
    //   className: 'crypto-run',
    //   image: image2,
    //   title: title2,
    //   excerpt: 'You can invite your friends to play with you, or you can win rewards in cryptocurrency!',
    //   bg_image: bg_image2,
    //   link: process.env.REACT_APP_DAPPARCADE_URL,
    //   link_color: '#670071',
    // },
    {
      _id: uuidv4(),
      className: 'coba',
      image: image3,
      title: title3,
      excerpt: 'Test your might in crypto reward battle arena',
      bg_image: bg_image3,
      link: 'https://coba.zone/',
      link_color: '#016ce9',
    }
  ]

  // Slider Settings
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  // i dont seem to need this
  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  return (
    <div className="section section-slider">
      <Slider
        className="bg-slide"
        asNavFor={nav2}
        ref={slider1}
        cssEase={'linear'}
        infinite={true}
        pauseOnHover={false}
      >
        {sliderData.length > 0 && sliderData.map((val) => (
          <div 
            className="bg-container"
            key={`${val._id}-bg`} 
            // ref={ref}
            style={{ 
              opacity: imageLoaded ? 1 : 0, 
              transition: 'opacity .4s ease-in-out',
            }} 
          >
            <img 
            src={val.bg_image} 
            alt={val.className} 
            onLoad={onLoadImage} 
            className="img-full"/>
          </div>
        ))}
      </Slider>
      <Slider
        className="content-slide"
        arrows={false}
        asNavFor={nav1}
        ref={slider2}
        slidesToShow={1}
        swipeToSlide={true}
        infinite={true}
        dots={true}
        // autoplay={true}
        // autoplaySpeed={9000}
        centerMode={true}
        cssEase={'linear'}
        pauseOnHover={false}
        responsive={[
          {
            breakpoint: 1280,
            settings: {
              centerPadding: "20px",
            }
          },
          {
            breakpoint: 960,
            settings: {
              centerPadding: "0",
            }
          }
        ]}
      >
        {sliderData.length > 0 && sliderData.map((val) => (
          <div key={val._id}>
            <div
              className={`wrapper-slide ${val.className}`}
              // style={{ height: height === 0 ? `${imageHeight}px` : `${height}px` }}
            >
              <div 
                className="cell right" 
                style={{ 
                  opacity: imageLoaded ? 1 : 0, 
                  transition: 'opacity .4s ease-in-out'
                }} 
              >
                <img src={val.title} alt={val.className} className="img-fluid" onLoad={() => setImageLoaded(true)}/>
                <p>
                  <Highlighter
                    searchWords={[searchState]}
                    textToHighlight={val.excerpt}
                  />
                </p>
                {val.link &&
                  <ButtonBase
                    href={val.link}
                    className="go-to-play"
                    target="_blank"
                    style={{ backgroundColor: val.link_color }}
                  >
                    Go to play
                  </ButtonBase>
                }
              </div>
              <div className="cell left">
                <img src={val.image} alt={val.className} className="img-fluid" />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SectionSlider;