import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import SliderMobile from "./SliderMobile";
import SliderDesktop from "./SliderDesktop";

import bg_coba from "../../assets/images/section-slider/coba.png";
import bg_arcade from "../../assets/images/section-slider/dapp-arcade.jpg";
import bg_kitties from "../../assets/images/section-slider/crypto-kitties.jpg";
import bg_decentraland from "../../assets/images/section-slider/decentraland.jpg";
import bg_maker from "../../assets/images/section-slider/maker-dao.jpg";
import bg_xangle from "../../assets/images/section-slider/xangle.jpg";
import bg_uniswap from "../../assets/images/section-slider/uniswap.jpg";
import bg_opensea from "../../assets/images/section-slider/opensea.jpg";
import bg_kucoin from "../../assets/images/section-slider/kucoin.jpg";
import bg_nft_presale from "../../assets/images/section-slider/nft-presale.jpg";
import bg_penalty from "../../assets/images/section-slider/penalty-showdown.jpg";
import bg_planet from "../../assets/images/section-slider/yed-banner-dt.png";
import bg_marble from "../../assets/images/section-slider/marble-game.jpg";
import sp_coba from "../../assets/images/section-slider/coba--sp.png";
import sp_arcade from "../../assets/images/section-slider/dapp-arcade--sp.jpg";
import sp_kitties from "../../assets/images/section-slider/crypto-kitties--sp.jpg";
import sp_decentraland from "../../assets/images/section-slider/decentraland--sp.jpg";
import sp_maker from "../../assets/images/section-slider/maker-dao--sp.jpg";
import sp_xangle from "../../assets/images/section-slider/xangle--sp.jpg";
import sp_uniswap from "../../assets/images/section-slider/uniswap--sp.jpg";
import sp_opensea from "../../assets/images/section-slider/opensea--sp.jpg";
import sp_kucoin from "../../assets/images/section-slider/kucoin--sp.jpg";
import sp_nft_presale from "../../assets/images/section-slider/nft-presale--sp.jpg";
import sp_penalty from "../../assets/images/section-slider/penalty-showdown--sp.jpg";
import sp_planet from "../../assets/images/section-slider/yed-banner-mb.png";
import sp_marble from "../../assets/images/section-slider/marble-game--sp.jpg";
import title_uniswap from "../../assets/images/section-slider/uniswap-title.png";
import title_kitties from "../../assets/images/section-slider/crypto-kitties-title.png";
import title_decentraland from "../../assets/images/section-slider/decentraland-title.png";
import title_maker from "../../assets/images/section-slider/maker-dao-title.png";
import title_xangle from "../../assets/images/section-slider/xangle-title.png";
import title_opensea from "../../assets/images/section-slider/opensea-title.png";
// import video_uniswap from "../../assets/videos/uniswap.mp4";
// import video_coba from '../../assets/videos/coba.mp4';
// import video_enftee from '../../assets/videos/enftee.mp4';
// import video_kitties from '../../assets/videos/crypto-kitties.mp4';
// import video_arcade from '../../assets/videos/dapp-arcade.mp4';
// import video_maker from '../../assets/videos/maker-dao.mp4';
// import video_decentraland from '../../assets/videos/decentraland.mp4';
// import video_kucoin from '../../assets/videos/kucoin.mp4';
// import video_nft_presale from '../../assets/videos/nft-presale.mp4';
// import video_penalty from '../../assets/videos/penalty-showdown.mp4';
// import video_marble from '../../assets/videos/marble-game.mp4';
// import coba_royale from '../../assets/videos/Coba-Royale9.2.2.mp4';
import logo_coba from "../../assets/images/section-slider/coba-logo.png";
import logo_arcade from "../../assets/images/section-slider/dapp-arcade-logo.png";
import logo_kitties from "../../assets/images/section-slider/crypto-kitties-logo.png";
import logo_decentraland from "../../assets/images/section-slider/decentraland-logo.png";
import logo_maker from "../../assets/images/section-slider/maker-dao-logo.png";
import logo_xangle from "../../assets/images/section-slider/xangle-logo.png";
import logo_uniswap from "../../assets/images/section-slider/uniswap-logo.png";
import logo_opensea from "../../assets/images/section-slider/opensea-logo.png";
// import logo_enftee from '../../assets/images/section-slider/enftee-logo.png';
import logo_kucoin from "../../assets/images/section-slider/kucoin-logo.png";
// import logo_planet from '../../assets/images/section-slider/planet-earth-logo.png';
import logo_planet from "../../assets/images/section-slider/your-earth-logo.png";
import logo_coba_royal from "../../assets/images/section-slider/coba-royale-logo.png";
import sp_yourearth_edao from "../../assets/images/section-slider/yedao-mob.png";
import bg_yourearth_edao from "../../assets/images/section-slider/yedao.png";
import landSaleDK from "../../assets/images/section-slider/landSaleDK2-live.png";
import landSaleMB from "../../assets/images/section-slider/landSaleMB2-live.png";
import alphaSaleMb from "../../assets/images/section-slider/AlphaTest-Mobile.png";
import alphaSaleDt from "../../assets/images/section-slider/AlphaTest-Desktop.png";
import cobaBattleMb from "../../assets/images/section-slider/COBARoyale-MB.png";
import cobaBattleDT from "../../assets/images/section-slider/COBARoyale-DT.png";
import how_it from "../../assets/images/section-slider/how-it.png";
import s_how_it from "../../assets/images/section-slider/s-how-it.png";
import p_how_it from "../../assets/images/section-slider/p-how-it.png";
import how_it_m from "../../assets/images/section-slider/how-it-m.png";
import s_how_it_m from "../../assets/images/section-slider/s-how-it-m.png";
import p_how_it_m from "../../assets/images/section-slider/p-how-it-m.png";
import ready_it from "../../assets/images/section-slider/ready-to.png";
import ready_it_m from "../../assets/images/section-slider/ready-to-m.png";
import s_ready_it from "../../assets/images/section-slider/s-ready-it.png";
import s_ready_it_m from "../../assets/images/section-slider/s-ready-it-m.png";
import p_ready_it from "../../assets/images/section-slider/p-ready-it.png";
import p_ready_it_m from "../../assets/images/section-slider/p-ready-it-m.png";
import copa_play from "../../assets/images/section-slider/coba-play.png";
import copa_play_m from "../../assets/images/section-slider/copa-1m.png";
import s_copa_play from "../../assets/images/section-slider/s-coba-play.png";
import s_copa_play_m from "../../assets/images/section-slider/s-coba-play-m.png";

import acent_logo from "../../assets/images/section-slider/acent-logo.png";
import play_safe from "../../assets/images/section-slider/safe-2.png";
import play_safe_m from "../../assets/images/section-slider/safe-m-2.png";

import p_copa_play from "../../assets/images/section-slider/p-coba-play.png";
import p_copa_play_m from "../../assets/images/section-slider/p-coba-play-m.png";
import copa_v_1 from "../../assets/videos/coba-1.mp4";
import s_copa_v_1 from "../../assets/videos/s-copa-1.mp4";
import p_copa_v_1 from "../../assets/videos/p-copa-1.mp4";
import copa_v_1_m from "../../assets/videos/coba-1-m.webm";

import mex_d from "../../assets/images/section-slider/mex.png";
import mex_m from "../../assets/images/section-slider/mex-mobile.png";
import mex_logo from "../../assets/images/section-slider/mex-logo.svg";
import dappx_logo from "../../assets/images/dappx-logo.png";

import ai_first_banner_d from "../../assets/images/section-slider/ai-first-banner-desktop.png";
import ai_first_banner_m from "../../assets/images/section-slider/ai-first-banner-mobile.png";
import ai_second_banner_d from "../../assets/images/section-slider/ai-second-banner-desktop.png";
import ai_second_banner_m from "../../assets/images/section-slider/ai-second-banner-mobile.png";
import ai_third_banner_d from "../../assets/images/section-slider/ai-third-banner-desktop.png";
import ai_third_banner_m from "../../assets/images/section-slider/ai-third-banner-mobile.png";

import { IS_UAT, IS_SAT, Config } from "../../constant/config";
import { useWindowSize } from "../../constant/useWindowSize";
import { ReactComponent as ETH } from "../../assets/images/icons/eth-icon.svg";
import "./SectionSlider.scss";
import axios from "axios";
import CustomDialog from "../../components/Modal/CustomDialog";
import Spinner from "../../components/Spinner/Spinner";
import { useTranslation } from "react-i18next";

const SectionSlider = ({ searchState }) => {
  const [mobileView, setMobileView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [osirisBrowser, setOsirisBrowser] = useState(false);
  const [window_width] = useWindowSize();
  let isOsiris = navigator.osiris;

  const { i18n, t } = useTranslation();
  const [lang, setLand] = useState(i18n.language);

  console.log(i18n.language);
  useEffect(() => {
    console.log(i18n.language);
    setLand(i18n.language.slice(0, 2));
  }, [i18n.language]);

  console.log(lang);

  useEffect(() => {
    if (window_width < 960) setMobileView(true);
    else setMobileView(false);
  }, [window_width]);

  const osirisBrowserCheaker = async () => {
    if (isOsiris) {
      setLoading(true);
      try {
        const result = await axios.get("https://api.ipify.org/?format=json");
        //  const  result = await axios.get('https://api.bigdatacloud.net/data/client-ip')
        console.log(result);

        //
        const res = await axios.post(
          `${process.env.REACT_APP_ODIN_API_URL}/v1/launcher/check-ip`,
          {
            IpAddress: result.data.ip ? result.data.ip : "1.1.1.1",
          }
        );
        // window.location.reload(false);
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 6000);
      } catch (err) {
        alert("http://localhost:8546/api/setflag api not working");
      }

      return;
    }

    setOsirisBrowser(true);
    console.log(110);
  };

  const sliderData = {
    howIt: {
      en: how_it,
      es: s_how_it,
      pt: p_how_it,
    },
    howItM: {
      en: how_it_m,
      es: s_how_it_m,
      pt: p_how_it_m,
    },
    copa_play: {
      en: copa_play,
      es: s_copa_play,
      pt: p_copa_play,
    },
    copa_play_m: {
      en: copa_play_m,
      es: s_copa_play_m,
      pt: p_copa_play_m,
    },
    ready_it: {
      en: ready_it,
      es: s_ready_it,
      pt: p_ready_it,
    },
    ready_it_m: {
      en: ready_it_m,
      es: s_ready_it_m,
      pt: p_ready_it,
    },
    copa_v_1: {
      en: copa_v_1,
      es: s_copa_v_1,
      pt: p_copa_v_1,
    },
  };

  const redirectURL = () => {
    window.location.href = "https://dappstore.me/intro";
  };

  const data = [
    {
      _id: "dappx-ai-first-banner",
      className: "dappx-ai-first-banner",
      image: mobileView ? ai_first_banner_m : ai_first_banner_d,
      position: 1,
      buttons: [
        {
          _id: uuidv4(),
          href: "https://ai.dappstore.me/ ",
          text: "Start",
          onClick: () => {
            window.open("https://ai.dappstore.me/ ", "_blank");
          },
        },
      ],
      logo: dappx_logo,
    },
    {
      _id: "dappx-ai-second-banner",
      className: "dappx-ai-second-banner",
      image: mobileView ? ai_second_banner_m : ai_second_banner_d,
      position: 1,
      buttons: [
        {
          _id: uuidv4(),
          href: "https://dappstore.me/blog/3-step-guide-to-accessing-the-dappx-ai-platform/",
          text: "Start",
          onClick: () => {
            window.open(
              "https://dappstore.me/blog/3-step-guide-to-accessing-the-dappx-ai-platform/",
              "_blank"
            );
          },
        },
      ],
      logo: dappx_logo,
    },
    {
      _id: "dappx-ai-third-banner",
      className: "dappx-ai-third-banner",
      image: mobileView ? ai_third_banner_m : ai_third_banner_d,
      position: 1,
      buttons: [
        {
          _id: uuidv4(),
          href: "https://www.kucoin.com/trade/DAPPX-USDT",
          text: "Start",
          onClick: () => {
            window.open("https://www.kucoin.com/trade/DAPPX-USDT", "_blank");
          },
        },
      ],
      logo: logo_kucoin,
    },
    // {
    //   _id: "planet-earth",
    //   className: "planet-earth",
    //   image: mobileView ? mex_m : mex_d,
    //   position: 1,
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: "https://www.mexc.com/exchange/DAPPX_USDT",
    //       text: "Start",
    //       onClick: () => {
    //         window.location.href = "https://www.mexc.com/exchange/DAPPX_USDT";
    //       },
    //     },
    //   ],
    //   logo: mex_logo,
    // },
    // {
    //   _id: "planet-earth",
    //   className: "planet-earth",
    //   image: mobileView ? sp_planet : bg_planet,
    //   position: 1,
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: 'https://dappstore.me/intro',
    //       text: "Start",
    //       onClick: () => redirectURL(),
    //     },
    //   ],
    //   logo: logo_planet,
    // },
    {
      _id: "land-sale",
      className: "land-sale",
      image: mobileView ? sliderData.howItM[lang] : sliderData.howIt[lang],
      position: 2,
      buttons: [
        {
          _id: uuidv4(),
          // href: "https://dappstore.me/intro/",
          text: "Start",
          onClick: () => osirisBrowserCheaker(),
        },
      ],
      logo: logo_coba_royal,
      href: "https://dappstore.me/intro/",
    },

    {
      _id: "land-sale",
      className: "land-sale",
      image: mobileView
        ? sliderData.copa_play_m[lang]
        : sliderData.copa_play[lang],
      position: 3,
      buttons: [
        // {
        //   _id: uuidv4(),
        //   href: 'https://doc.yourearth.io/stake-ace-earn-nft-lands/',
        //   text: 'Start',
        // },

        {
          _id: uuidv4(),
          text: "Start",
          onClick: () => osirisBrowserCheaker(),
        },
      ],
      logo: logo_coba_royal,
      videoOnHover: sliderData.copa_v_1[lang],
      videoOnHoverMobile: copa_v_1_m,
    },

    {
      _id: "land-sale",
      className: "land-sale",
      image: mobileView
        ? sliderData.ready_it_m[lang]
        : sliderData.ready_it[lang],
      position: 3,
      buttons: [
        {
          _id: uuidv4(),
          href: "https://dappstore.me/blog/how-to-maximize-revenue-on-dappx-in-6-ways/",
          text: "Start",
          onClick: () => osirisBrowserCheaker(),
        },
      ],
      logo: logo_coba_royal,
      href: "https://dappstore.me/blog/how-to-maximize-revenue-on-dappx-in-6-ways/",
    },

    // {
    //   _id: "land-safe",
    //   className: "land-sale",
    //   image: mobileView ? play_safe_m : play_safe,
    //   position: 3,
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: "https://surfshark.com/download/chrome?coupon=surfsharkdeal&transaction_id=102990521dec6de0e8a32fe7026fe7&offer_id=926&affiliate_id=16660&source=&aff_sub=&utm_source=Affiliates&utm_medium=16660&utm_campaign=affiliate&recurring_goal_id=919",
    //       text: "Sign up",
    //       // onClick: () => osirisBrowserCheaker(),
    //     },
    //   ],
    //   logo: acent_logo,
    //   href: "https://doc.yourearth.io/safe-and-free-vpn-in-the-acent-browser-through-surfshark/",
    // },

    // {
    //   _id: "alpha-sale",
    //   className: "land-sale",
    //   image: mobileView ? alphaSaleMb : alphaSaleDt,
    //   position: 7,
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       // href: 'https://doc.yourearth.io/how-to-access-the-acent-metaweb-os-and-the-your-earth-dao-parallel-reality-web-service-today',
    //       text: "Start",
    //       onClick: () => osirisBrowserCheaker(),
    //     },
    //   ],
    //   logo: logo_planet,
    // },

    // {
    //   _id: "land-sale",
    //   className: "land-sale",
    //   image: mobileView ? landSaleMB : landSaleDK,
    //   position: 7,
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: "https://doc.yourearth.io/stake-ace-earn-nft-lands/",
    //       text: "Start",
    //       onClick: () => osirisBrowserCheaker(),
    //     },
    //   ],
    //   logo: logo_planet,
    // },

    //  {
    //       _id: 'coba-royal',
    //       className: 'land-sale',
    //       image: mobileView ? cobaBattleMb : cobaBattleDT,
    //       position: 5,
    //       buttons: [],
    //       // videoOnHover: coba_royale,
    //       logo: logo_coba_royal,
    //     },

    // {
    //   _id: "your-earth-edao",
    //   className: "planet-earth",
    //   image: mobileView ? sp_yourearth_edao : bg_yourearth_edao,
    //   position: 8,
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: "https://doc.yourearth.io/pledge-your-ace-and-earn-edao-tokens/",
    //       text: "Start",
    //       onClick: () => osirisBrowserCheaker(),
    //     },
    //   ],
    //   logo: logo_planet,
    // },
    {
      _id: "coba",
      className: "coba",
      image: mobileView ? sp_coba : bg_coba,
      title: "",
      excerpt: "",
      position: 10,
      titleColor: "#fff",
      textColor: "#fff",
      buttons: [
        {
          _id: uuidv4(),
          href: "https://coba.zone/",
          text: "Download Demo",
          onClick: () => osirisBrowserCheaker(),
        },
        {
          _id: uuidv4(),
          href: "https://coba.zone/",
          text: "Hero Auction",
        },
        {
          _id: uuidv4(),
          href: "https://coba.zone/",
          text: "Join Closed Beta",
        },
      ],
      currency: <ETH />,
      logo: logo_coba,
      with_store_logo: true,
      store: "https://opensea.io/assets/coba-crypto-online-battle-arena",
    },

    // {
    //   _id: 'marble-game',
    //   className: 'marble-game',
    //   image: mobileView ? sp_marble : bg_marble,
    //   position: 1,
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: `${process.env.REACT_APP_DAPPARCADE_URL}/game-page`,
    //       text: 'Start',
    //     },
    //   ],
    //   videoOnHover: video_marble,
    //   logo: logo_arcade,
    // },

    // {
    //   _id: 'penalty-showdown',
    //   className: 'penalty-showdown',
    //   image: mobileView ? sp_penalty : bg_penalty,
    //   position: 2,
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: process.env.REACT_APP_DAPPARCADE_URL,
    //       text: 'Start',
    //     },
    //   ],
    //   videoOnHover: video_penalty,
    //   logo: logo_arcade,
    // },
    // {
    //   _id: 'kucoin',
    //   className: 'kucoin',
    //   image: mobileView ? sp_kucoin : bg_kucoin,
    //   position: 3,
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: 'https://trade.kucoin.com/DAPPX-USDT',
    //       text: 'Start',
    //     },
    //   ],
    //   videoOnHover: video_kucoin,
    //   mobileLink: 'https://m.kucoin.com/markets/symbol/DAPPX-USDT',
    //   logo: logo_kucoin,
    //   white_paper: 'https://dappstore.me/pdf/dAppstore_whitepaper_Eng.pdf'
    // },
    // {
    //   _id: 'nft-presale',
    //   className: 'nft-presale',
    //   image: mobileView ? sp_nft_presale : bg_nft_presale,
    //   title_is_image: true,
    //   position: 4,
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: process.env.REACT_APP_DAPPARCADE_URL,
    //       text: 'Start',
    //     },
    //   ],
    //   videoOnHover: video_nft_presale,
    //   logo: logo_arcade,
    //   with_store_logo: true,
    //   store: ''
    // },
    // {
    //   _id: 'uniswap',
    //   className: 'uniswap',
    //   image: mobileView ? sp_uniswap : bg_uniswap,
    //   title: title_uniswap,
    //   title_is_image: true,
    //   excerpt: 'Activate your liquidity',
    //   position: 5,
    //   titleColor: '#fff',
    //   textColor: '#fff',
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: 'https://uniswap.org/',
    //       text: 'Start',
    //     },
    //   ],
    //   videoOnHover: video_uniswap,
    //   logo: logo_uniswap,
    //   with_store_logo: false,
    //   store: ''
    // },
    // {
    //   _id: 'enftee',
    //   className: 'enftee',
    //   image: mobileView ? sp_enftee : bg_enftee,
    //   title: title_enftee,
    //   title_is_image: true,
    //   excerpt: <>
    //     <b>THE DIGITAL COLLECTIBLES</b>
    //   </>,
    //   textColor: '#000',
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: 'https://enftee.com/',
    //       text: 'Start',
    //     },
    //   ],
    //   videoOnHover: video_enftee,
    //   // videoUrl: 'https://www.youtube.com/watch?v=JjTyYevke_M&ab_channel=dAppstore',
    //   currency: <ETH />,
    //   logo: logo_enftee,
    //   with_store_logo: false,
    //   store: ''
    // },
    // {
    //   _id: 'dapp-arcade',
    //   className: 'dapp-arcade',
    //   image: mobileView ? sp_arcade : bg_arcade,
    //   title: '',
    //   excerpt: '',
    //   titleColor: '#fff',
    //   textColor: '#fff',
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: 'https://dapparcade.io/?utm_source=dAppstore&utm_medium=dappbanner&utm_id=Website',
    //       text: 'Start',
    //     },
    //   ],
    //   videoOnHover: video_arcade,
    //   logo: logo_arcade,
    //   with_store_logo: true,
    //   store: ''
    // },
    // {
    //   _id: 'crypto-kitties',
    //   className: 'crypto-kitties',
    //   image: mobileView ? sp_kitties : bg_kitties,
    //   title: title_kitties,
    //   title_is_image: true,
    //   excerpt: 'Collect and breed furrever friends!',
    //   position: 7,
    //   titleColor: '#fff',
    //   textColor: '#fff',
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: 'https://www.cryptokitties.co/',
    //       text: 'Play',
    //     },
    //   ],
    //   videoOnHover: video_kitties,
    //   currency: <ETH />,
    //   logo: logo_kitties,
    //   with_store_logo: true,
    //   store: 'https://opensea.io/assets/cryptokitties'
    // },
    // {
    //   _id: 'decentraland',
    //   className: 'decentraland',
    //   image: mobileView ? sp_decentraland : bg_decentraland,
    //   title: title_decentraland,
    //   title_is_image: true,
    //   excerpt: 'Determine the future of the virtual world',
    //   position: 8,
    //   titleColor: '#fff',
    //   textColor: '#fff',
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: 'https://decentraland.org/',
    //       text: 'Play',
    //     },
    //   ],
    //   videoOnHover: video_decentraland,
    //   currency: <ETH />,
    //   logo: logo_decentraland,
    //   with_store_logo: true,
    //   store: 'https://opensea.io/assets/decentraland'
    // },
    // {
    //   _id: 'maker-dao',
    //   className: 'maker-dao',
    //   image: mobileView ? sp_maker : bg_maker,
    //   title: title_maker,
    //   title_is_image: true,
    //   excerpt: "World's first unbiased currency and decentralized stablecoin",
    //   position: 9,
    //   titleColor: '#fff',
    //   textColor: '#fff',
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: 'https://makerdao.com/en/',
    //       text: 'Start',
    //     },
    //   ],
    //   videoOnHover: video_maker,
    //   currency: <ETH />,
    //   logo: logo_maker,
    //   with_store_logo: false,
    //   store: ''
    // },
    // {
    //   _id: 'xangle',
    //   className: 'xangle',
    //   image: mobileView ? sp_xangle : bg_xangle,
    //   title: title_xangle,
    //   title_is_image: true,
    //   excerpt: 'Global leading crypto asset disclosure platform',
    //   position: 10,
    //   titleColor: '#000',
    //   textColor: '#000',
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: 'https://xangle.io/',
    //       text: 'Start',
    //     },
    //   ],
    //   currency: <ETH />,
    //   logo: logo_xangle,
    //   with_store_logo: false,
    //   store: ''
    // },
    // {
    //   _id: 'opensea',
    //   className: 'opensea',
    //   image: mobileView ? sp_opensea : bg_opensea,
    //   title: title_opensea,
    //   title_is_image: true,
    //   excerpt: 'A peer-to-peer marketplace for rare digital items, such as NFTs',
    //   position: 11,
    //   titleColor: '#3892f3',
    //   textColor: '#fff',
    //   buttons: [
    //     {
    //       _id: uuidv4(),
    //       href: 'https://opensea.io/?ref=0x3067b9df58fcbe7fdb29a5de52d12d17e3dcfb1f',
    //       text: 'Start',
    //     },
    //   ],
    //   currency: <ETH />,
    //   logo: logo_opensea,
    //   with_store_logo: false,
    //   store: ''
    // },
  ];

  return (
    <div className="section section-slider">
      {loading && (
        <div className="page-spinner">
          <Spinner />
        </div>
      )}

      {mobileView ? (
        <SliderMobile
          data={data}
          imageLoaded={imageLoaded}
          setImageLoaded={setImageLoaded}
        />
      ) : (
        <SliderDesktop
          data={data}
          imageLoaded={imageLoaded}
          setImageLoaded={setImageLoaded}
        />
      )}

      <CustomDialog
        open={osirisBrowser}
        className="osiris-browser-modal"
        header={<p>Important!</p>}
        body={
          <div className="mobile-modal-conatainer">
            <p className="policy-modal-body osiris-browser-modal">
              Esports rewards and profits feature can only be accessed through
              the Acent Web 3.0 Browser. Simply click the "Install DAPPX"
              button, and it will guide you through the process.
              {/* You can access this game only through Acent browser. Please download and Install Acent browser from <a href="https://acent.tech/" target='_blank'>www.acent.tech </a> website. */}
            </p>
          </div>
        }
        footer={
          <div className="policy-button-container">
            <button
              className="policy-button"
              onClick={() => setOsirisBrowser(false)}
            >
              Ok
            </button>
          </div>
        }
      />
    </div>
  );
};

export default SectionSlider;
