import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import { Button, Fade, TextField, Link, FormGroup, FormControlLabel, Checkbox, ClickAwayListener, Tooltip, IconButton } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import { Config } from '../../../../constant/config';
import CustomDialog from '../../../../components/Modal/CustomDialog';
import acent_logo from '../../../../assets/images/icons/acent-logo.svg';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ReactComponent as WarningIcon } from '../../../../assets/images/icons/warning.svg';
import { ReactComponent as LoaderIcon } from '../../../../assets/images/icons/loader-2.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/images/icons/error-x.svg';
import { ReactComponent as SuccessIcon } from '../../../../assets/images/icons/success-check.svg';
import TransactionPreviewCurrent from '../../../../components/Modal/TransactionPreviewCurrent';
import TransactionPreview from '../../../../components/Modal/TransactionPreview';
import { NumberFormatCustom } from '../../../../components/CustomInput';
import './index.scss';

const GovernancePledge = (props) => {

  const [input_val, set_input_val] = useState('')
  const [input_fiat_val, set_input_fiat_val] = useState(0)
  const [wallet_action, set_wallet_action] = useState(false)
  const [check_box, set_check_box] = useState(false)
  const [minPledge, setMinPledge] = useState(0)
  const [maxPledge, setMaxPledge] = useState(0)
  const [maxAccPledge, setMaxAccPledge] = useState(0)
  const [holdingPeriod, setHoldingPeriod] = useState(0)
  const [activationFee, setActivationFee] = useState(0)
  const [aceBalance, setAceBalance] = useState(0)
  const [aceBalanceLoading, setAceBalanceLoading] = useState(true)
  const [pledgeBalanceLoading, setPledgeBalanceLoading] = useState(true)

  const [pledgedBalance, setPledgedBalance] = useState(0)
  const [fiat_val, setFiat_val] = useState(0)
  const [newDappXBal, setNewDappXBal] = useState(0)
  const [newPledgeBal, setNewPledgeBal] = useState(0)
  const [newMaxAccPledge, setNewMaxAccPledge] = useState(0)
  const [activatedUser, setActivatedUser] = useState(false)
  const [usdValue, setUsdValue] = useState(0)
  const [isMarketPriceReady, setIsMarketPriceReady] = useState(true)
  const [marketPrice, setMarketPrice] = useState(undefined)
  const [isTimerRunning, setIsTimerRunning] = useState(false)
  const [serviceFee, setServiceFee] = useState(0)
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [whole_num, setWholeNumber] = useState(0)
  const [decimal_num, setDecimalNumber] = useState('0000')
  const [coreFee, setCoreFee] = useState(0)
  const [nitroFee, setNitroFee] = useState(0)
  const [ubiFee, setUbiFee] = useState(0)
  const session_Id = localStorage.getItem('session-id')

  // Input Error
  const [err_message, set_err_message] = useState('');
  const [outline_error, set_outline_error] = useState([]);
   useEffect(()=>{
     let wholeN=aceBalance ? aceBalance.toString().split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''
     setWholeNumber(  wholeN )
      let decimalN=aceBalance ? aceBalance.toString().split('.')[1] : '';
     setDecimalNumber(decimalN)
   },[aceBalance])
  // Modal Deposit
  const [open_modal, set_open_modal] = useState(false);
  const onOpenModal = () => {
    set_open_modal(true)
  }
  const onCloseModal = (e) => {
    props.closeHandler()
    e.preventDefault()
    if (wallet_action) {
      set_wallet_action(false)
      set_err_message('')
    } else {
      onClear()
    }

  }

  const hasUserPledged = async () => {
    setLoading(true)
    await axios.get(Config.DAPPX_BASE_URL + '/users/acent/governance-pledging/status', {headers: {'session-id': session_Id}})
    .then((result) => {
      setLoading(false)
      setActivatedUser(result.data.activated)
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const fetchAceBalance = async () => {
    await axios.get(Config.DAPPX_BASE_URL + '/users/acent/active/balance', {headers: {'session-id': session_Id}})
        .then(async(result) => {
          let hasNoDecimals = result.data.balance.toFixed(6).split('.')[1]
          hasNoDecimals == 0 || hasNoDecimals === undefined ? setAceBalance(Number(Math.trunc(Number(result.data.balance)))) : setAceBalance(Number(result.data.balance).toFixed(6))
         setAceBalanceLoading(false)
          // setAceBalance(Number(result.data.balance))
        })
        .catch((error) => {
          console.log(error)
        })
  }

  const fetchPledgeBalance = async () => {
    await axios.get(Config.DAPPX_BASE_URL + '/users/acent/governance-pledging/balance', {headers: {'session-id': session_Id}})
    .then((result) => {
      // let balance_wei = Web3.utils.fromWei(result.data.dappx_balance.toString(), 'ether');
      // let num2 = Number(balance_wei.toString().match(/^\d+(?:\.\d{0,6})?/));
      // let hasNoDecimals = balance_wei.split('.')[1]
      // hasNoDecimals == 0 || hasNoDecimals === undefined ? setPledgedBalance(Number(Math.trunc(num2))) : setPledgedBalance(Number(num2).toFixed(6))
      setPledgedBalance(result.data.balance)
      setPledgeBalanceLoading(false)
    })
    .catch((error) => {
      console.log(error)
    })
  }

  const fetchPledgeRules = async () => {
    axios.get(Config.DAPPX_BASE_URL + '/users/acent/governance-pledging/settings', {headers: {'session-id': session_Id}})
    .then((res) => {
      // console.log('nn',res.data)
      // let min_pledge = Web3.utils.fromWei(res.data.min_pledge_per_tx.toString(), 'ether');
      // let max_pledge = Web3.utils.fromWei(res.data.max_pledge_per_tx.toString(), 'ether');
      // let max_account_pledge = Web3.utils.fromWei(res.data.max_pledge_per_user.toString(), 'ether');
      // let activation_fee = Web3.utils.fromWei(res.data.activation_fee.toString(), 'ether')
      // console.log(activation_fee)
      setMinPledge(Number(res.data.min_pledge_per_tx))
      setMaxPledge(Number(res.data.max_pledge_per_tx));
      setMaxAccPledge(Number(res.data.max_pledge_per_user))
      setActivationFee(Number(res.data.activation_fee))
      setHoldingPeriod(Number(res.data.penalty_duration))
      let pledge_fee_core=res.data.pledge_fee_core
      let pledge_fee_nitro=res.data.pledge_fee_nitro
      let pledge_fee_ubi=res.data.pledge_fee_ubi
      let totalFee=pledge_fee_core+pledge_fee_nitro+pledge_fee_ubi
      setCoreFee(pledge_fee_core)
      setNitroFee(pledge_fee_nitro)
      setUbiFee(pledge_fee_ubi)
      setServiceFee(Number(totalFee))
    })
    .catch((error) => {
      console.log(error);
    })
  }


  // Modal Loading
  const [open_modal_loading, set_open_modal_loading] = useState(false);
  const onOpenModalLoading = () => {
    set_open_modal_loading(true)
    set_wallet_action(false)
    set_open_modal(false)
  }
  const onCloseModalLoading = () => {
    set_open_modal_loading(false)
  }

  // Modal Success
  const [open_modal_success, set_open_modal_success] = useState(false);
  const onOpenModalSuccess = () => {
    set_open_modal_success(true)
    set_open_modal(false)
  }
  const onCloseModalSuccess = () => {
    set_open_modal_success(false)
    window.location.reload()
  }

  // Modal Error
  const [open_modal_error, set_open_modal_error] = useState(false);
  const onOpenModalError = () => {
    set_open_modal_error(true)
    onClear()
  }
  const onCloseModalError = () => {
    set_open_modal_error(false)
    window.location.reload()
  }

  // Functions
  const remove_errors = (target) => {
    set_err_message('')
    let update_outline_error = outline_error.filter(id => id !== target)
    set_outline_error(update_outline_error)
  }

  const convertCurrency = async () => {
    await axios.get(Config.DAPPX_BASE_URL + '/users/getMarketPrices')
    .then((res) => {
      setUsdValue(res.data.DAPPX.USD)
    })
    .catch((error) => {
      set_input_fiat_val(0)
      set_err_message("Can't pull conversion rate.")
    })
  }

  const convertValue = (balance, tokenCode) => {
    let convertion_currency = 'USD';

    if (isMarketPriceReady) {
      axios.get(Config.DAPPX_BASE_URL + '/users/getMarketPrices')
        .then((prices) => {
          let fiat_currency = prices.data[tokenCode][convertion_currency] ? prices.data[tokenCode][convertion_currency] : 0;
          let fiat_value_value = parseFloat(balance) * parseFloat(fiat_currency);
          let convertion_value;

          if (fiat_value_value === 0) {
            convertion_value = parseFloat(fiat_value_value)
          } else {
            convertion_value = parseFloat(fiat_value_value).toFixed(2)
          }

          set_input_fiat_val(convertion_value);
          setMarketPrice(prices.data)
          setIsMarketPriceReady(false)
          if (!isTimerRunning) marketPriceTimer()
        })
        .catch((error) => {
          console.log('convertValue error: ', error)
          set_input_fiat_val(0)
          set_err_message("Can't pull conversion rate.")
        })
    } else {
      let fiat_currency = marketPrice[tokenCode][convertion_currency] ? marketPrice[tokenCode][convertion_currency] : 0;
      let fiat_value_value = parseFloat(balance) * parseFloat(fiat_currency);
      let convertion_value;

      if (fiat_value_value === 0) {
        convertion_value = parseFloat(fiat_value_value)
      } else {
        convertion_value = parseFloat(fiat_value_value).toFixed(2)
      }

      set_input_fiat_val(convertion_value);
      if (!isTimerRunning) marketPriceTimer()
    }
  }

  const marketPriceTimer = () => {
    setIsTimerRunning(true)

    setTimeout(() => {
      setIsMarketPriceReady(true)
      setIsTimerRunning(false)
    }, 60000)
  }

  useEffect(() => {
    hasUserPledged()
    fetchPledgeRules()
    fetchAceBalance()
    fetchPledgeBalance()
    convertCurrency()
  },[])
useEffect(()=>{
  set_open_modal(props.open)

},[props.open])
  useEffect(() => {

    if(maxAccPledge>=pledgedBalance){
      setNewMaxAccPledge(maxAccPledge - pledgedBalance)

    }
  },[input_val, pledgedBalance,open_modal])

  const onChange_num = (e) => {
    const target_val = e.target.value;
    const decimals = target_val.split('.')[1] || '';
    remove_errors(e.target.id)

    if (target_val !== '' && decimals.length <= 6) {
      setShow(true)
      set_input_val(target_val)
      let convertion_value = (target_val * usdValue).toFixed(2)
      // convertValue(target_val, 'DAPPX')
      set_input_fiat_val(convertion_value)
      convertCurrency()
    }

    if (target_val === '') {
      setShow(false)
      set_input_val(target_val)
      set_input_fiat_val(target_val * usdValue)
      convertCurrency()
      // convertValue(0, 'DAPPX')
    }
  }

  const onCheckDisclaimer = (event) => {
    set_check_box(event.target.checked);
  };

  const maxVal = () => {
    set_input_val(Math.min(aceBalance, maxPledge, newMaxAccPledge))
    convertValue(Math.min(aceBalance, maxPledge, newMaxAccPledge), 'ACE')
  }

  const minVal = () => {
    set_input_val(minPledge)
    convertValue(minPledge, 'ACE')
  }

  const newUserSubmit =() => {
    if(!check_box) {
      set_err_message('Please read labels and check the box before we proceed.')
    } else if(aceBalance < activationFee) {
      set_err_message('Insufficient balance!')
    } else {
      axios.post(Config.DAPPX_BASE_URL + '/users/acent/governance-pledging/activate',{} ,{headers: {'session-id': session_Id}})
      .then((res) => {
        set_err_message('')
        setActivatedUser(true)
        fetchAceBalance()
        set_check_box(false)
        onOpenModal()
      })
      .catch((error) => {
        console.log(error);
        set_err_message("Could not process Activation!")
      })
    }
  }

  const onSubmit = () => {
    setNewDappXBal(aceBalance - input_val)
    setNewPledgeBal(Number(pledgedBalance) + Number(input_val) )
      if(!check_box) {
        set_err_message('Please read labels and check the box before we proceed.')
      }else if(Number(input_val) > aceBalance ) {
        set_err_message('Insufficient balance!')

      }else if(Number(input_val)+input_val*(serviceFee/100) > aceBalance ) {
        set_err_message('Insufficient balance!')

      } else if (Number(input_val) > newMaxAccPledge) {
        set_err_message('Pledge amount exceeds the maximum amount per transaction.')

      } else if(Number(input_val) < minPledge) {
        set_err_message('Pledge amount is below the minimum amount per transaction.')

      } else if(Number(input_val) > maxPledge){
        set_err_message('Pledge amount exceeds the maximum amount per transaction.')

      } else if(Number(input_val) === 0 || !input_val) {
        set_err_message('Invalid input!')

      } else {
        set_err_message('')
        if (wallet_action) {

          const data = {
            amount: parseFloat(input_val),
          }
          onOpenModalLoading()

          axios.post(Config.DAPPX_BASE_URL + '/users/acent/governance-pledging/pledge', data, {headers: {'session-id': session_Id}})
          .then((res) => {
            setAceBalance(newDappXBal)
            setPledgedBalance(newPledgeBal)
            onCloseModalLoading()
            onOpenModalSuccess()
          })
          .catch((error) => {
            console.log(error);
              onCloseModalLoading()

            onOpenModalError()
          })

          // setTimeout(() => { // for UI only
          //   onCloseModalLoading()
          //   setTimeout(() => {
          //     onOpenModalSuccess()
          //   }, 300)
          //   // onOpenModalError()
          // }, [2000])
        } else {
          set_wallet_action(true)
        }
  }
  }

  const onClear = () => {
    setTimeout(() => {
      set_wallet_action(false)
      set_open_modal(false)
      set_input_val('')
      set_input_fiat_val(0)
      set_err_message('')
      set_outline_error([])
      set_wallet_action(false)
      set_check_box(false)
    }, 400)
  }

  // Tooltip
  const [open_tooltip, set_tooltip] = useState(false)
  const onOpen_tooltip = () => set_tooltip(true)
  const onClose_tooltip = () => set_tooltip(false)
  // Tooltip for Service fee
  const [open_service_tooltip, set_service_tooltip] = useState(false)
  const onOpen_service_tooltip = () => set_service_tooltip(true)
  const onClose_service_tooltip = () => set_service_tooltip(false)

  return (
    <>
      {/*<Button*/}
      {/*  className="pledge-btn"*/}
      {/*  variant="outlined"*/}
      {/*  onClick={()=>{*/}
      {/*    document.body.click()*/}
      {/*    onOpenModal()*/}
      {/*  }}>*/}
      {/*  Pledge*/}
      {/*</Button>*/}

      {(!activatedUser && !loading) ?
      <CustomDialog
      open={open_modal}
      className="deposit-dialog"
      onClose={onCloseModal}
      header="Stake Activation"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      body={
      <>
        {!wallet_action && ( !aceBalanceLoading?
                <div className="container-balance">
                  <label>Available ACE Balance</label>
                  <div className="block">
                    <div className="icon-block">
                      <img src={acent_logo} alt=""/>
                    </div>
                    <div className="amount-block">

                      <>
                        {/*<p className="amount">{whole_num ? whole_num : "0"}.<span>{decimal_num ? decimal_num : "000000"}</span></p>*/}
                        <p className="amount">{whole_num ? whole_num : "0"}.<span>{decimal_num ? decimal_num : "000000"}</span></p>
                        {/*<p className="amount">{aceBalance}</p>*/}
                        <p className="convertion">{(aceBalance*usdValue).toFixed(2)} USD</p>
                        {/* <p className="convertion">{fiat_val} USD</p> */}
                      </>

                    </div>
                  </div>
                </div>:<LoaderIcon className="icon"/>

        )

        }
        <div className={`container-textfield ${wallet_action ? 'wallet-action' : ''}`}>
          <div className='activation-message'>
            <p className="activation-text">ACE Staking allows you to earn more EDAO.</p>
          </div>
          <FormGroup className="container-check">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={check_box}
                    onChange={onCheckDisclaimer}
                    name="remember_me"
                    color="primary" />
                }
                label={`I understand that the one-time activation fee of ${activationFee} ACE is almost negligible compared to the rewards I can obtain from ACE Staking.`}
              />
            </FormGroup>
        </div>
        </>
      }
      footer={
      <>
        {err_message &&
          <Fade className="error custom-error" in={err_message ? true : false} timeout={500} >
            <Alert severity="error"><WarningIcon className="icon" />{err_message}</Alert>
          </Fade>
        }
        <div className="button-block">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disableElevation
            disabled={check_box?false:true}
            onClick={!activatedUser ? newUserSubmit : onSubmit}
            type="submit">{wallet_action ? 'Submit' : 'Continue'}</Button>
          <Button variant="contained" color="default" fullWidth disableElevation onClick={onCloseModal}>Cancel</Button>
        </div>
      </>}
        />
    :
    <CustomDialog
        open={open_modal}
        className="pledge-dialog"
        onClose={onCloseModal}
        header="Stake ACE Governance"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={
        <>
          {!wallet_action && ((!aceBalanceLoading && !pledgeBalanceLoading) ?
            <div className="container-balance">
              <TransactionPreviewCurrent
                bal_1_title="Balance"
                bal_2_title="Stake"
                current_bal_1={aceBalance.toString()}
                current_bal_2={pledgedBalance.toString()}
                error_message={set_err_message}
                token_code="ACE"
                open={wallet_action}
              />
            </div> : <LoaderIcon className="icon"/>)
          }

          <div className={`container-textfield ${wallet_action ? 'wallet-action' : ''}`}>
            <div className="number-label">
              <label>
                Stake
                <ClickAwayListener onClickAway={onClose_tooltip}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={onClose_tooltip}
                    open={open_tooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow
                    placement="right"
                    title={
                      <div className="tooltip-content">
                        <h5>Stake Limit</h5>
                        <p>Minimum per transaction: <span>{minPledge} ACE</span></p>
                        <p>Maximum per transaction: <span>{maxPledge} ACE</span></p>
                        {/*<p>Remaining amount for pledging: <span>{newMaxAccPledge} ACE</span></p>*/}
                        <p>Remaining amount for staking: <span>{maxAccPledge-pledgedBalance} ACE</span></p>
                      </div>
                    }
                  >
                    <IconButton onClick={onOpen_tooltip} className={`info-btn ${open_tooltip ? 'open' : ''}`}><InfoOutlinedIcon/></IconButton>
                  </Tooltip>
                </ClickAwayListener>
              </label>
              <div className="right">
                <Button disableRipple onClick={minVal}>Min</Button>
                <Button disableRipple onClick={maxVal}>Max</Button>
              </div>
            </div>
            <div className="textfield-block">
              <TextField
                fullWidth
                className="number-field"
                error={outline_error.includes('amount')}
                value={input_val}
                onChange={onChange_num}
                autoComplete="off"
                variant="outlined"
                id='amount'
                placeholder="0.0"
                disabled={wallet_action ? true : false}
                name="number-field"

                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              {
                show &&  <div className="convet_in_input">(≈ {input_fiat_val} USD)</div>

              }
              <img className="coin-logo" src={acent_logo} alt={"logo"} />
            </div>
            {
              show &&
              <div className="number-label">
                <label className="service_label">
                  Staking Fee:<div className="convertion">{Number((input_val*(serviceFee/100)).toFixed(6))} ACE </div>
                  <ClickAwayListener onClickAway={onClose_service_tooltip}>
                    <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={onClose_service_tooltip}
                        open={open_service_tooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                        placement="right"
                        title={
                          <div className="tooltip-content">
                            <h5>Staking Fee</h5>
                            {/*<p>*/}
                            {/*  {serviceFee}% of the pledged amount will be used to develop the core metaweb ecosystem; compensate the EDAO governance token holders, including landowners; and push for a decentralized immersive web service.*/}
                            {/*</p>*/}
                            <ul>
                              <li>
                                <span>{nitroFee}%</span> Nitro Fees
                              </li>
                              <li>
                                <span>{ubiFee}%</span> UBI protocol fee will be used to compensate token holders, including land owners.
                              </li>
                              <li>
                                <span>{coreFee}%</span> Core Ecosystem development fee to deploy a self-sufficient, robust, decentralized immersive web service.
                              </li>
                            </ul>
                          </div>
                        }
                    >
                      <IconButton onClick={onOpen_service_tooltip} className={`info-btn ${open_service_tooltip ? 'open' : ''}`}><InfoOutlinedIcon/></IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </label>
              </div>

            }

          </div>

          {!wallet_action &&
            <FormGroup className="container-check">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={check_box}
                    onChange={onCheckDisclaimer}
                    name="remember_me"
                    color="primary" />
                }
                label={`I understand that minimizing and/or withdrawing my staked amount within ${holdingPeriod} hours of the last stake will incur a penalty fee if I proceed.`}
              />
            </FormGroup>
          }

          {wallet_action &&
            <div className="container-preview">
              <TransactionPreview
                bal_1_title="Balance"
                bal_2_title="Stake"
                current_bal_1={aceBalance.toString()}
                current_bal_2={pledgedBalance.toString()}
                after_bal_1={Number((newDappXBal-(input_val*(serviceFee/100))).toFixed(6)).toString()}
                after_bal_2={Number(newPledgeBal).toString()}
                error_message={set_err_message}
                token_code="DAPPX"
                open={wallet_action}
              />
            </div>
          }
        </>
        }
        footer={<>
          {err_message &&
            <Fade className="error custom-error" in={err_message ? true : false} timeout={500} >
              <Alert severity="error"><WarningIcon className="icon" />{err_message}</Alert>
            </Fade>
          }
          <div className="button-block">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disableElevation
              onClick={onSubmit}
              // disabled={!check_box?true:false}
              type="submit">{wallet_action ? 'Submit' : 'Continue'}</Button>
            {
              wallet_action ?
                  <Button variant="contained" color="default" fullWidth disableElevation onClick={()=>{
                    set_err_message('')
                    set_wallet_action(false)
                  }}>Cancel</Button>

                  :
                  <Button variant="contained" color="default" fullWidth disableElevation onClick={onCloseModal}>Cancel</Button>


            }
          </div>
        </>}
      />
    }

      <CustomDialog
        open={open_modal_loading}
        className="pledge-dialog loading-dialog"
        onClose={onCloseModalLoading}
        header="Processing your request"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={<>
          <LoaderIcon className="icon"/>
          <p>Almost there!</p>
          <p>Please wait a moment.</p>
        </>}
      />

      <CustomDialog
        open={open_modal_success}
        className="pledge-dialog success-dialog"
        onClose={onCloseModalSuccess}
        header= {"Stake Successful!"}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={<>
          <SuccessIcon className="icon"/>
          <>
          <p>You have successfully staked</p>
          <p className="amount-preview">{input_val} ACE</p>
          </>
        </>}
        footer={<>
          <div className="button-block">
            <Button variant="contained" color="primary" disableElevation onClick={onCloseModalSuccess} type="submit">OK</Button>
          </div>
        </>}
      />

      <CustomDialog
        open={open_modal_error}
        className="pledge-dialog error-dialog"
        onClose={onCloseModalError}
        header="Stake Failed"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={<>
          <ErrorIcon className="icon"/>
          <p>Your stake transaction didn't push through.</p>
          <p>Amount of ACE has been reverted to your wallet.</p>
        </>}
        footer={<>
          <div className="button-block">
            <Button variant="contained" color="primary" disableElevation onClick={onCloseModalError} type="submit">OK</Button>
          </div>
        </>}
      />
    </>
  )
}

export default GovernancePledge;
