import { useState } from "react";
import {
  Modal,
  Backdrop,
  Zoom,
  IconButton,
  ButtonBase,
  Fade,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { detect } from "detect-browser";
import { isMobile } from "react-device-detect";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close.svg";
import dappstore_logo from "../../assets/images/dappstore-logo.svg";
import wonderwallet from "../../assets/images/dappxpr/wonder-wallet.svg";
import metawallet from "../../assets/images/dappxpr/acent-metawallet.png";
import metamask from "../../assets/images/dappxpr/metamask.png";
import eth from "../../assets/images/dappxpr/connect-ethereum.png";
import tron from "../../assets/images/dappxpr/connect-tron.png";
import bnb from "../../assets/images/dappxpr/connect-binance.png";
import { ReactComponent as Warning } from "../../assets/images/icons/warning.svg";
import ModalNotice from "./ModalNotice";
import "./ModalDappxPR.scss";

const ModalConnectWallet = ({
  open,
  onClose,
  onClickBtn,
  disable_btn,
  executeConnect,
  connect_error,
}) => {
  // Loading
  const [loading_alert, set_loading_alert] = useState(false);
  const onClose_loading_alert = () => set_loading_alert(false);
  const onOpen_loading_alert = () => set_loading_alert(true);

  const onContinue = async () => {
    onOpen_loading_alert();

    await executeConnect();

    setTimeout(() => {
      onClose_loading_alert();
      if (connect_error) {
        onClickBtn();
      }
    }, 2000);
  };

  // open notice modal
  const [openNotice, setOpenNotice] = useState(false);
  let onOpenNotice = () => {
    onClose();
    onClose_loading_alert();
    setOpenNotice(true);
  };
  let onCloseNotice = () => {
    setOpenNotice(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="connect-wallet-title"
        aria-describedby="connect-wallet-desc"
        className={`connect-wallet-modal custom-scroll dappxPR-modal ${
          isMobile ? "is-mobile" : ""
        }`}
        open={open}
        onClose={() => {
          onClose();
          onClose_loading_alert();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Zoom in={open}>
          <div className="paper-wrapper">
            <div className="paper">
              <div className="mdl-header">
                <h2>Connect to a Wallet</h2>
                <IconButton
                  onClick={() => {
                    onClose();
                    onClose_loading_alert();
                  }}
                  className="close-btn"
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="mdl-body">
                {isMobile ? (
                  <MobileContent />
                ) : (
                  <DesktopContent
                    onOpenNotice={onOpenNotice}
                    connect_error={connect_error}
                    loading_alert={loading_alert}
                    onContinue={onContinue}
                  />
                )}
                {connect_error && (
                  <Fade
                    className="error custom-error"
                    in={connect_error ? true : false}
                    timeout={500}
                  >
                    <Alert severity="error">
                      <Warning className="icon" />
                      {connect_error}
                    </Alert>
                  </Fade>
                )}
              </div>
            </div>
          </div>
        </Zoom>
      </Modal>

      <ModalNotice open={openNotice} onClose={onCloseNotice} />
    </div>
  );
};

const DesktopContent = ({
  onOpenNotice,
  connect_error,
  loading_alert,
  onContinue,
}) => {
  return (
    <>
      <div className="btn-group">
        <div className={`btn-container`}>
          <ButtonBase
            variant="contained"
            color="primary"
            onClick={onOpenNotice}
          >
            <div className="img-container">
              <img
                src={metawallet}
                alt="Acent Metawallet"
                className="img-fluid"
              />
              <ul className="supported-list">
                <li>
                  <img src={eth} alt="Ethereum" className="img-fluid" />
                </li>
                <li>
                  <img src={tron} alt="Tron" className="img-fluid" />
                </li>
                <li>
                  <img src={bnb} alt="Binance" className="img-fluid" />
                </li>
              </ul>
            </div>
          </ButtonBase>
          <div className="note">
            <ul className="note-list">
              <li>
                High performance true play-to-earn games and applications
                rendering
              </li>
              <li> WEB-3 Rendering for ERC(ETH), TRC(TRX) Networks</li>
              <li> Crypto wallet for ETH, TRX, BSC, and more</li>
              <li> No centralized private wallet information gathering</li>
            </ul>
          </div>
        </div>

        <div
          className={`btn-container ${connect_error ? "disabled" : ""} ${
            loading_alert ? "loading" : ""
          }`}
        >
          <ButtonBase
            variant="contained"
            color="primary"
            className="metamask"
            onClick={onContinue}
            disabled={loading_alert || connect_error ? true : false}
          >
            <div className="img-container">
              <img src={metamask} alt="Metamask" className="img-fluid" />
            </div>
          </ButtonBase>
        </div>
      </div>
      <div className="notice">
        DAPPX true play-to-earn games and applications are only accessible
        through the{" "}
        <a href="https://browseosiris.com/" target="_blank" rel="noreferrer">
          Acent Web 3.0 browser
        </a>
        .
      </div>
    </>
  );
};

const MobileContent = () => {
  let isIOS = detect().os === "iOS" || detect().os === "Mac OS";
  return (
    <>
      <div className={`btn-group ${isIOS ? "disabled" : ""}`}>
        <div className="powered-outside">
          <span>Powered by </span>
          <img src={dappstore_logo} alt="dAppstore" />
        </div>
        <div
          className={`btn-container wonder-wallet ${isIOS ? "disabled" : ""}`}
        >
          <ButtonBase
            variant="contained"
            color="primary"
            component="a"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.wonder.wallet"
            disabled={isIOS}
          >
            <div className="img-container">
              <div className="powered">
                <span>Powered by </span>
                <img src={dappstore_logo} alt="dAppstore" />
              </div>
              <img
                src={wonderwallet}
                alt="Wonder Wallet"
                className="img-fluid"
              />
            </div>
          </ButtonBase>
          <div className="note">RECOMMENDED FOR WEB3</div>
        </div>
      </div>

      <div className="notice">
        dAppstore is best experienced with the <span>WonderWallet</span>
      </div>
    </>
  );
};

export default ModalConnectWallet;
