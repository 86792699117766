import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Config } from '../../constant/config';
import './TransactionPreview.scss';

const TransactionPreviewCurrent = ({
  bal_1_title,
  bal_2_title,
  current_bal_1,
  current_bal_2,
  error_message,
  token_code,
  open
}) => {
  let current_whole_num_bal_1 = current_bal_1 ? current_bal_1.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
  let current_decimal_num_bal_1 = current_bal_1 ? current_bal_1.split('.')[1] : '';
  let current_whole_num_bal_2 = current_bal_2 ? current_bal_2.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
  let current_decimal_num_bal_2 = current_bal_2 ? current_bal_2.split('.')[1] : '';

  const [currencies, set_currencies] = useState([])

  const convertValue = async (balance, tokenCode) => {
    let convertion_currency = 'USD';

    await axios.get(Config.DAPPX_BASE_URL + '/users/getMarketPrices')
    .then((prices) => {
      console.log('prices',prices)
      let array_of_currency = [];
      console.log('balance',balance)
      balance.map(i => {
        let fiat_currency = prices.data[tokenCode][convertion_currency] ? prices.data[tokenCode][convertion_currency] : 0;
       console.log('fiat_currency',fiat_currency)
       console.log('i',i)
       console.log('parseFloat(i)',parseFloat(i))
       console.log('parseFloat(fiat_currency)',parseFloat(fiat_currency))
        let fiat_value_value = parseFloat(i) * parseFloat(fiat_currency);
        console.log('fiat_value_value',fiat_value_value)

        let convertion_value = parseFloat(fiat_value_value).toFixed(2);
        console.log('convertion_value',convertion_value)
        array_of_currency.push(convertion_value)
      })
      console.log('array_of_currency',array_of_currency)
      set_currencies(array_of_currency)
    })
    .catch((error) => {
      console.log(error)
      error_message("Can't pull conversion rate.")
      set_currencies([])
    })
  }

  useEffect(() => {
    let array_of_balances = [current_bal_1, current_bal_2]
    convertValue(array_of_balances, token_code)
  }, [open])

  return (
    <div className="transaction-preview">
      <div className="block current">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{bal_1_title}</th>
              <th>{bal_2_title}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Current</td>
              <td>
                <div className="amount">{current_whole_num_bal_1}<span>{current_decimal_num_bal_1 ? `.${current_decimal_num_bal_1}` : ''}</span></div>
                <div className="currency">{currencies.length ? currencies[0] : '0.00'} USD</div>
              </td>
              <td>
                <div className="amount">{current_whole_num_bal_2}<span>{current_decimal_num_bal_2 ? `.${current_decimal_num_bal_2}` : ''}</span></div>
                <div className="currency">{currencies.length ? currencies[1] : '0.00'} USD</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TransactionPreviewCurrent;
