import React from 'react';
import { ReactComponent as Plus } from '../../../../assets/images/icons/+.svg';
import { ReactComponent as Minus } from '../../../../assets/images/icons/minus.svg';
import AceIcon from '../../../../assets/images/icons/icon-ace.svg'
import moment from 'moment'
const HistoryItem = ({ transaction }) => {
    const { type, date, amount, tn } = transaction

    let decimal = []
    if (amount) {
        decimal = String(amount).split('.')
    }
    return (
        <div className="history-item">
            <div className="history-item-top">
                <p> {moment(date).format('YYYY/MMM/DD - hh:mm:ss a')}
                    {/* 2022/Aug/17 - 13:24:11 */}
                </p>

                <span>TN: {tn.substring(0, 10)}...</span>
            </div>
            <div className="history-item-middle">
                <div className="history-item-content">
                    <div className="history-item-thumb">
                        <img src={AceIcon} alt="aasdfasd" />
                    </div>
                    <div className="history-item-typo">
                        <h5>ACE</h5>
                        <p>{type}</p>
                    </div>
                </div>
                <h4 className="amount">
                    {type === 'deposit' && (
                        <Plus />
                    )}
                    {type === 'withdraw' && (
                        <Minus />
                    )}

                    {decimal[0]}.<span>{decimal[1] ? decimal[1] : '00'}</span>
                </h4>
            </div>
        </div>
    );
}

export default HistoryItem;
