import { useState } from "react";
import {
  Modal,
  Backdrop,
  Zoom,
  IconButton,
  ButtonBase,
  Fade,
  Button,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { detect } from "detect-browser";
import { isMobile } from "react-device-detect";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/close.svg";
import dappstore_logo from "../../assets/images/dappstore-logo.svg";
import wonderwallet from "../../assets/images/dappxpr/wonder-wallet.svg";
import metawallet from "../../assets/images/dappxpr/acent-metawallet.png";
import metamask from "../../assets/images/dappxpr/metamask.png";
import eth from "../../assets/images/dappxpr/connect-ethereum.png";
import tron from "../../assets/images/dappxpr/connect-tron.png";
import bnb from "../../assets/images/dappxpr/connect-binance.png";
import { ReactComponent as Warning } from "../../assets/images/icons/warning.svg";
import ModalNotice from "./ModalNotice";
import "./ModalDappxPR.scss";

const RestrictedModal = ({
  open,
  onClose,
  onClickBtn,
  disable_btn,
  executeConnect,
  connect_error,
}) => {
  // Loading
  const [loading_alert, set_loading_alert] = useState(false);
  const onClose_loading_alert = () => set_loading_alert(false);
  const onOpen_loading_alert = () => set_loading_alert(true);

  const onContinue = async () => {
    onOpen_loading_alert();

    await executeConnect();

    setTimeout(() => {
      onClose_loading_alert();
      if (connect_error) {
        onClickBtn();
      }
    }, 2000);
  };

  // open notice modal
  const [openNotice, setOpenNotice] = useState(false);
  let onOpenNotice = () => {
    onClose();
    onClose_loading_alert();
    setOpenNotice(true);
  };
  let onCloseNotice = () => {
    setOpenNotice(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="connect-wallet-title"
        aria-describedby="connect-wallet-desc"
        className={`connect-wallet-modal custom-scroll dappxPR-modal ${
          isMobile ? "is-mobile" : ""
        }`}
        open={open}
        onClose={() => {
          onClose();
          onClose_loading_alert();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Zoom in={open}>
          <div className="paper-wrapper">
            <div className="paper restricted">
              <div className="mdl-header">
                {/* <IconButton
                  onClick={() => {
                    onClose();
                    onClose_loading_alert();
                  }}
                  className="close-btn"
                >
                  <CloseIcon />
                </IconButton> */}
              </div>
              <div className="mdl-body">
              <h5> This feature is currently not available in your country or region. </h5>
              <Button
                  className="btn-ok"
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={onClose}>
                    {/* DONE */}
                  OK
                </Button>
              </div>
            </div>
          </div>
        </Zoom>
      </Modal>

      <ModalNotice open={openNotice} onClose={onCloseNotice} />
    </div>
  );
};


export default RestrictedModal;
