import React from 'react';
import { Container } from '@material-ui/core';
import './Disclaimer.scss';

const Disclaimer = () => {
  return (
    <div className="page-content disclaimer-content">
      <Container>
        <h1>Disclaimer</h1>

        <p>Your use of the DAPPX protocol involves various risks, including, losses of digital assets. Before using the DAPPX protocol, you should review the relevant documentation to make sure you understand how the dAPPX protocol works. Additionally, you can access the dAPPX protocol through dozens of web or mobile interfaces. You are responsible for doing your own diligence on those interfaces to understand the fees and risks they present.</p>

        <p>AS DESCRIBED IN THE DAPPX PROTOCOL LICENSES, THE DAPPX PROTOCOL IS PROVIDED ”AS IS”, AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. No developer or entity involved in creating the dAPPX protocol will be liable for any claims or damages whatsoever associated with your use, inability to use, or your interaction with other users of, the dAPPX protocol, including any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of value.</p>

        <p>&copy; 2021 DAPPX</p>
      </Container>
    </div>
  );
}

export default Disclaimer;