import { useState, useEffect, useContext } from "react";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  useTheme,
} from "@material-ui/core/styles";
import axios from "axios";
import { Grid, Button, Typography, useMediaQuery } from "@material-ui/core";

import { ReactComponent as AcentActiveIcon } from "../../../assets/images/icons/acent-active.svg";
import { ReactComponent as VaultIcon } from "../../../assets/images/icons/vault.svg";
import { ReactComponent as HistoryIcon } from "../../../assets/images/icons/history.svg";
import { ReactComponent as TodoIcon } from "../../../assets/images/icons/to-do-list.svg";
import infoIcon from "../../../assets/images/dappstore-info.png";
import acent_logo from "../../../../src/assets/images/icons/acent-logo.svg";
import dappxlogo from "../../../../src/assets/images/dappx-logo.webp";
import earthdaologo from "../../../../src/assets/images/your-earth-dao-white.png";
import edao_logo from "../../../../src/assets/images/icons/edao-logo.svg";
import { Popover, OverlayTrigger } from "react-bootstrap";
import Countdown, { zeroPad } from "react-countdown";
import "./index.scss";
import { Config, DAPPX_BASE_URL } from "../../../constant/config";
import { Ticker } from "react-flip-ticker";
import { Deposit, Transfer, Withdraw } from "../common";
import { AuthContext } from "../../../context/AuthContext";
import { convertValue } from "../Profile";
import gate from "../../../assets/images/icons/gate.io.png";
import kucoin from "../../../assets/images/icons/kucoin.png";
import AceButtonPledge from "../common/Pledge/AcePledge";
import GovernanceUnPledge from "../common/Pledge/GovernanceUnPledge";
import AceDialog from "../../../components/Modal/AceDialog";
import CustomDialog from "../../../components/Modal/CustomDialog";
import { ReactComponent as SuccessIcon } from "../../../assets/images/icons/success-check.svg";
import Pagination from "@mui/material/Pagination";
import DP_Placeholder from "../../../assets/images/dappxpr/dp-placeholder.png";
import "../../../components/IMX/index.scss";

import { format } from "date-fns";
import TransactionHistoryDrawer from "../common/History/TransactionHistoryDrawer";
import SideBarData from "../../../components/UserSidebar/SideBarData";
const APP_URL = DAPPX_BASE_URL;
const sessionID = localStorage.getItem("session-id");
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#016ce9",
    },
    secondary: {
      main: "#f44336",
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  root: {
    color: "#111111",
    padding: "2rem",

    "& .amount": {
      "& > *": {
        display: "inline-block",
      },
    },
    "& button": {
      textTransform: "none",
      fontSize: "16px",

      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },

    [theme.breakpoints.down("xs")]: {
      padding: "2rem .5rem",
    },

    [theme.breakpoints.down("sm")]: {
      paddingTop: "5rem",
    },
  },

  divider: {
    width: "1rem",
    visibility: "hidden",
  },
  center: {
    textAlign: "center",
  },
  brdr: {
    borderBottom: "1px solid #000 !important",
    borderColor: "red",
  },
  dot: {
    fontWeight: "bold",
    lineHeight: 2,
  },
  balanceLabel: {
    fontSize: "24px",
    fontWeight: "bold",
    lineHeight: 1,
    color: "#111111",
    borderBottom: "1px solid #dddddd",
    height: "50px",

    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },

  bgLightGray: {
    backgroundColor: "#F7F7F7",
  },
  comingSoonContainer: {
    width: "100%",
    borderRadius: "0.6rem",
    backgroundColor: "rgba(0,0,0,.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1rem",
  },
  comingSoonText: {
    position: "absolute",
    fontWeight: "bold",
    color: "#FFF",
    lineHeight: 1,

    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  detailsTitle: {
    fontSize: "20px",
    lineHeight: "1.2",
    color: "#626262",
    fontWeight: "normal",

    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  btn: {
    fontSize: "1rem",
    lineHeight: 1.6,
    borderRadius: "5px",
    borderColor: "#016ce9",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
}));

const defaultFloatVal = "0.000000";

const Acent = () => {
  const classes = useStyles();
  const displayDecimals = 6;
  const computeDecimals = 12;
  const isNative = localStorage.getItem("current_network") === '0x22b8' || localStorage.getItem('current_network') === "0x231d" ? true : false;

  const [reloadData, setReloadData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [policyModal, setPolicyModal] = useState(false);
  const [withdrawalOn, setWithdrawalOn] = useState(false);
  const [withdrawalMaitenance, setWithdrawalMatintenance] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [activeSeasonId, setActiveSeasonId] = useState();
  const [activeSeasonPledgedAmount, setActiveSeasonPledgedAmount] = useState(0);
  const [pledgeHistory, setPledgeHistory] = useState(0);
  const [participationHistory, setParticipationHistory] = useState([]);
  const [pledgedBalance, setPledgedBalance] = useState(0);
  const [edao, setEDAO] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [pOffset, setPOffset] = useState(1);
  const [pCount, setPCount] = useState(0);
  const [governancePledgeHistory, setGovernancePledgeHistory] = useState([]);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [data, setData] = useState({
    mainBal: defaultFloatVal,
    pocketBal: defaultFloatVal,
  });
  const { mainBal, pocketBal } = data;
  const balance = (parseFloat(mainBal) + parseFloat(pocketBal)).toFixed(
    computeDecimals
  );
  const [digital_ace, set_digital_ace] = useState({
    coin_value: defaultFloatVal,
    fiat_value: "0.00",
  });
  const [vaultFiat, setVaultFiat] = useState(0);

  const [withdrawLimit, setWithdrawLimit] = useState(0);
  const [inputVal, setInputVal] = useState(0);
  const [inputFiatVal, setInputFiatVal] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const [nitroFee, setNitroFee] = useState(0);
  const [ubiFee, setUbiFee] = useState(0);
  const [coreFee, setCoreFee] = useState(0);

  const { authState } = useContext(AuthContext);
  const { wallet_balance, user_details } = authState;
  let user_username = user_details.username;
  const user_ace = wallet_balance.find((x) => x.token === "ACE");

  const handleOpen = () => {
    setReloadData(true);
    setOpen(true);
  };

  const handleClose = () => {
    setReloadData(true);
    setOpen(false);
  };
  const popoverAcent = (
    <Popover
      className="pop1"
      id="popover-positioned-bottom"
      title="Popover bottom"
    >
      <div className="buydrpdn">
        <div className="drpheader">
          <h1>BUY ACENT from</h1>
        </div>
        <div className="drpbody">
          <div className="drpbtn1">
            <Button
              href="https://www.kucoin.com/ucenter/signup?rcode=rJCP33Y"
              target="_blank"
            >
              <span>
                <img src={kucoin} />
              </span>
            </Button>
          </div>
          <div className="drpbtn2">
            <Button
              className="button"
              href="https://www.gate.io/signup/8166666 "
              target="_blank"
            >
              <span>
                <img src={gate} />
              </span>
            </Button>
          </div>
        </div>
        {/*<div className="drpbdr">*/}
        {/*  <p className="dot"> . . . . . . . . . . . .</p>*/}
        {/*  <div className="drpor"> <p>or</p> </div>*/}
        {/*  <p className="dot"> . . . . . . . . . . . .</p>*/}
        {/*</div>*/}
        {/*<div className="drpftr">*/}
        {/*  <p>Easily Exchange ETH to ACE in dAppstore!</p>*/}
        {/*  <Button>BUY in dAppstore</Button>*/}
        {/*</div>*/}
      </div>
    </Popover>
  );
  // useEffect(async () => {
  //   setLoading(true);
  //   const startTime = new Date().getTime();
  //   const minLoadTime = 1000;

  //   function hideLoader() {
  //     const endTime = new Date().getTime();
  //     const waitTime = minLoadTime - (endTime - startTime);

  //     setTimeout(() => {
  //       setLoading(false);
  //     }, waitTime);
  //   }

  //   const [
  //     vault,
  //     active,
  //   ] = await Promise.all([
  //     axios.get(
  //       APP_URL + "/users/acent/vault/balance",
  //       {
  //         headers: {
  //           'session-id': sessionID,
  //         },
  //       },
  //     ).catch(error => error),
  //     axios.get(
  //       APP_URL + "/users/acent/active/balance",
  //       {
  //         headers: {
  //           'session-id': sessionID,
  //         },
  //       },
  //     ).catch(error => error),
  //   ]);

  //   if ((vault && vault.status === 200 && vault.data) ||
  //     (active && active.status === 200 && active.data)) {
  //     setData({
  //       ...data,
  //       mainBal: vault && vault.data ? vault.data.balance.toFixed(computeDecimals) : defaultFloatVal,
  //       pocketBal: active && active.data ? active.data.balance.toFixed(computeDecimals) : defaultFloatVal,
  //     });
  //   }

  //   hideLoader();
  // }, []);
  useEffect(() => {
    fetchActivePledgeSeason();
    fetchPledgeHistory();
    fetchParticipation(0);
    fetchPledgeBalance();
    fetchtotalEDAOEarned();
    fetchGovernancePledgeHistory(0);
    fetchWithdrawaLimit();
    convertCurrency();
    fetchWithdrawSettings();
  }, []);

  useEffect(() => {
    fetchUserActivePledgeSeasonAmount();
  }, [activeSeasonId]);

  const fetchActivePledgeSeason = async () => {
    axios
      .get(Config.DAPPX_BASE_URL + "/users/acent/currentPledgeSeason")
      .then((res) => {
        const active_season = res.data.season.map(({ _id }) => _id).join(",");
        const result = res.data.season;
        setActiveSeasonId(active_season);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchPledgeHistory = async () => {
    axios
      .get(Config.DAPPX_BASE_URL + "/users/acent/pledgedAcent", {
        headers: {
          "session-id": sessionID,
        },
      })
      .then((res) => {
        const result = res.data.pledge_amount;
        setPledgeHistory(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchParticipation = async (page) => {
    axios
      .get(
        Config.DAPPX_BASE_URL +
        "/users/acent/pledgeHistory?offset=" +
        page * limit +
        "&limit=" +
        limit,
        {
          headers: {
            "session-id": sessionID,
          },
        }
      )
      .then((res) => {
        let check = parseInt(res.data.total_rows) % limit;
        let nc = parseInt(res.data.total_rows / limit);
        if (nc <= 0) {
          setPCount(1);
        } else if (check !== 0) {
          setPCount(nc + 1);
        } else {
          setPCount(nc);
        }
        setParticipationHistory(res.data.pledge_history);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchUserActivePledgeSeasonAmount = async () => {
    axios
      .get(
        Config.DAPPX_BASE_URL +
        "/users/acent/pledgedAcentPerSeason?season_ids=" +
        activeSeasonId,
        {
          headers: {
            "session-id": sessionID,
          },
        }
      )

      .then((res) => {
        const result = res.data.pledge_amount;
        setActiveSeasonPledgedAmount(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchPledgeBalance = async () => {
    await axios
      .get(Config.DAPPX_BASE_URL + "/users/acent/governance-pledging/balance", {
        headers: { "session-id": sessionID },
      })
      .then((result) => {
        setPledgedBalance(result.data.balance);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchtotalEDAOEarned = async () => {
    await axios
      .get(
        Config.DAPPX_BASE_URL + "/users/acent/governance-pledging/edao-earned",
        { headers: { "session-id": sessionID } }
      )
      .then((result) => {
        // let balance_wei = Web3.utils.fromWei(result.data.dappx_balance.toString(), 'ether');
        // let num2 = Number(balance_wei.toString().match(/^\d+(?:\.\d{0,6})?/));
        // let hasNoDecimals = balance_wei.split('.')[1]
        // hasNoDecimals == 0 || hasNoDecimals === undefined ? setPledgedBalance(Number(Math.trunc(num2))) : setPledgedBalance(Number(num2).toFixed(6))
        try {
          if (result.data.edao_earned) {
            setEDAO(result.data.edao_earned);
          }
        } catch (e) {
          setEDAO(0);

          console.log(e);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchGovernancePledgeHistory = async (page) => {
    await axios
      .get(
        Config.DAPPX_BASE_URL +
        "/users/acent/governance-pledging/history?offset=" +
        page * limit +
        "&limit=" +
        limit,
        { headers: { "session-id": sessionID } }
      )
      .then((result) => {
        let check = parseInt(result.data.total_rows) % limit;
        let nc = parseInt(result.data.total_rows / limit);
        if (nc <= 0) {
          setCount(1);
        } else if (check !== 0) {
          setCount(nc + 1);
        } else {
          setCount(nc);
        }
        // let balance_wei = Web3.utils.fromWei(result.data.dappx_balance.toString(), 'ether');
        // let num2 = Number(balance_wei.toString().match(/^\d+(?:\.\d{0,6})?/));
        // let hasNoDecimals = balance_wei.split('.')[1]
        // hasNoDecimals == 0 || hasNoDecimals === undefined ? setPledgedBalance(Number(Math.trunc(num2))) : setPledgedBalance(Number(num2).toFixed(6))
        setGovernancePledgeHistory(result.data.transactions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchWithdrawaLimit = async () => {
    // const tokenType = isNative ? 'native' : 'token'
    await axios
      .get(Config.DAPPX_BASE_URL + `/users/acent/withdraw/limit-status`, {
        headers: { "session-id": sessionID },
      })
      .then((res) => {
        if (
          res.data.remaining_daily_tx_amount >
          res.data.remaining_user_daily_tx_amount
        ) {
          setWithdrawLimit(res.data.remaining_user_daily_tx_amount);
        } else {
          setWithdrawLimit(res.data.remaining_daily_tx_amount);
        }
      });
  };

  const fetchWithdrawSettings = async () => {
    // const tokenType = isNative ? 'native' : 'token'
    await axios
      .get(Config.DAPPX_BASE_URL + `/users/acent/withdraw/settings/`, {
        headers: { "session-id": sessionID },
      })
      .then((res) => {
        setCoreFee(res.data.withdrawal_fee_core);
        setUbiFee(res.data.withdrawal_fee_ubi);
        setNitroFee(res.data.withdrawal_fee_nitro);
        setWithdrawalOn(res.data.allow_withdrawal);
      });
  };

  const paginationChange = (event, value) => {
    if (value !== offset) {
      setOffset(value);
      let page = value - 1;
      fetchGovernancePledgeHistory(page);
    }
  };

  const participationPaginationChange = (event, value) => {
    if (value !== pOffset) {
      setPOffset(value);
      let page = value - 1;
      fetchParticipation(page);
    }
  };
  useEffect(async () => {
    if (reloadData) {
      const [vault, active] = await Promise.all([
        axios
          .get(APP_URL + "/users/acent/vault/balance", {
            headers: {
              "session-id": sessionID,
            },
          })
          .catch((error) => error),
        axios
          .get(APP_URL + "/users/acent/active/balance", {
            headers: {
              "session-id": sessionID,
            },
          })
          .catch((error) => error),
      ]);

      if (
        (vault && vault.status === 200 && vault.data) ||
        (active && active.status === 200 && active.data)
      ) {
        const mainBal =
          vault && vault.data ? vault.data.balance : defaultFloatVal;
        const pocketBal =
          active && active.data ? active.data.balance : defaultFloatVal;
        const total = (parseFloat(mainBal) + parseFloat(pocketBal)).toFixed(
          computeDecimals
        );
        const whole_num = total.split(".")[0];
        const decimal_num = total.split(".")[1].substr(0, displayDecimals);
        const balance = whole_num + "." + decimal_num;

        setData({
          ...data,
          mainBal:
            vault && vault.data
              ? vault.data.balance.toFixed(computeDecimals)
              : defaultFloatVal,
          pocketBal:
            active && active.data
              ? active.data.balance.toFixed(computeDecimals)
              : defaultFloatVal,
        });

        const convertVaultFiat = await convertValue(mainBal, "ACE");
        setVaultFiat(convertVaultFiat);

        const fiat_value = await convertValue(balance, "ACE");
        set_digital_ace({
          ...digital_ace,
          coin_value: balance,
          fiat_value: fiat_value,
        });
      }

      setReloadData(false);
    }
  }, [reloadData]);

  const formatIntegers = (val) => {
    return new Intl.NumberFormat().format(val);
  };

  const pledgingGuideClick = () => {
    const url =
      "https://doc.yourearth.io/get-your-acent-ready-your-earth-dao-pledging-is-here/";
    setModalOpen(false);
    window.open(url, "_blank").focus();
  };

  const builtInTheme = useTheme();
  const isXS = useMediaQuery(builtInTheme.breakpoints.down("xs"));
  const isSM = useMediaQuery(builtInTheme.breakpoints.down("sm"));
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <h6>completed</h6>;
    } else {
      // Render a countdown
      return (
        <span>
          {zeroPad(days)}d {zeroPad(hours)}h {zeroPad(minutes)}m{" "}
          {zeroPad(seconds)}s
        </span>
      );
    }
  };
  const depositComponent = ({ onClick, disabled }) => (
    <Button
      variant="outlined"
      color="primary"
      fullWidth
      className={classes.btn}
      onClick={onClick}
      disabled={disabled}
    >
      Add
    </Button>
  );

  const getWholeNumber = (value) => {
    return value
      ? value.split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : "";
  };
  const getDecimalNumber = (value) => {
    return value ? value.split(".")[1].substring(0, 6) : "";
  };

  const convertCurrency = async () => {
    await axios
      .get(DAPPX_BASE_URL + "/users/getMarketPrices")
      .then((res) => {
        setUsdValue(res.data.ACE.USD);
      })
      .catch((error) => {
        console.log("ERROR", error);
        // set_input_fiat_val(0)
        // setError("Can't pull conversion rate.")
      });
  };

  const onCloseSuccessModal = () => {
    setSuccessModal(false);
    setWithdrawModal(false);
  };

  const withdrawButton = () => {
    if (withdrawalOn) {
      setWithdrawModal(true);
    } else {
      setWithdrawalMatintenance(true);
    }
  };

  const transactionSuccess = () => {
    setSuccessModal(true);
  };
  let acTotal = Number(mainBal) + Number(pocketBal);
  acTotal = String(acTotal);
  console.log(acTotal, typeof mainBal, typeof pocketBal);

  const [isVerified, setIsVerified] = useState(false);

  const isUserVerified = async () => {
    // console.log("check if data is verified");
    await axios
      .get(Config.DAPPX_BASE_URL + "/users/userProfile", {
        headers: {
          "session-id": sessionID,
        },
      })
      .then((res) => {
        setIsVerified(res.data.is_verified);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    isUserVerified();
  }, []);

  return (


    <div className=" user-sidebar-wrapper user-sidebar-wrapper-v2">

      <div className="content-wrapper-v2">
        <div className="content marginbot">
          <div
            className="section s1"
            style={{ display: "flex", borderBottom: "1px solid #dedede" }}
          >
            <div
              className="img-container"
              style={{ display: "flex", margin: "15px", alignItems: "center" }}
            >
              <img
                src={DP_Placeholder}
                alt="place your cool face here"
                style={{ height: 60, width: 60, margin: 10 }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  style={{ fontSize: "1.5rem", color: "#21212", fontWeight: 700 }}
                >
                  {user_username ? user_username : "Unnamed"}
                </p>
                <a href="/user-profile" style={{ textDecoration: "underline" }}>
                  My Digital Assets
                </a>
              </div>
            </div>
          </div>

          {/* {
        loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '300px' }}>
            <img src={Loader} className="loader" />
          </div>
        ) : (
          <> */}
          <div className={classes.root} id="ace-wrapper">
            <Typography
              variant="h5"
              gutterBottom
              className={classes.balanceLabel}
            >
              Current ACE Balance
            </Typography>
            <Grid container spacing={isXS ? 1 : 2}>
              <Grid item xs={12}>
                <div className="balance-transaction-history">
                  {acTotal && (
                    <div className="amount">
                      <Ticker textClassName="whole-num-large">
                        {formatIntegers(acTotal.split(".")[0])}
                      </Ticker>
                      <Ticker textClassName={`decimal-num-large `}>
                        {acTotal?.split(".")[1] == 0
                          ? ""
                          : `${acTotal?.split(".")[1]
                            ? "." + acTotal?.split(".")[1]
                            : ""
                          }`}
                      </Ticker>
                    </div>
                  )}

                  <TransactionHistoryDrawer type="ace" />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="token-details">
                  <div className="details-text-container">
                    <Typography
                      variant="h6"
                      className={classes.detailsTitle}
                      gutterBottom
                    >
                      Acent Vault
                    </Typography>
                    <div className="amount">
                      <Ticker textClassName="whole-num">
                        {formatIntegers(mainBal.split(".")[0])}
                      </Ticker>
                      <Ticker
                        textClassName={`decimal-num ${mainBal.split(".")[1].substr(0, displayDecimals) == 0 &&
                          "hidden"
                          }`}
                      >
                        <span className={classes.dot}></span>
                        {`.${mainBal.split(".")[1].substr(0, displayDecimals)}`}
                      </Ticker>
                    </div>
                  </div>
                  <VaultIcon className="details-icon" />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="token-details">
                  <div className="details-text-container">
                    <Typography
                      variant="h6"
                      className={classes.detailsTitle}
                      gutterBottom
                    >
                      Active Acent
                    </Typography>
                    <div className="amount">
                      <Ticker textClassName="whole-num">
                        {formatIntegers(pocketBal.split(".")[0])}
                      </Ticker>
                      <Ticker
                        textClassName={`decimal-num ${pocketBal.split(".")[1].substr(0, displayDecimals) ==
                          0 && "hidden"
                          }`}
                      >
                        <span className={classes.dot}></span>
                        {`.${pocketBal.split(".")[1].substr(0, displayDecimals)}`}
                      </Ticker>
                    </div>
                  </div>
                  <AcentActiveIcon className="details-icon" />
                </div>
              </Grid>
              {/*{*/}
              {/*  !isXS && <Grid item sm={3}></Grid>*/}
              {/*}*/}
              {/*Buy Button*/}
              <div className="buttons-block current-balance-btns btns-container">
                <Grid item xs={3} sm={3}>
                  <MuiThemeProvider theme={theme}>
                    <OverlayTrigger
                      trigger={["click"]}
                      rootClose
                      placement="bottom"
                      overlay={popoverAcent}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        className={classes.btn}
                      >
                        Buy
                      </Button>
                    </OverlayTrigger>
                  </MuiThemeProvider>
                </Grid>
                {/*End Buy Button*/}
                <Grid item xs={3} sm={3}>
                  <Deposit
                    tokenType="ACE"
                    balance={user_ace}
                    digital_balance={digital_ace}
                    notDisabled={true}
                    BtnComponent={depositComponent}
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <AceButtonPledge check={true} />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <GovernanceUnPledge check={true} />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <MuiThemeProvider theme={theme}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={handleOpen}
                      className={classes.btn}
                    >
                      Transfer
                    </Button>
                  </MuiThemeProvider>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <MuiThemeProvider theme={theme}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={withdrawButton}
                      className={classes.btn}
                      disabled={!isVerified}
                    >
                      Withdraw
                    </Button>
                  </MuiThemeProvider>
                </Grid>
              </div>
            </Grid>
          </div>
        </div>
        {/*Governance Pledge*/}
        <div className="content marginbot">
          <div className={classes.root} id="ace-wrapper">
            <Typography
              variant="h5"
              gutterBottom
              className={classes.balanceLabel}
            >
              Governance Stake
            </Typography>
            <Grid container spacing={isXS ? 1 : 2}>
              <Grid item xs={6}>
                <div className="token-details">
                  <div className="details-text-container">
                    <Typography
                      variant="h6"
                      className={classes.detailsTitle}
                      gutterBottom
                    >
                      Stake
                      <img src={acent_logo} alt={"logo"} />
                    </Typography>
                    <div className="amount">
                      <Ticker textClassName="whole-num">
                        {/*{formatIntegers(activeSeasonPledgedAmount.split('.')[0])}*/}
                        {pledgedBalance.toFixed(6).replace(/\.?0+$/, "")}
                      </Ticker>
                      {/*<span className="convertion">(≈ 100.22 USD)</span>*/}
                      {/*<Ticker textClassName={`decimal-num ${(activeSeasonPledgedAmount.split('.')[1]).substr(0, displayDecimals) == 0 && 'hidden'}`}>*/}
                      {/*  <span className={classes.dot}></span>{`.${(activeSeasonPledgedAmount.split('.')[1]).substr(0, displayDecimals)}`}*/}
                      {/*</Ticker>*/}
                    </div>
                  </div>
                  {/*<TodoIcon className="details-icon" />*/}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="token-details">
                  <div className="details-text-container">
                    <Typography
                      variant="h6"
                      className={classes.detailsTitle}
                      gutterBottom
                    >
                      Total EDAO Earned
                      <img src={edao_logo} alt={"logo"} />
                    </Typography>

                    <div className="amount">
                      <Ticker textClassName="whole-num">
                        {edao}
                        {/*{formatIntegers(pocketBal.split('.')[0])}*/}
                      </Ticker>
                      {/*<Ticker textClassName={`decimal-num ${(pocketBal.split('.')[1]).substr(0, displayDecimals) == 0 && 'hidden'}`}>*/}
                      {/*  <span className={classes.dot}></span>{`.${(pocketBal.split('.')[1]).substr(0, displayDecimals)}`}*/}
                      {/*</Ticker>*/}
                    </div>
                  </div>
                  {/*<HistoryIcon className="details-icon" />*/}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.root} id="ace-wrapper">
            <Typography
              variant="h5"
              gutterBottom
              className={classes.balanceLabel}
            >
              History
            </Typography>
            <table className="history-table">
              <thead>
                <tr>
                  <th>Activity</th>
                  <th>Date/Time </th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {governancePledgeHistory.map((row) => {
                  let date = new Date(row.end_date);
                  date.setDate(date.getDate() + row.duration);

                  return (
                    <tr>
                      <td>
                        {row.type == "pledge" || "stake" ? "Stake" : "Unstake"}
                      </td>
                      <td>
                        {format(new Date(row.start), "yyyy/MMM/dd - kk:mm:ss")}
                      </td>

                      <td>
                        {row.amount}
                        <img
                          style={{ width: "22px", paddingBottom: "8px" }}
                          src={acent_logo}
                          alt={"logo"}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <Pagination
              className={"pagination"}
              siblingCount={1}
              boundaryCount={1}
              onChange={paginationChange}
              count={count}
              page={offset}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
        <div className="content ">
          <div className={classes.root} id="ace-wrapper">
            <Typography
              variant="h5"
              gutterBottom
              className={classes.balanceLabel}
            >
              Stake
            </Typography>
            <Grid container spacing={isXS ? 1 : 2}>
              <Grid item xs={6}>
                <div className="token-details">
                  <div className="details-text-container">
                    <Typography
                      variant="h6"
                      className={classes.detailsTitle}
                      gutterBottom
                    >
                      Current Season
                    </Typography>
                    <div className="amount">
                      <Ticker textClassName="whole-num">
                        {/*{formatIntegers(activeSeasonPledgedAmount.split('.')[0])}*/}
                        {activeSeasonPledgedAmount
                          .toFixed(6)
                          .replace(/\.?0+$/, "")}
                      </Ticker>
                      {/*<Ticker textClassName={`decimal-num ${(activeSeasonPledgedAmount.split('.')[1]).substr(0, displayDecimals) == 0 && 'hidden'}`}>*/}
                      {/*  <span className={classes.dot}></span>{`.${(activeSeasonPledgedAmount.split('.')[1]).substr(0, displayDecimals)}`}*/}
                      {/*</Ticker>*/}
                    </div>
                  </div>
                  <TodoIcon className="details-icon" />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="token-details">
                  <div className="details-text-container">
                    <Typography
                      variant="h6"
                      className={classes.detailsTitle}
                      gutterBottom
                    >
                      Stake History
                    </Typography>
                    <div className="amount">
                      <Ticker textClassName="whole-num">
                        {pledgeHistory}
                        {/*{formatIntegers(pocketBal.split('.')[0])}*/}
                      </Ticker>
                      {/*<Ticker textClassName={`decimal-num ${(pocketBal.split('.')[1]).substr(0, displayDecimals) == 0 && 'hidden'}`}>*/}
                      {/*  <span className={classes.dot}></span>{`.${(pocketBal.split('.')[1]).substr(0, displayDecimals)}`}*/}
                      {/*</Ticker>*/}
                    </div>
                  </div>
                  <HistoryIcon className="details-icon" />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.root} id="ace-wrapper">
            <Typography
              variant="h5"
              gutterBottom
              className={classes.balanceLabel}
            >
              Participation
            </Typography>
            <table className="participation-table">
              <thead>
                <tr>
                  <th>Season Name</th>
                  <th>Stake </th>
                  <th>Remaining Time</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {participationHistory.map((row) => {
                  let date = new Date(row.end_date);
                  date.setDate(date.getDate() + row.duration);

                  return (
                    <tr>
                      <td>{row.name}</td>
                      <td>
                        {row.pledge_amount}
                        <img
                          style={{ width: "22px", paddingBottom: "8px" }}
                          src={acent_logo}
                          alt={"logo"}
                        />
                      </td>
                      {row.status === "ongoing" ? (
                        <td>
                          <Countdown date={row.end_date} renderer={renderer} />
                        </td>
                      ) : row.status === "collection-done" ? (
                        <td>
                          <Countdown date={date} renderer={renderer} />
                        </td>
                      ) : (
                        <td style={{ color: "#016ce9" }}>Completed</td>
                      )}

                      <td>
                        <Button
                          variant="outlined"
                          color="primary"
                          fullWidth
                          disabled
                          className={classes.btn}
                        >
                          {row.status === "ongoing"
                            ? "Staking"
                            : row.status === "collection-done"
                              ? "Locked"
                              : "Unstaked"}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              className={"pagination"}
              siblingCount={1}
              boundaryCount={1}
              onChange={participationPaginationChange}
              count={pCount}
              page={pOffset}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
        <Transfer
          token="acent"
          open={open}
          handleClose={handleClose}
          data={data}
          decimals={displayDecimals}
        />
        {/*popup to work today
      Hello FunkyPapa129!

      Welcome to your Acent page.
      This page will help you monitor your Acent within
      DAPPX ecosystem including your Acent pledging.

      Click the Pledging Guide button to know more about Acent pledging.
      */}
        <AceDialog
          open={false}
          className="pledge-dialog success-dialog ace-dialog"
          onClose={() => setModalOpen(false)}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          body={
            <>
              <>
                <p>Hello {user_username ? user_username : "Unnamed"}!</p>
                <div className="contt-mid">
                  <p>Welcome to your Acent page.</p>
                  <p>
                    This page will help you monitor your Acent within DAPPX
                    ecosystem including your Acent staking.
                  </p>
                </div>
                <p>
                  Click the Staking Guide button to know more about Acent staking.
                </p>
                <div className="blkbtn">
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    type="submit"
                    onClick={pledgingGuideClick}
                  >
                    Staking Guide
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => setModalOpen(false)}
                    type="submit"
                  >
                    Got It!
                  </Button>
                </div>
              </>
            </>
          }
          footer={
            <>
              <div className="foot-main">
                <div>
                  <img src={dappxlogo} />
                </div>
                <div>
                  <img src={earthdaologo} />
                </div>
              </div>
            </>
          }
        />
        <Withdraw
          checkOpen={withdrawModal}
          vaultBalance={data.mainBal}
          withdrawLimit={withdrawLimit}
          nitroFee={nitroFee}
          ubiFee={ubiFee}
          coreFee={coreFee}
          usdValue={usdValue}
          vaultFiat={vaultFiat}
          onClose={() => setWithdrawModal(false)}
          onSuccess={transactionSuccess}
          refetch={fetchWithdrawSettings}
          isVerified={isVerified}
        />
        <CustomDialog
          open={policyModal}
          header={<p>Policy Update!</p>}
          body={
            <div className="mobile-modal-conatainer">
              <img src={infoIcon} alt="" className="mobile-modal-logo" />
              <p className="policy-modal-body">
                Daily withdraw policy was updated.
                <br />
                Kindly check and process your withdrawal again.
              </p>
            </div>
          }
          footer={
            <div className="policy-button-container">
              <button
                className="policy-button"
                onClick={() => setPolicyModal(false)}
              >
                Ok
              </button>
            </div>
          }
        />
        <CustomDialog
          open={withdrawalMaitenance}
          header={<p>Service Maintenance</p>}
          body={
            <div className="mobile-modal-conatainer">
              <img src={infoIcon} alt="" className="mobile-modal-logo" />
              <p className="policy-modal-body" style={{ marginRight: "-23%" }}>
                Service is not available at the moment.
                <br />
                Please try again later. Thank you.
              </p>
            </div>
          }
          footer={
            <div className="policy-button-container">
              <button
                className="policy-button"
                onClick={() => setWithdrawalMatintenance(false)}
              >
                Ok
              </button>
            </div>
          }
        />
        <CustomDialog
          open={successModal}
          className="deposit-dialog loading-dialog"
          onClose={onCloseSuccessModal}
          header="Your withdraw is on it's way!"
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          body={
            <>
              <SuccessIcon className="icon" />
              <p>
                Please confirm the deposit action on your wallet to submit the
                request to the network.
              </p>

              <p>
                Updated status will be reflected on your wallet transaction
                history.
              </p>

              <p>
                For successful transaction, reflecting the balance on your
                dAppstore Balances may take longer than usual.
              </p>
            </>
          }
          footer={
            <>
              <div className="button-block">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={onCloseSuccessModal}
                  type="submit"
                >
                  OK
                </Button>
              </div>
            </>
          }
        />
      </div>

      <SideBarData />
    </div>

  );
};

export default Acent;
