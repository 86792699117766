import React, {useState, useEffect} from 'react';
import { Typography, Grid, Box, ButtonBase } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import HoverVideoPlayer from 'react-hover-video-player';
import image1 from '../../assets/images/section-recomended/image-1.jpg';
import image2 from '../../assets/images/section-recomended/image-2.jpg';
import image3 from '../../assets/images/section-recomended/image-3.jpg';
import image4 from '../../assets/images/section-recomended/pancake.jpg';
import image5 from '../../assets/images/section-recomended/open-ocean.jpg';
import image6 from '../../assets/images/section-recomended/venus.jpg';
import gods_unchained from '../../assets/videos/gods-unchained.mp4';
import sandbox from '../../assets/videos/sandbox.mp4';
import uniswap from '../../assets/images/section-recomended/uniswap.png';
import Highlighter from "react-highlight-words";
import { useWindowSize } from '../../constant/useWindowSize';
import { useTranslation } from 'react-i18next';

const SectionRecommended = ({ searchState }) => {
  const [mobileView, setMobileView] = useState(false);
  const [xs_view, set_xs_view] = useState(false);
  const {t} = useTranslation()

  const [ window_width ] = useWindowSize();
  useEffect(() => {
    if(window_width < 960) setMobileView(true)
    else setMobileView(false)

    if(window_width < 600) set_xs_view(true)
    else set_xs_view(false)
  }, [window_width]);


  const data = [
    {
      _id: uuidv4(),
      image: isMobile || mobileView ? uniswap : image1,
      name: isMobile || mobileView ? 'Uniswap v3' : 'Maker DAO',
      href: isMobile || mobileView ? 'https://app.uniswap.org/#/swap' : 'https://makerdao.com/en/',
      currency: 'ETH',
    },
    {
      _id: uuidv4(),
      image: image2,
      name: 'GODS Unchained',
      href: 'https://godsunchained.com/',
      currency: 'ETH',
      videoOnHover: gods_unchained
    },
    {
      _id: uuidv4(),
      image: image3,
      name: 'THE SANDBOX',
      href: 'https://www.sandbox.game/login/?r=P~.UQTzO_nF.teQMC2owU',
      currency: 'ETH',
      videoOnHover: sandbox,
    },
    {
      _id: uuidv4(),
      image: image4,
      name: 'PancakeSwap',
      href: 'https://pancakeswap.finance/',
      currency: 'BSC',
    },
    {
      _id: uuidv4(),
      image: image5,
      name: 'OpenOcean',
      href: 'https://openocean.finance/',
      currency: 'BSC',
    },
    {
      _id: uuidv4(),
      image: image6,
      name: 'Venus',
      href: 'https://app.venus.io/dashboard',
      currency: 'BSC',
    },
  ]
  return (
    <div className="section section-recommended">
      <Typography variant="h2" className="section-title">
        <Highlighter
          searchWords={[searchState]}
          textToHighlight={t('RECOMMENDED')}
        />
      </Typography>
      <Grid container spacing={xs_view ? 1 : 3} >
        {data && data.map((val) => {
          return (
            <Grid item xs={4} md={2} lg={2} xl={2} key={val._id} >
              <Box className="box">
                <ButtonBase href={val.href} target="_blank" classNamPsee="w-100">
                  <div className="img-container">
                    <img className="img-fluid w-100" src={val.image} alt={val.name} />
                    {(val.videoOnHover && !mobileView) &&
                      <div className="hover-video-container">
                        <HoverVideoPlayer
                          className="hover-video-player"
                          videoSrc={val.videoOnHover}
                          pausedOverlay={
                            <img className="img-fluid w-100" src={val.image} alt={val.name} />
                          }
                          loadingOverlay={
                            <div className="loading-spinner-overlay" />
                          }
                        />
                      </div>
                    }
                  </div>
                  <div className="details">
                    <h4 id={`#${val._id}`}>
                      <Highlighter
                        searchWords={[searchState]}
                        textToHighlight={val.name}
                      />
                    </h4>
                    <p className="currency">
                      <Highlighter
                        searchWords={[searchState]}
                        textToHighlight={val.currency}
                      />
                    </p>
                  </div>
                </ButtonBase>
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </div>
  );
}

export default SectionRecommended;