import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Link,
  Box,
  CircularProgress,
} from "@material-ui/core";
// import { ReactComponent as LogoWhite } from "../../assets/images/dappx-logo-alpha-footer-v2.svg";
import  LogoWhite  from "../../assets/images/dappx-logo-alpha-footer-v2.png";
import telegram01 from "../../assets/images/icons/SL-telegram-1.png";
import telegram02 from "../../assets/images/icons/SL-telegram-2.png";
import facebook from "../../assets/images/icons/SL-facebook.png";
import twitter from "../../assets/images/icons/SL-twitter.png";
import instagram from "../../assets/images/icons/SL-instagram.png";
import medium from "../../assets/images/icons/SL-medium.png";
import youtube from "../../assets/images/icons/youtube.png";
import "./Footer.scss";
import axios from "axios";

import { Config } from "../../constant/config";

const Footer = () => {
  const url =
    "https://dappstore.us7.list-manage.com/subscribe/post?u=acae6db1116d0268d9dfa5da6&amp;id=3b0a7cc76b";

  const reloadPage = () => {
    window.scrollTo(0, 0);
  };
  const location = useLocation();
  const removeFooterLinks = [
    "/user-profile",
    "/user-profile/dappx",
    "/user-profile/zera",
    "/user-profile/edao",
    "/user-profile/dappx-paypal",
  ];

  const isRenderFooter = !removeFooterLinks.includes(location.pathname);
  console.log(location);
  return (
    <>
      {isRenderFooter && (
        <div className="footer-area">
          <Container>
            <div className="row">
              <Box className="col logo-block">
                <RouterLink
                  to="/"
                  className="logo"
                  onClick={() => reloadPage()}
                >
                  <img src={'https://dappstore.me/intro/wp-content/uploads/2022/10/Group-1908-2.png'} alt="logo" />
                  {/* <LogoWhite /> */}
                </RouterLink>
                <ul className="website-list">
                <li>
                    <span>Introduction: </span>
                    <Link href="https://dappstore.me/intro">
                      dappstore.me/intro
                    </Link>
                  </li>
                  <li>
                    <span>Playsite: </span>
                    <Link href="https://dappstore.me">dappstore.me</Link>
                  </li>
                
                  {/* <li>s */}
                  <li className="copyright">
                    &copy; 2024 DAPPX. All rights reserved.
                  </li>
                </ul>
              </Box>
              <Box className="col contact-block">
                <h4 className="title">Contact Info</h4>
                <ul className="contact-list">
                  <li>
                    <span>Info: </span>
                    <Link
                      href="mailto:info@dappstore.me"
                      target="_blank"
                      rel="noopener"
                    >
                      info@dappstore.me
                    </Link>
                  </li>
                  <li>
                    <span>Business/Partnership: </span>
                    <Link
                      href="mailto:biz@dappstore.me"
                      target="_blank"
                      rel="noopener"
                    >
                      biz@dappstore.me
                    </Link>
                  </li>
                  {/* <li>
								<span>Advertisement: </span>
								<Link href="mailto:ads@dappstore.me" target="_blank" rel="noopener">
									ads@dappstore.me
								</Link>
							</li> */}
                  <li>
                    <span>Join Our Journey: </span>
                    <Link
                      href="mailto:career@dappstore.me"
                      target="_blank"
                      rel="noopener"
                    >
                      career@dappstore.me
                    </Link>
                  </li>
                  <li className="sp-mode">
                    <ul className="website-list">
                    <li>
                        <span>Introduction: </span>
                        <Link href="https://dappstore.me/intro">
                          dappstore.me/intro
                        </Link>
                      </li>
                      <li>
                        <span>Playsite: </span>
                        <Link href="https://dappstore.me">dappstore.me</Link>
                      </li>
                    
                      {/* <li>
                        <span>Acent Browser Download: </span>
                        <Link href="https://acent.tech/">www.acent.tech/</Link>
                      </li> */}
                    </ul>
                  </li>
                </ul>
              </Box>
              <Box className="col subscribe-block">
                <h4 className="title">Subscribe to our newsletter</h4>
                <p className="desc">
                  Subscribe to our newsletter and stay updated on the top
                  performing dapps and also upcoming and promising dapps.
                </p>

                {/* <MailchimpSubscribe
                  url={url}
                  render={({ subscribe, status, message }) => (
                    <CustomForm
                      status={status}
                      message={message}
                      onValidated={(formData) => subscribe(formData)}
                    />
                  )}
                /> */}
                <CustomForm
                // status={status}
                // message={message}
                // onValidated={(formData) => subscribe(formData)}
                />

                <ul className="social-links">
                  <li>
                    <Link href="https://t.me/dAppstoreOfficial" target="_blank">
                      <img src={telegram01} alt="telegram 01" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://t.me/dAppstoreOfficialChat"
                      target="_blank"
                    >
                      <img src={telegram02} alt="telegram 02" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://www.facebook.com/dAppstoreUniverse"
                      target="_blank"
                    >
                      <img src={facebook} alt="facebook" />
                    </Link>
                  </li>
                  <li>
                    <Link href="https://twitter.com/d_appstore" target="_blank">
                      <img src={twitter} alt="twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://www.instagram.com/dappstore_official"
                      target="_blank"
                    >
                      <img src={instagram} alt="instagram" />
                    </Link>
                  </li>
                  {/* <li>
                    <Link href="https://medium.com/dappstore" target="_blank">
                      <img src={medium} alt="medium" />
                    </Link>
                  </li> */}
                  <li>
                    <Link href="https://www.youtube.com/channel/UCiUTNVa9IXRy7j5cIi-QYxA" target="_blank">
                      <img src={youtube} alt="youtube" style={{height:"34px"}} />
                    </Link>
                  </li>
                  {/* <li>
                    <Link href="https://www.youtube.com/channel/UCiUTNVa9IXRy7j5cIi-QYxA" target="_blank">
                      <div style={{ borderRadius: "8px", height: "34px", width:"34px",backgroundColor:"#fff",padding:"7.2px 7px" }} >
                        <img src={youtube} alt="medium" />
                      </div>
                    </Link>
                  </li> */}
                </ul>
              </Box>
            </div>

            <Box className="sp-mode">
              <p className="copyright">
                &copy; 2022 DAPPX. All rights reserved.
              </p>
            </Box>
          </Container>
        </div>
      )}
    </>
  );
};

const CustomForm = () => {
  const validity = {
    success: false,
    error: false,
    label: "",
    helperText: "",
  };

  const [successInput, setSuccessInput] = useState("");
  const [errorInput, setErrorInput] = useState("");
  const [status, setStatus] = useState(null);
  const [fieldVal, setFieldVal] = useState("");
  const [validate, setValidate] = useState(validity);

  useEffect(() => {
    if (status === "error" && errorInput === fieldVal) {
      setValidate({
        ...validate,
        error: true,
        success: false,
        label: "Error",
        helperText: "Given email address is already subscribed, thank you!",
      });
    }
    if (status === "success" && successInput === fieldVal) {
      setValidate({
        ...validate,
        success: true,
        error: false,
        label: "Success",
        helperText:
          "Thank you, your sign-up request was successful! Please check your email inbox to confirm.",
      });
    }
    if (fieldVal === "") {
      setValidate(validity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldVal, status, errorInput, successInput]);

  const sessionID = localStorage.getItem("session-id");

  const onChange = (e) => {
    let value = e.target.value;
    setFieldVal(value);
  };

  const onKeyPress = () => {
    setValidate(validity);
  };

  const onSubmit = () => {
    if (/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(fieldVal)) {
      setSuccessInput(fieldVal);
      setErrorInput(fieldVal);
      submit();
    } else {
      setErrorInput("");
      setValidate({
        ...validate,
        error: true,
        label: "Error",
        helperText: "Please enter a valid email address",
      });
    }
  };

  let email;
  const submit = () => {
    setStatus("sending");
    let emailText = email.value;
    console.log(`registering`, Config.DAPPX_BASE_URL + "/newsletter/submit");

    axios
      .post(
        Config.DAPPX_BASE_URL + "/newsletter/submit",
        { email: emailText },
      )
      .then((res) => {
        // setIsVerified(res.data.is_verified);
        // setIsLoadingUserData(false);

        setStatus("success");
        console.log(`responsing`);
      })
      .catch((err) => {
        // console.log(err);
        console.log("encountering", err.message);
        setStatus("error");
      });
  };

  return (
    <div className="subscribe-form">
      <TextField
        id="subscribe-field"
        className={`${validate.success ? "successRoot" : ""}`}
        variant="outlined"
        error={validate.error}
        helperText={validate.helperText}
        // label={validate.label}
        onChange={(e) => onChange(e)}
        value={fieldVal}
        onKeyPress={onKeyPress}
        inputRef={(node) => (email = node)}
        inputProps={{
          type: "email",
        }}
      />
      <div className="btn-wrapper">
        <Button
          variant="contained"
          type="submit"
          disabled={status === "sending" ? true : false}
          onClick={() => onSubmit()}
        >
          Subscribe
        </Button>
        {status === "sending" ? (
          <CircularProgress className="loader" size={20} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Footer;
