import React from 'react';
import { Modal, Backdrop, Zoom, Button } from '@material-ui/core';
import { ReactComponent as Error } from '../../assets/images/icons/alert-warning.svg'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import './ModalDappxPR.scss'

const ModalErrorPopup = ({
  open,
  onClose,
  modal_icon,
  modal_title,
  modal_text,
  modal_btn_label,
  className
}) => {

  return (
    <div>
      <Modal
        aria-labelledby={`${modal_icon === 'info' ? 'info' : 'error' }-connect-title`}
        aria-describedby={`${modal_icon === 'info' ? 'info' : 'error' }-connect-desc`}
        className={`${modal_icon === 'info' ? 'info' : 'error' }-connect-modal custom-scroll dappxPR-modal ${className ? className : ''}`}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Zoom in={open}>
          <div className="paper-wrapper">
            <div className="paper">
              <div className="mdl-body">
                {modal_icon === "info" ? <InfoOutlinedIcon className="icon info"/> : <Error className="icon"/>  }
                {/* Sign in error */}
                <h3>{modal_title ? modal_title : 'Error'}</h3>
                {/* Please approve the wallet signature to complete the transaction */}
                <p className="desc">{modal_text ? modal_text : 'Something went wrong.'}</p>

                <Button
                  className="btn-ok"
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={onClose}>
                    {/* DONE */}
                  <span className="text">{modal_btn_label ? modal_btn_label : 'OK'}</span>
                </Button>
              </div>
            </div>
          </div>
        </Zoom>
      </Modal>
    </div>
  );
}

export default ModalErrorPopup;