import React from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import Highlighter from "react-highlight-words";
import ImageWithHover from '../../components/image-with-hover/ImageWithHover';

const SectionSpecial = ({ data, md_view, xs_view, searchState }) => {

  return (
    <div className="section section-special">
      <Typography variant="h2" className="section-title">
        <Highlighter
          searchWords={[searchState]}
          textToHighlight="Special Offers"
        />
      </Typography>
      <Grid container spacing={3}>
        {data.length > 0 && data.map((val) => {
          return (
            <Grid item xs={12} key={val._id}>

              <ImageWithHover 
                val={val}
                mobile_xs={md_view}
                searchState={searchState}
              />

              {val.hashtag.length > 0 &&
                <Box className="hastag-box">
                  <Grid container spacing={md_view ? 2 : xs_view ? 1 : 3}>
                    {val.hashtag.map((val) => (
                      <Grid item xs={4} key={`hashtag-${val.toLowerCase()}`}>
                        <Box className={`box-wrap box-${val.toLowerCase()}`}>
                          <Highlighter
                            searchWords={[searchState]}
                            textToHighlight={`#${val}`}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              }

            </Grid>
          )
        })}
      </Grid>
    </div>
  );
}

export default SectionSpecial;