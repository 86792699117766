import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Typography, Grid } from "@material-ui/core";
import Highlighter from "react-highlight-words";
import ImageWithHover from "../../components/image-with-hover/ImageWithHover";

import image1 from "../../assets/images/page-games/popular/delta-time.jpg";
import image2 from "../../assets/images/page-games/popular/revv-motors.jpg";
import image3 from "../../assets/images/page-games/popular/crypto-voxels.jpg";
import image4 from "../../assets/images/page-games/popular/splinterlands.jpg";
import image5 from "../../assets/images/page-games/popular/zed-run.jpg";
import image6 from "../../assets/images/page-games/popular/dark-country.jpg";
import image7 from "../../assets/images/page-games/popular/node-runners.jpg";
import image8 from "../../assets/images/page-games/popular/crypto-dozer.jpg";
import image9 from "../../assets/images/page-games/popular/blockchain-cuties.jpg";
import image10 from "../../assets/images/page-games/popular/league-of-kingdoms.jpg";
import image11 from "../../assets/images/page-games/popular/war-riders.jpg";
import image12 from "../../assets/images/page-games/popular/chain-monsters.jpg";

import zed_video from "../../assets/videos/zed.mp4";
import war_riders_video from "../../assets/videos/war-riders.mp4";
import blockchain_cuties_video from "../../assets/videos/blockchain-cuties.mp4";
import chain_monsters_video from "../../assets/videos/chain-monsters.mp4";
import crypto_dozer_video from "../../assets/videos/crypto-dozer.mp4";
import dark_country_video from "../../assets/videos/dark-country.mp4";
import delta_time_video from "../../assets/videos/F1-delta-time.mp4";
import league_video from "../../assets/videos/league-of-kingdoms.mp4";
import node_runner_video from "../../assets/videos/node-runner.mp4";
import splinterlands_video from "../../assets/videos/splinterlands.mp4";

import { ReactComponent as ETH } from "../../assets/images/icons/eth-icon.svg";

const SectionPopular = ({ md_view, xs_view, searchState }) => {
  let data = [
    {
      _id: uuidv4(),
      image: image1,
      name: "Delta Time",
      currency: <ETH />,
      href: "https://www.f1deltatime.com/",
      store: "https://opensea.io/assets/f1-delta-time",
      videoOnHover: delta_time_video,
    },
    {
      _id: uuidv4(),
      image: image2,
      name: "Revv motors",
      currency: <ETH />,
      href: "https://revvmotorsport.com/",
      store: "https://opensea.io/assets/revv-motorsport",
    },
    {
      _id: uuidv4(),
      image: image3,
      name: "Cryptovoxels",
      currency: <ETH />,
      href: "https://www.cryptovoxels.com/",
      store: "https://opensea.io/assets/cryptovoxels",
    },
    {
      _id: uuidv4(),
      image: image4,
      name: "Splinterlands",
      currency: <ETH />,
      href: "https://splinterlands.com/",
      store: "https://opensea.io/assets/splinterlands",
      videoOnHover: splinterlands_video,
    },
    {
      _id: uuidv4(),
      image: image5,
      name: "Zed",
      currency: <ETH />,
      href: "https://zed.run/",
      store: "https://opensea.io/assets/zed-run-official",
      videoOnHover: zed_video,
    },
    {
      _id: uuidv4(),
      image: image6,
      name: "Dark Country",
      currency: <ETH />,
      href: "https://darkcountry.io/",
      store: "https://opensea.io/assets/dark-country",
      videoOnHover: dark_country_video,
    },
    {
      _id: uuidv4(),
      image: image7,
      name: "Node Runners",
      currency: <ETH />,
      href: "https://noderunners.io/",
      store: "https://opensea.io/assets/noderunners",
      videoOnHover: node_runner_video,
    },
    {
      _id: uuidv4(),
      image: image8,
      name: "Crypto Dozer",
      currency: <ETH />,
      href: "https://cryptodozer.io/",
      store: "https://opensea.io/assets?search[query]=Crypto%20dozer",
      videoOnHover: crypto_dozer_video,
    },
    {
      _id: uuidv4(),
      image: image9,
      name: "Blockchain Cuties",
      currency: <ETH />,
      href: "https://www.cryptokitties.co/",
      store: "https://opensea.io/assets/blockchaincuties",
      videoOnHover: blockchain_cuties_video,
    },
    {
      _id: uuidv4(),
      image: image10,
      name: "League of Kingdoms",
      currency: <ETH />,
      href: "https://www.leagueofkingdoms.com/",
      store: "https://opensea.io/assets/league-of-kingdoms",
      videoOnHover: league_video,
    },
    {
      _id: uuidv4(),
      image: image11,
      name: "War Riders",
      currency: <ETH />,
      href: "https://app.warriders.com/",
      store: "https://opensea.io/assets/war-riders",
      videoOnHover: war_riders_video,
    },
    {
      _id: uuidv4(),
      image: image12,
      name: "Chain Monsters",
      currency: <ETH />,
      href: "https://playchainmonsters.com/",
      store: "https://opensea.io/assets?search[query]=chain%20monsters",
      videoOnHover: chain_monsters_video,
    },
  ];

  return (
    <div className="section section-popular">
      <Typography variant="h2" className="section-title">
        <Highlighter
          searchWords={[searchState]}
          textToHighlight="Popular Games"
        />
      </Typography>
      <Grid container spacing={md_view ? 2 : xs_view ? 1 : 3}>
        {data.length > 0 &&
          data.map((val) => {
            return (
              <Grid item xs={4} md={md_view ? 2 : 3} key={val._id}>
                <ImageWithHover
                  val={val}
                  mobile_xs={md_view}
                  searchState={searchState}
                />
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default SectionPopular;
