import React from 'react';
import { ReactComponent as Buy } from '../../assets/images/icons/buy.svg';
import aceIcon from '../../assets/images/icons/acent-logo.svg'
import dappxIcon from '../../assets/images/dappxpr/dappstore.png'
import ethIcon from '../../assets/images/dappxpr/ethereum.png'
import { Popover,OverlayTrigger } from 'react-bootstrap';
import gate from '../../assets/images/icons/gate.io.png';
import kucoin from '../../assets/images/icons/kucoin.png';
import { Button } from "@material-ui/core";

const SideBarItem = ({ token }) => {
  const popoverAcent = (
    <Popover className="pop1" id="popover-positioned-bottom" title="Popover bottom" style={{marginTop: '8%'}}>
      <div className="buydrpdn">
        <div className="drpheader">
          <h1>Buy from</h1>
        </div>
        <div className="drpbody">
          <div className="drpbtn1">
            <Button  href="https://www.kucoin.com/ucenter/signup?rcode=rJCP33Y"
                     target="_blank"><span><img src={kucoin}/></span></Button>
          </div>
          <div    className="drpbtn2">

            <Button className="button" href="https://www.gate.io/signup/8166666 " target="_blank"><span><img src={gate}/></span></Button>

          </div>
        </div>
      </div>
    </Popover>
);
    return (
        <div className="history-item">
            <div className="history-item-middle wallet-item-container">
                <div className="history-item-content">
                    <div className="history-item-thumb" style={{background: 'none'}}>
                        <img style={{width: '22px',}} src={token.token === 'ACE' ? aceIcon : token.token === 'DAPPX' ? dappxIcon : ethIcon} alt="aasdfasd"/>
                    </div>
                    <div className="history-item-typo">
                        <h5>{token.token}</h5>
                    </div>
                </div>
                <div className={token.token === "DAPPX" ? "dappx-custom " : " "} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                  <p><b>{Number(token.value).toFixed(4).replace(/\.0+$/,'')}</b></p>
                  <p>${token.fiat_value}</p>
                </div>


                <OverlayTrigger trigger='click' rootClose placement="left" overlay={popoverAcent}>
                  <h4 className="amount">
                    <Buy />
                      <a className='buy-link' style={{	fontSize: 14,cursor: 'pointer',	color: '#016CE9'}}>Buy</a>
                  </h4>
                  </OverlayTrigger>
            </div>
        </div>
    );
}

export default SideBarItem;
