import React from "react";
import {ReactComponent as Logo} from "../../assets/images/dappstore-logo.svg";
import {Link as RouterLink} from "react-router-dom";
import './UserProfile.scss';
export function UnAuthenticated(){
    return(
        <div className={"connct"}>
            <div className={"connct-main"}>
                <p>Connect your wallet to access your user profile page.</p>
                <div className={"connctpic"}>
                    <RouterLink to="/" className="connctlogo"><Logo /></RouterLink>
                </div>
            </div>
        </div>
    )

}
