import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DP_Placeholder from '../../assets/images/dappxpr/dp-placeholder.png';
import { NFT_IMX_BASE_URL } from '../../constant/config';
import './index.scss'

const TopBuyers = () => {
    const [buyersData, setBuyersData] = useState([])

    useEffect(() => {
      axios.get(NFT_IMX_BASE_URL + '/v1/marketplace/top-nft-buyers')
      .then((res) => {
          console.log(res)
          setBuyersData(res.data.data);
      })
      .catch((error) => {
          console.log(error)
          setBuyersData([])
      })
    }, [])
   
    return (
        <section className="top-buyer-area p-0 top-buyers-main">
            { buyersData.length > 0 ? (
              <div className="container">
                  <div className="row">
                      <div className="col-12">
                          {/* Intro */}
                          <div className="intro m-0">
                              <div className="intro-content">
                                  <h3 className="mt-3 mb-0 top-buyers-title">Top NFT Buyers</h3>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="row items">
                      {buyersData.map((buyer, idx) => {
                          return (
                              <div key={`top_buyers_${buyer._id}`} className="col-12 col-sm-6 col-lg-3 item">
                                  {/* Single Buyer */}
                                  <div className="card no-hover top-buyers-boxes">
                                      <div className="single-buyer d-flex align-items-center">
                                          <img className="img-md rounded-circle top-buyers-image" src={DP_Placeholder} alt="" />
                                          {/* Buyer Info */}
                                          <div className="buyer-info ml-3">
                                              <p className="buyer mb-2 top-buyers-name">{buyer.username ? buyer.username : 'Unnamed'}</p>
                                              <p className="top-buyers-items">NFT Count: <span className="count">{buyer.count}</span></p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          );
                      })}
                  </div>
              </div>
            ) : ''}
        </section>
    );
}

export default TopBuyers;