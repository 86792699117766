import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import TopChartCommon from './TopChartCommon';
import image1 from '../../assets/images/section-top-charts/compound.jpg';
import image2 from '../../assets/images/section-top-charts/aave.jpg';
import image3 from '../../assets/images/section-top-charts/maker.jpg';
import image4 from '../../assets/images/section-top-charts/uniswap.jpg';
import image5 from '../../assets/images/section-top-charts/sushiswap.jpg';
import image6 from '../../assets/images/section-top-charts/dodo.jpg';
import image7 from '../../assets/images/section-top-charts/acent-wallet.jpg';
import image7_long from '../../assets/images/section-top-charts/acent-wallet--long.jpg';
import image8 from '../../assets/images/section-top-charts/1inch.jpg';
import image9 from '../../assets/images/section-top-charts/yearn-finance.jpg';
import image10 from '../../assets/images/section-top-charts/synthetix-exchange.jpg';

import './TopCharts.scss'

const DefiCharts = ({ searchState }) => {
  const data = [
    {
      _id: uuidv4(),
      image: image1,
      name: 'compound',
      href: 'https://app.compound.finance/',
    },
    {
      _id: uuidv4(),
      image: image2,
      name: 'aave',
      href: 'https://aave.com/',
    },
    {
      _id: uuidv4(),
      image: image3,
      name: 'maker',
      href: 'https://makerdao.com/en/',
    },
    {
      _id: uuidv4(),
      image: image4,
      name: 'uniswap',
      href: 'https://app.uniswap.org/#/swap',
    },
    {
      _id: uuidv4(),
      image: image5,
      name: 'sushiswap',
      href: 'https://sushiswapclassic.org/',
    },
    {
      _id: uuidv4(),
      image: image6,
      name: 'dodo',
      href: 'https://dodoex.io/',
    },
    // {
    //   _id: uuidv4(),
    //   withLongImage: true,
    //   image: image7,
    //   image_long: image7_long,
    //   name: 'acent-wallet',
    //   href: process.env.REACT_APP_ACENT_WALLET_URL,
    // },
    {
      _id: uuidv4(),
      image: image8,
      name: '1inch',
      href: 'https://app.1inch.io/#/r/0x4B6D241751b90E51e83732B275C2e4AC55525429',
    },
    {
      _id: uuidv4(),
      image: image9,
      name: 'yearn-finance',
      href: 'https://yearn.finance/',
    },
    {
      _id: uuidv4(),
      image: image10,
      name: 'synthetix-exchange',
      href: 'https://synthetix.exchange/#/',
    },
  ]

  const otherChartsData = [
    {
      _id: uuidv4(),
      name: 'Game',
      href: '/top-game-charts'
    },
    {
      _id: uuidv4(),
      name: 'NFT',
      href: '/top-nft-charts'
    },
    {
      _id: uuidv4(),
      name: 'Exchange',
      href: '/exchange'
    }
  ]

  return (
    <TopChartCommon 
      searchState={searchState}
      data={data}
      mainChartTitle="DeFi"
      otherChartsData={otherChartsData} 
    />
  );
}

export default DefiCharts;