import React from 'react';
import { Container, Grid, Box, Link } from '@material-ui/core';
import Osiris from '../../assets/images/page-download/img-osiris.png';
import Dappstore from '../../assets/images/page-download/img-dappstore.png';
import LogoOsiris from '../../assets/images/page-download/logo-osiris.png';
import LogoDappstore from '../../assets/images/page-download/logo-dappstore.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Highlighter from "react-highlight-words";

const SectionHowTo = ({ searchState }) => {
  return (
    <div className="section section-how-to">
      <Container className="section-container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box className="heading-block">
              <h2>
                <Highlighter
                  searchWords={[searchState]}
                  textToHighlight="How to use dAppstore?"
                />
              </h2>
              <p>
                <Highlighter
                  searchWords={[searchState]}
                  textToHighlight="Use the Osiris browser and the dAppstore conveniently anytime, anywhere."
                />
              </p>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="block first-block">
              <Grid container spacing={2}>
                <Grid item >
                  <img src={Osiris} className="img-fluid" alt="In the Osiris" />
                </Grid>
                <Grid item md >
                  <Box className="details-contanier">
                    <h3>
                      <img src={LogoOsiris} className="img-fluid" alt="In the Osiris" />
                    </h3>
                    <p>
                      <Highlighter
                        searchWords={[searchState]}
                        textToHighlight="Access the dAppstore directly from within the Osiris browser."
                      />
                    </p>
                    <Link href="https://browseosiris.com/" target="_blank" className="link">
                      <Highlighter
                        searchWords={[searchState]}
                        textToHighlight="Osiris browser download"
                      />
                      <ArrowForwardIosIcon className="icon" />
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="block second-block">
              <Grid container spacing={2}>
                <Grid item md >
                  <Box className="details-contanier">
                    <h3>
                      <img src={LogoDappstore} className="img-fluid" alt="In the dAppstore" />
                    </h3>
                    <p>
                      <Highlighter
                        searchWords={[searchState]}
                        textToHighlight="You can download the dAppstore from a regular browser and use it at any time."
                      />
                    </p>
                    <Link href="https://dappstore.me/" target="_blank" className="link">
                      <Highlighter
                        searchWords={[searchState]}
                        textToHighlight="More about dAppstore"
                      />
                      <ArrowForwardIosIcon className="icon" />
                    </Link>
                  </Box>
                </Grid>
                <Grid item >
                  <img src={Dappstore} className="img-fluid" alt="In the dAppstore" />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default SectionHowTo;