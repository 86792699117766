import React, { createContext, useEffect, useState } from 'react';
import { Snackbar, IconButton } from "@material-ui/core";
import { ReactComponent as CloseIcon } from '../assets/images/icons/snackbar-close.svg';
import { ReactComponent as SuccessIcon } from '../assets/images/icons/check-filled.svg';
import { ReactComponent as WarningIcon } from '../assets/images/icons/warning.svg';

export const SnackbarContext = createContext({});

const statusIcons = {
    success: <SuccessIcon />,
    warning: <WarningIcon />
}

const SnackbarProvider = ({ children }) => {
    const [snackPack, setSnackPack] = useState([]);
    const [open, setOpen] = useState(false);
    const [messageInfo, setMessageInfo] = useState(undefined);

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        if (snackPack.length && !messageInfo) {
          // Set a new snack when we don't have an active one
          setMessageInfo({ ...snackPack[0] });
          setSnackPack((prev) => prev.slice(1));
          setOpen(true);
        } else if (snackPack.length && messageInfo && open) {
          // Close an active snack when a new one is added
          setOpen(false);
        }
    }, [snackPack, messageInfo, open]);
    
    const showSnack = (message, status = 'success') => {
        message = <div className={`${status}`}>{statusIcons[status]} {message} </div>;
        setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
    };
    
    const handleExited = () => {
        setMessageInfo(undefined);
    };

    return (
        <SnackbarContext.Provider value={{
            showSnack,
        }}>
            <Snackbar
                key={messageInfo ? messageInfo.key : undefined}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                TransitionProps={{ onExited: handleExited }}
                message={messageInfo ? messageInfo.message : undefined}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                action={
                <React.Fragment>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.5 }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </React.Fragment>
                }
            />
            {children}
        </SnackbarContext.Provider>
    );
}

export default SnackbarProvider;