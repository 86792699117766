import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, Grow, Button, ButtonBase } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import image1 from '../../assets/images/section-weekly-top/image-1.jpg';
import image2 from '../../assets/images/section-weekly-top/image-2.jpg';
import image3 from '../../assets/images/section-weekly-top/image-3.jpg';
import image4 from '../../assets/images/section-weekly-top/image-4.jpg';
import image5 from '../../assets/images/section-weekly-top/image-5.jpg';
import image6 from '../../assets/images/section-weekly-top/image-6.jpg';
import image7 from '../../assets/images/section-weekly-top/image-7.jpg';
import image8 from '../../assets/images/section-weekly-top/image-8.jpg';
import image9 from '../../assets/images/section-weekly-top/image-9.jpg';
import image10 from '../../assets/images/section-weekly-top/image-10.jpg';
import image11 from '../../assets/images/section-weekly-top/image-11.jpg';
import image12 from '../../assets/images/section-weekly-top/image-12.jpg';
import image13 from '../../assets/images/section-weekly-top/image-13.jpg';
import image14 from '../../assets/images/section-weekly-top/image-14.jpg';
import image15 from '../../assets/images/section-weekly-top/image-15.jpg';
import Highlighter from "react-highlight-words";
import { useWindowSize } from '../../constant/useWindowSize';
import { useTranslation } from 'react-i18next';

const SectionWeeklyTop = ({ searchState }) => {
  const [mobileView, setMobileView] = useState(false);
  const [checked, setChecked] = useState(false);

  const [ window_width ] = useWindowSize();
  const {t} = useTranslation()
  useEffect(() => {
    if(window_width < 960) setMobileView(true)
    else setMobileView(false)
  }, [window_width]);

  const onViewMore = () => {
    setChecked((prev) => !prev);
  };


  const data = [
    {
      _id: uuidv4(),
      rank: '01',
      image: image1,
      name: 'Uniswap',
      href: 'https://uniswap.org/',
      currency: 'ETH',
    },
    {
      _id: uuidv4(),
      rank: '02',
      image: image2,
      name: 'Rarible',
      href: 'https://rarible.com/',
      currency: 'ETH',
    },
    {
      _id: uuidv4(),
      rank: '03',
      image: image3,
      name: 'Tokenlon',
      href: 'https://tokenlon.im/#/',
      currency: 'ETH',
    },
    {
      _id: uuidv4(),
      rank: '04',
      image: image4,
      name: 'SushiSwap',
      href: 'https://sushiswapclassic.org/',
      currency: 'ETH',
    },
    {
      _id: uuidv4(),
      rank: '05',
      image: image5,
      name: 'Axie Infinity',
      href: 'https://axieinfinity.com/',
      currency: 'ETH',
    },
    {
      _id: uuidv4(),
      rank: '06',
      image: image6,
      name: 'Compound',
      href: 'https://compound.finance/',
      currency: 'ETH',
    },
    {
      _id: uuidv4(),
      rank: '07',
      image: image7,
      name: 'Balancer',
      href: 'https://balancer.finance/',
      currency: 'ETH',
    },
    {
      _id: uuidv4(),
      rank: '08',
      image: image8,
      name: 'OpenSea',
      href: 'https://opensea.io/?ref=0x3067b9df58fcbe7fdb29a5de52d12d17e3dcfb1f',
      currency: 'ETH',
    },
    {
      _id: uuidv4(),
      rank: '09',
      image: image9,
      name: 'Aave',
      href: 'https://aave.com/',
      currency: 'ETH',
    },
    {
      _id: uuidv4(),
      rank: '10',
      image: image10,
      name: 'HEX',
      href: 'https://hex.com/',
      currency: 'ETH',
    },
    {
      _id: uuidv4(),
      rank: '11',
      image: image11,
      name: 'Synthetix',
      href: 'https://www.synthetix.io/',
      currency: 'ETH',
    },
    {
      _id: uuidv4(),
      rank: '12',
      image: image12,
      name: 'WBTC',
      href: 'https://wbtc.network/',
      currency: 'ETH',
    },

    {
      _id: uuidv4(),
      rank: '13',
      image: image13,
      name: '1inch',
      href: 'https://app.1inch.io/#/r/0x4B6D241751b90E51e83732B275C2e4AC55525429',
      currency: 'ETH',
    },
    {
      _id: uuidv4(),
      rank: '14',
      image: image14,
      name: 'Yearn Finance',
      href: 'https://yearn.finance/',
      currency: 'ETH',
    },
    {
      _id: uuidv4(),
      rank: '15',
      image: image15,
      name: 'Crypto Kitties',
      href: 'https://www.cryptokitties.co/',
      currency: 'ETH',
    },
  ];

  const items = (start, end) => {
    let cut_data = data.slice(start, end).map((val, i) => {
      return (
        <div key={val._id}>
          <ButtonBase href={val.href} target="_blank">
          <Box className="box">
            <div className="img-container">
              <span className="rank">
                {val.rank}
              </span>
              <img className="img-fluid" src={val.image} alt={val.name} />
            </div>
            <div className="details">
              <h4>
                <Highlighter
                  searchWords={[searchState]}
                  textToHighlight={val.name}
                />
              </h4>
              <p className="currency">
                <Highlighter
                  searchWords={[searchState]}
                  textToHighlight={val.currency}
                />
              </p>
            </div>
          </Box>
        </ButtonBase>
        </div>
      )
    })
    return cut_data
  }

  const displayDesktop = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} >
          {items(0, 5)}
        </Grid>
        <Grid item xs={12} md={4} >
          {items(5, 10)}
        </Grid>
        <Grid item xs={12} md={4} >
          {items(10, 15)}
        </Grid>
      </Grid>
    )
  }

  const displayMobile = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} >
            {items(0, 5)}
            {checked ? items(5, 15) : null}
          </Grid>
        </Grid>
        <Button
          className="view-more--btn"
          variant="outlined"
          onClick={() => onViewMore()}>
          {checked
            ? <Highlighter
              searchWords={[searchState]}
              textToHighlight='View Less'
            />
            : <Highlighter
              searchWords={[searchState]}
              textToHighlight='View More'
            />
          }
        </Button>
      </>
    )
  }

  return (
    <div className="section section-weekly-top">
      <Typography variant="h2" className="section-title">
        <Highlighter
          searchWords={[searchState]}
          textToHighlight={t("WEEKLY TOP 15")}
        />
      </Typography>
      {mobileView ? displayMobile() : displayDesktop()}
    </div>
  );
}

export default SectionWeeklyTop;