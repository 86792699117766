import React, { useState, useEffect, useRef } from 'react'
import { makeStyles, useTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import axios from 'axios';
import {
  Grid,
  Divider,
  Button,
  Paper,
  useMediaQuery,
  MenuItem,
  Typography,
  FormControl,
  Select,
  Modal,
  Backdrop,
  Fade,
  TextField,
} from '@material-ui/core';
import {
  ArrowDownward,
  ArrowForward,
  WarningRounded,
} from '@material-ui/icons';
import { ReactComponent as DappxLogo } from '../../../../assets/images/dappstore-icon.svg';
import { ReactComponent as AcentLogo } from '../../../../assets/images/icons/acent-logo.svg';
import { ReactComponent as ZeraLogo } from '../../../../assets/images/icons/zera-logo.svg';
import { ReactComponent as AcentActiveIcon } from '../../../../assets/images/icons/acent-active.svg';
import { ReactComponent as InGameIcon } from '../../../../assets/images/icons/in-game.svg';
import { ReactComponent as VaultIcon } from '../../../../assets/images/icons/vault.svg';
import { ReactComponent as Loader } from '../../../../assets/images/icons/loader-2.svg';
import { ReactComponent as SuccessIcon } from '../../../../assets/images/icons/success-check.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/images/icons/error-x.svg';
import { DAPPX_BASE_URL } from '../../../../constant/config';
import { NumberFormatCustom } from '../../../../components/CustomInput';

const APP_URL = DAPPX_BASE_URL;

const btnTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#016ce9',
    },
    secondary: {
      main: '#f44336',
    },
  },
});

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',
    '& button': {
      textTransform: 'none',
      fontSize: '16px',

      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      },
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '.25rem',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  formControlSelect: {
    width: '120px',
  },
  menuitem: {
    paddingBottom: '.25rem',
    paddingTop: '.25rem',
  },
  inputField: {
    '& input': {
      margin: theme.spacing(1),
      fontSize: '30px',
      fontWeight: '400',
      textAlign: 'right',
    },
  },
  inputFieldLeft: {
    '& input': {
      margin: theme.spacing(1),
      fontSize: '30px',
      fontWeight: '400',
    },
  },
  icon: {
    height: '25px',
    width: 'auto',
    filter: 'invert(73%) sepia(25%) saturate(534%) hue-rotate(180deg) brightness(103%) contrast(102%)',
  },
  iconSuccess: {
    height: '130px',
    width: 'auto',
    filter: 'invert(38%) sepia(83%) saturate(5889%) hue-rotate(203deg) brightness(97%) contrast(99%)',
  },
  iconError: {
    height: '130px',
    width: 'auto',
    filter: 'invert(13%) sepia(77%) saturate(7402%) hue-rotate(1deg) brightness(106%) contrast(114%)',
  },
  iconContainer: {
    marginBottom: '3rem',
  },
  bold: {
    fontWeight: 'bold',
  },
  medium: {
    fontWeight: 500,
  },
}));

const transferData = {
  zera: {
    tokenURL: 'zera',
    transferURL: {
      fromVault: 'main-to-pocket',
      toVault: 'pocket-to-main',
    },
  },
  acent: {
    tokenURL: 'acent',
    transferURL: {
      fromVault: 'vault-to-active',
      toVault: 'active-to-vault',
    },
    title:'ACE', 
    logo:(
      <AcentLogo style={{ margin: '0 1rem', height: '26px', width: 'auto' }} />
    )
  },
  dappx: {
    tokenURL: 'dappx',
    transferURL: {
      fromVault: 'vault-to-active',
      toVault: 'active-to-vault',
    },
    title:'DAPPX',
    logo:(
      <DappxLogo style={{ margin: '0 1rem', height: '26px', width: 'auto' }} />
    )
  },
}

const TransferModal = ({
  token,
  open,
  handleClose,
  data,
  decimals,
}) => {
  const classes = useStyles();

  const isZera = token === 'zera';
  const title = isZera ? 'Zera' : transferData[token].title;
  const [step, setStep] = useState(1);
  // const withValueLimit = ({ floatValue }) => floatValue <= limit;

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= limit) return true;
    return false;
  };

  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [from, setFrom] = useState('mainBal');
  const [fromValue, setFromValue] = useState('0');
  const [to, setTo] = useState('pocketBal');
  // const [toValue, setToValue] = useState('0');
  const [limit, setLimit] = useState(data[from]);

  const handleFromChange = ({ target: { value } }) => {
    setFrom(value);
    value === 'mainBal' ? setTo('pocketBal') : setTo('mainBal');
  };

  const handleFromValueChange = ({ target: { value } }) => {
    setFromValue(value);
  };

  const handleToChange = ({ target: { value } }) => {
    setTo(value);
    value === 'mainBal' ? setFrom('pocketBal') : setFrom('mainBal');
  };

  const TransferZera = async (from, amount) => {
    const tokenURL = isZera ? transferData.zera.tokenURL : transferData[token].tokenURL;
    const transferURL = from === 'mainBal'
      ? (isZera ? transferData.zera.transferURL.fromVault : transferData[token].transferURL.fromVault)
      : (isZera ? transferData.zera.transferURL.toVault : transferData[token].transferURL.toVault);
    const API_PATH = `/users/${tokenURL}/transfer/${transferURL}`;
    const sessionID = localStorage.getItem('session-id');
    const startTime = new Date().getTime();
    const minLoadTime = 1000;

    const hideLoader = () => {
      const endTime = new Date().getTime();
      const waitTime = minLoadTime - (endTime - startTime);

      setTimeout(() => {
        setStep(step + 1);
      }, waitTime);
    }

    const transfer = await axios.post(
      APP_URL + `/users/${tokenURL}/transfer/${transferURL}`,
      {
        amount: amount
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'session-id': sessionID,
        },
      },
    );

    if (transfer && transfer.data.message === 'Ok') {
      hideLoader();
    } else {
      setErrMsg('An error has occurred. Please try again later.');
      setError(true);
      hideLoader();
    }
  };

  useEffect(() => {
    if (open) {
      setFromValue('0');
      setError(false);
      setErrMsg('');
      setStep(1);
    }
  }, [open])

  useEffect(() => {
    // setFromValue('0');
    setLimit(data[from]);
    setError(false);
  }, [from]);

  useEffect(() => {
    setLimit(data[from]);
  }, [data]);

  useEffect(() => {
    if (parseFloat(fromValue) > parseFloat(limit)) {
      setErrMsg('Insufficient Balance.');
      setError(true);
    } else {
      setError(false);
    }
  }, [fromValue]);

  useEffect(() => {
    if (step === 3) {
      TransferZera(from, fromValue);
    }
  }, [step]);

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));

  const getFromToIcon = (icon) => {
    return icon === 'mainBal' ? (
      <VaultIcon className={classes.icon} />
    ) : (
      isZera
        ? <InGameIcon className={classes.icon} />
        : <AcentActiveIcon className={classes.icon} />
    )
  };

  const ItemLabel = {
    zera: {
      vault: 'Vault',
      pocket: 'In-game',
    },
    acent: {
      vault: 'Vault',
      pocket: 'Active',
    },
    dappx: {
      vault: 'Vault',
      pocket: 'Active',
    },
  }

  const getLabel = (val) => {
    const label = val === 'mainBal'
      ? 'Vault'
      : (isZera ? 'In-game' : 'Active');
    
    return <div className={classes.bold}>{label}</div>
  };

  const handleFromValueFocus = ({ target: { value } }) => {
    if (value <= 0) setFromValue('');
  };

  const handleFromValueBlur = ({ target: { value } }) => {
    if (value <= 0) setFromValue('0');
  };

  const handleNextStep = () => {
    if (step === 1 && parseFloat(fromValue) <= 0) {
      setErrMsg('Please input amount to be transferred.');
      setError(true);
      return false;
    } else if (parseFloat(fromValue) > parseFloat(limit)) {
      setErrMsg('Insufficient Balance.');
      setError(true);
      return false;
    }

    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const fromResult = (parseFloat(data[from]) - parseFloat(fromValue)).toFixed(12).split('.');
  const toResult = (parseFloat(data[to]) + parseFloat(fromValue)).toFixed(12).split('.');

  return (
    <MuiThemeProvider theme={btnTheme}>
      <Modal
        aria-labelledby="zera-transfer-title"
        aria-describedby="zera-transfer-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          {
            {
              1: (
                <div className={classes.paper} style={{ width: isXS ? '100%' : '500px' }}>
                  <div style={{ padding: '1rem 1.5rem', display: 'flex', justifyContent: 'center' }}> 
                    <Typography variant="h5" className={classes.medium}>
                      Transfer {title}
                    </Typography>
                  </div>

                  <Divider />

                  <div style={{ padding: isXS ? '1rem' : '1.5rem' }}> 
                    <div style={{ marginBottom: '1rem' }}>
                      <Paper variant="outlined" style={{
                        padding: isXS ? '.75rem' : '1rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '.5rem',
                      }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography variant="p" className={classes.bold}>
                            From
                          </Typography>
                          <FormControl variant="outlined" className={classes.formControlSelect}>
                            <Select
                              value={from}
                              onChange={handleFromChange}
                              displayEmpty
                            >
                              <MenuItem value='mainBal' className={classes.menuitem}>{ItemLabel[token].vault}</MenuItem>
                              <MenuItem value='pocketBal' className={classes.menuitem}>{ItemLabel[token].pocket}</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <form noValidate autoComplete="off">
                          <TextField
                            className={classes.inputField}
                            value={fromValue}
                            onChange={handleFromValueChange}
                            name="numberformat"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                              disableUnderline: true,
                            }}
                            isAllowed={withValueCap}
                            onFocus={handleFromValueFocus}
                            onBlur={handleFromValueBlur}
                          />
                        </form>
                      </Paper>
                      <div style={{ textAlign: 'end', padding: '0 .5rem' }}>
                        <Typography variant="p">
                          Available Balance: {`${data[from].split('.')[0]}`}{data[from].split('.')[1] > 0 && `.${data[from].split('.')[1].substr(0, decimals)}`}
                        </Typography>
                      </div>
                    </div>

                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '1rem',
                    }}>
                      <ArrowDownward />
                    </div>

                    {/* <NumberFormat value={12} isAllowed={withValueCap} /> */}

                    <Paper variant="outlined" style={{
                      padding: isXS ? '.75rem' : '1rem',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '1rem',
                    }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="p" className={classes.bold}>
                          To
                        </Typography>
                        <FormControl variant="outlined" className={classes.formControlSelect}>
                          <Select
                            value={to}
                            onChange={handleToChange}
                            displayEmpty
                          >
                            <MenuItem value='pocketBal' className={classes.menuitem}>{ItemLabel[token].pocket}</MenuItem>
                            <MenuItem value='mainBal' className={classes.menuitem}>{ItemLabel[token].vault}</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {/* <form noValidate autoComplete="off">
                      <TextField
                        className={classes.inputField}
                        value={fromValue}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          disableUnderline: true,
                        }}
                        readonly
                      />
                    </form> */}
                      <p style={{ fontSize: '30px', paddingRight: '8px' }}>
                        {/* {`${data[to].split('.')[0]}.${data[to].split('.')[1].substr(0, decimals)}`} */}
                        {fromValue}
                      </p>
                    </Paper>

                    {
                      error && (
                        <div style={{
                          color: '#F44336',
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: '1rem',
                        }}>
                          <WarningRounded style={{ marginRight: '.5rem' }} />
                          <Typography variant="p">
                            {errMsg}
                          </Typography>
                        </div>
                      )
                    }

                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                      <Grid item xs={6}>
                        <Button variant="contained" color="primary" fullWidth onClick={handleNextStep} disabled={error}>
                          Continue
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button variant="contained" color="default" fullWidth onClick={handleClose} >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ),

              2: (
                <div className={classes.paper} style={isXS && { width: '100%' }}>
                  <div style={{ padding: '1rem 1.5rem', display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h5" className={classes.medium}>
                      Confirm Transfer
                    </Typography>
                  </div>

                  <Divider />

                  {
                    isXS ? (
                      <div style={{ padding: '1rem' }}>
                        <div style={{ marginBottom: '1rem' }}>
                          <Typography variant="p" className={classes.bold}>
                            Transfer Amount:
                          </Typography>
                          <Paper variant="outlined" style={{
                            height: '50px',
                            padding: '.5rem .8rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '.5rem',
                            backgroundColor: '#EBEBEB',
                          }}>
                            <p style={{ fontSize: '30px' }}>
                              {fromValue}
                            </p>
                            {
                              isZera ? (
                                <ZeraLogo style={{ margin: '0 1rem', height: '26px', width: 'auto' }} />
                              ) : transferData[token].logo
                            }
                          </Paper>
                        </div>

                        <Grid container spacing={1} style={{ textAlign: 'center', marginBottom: '.3rem' }}>
                          <Grid item xs={1}></Grid>
                          <Grid item xs={5} className={classes.bold}>
                            Current
                          </Grid>
                          <Grid item xs={1}></Grid>
                          <Grid item xs={5} className={classes.bold}>
                            After
                          </Grid>
                        </Grid>

                        <Paper variant="outlined" style={{
                          padding: '.5rem',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '1rem',
                          backgroundColor: '#F5F5F5',
                        }}>
                          <Grid container spacing={1} style={{ textAlign: 'center', alignItems: 'center' }}>
                            <Grid item xs={1}>
                              {getFromToIcon(from)}
                            </Grid>
                            <Grid item xs={5}>
                              <span className={classes.bold} style={{ fontSize: '22px' }}>{data[from].split('.')[0]}</span>
                              <span style={{
                                fontSize: '16px',
                                opacity: data[from].split('.')[1] > 0 ? 1 : 0
                              }}>
                                {`.${data[from].split('.')[1].substr(0, decimals)}`}
                              </span>
                            </Grid>
                            <Grid item xs={1}>
                              <ArrowForward />
                            </Grid>
                            <Grid item xs={5}>
                              <span className={classes.bold} style={{ fontSize: '22px' }}>{fromResult[0]}</span>
                              <span style={{
                                fontSize: '16px',
                                opacity: fromResult[1] && fromResult[1] > 0 ? 1 : 0
                              }}>
                                {`.${fromResult[1] && fromResult[1].substr(0, decimals)}`}
                              </span>
                            </Grid>
                          </Grid>
                        </Paper>

                        <Paper variant="outlined" style={{
                          padding: '.5rem',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          backgroundColor: '#F5F5F5',
                        }}>
                          <Grid container spacing={1} style={{ textAlign: 'center', alignItems: 'center' }}>
                            <Grid item xs={1}>
                              {getFromToIcon(to)}
                            </Grid>
                            <Grid item xs={5}>
                              <span className={classes.bold} style={{ fontSize: '22px' }}>{data[to].split('.')[0]}</span>
                              <span style={{
                                fontSize: '16px',
                                opacity: data[to].split('.')[1] > 0 ? 1 : 0
                              }}>
                                {`.${data[to].split('.')[1].substr(0, decimals)}`}
                              </span>
                            </Grid>
                            <Grid item xs={1}>
                              <ArrowForward />
                            </Grid>
                            <Grid item xs={5}>
                              <span className={classes.bold} style={{ fontSize: '22px' }}>{toResult[0]}</span>
                              <span style={{
                                fontSize: '16px',
                                opacity: toResult[1] && toResult[1] > 0 ? 1 : 0
                              }}>
                                {`.${toResult[1] && toResult[1].substr(0, decimals)}`}
                              </span>
                            </Grid>
                          </Grid>
                        </Paper>

                        <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                          <Grid item xs={6}>
                            <Button variant="contained" color="primary" fullWidth onClick={handleNextStep} disabled={error}>
                              Continue
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button variant="contained" color="default" fullWidth onClick={handlePrevStep}>
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    ) : (
                      <div style={{ padding: '1.5rem', minWidth: '600px' }}>
                        <div style={{ marginBottom: '1rem' }}>
                          <Typography variant="p" className={classes.bold}>
                            Transfer Amount:
                          </Typography>
                          <Paper variant="outlined" style={{
                            padding: '1rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '.5rem',
                            backgroundColor: '#EBEBEB',
                          }}>
                            <Typography variant="h4">
                              {fromValue}
                            </Typography>
                            {
                              isZera ? (
                                <ZeraLogo style={{ margin: '0 1rem', height: '35px', width: 'auto' }} />
                              ) : transferData[token].logo
                            }
                          </Paper>
                        </div>

                        <Grid container spacing={1} style={{ textAlign: 'center', marginBottom: '.3rem' }}>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={4} className={classes.bold} style={{ fontSize: 20 }}>
                            Current
                          </Grid>
                          <Grid item xs={1}></Grid>
                          <Grid item xs={4} className={classes.bold} style={{ fontSize: 20 }}>
                            After
                          </Grid>
                        </Grid>

                        <Paper variant="outlined" style={{
                          padding: '.5rem',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '1rem',
                          backgroundColor: '#F5F5F5',
                        }}>
                          <Grid container spacing={1} style={{ textAlign: 'center', alignItems: 'center' }}>
                            <Grid item xs={3} style={{ textAlign: 'center', fontSize: 20 }}>
                              {getLabel(from)} {getFromToIcon(from)}
                            </Grid>
                            <Grid item xs={4}>
                              <span className={classes.bold} style={{ fontSize: '22px' }}>{data[from].split('.')[0]}</span>
                              <span style={{
                                fontSize: '16px',
                                opacity: data[from].split('.')[1] > 0 ? 1 : 0
                              }}>
                                {`.${data[from].split('.')[1].substr(0, decimals)}`}
                              </span>
                            </Grid>
                            <Grid item xs={1}>
                              <ArrowForward />
                            </Grid>
                            <Grid item xs={4}>
                              <span className={classes.bold} style={{ fontSize: '22px' }}>{fromResult[0]}</span>
                              <span style={{
                                fontSize: '16px',
                                opacity: fromResult[1] && fromResult[1] > 0 ? 1 : 0
                              }}>
                                {`.${fromResult[1] && fromResult[1].substr(0, decimals)}`}
                              </span>
                            </Grid>
                          </Grid>
                        </Paper>

                        <Paper variant="outlined" style={{
                          padding: '.5rem',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          backgroundColor: '#F5F5F5',
                        }}>
                          <Grid container spacing={1} style={{ textAlign: 'center', alignItems: 'center' }}>
                            <Grid item xs={3} style={{ textAlign: 'center', fontSize: 20 }}>
                              {getLabel(to)} {getFromToIcon(to)}
                            </Grid>
                            <Grid item xs={4}>
                              <span className={classes.bold} style={{ fontSize: '22px' }}>{data[to].split('.')[0]}</span>
                              <span style={{
                                fontSize: '16px',
                                opacity: data[to].split('.')[1] > 0 ? 1 : 0
                              }}>
                                {`.${data[to].split('.')[1].substr(0, decimals)}`}
                              </span>
                            </Grid>
                            <Grid item xs={1}>
                              <ArrowForward />
                            </Grid>
                            <Grid item xs={4}>
                              <span className={classes.bold} style={{ fontSize: '22px' }}>{toResult[0]}</span>
                              <span style={{
                                fontSize: '16px',
                                opacity: toResult[1] && toResult[1] > 0 ? 1 : 0
                              }}>
                                {`.${toResult[1] && toResult[1].substr(0, decimals)}`}
                              </span>
                            </Grid>
                          </Grid>
                        </Paper>

                        <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                          <Grid item xs={6}>
                            <Button variant="contained" color="primary" fullWidth onClick={handleNextStep} disabled={error}>
                              Continue
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button variant="contained" color="default" fullWidth onClick={handlePrevStep}>
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    )
                  }

                </div>
              ),

              3: (
                <div className={classes.paper}>
                  <div style={{ padding: '1rem 1.5rem', display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h5" className={classes.bold}>
                      Processing your request
                    </Typography>
                  </div>

                  <Divider />

                  <div style={{ padding: '3rem', width: '600px', fontSize: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <div className={classes.iconContainer}>
                        <Loader style={{ height: '100px', width: 'auto' }} />
                      </div>
                      <Typography variant="p">
                        Almost there!
                      </Typography>
                      <Typography variant="p" style={{ marginBottom: '2rem' }}>
                        Please wait a moment.
                      </Typography>
                    </div>
                  </div>
                </div>
              ),

              4: (
                <div className={classes.paper}>
                  <div style={{ padding: '1rem 1.5rem', display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h5" className={classes.bold}>
                      {
                        error ? 'Transfer Failed' : 'Transfer Successful!'
                      }
                    </Typography>
                  </div>

                  <Divider />

                  <div style={{ padding: '3rem', width: '600px', fontSize: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {
                        error ? (
                          <>
                            <div className={classes.iconContainer}>
                              <ErrorIcon className={classes.iconError} />
                            </div>
                            <Typography variant="p">
                              {errMsg}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <div className={classes.iconContainer}>
                              <SuccessIcon className={classes.iconSuccess} />
                            </div>
                            <Typography variant="p">
                              You have successfully transferred
                            </Typography>
                            <Typography variant="p" color='primary' className={classes.bold}>
                              {fromValue} {title}
                            </Typography>
                          </>
                        )
                      }
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                        size="large"
                        style={{
                          marginTop: '2rem',
                          minWidth: '200px',
                        }}>
                        OK
                      </Button>
                    </div>
                  </div>
                </div>
              ),
            }[step]
          }
        </Fade>
      </Modal >
    </MuiThemeProvider>
  );
};

export default TransferModal;