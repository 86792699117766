import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Web3 from "web3";
import { Button, Fade, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CustomDialog from "../../../../components/Modal/CustomDialog";
import dappx_logo from "../../../../assets/images/dappstore-icon.svg";
import ace_logo from "../../../../assets/images/icons/acent-logo.svg";
import wallet_logo from "../../../../assets/images/icon-assets.svg";
import cart_logo from "../../../../assets/images/icon-cart.svg";


import Loader from "../../../../components/loader/Loader";
import { ReactComponent as WarningIcon } from "../../../../assets/images/icons/warning.svg";
import { ReactComponent as SuccessIcon } from "../../../../assets/images/icons/success-check.svg";
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Config, IS_UAT } from "../../../../constant/config";
import { NumberFormatCustom } from "../../../../components/CustomInput";
import "./index.scss";
import TransactionPreview from "../../../../components/Modal/TransactionPreview";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import plusCircle from "../../../../assets/images/icons/plus-circle.svg";
import nativeContract from '../../../../contract/ace_deposit_gateway.json'
import { Link } from "react-router-dom";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#016ce9",
    },
    secondary: {
      main: "#f44336",
    },
  },
});

const DepositModal = ({
  tokenType,
  balance,
  digital_balance,
  notDisabled,
  BtnComponent,
}) => {
  const isAcent = tokenType === "ACE";
  const tokenLogo = isAcent ? ace_logo : dappx_logo;
  const { token, value, currency, fiat_value } = balance || {};
  let whole_num = value
    ? value.split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "";
  let decimal_num = value ? value.split(".")[1] : "";

  const isNative = localStorage.getItem("current_network") === '0x22b8' ? true : false;
  const isUATNative = localStorage.getItem("current_network") === '0x231d' ? true : false;

  const isTestnet =
    localStorage.getItem("current_network") === "0x2a" ? true : false;
  const CONTRACT_ADDRESS_DEPOSIT_DAPPX = isTestnet
    ? Config.DEPOSIT_TESTNET_CONTRACT_ADDRESS_DAPPX
    : IS_UAT
      ? Config.DEPOSIT_UAT_MAINNET_CONTRACT_ADDRESS_DAPPX
      : Config.DEPOSIT_PROD_MAINNET_CONTRACT_ADDRESS_DAPPX;
  const CONTRACT_ADDRESS_DAPPX = isTestnet
    ? Config.TESTNET_CONTRACT_ADDRESS_DAPPX
    : Config.MAINNET_CONTRACT_ADDRESS_DAPPX;

  const CONTRACT_ADDRESS_DEPOSIT_ACENT = isTestnet
  // const CONTRACT_ADDRESS_DEPOSIT_ACENT = isTestnet
    ? Config.DEPOSIT_TESTNET_CONTRACT_ADDRESS_ACE
    : IS_UAT
      ? Config.DEPOSIT_UAT_MAINNET_CONTRACT_ADDRESS_ACE
      : Config.DEPOSIT_PROD_MAINNET_CONTRACT_ADDRESS_ACE;
  const CONTRACT_ADDRESS_ACENT = isTestnet
    ? Config.TESTNET_CONTRACT_ADDRESS_ACE
    : Config.MAINNET_CONTRACT_ADDRESS_ACE;

  const DEPOSIT_CONTRACT_ADDRESS = isAcent
    ? CONTRACT_ADDRESS_DEPOSIT_ACENT
    : CONTRACT_ADDRESS_DEPOSIT_DAPPX;
  const TOKEN_CONTRACT_ADDRESS = isAcent
    ? CONTRACT_ADDRESS_ACENT
    : CONTRACT_ADDRESS_DAPPX;

  const [input_val, set_input_val] = useState("");
  const [input_fiat_val, set_input_fiat_val] = useState(0);
  const [wallet_action, set_wallet_action] = useState(false);
  const [usdValue, setUsdValue] = useState(0);
  const [isMarketPriceReady, setIsMarketPriceReady] = useState(true);
  const [marketPrice, setMarketPrice] = useState(undefined);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  // Input Error
  const [err_message, set_err_message] = useState("");
  const [outline_error, set_outline_error] = useState([]);

  // Modal Deposit
  const [open_modal, set_open_modal] = useState(false);
  const [pre_open_modal, set_pre_open_modal] = useState(false);

  const myRef = useRef();

  const handleClickOutside = e => {
      if (!myRef?.current?.contains(e.target)) {
        set_pre_open_modal(false);
      }
  };

  const handleClickInside = () => set_pre_open_modal(false);

  useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
  });


  const onOpenModal = () => {
    console.log("value", isTestnet);
    console.log("DEPOSIT_CONTRACT_ADDRESS", DEPOSIT_CONTRACT_ADDRESS);
    console.log("TOKEN_CONTRACT_ADDRESS", TOKEN_CONTRACT_ADDRESS);
    set_open_modal(true);
  };
  const onCloseModal = () => {
    if (wallet_action) {
      set_wallet_action(false);
      set_err_message("");
    } else {
      onClear();
    }
  };

  // Modal Loading
  const [open_modal_loading, set_open_modal_loading] = useState(false);
  const onOpenModalLoading = () => {
    set_open_modal_loading(true);
    set_wallet_action(false);
    set_open_modal(false);
  };
  const onCloseModalLoading = () => {
    set_open_modal_loading(false);
  };

  // Button Loading
  const [load_btn, set_load_btn] = useState(false);

  // Initialize Web3
  let web3 = new Web3(window.ethereum);
  let wallet_address = localStorage.getItem("wallet_address");
  const [deployed_contract, set_deployed_contract] = useState();
  const [dappx_contract, set_dappx_contract] = useState();

  const aceDepositContractPath = isTestnet 
  // || isNative
    ? "/acent-contract.json"
    : IS_UAT 
    // || isUATNative
      ? "/acent-contract-uat.json"
      : "/acent-contract-prod.json";
  const dappxDepositContractPath = "/dappstore-contract.json";
  const depositContractPath = isAcent
    ? aceDepositContractPath
    : dappxDepositContractPath;

  const tokenContractPath = isAcent ? "/acentToken.json" : "/dappx.json";

  const initDepositTokenContract = async () => {
    // if(isNative || isUATNative) {
    //   let aceDepositContract = new web3.eth.Contract(nativeContract, DEPOSIT_CONTRACT_ADDRESS)
    //   set_deployed_contract(aceDepositContract);
    //   return 
    // }
    await axios
      .get(Config.DAPPX_BASE_URL + depositContractPath)
      .then((responseContract) => {
        set_deployed_contract(
          new web3.eth.Contract(
            responseContract.data.abi,
            DEPOSIT_CONTRACT_ADDRESS
          )
        );
      })
      .catch((error) => {
        console.log("contract error: ", error);
      });
  };

  const initTokenContract = async () => {
    await axios
      .get(Config.DAPPX_BASE_URL + tokenContractPath)
      .then((responseContract) => {
        set_dappx_contract(
          new web3.eth.Contract(
            responseContract.data.abi,
            TOKEN_CONTRACT_ADDRESS
          )
        );
      })
      .catch((error) => {
        console.log("contract error: ", error);
      });
  };

  const convertCurrency = async () => {
    await axios
      .get(Config.DAPPX_BASE_URL + "/users/getMarketPrices")
      .then((res) => {
        const currencyVal = isAcent ? res.data.ACE.USD : res.data.DAPPX.USD;
        setUsdValue(currencyVal);
      })
      .catch((error) => {
        set_input_fiat_val(0);
        set_err_message("Can't pull conversion rate.");
      });
  };

  useEffect(() => {
    if (open_modal) {
      initDepositTokenContract();
      initTokenContract();
      convertCurrency();
    }
  }, [open_modal]);

  // Functions
  const remove_errors = (target) => {
    set_err_message("");
    let update_outline_error = outline_error.filter((id) => id !== target);
    set_outline_error(update_outline_error);
  };

  const convertValue = (balance, tokenCode) => {
    let convertion_currency = "USD";

    if (isMarketPriceReady) {
      axios
        .get(Config.DAPPX_BASE_URL + "/users/getMarketPrices")
        .then((prices) => {
          let fiat_currency = prices.data[tokenCode][convertion_currency]
            ? prices.data[tokenCode][convertion_currency]
            : 0;
          let fiat_value_value =
            parseFloat(balance) * parseFloat(fiat_currency);
          let convertion_value;

          if (fiat_value_value === 0) {
            convertion_value = parseFloat(fiat_value_value);
          } else {
            convertion_value = parseFloat(fiat_value_value).toFixed(2);
          }

          set_input_fiat_val(convertion_value);
          setMarketPrice(prices.data);
          setIsMarketPriceReady(false);
          if (!isTimerRunning) marketPriceTimer();
        })
        .catch((error) => {
          console.log("convertValue error: ", error);
          set_input_fiat_val(0);
          set_err_message("Can't pull conversion rate.");
        });
    } else {
      let fiat_currency = marketPrice[tokenCode][convertion_currency]
        ? marketPrice[tokenCode][convertion_currency]
        : 0;
      let fiat_value_value = parseFloat(balance) * parseFloat(fiat_currency);
      let convertion_value;

      if (fiat_value_value === 0) {
        convertion_value = parseFloat(fiat_value_value);
      } else {
        convertion_value = parseFloat(fiat_value_value).toFixed(2);
      }

      set_input_fiat_val(convertion_value);
      if (!isTimerRunning) marketPriceTimer();
    }
  };

  const marketPriceTimer = () => {
    setIsTimerRunning(true);

    setTimeout(() => {
      setIsMarketPriceReady(true);
      setIsTimerRunning(false);
    }, 60000);
  };

  const onChange_num = (e) => {
    const target_val = e.target.value;
    const decimals = target_val.split(".")[1] || "";
    remove_errors(e.target.id);

    if (target_val !== "" && decimals.length <= 6) {
      set_input_val(target_val);
      let convertion_value = (target_val * usdValue).toFixed(2);
      set_input_fiat_val(convertion_value);
      convertCurrency();
    }

    if (target_val === "") {
      set_input_val(target_val);
      convertCurrency();
    }
  };

  const maxVal = () => {
    let number = parseFloat(value);
    remove_errors("amount");
    set_input_val(number);
    convertValue(number, tokenType);
  };

  const onSubmit = async () => {
    let to_num_input_val = parseFloat(input_val);
    let to_num_balance_val = parseFloat(value);
    let acceptedNumberToAdd = to_num_input_val - 0.000001;
    let err_arr = [];

    if (to_num_balance_val < to_num_input_val) {
      err_arr.push("amount");
      set_outline_error(err_arr);
      set_err_message("Insufficient balance.");
    } else if (to_num_input_val === 0 || isNaN(to_num_input_val)) {
      err_arr.push("amount");
      set_outline_error(err_arr);
      set_err_message("Amount should be greater than zero.");
    } else {
      if (wallet_action) {
        // handleDeposit(acceptedNumberToAdd)
        handleDeposit(to_num_input_val);
      } else {
        set_wallet_action(true);
        set_err_message("");
      }
    }
  };

  async function depositNative(_inputWei) {
    set_load_btn(true);
    const val =  web3.utils.toWei(_inputWei.toString(), "ether")
    await deployed_contract.methods
      .deposit()
      .send({ from: wallet_address, value: val })
      .then((tx) => {
        set_load_btn(false);
        onOpenModalLoading();
      })
      .catch((error) => {
        console.log("error: ", error);
        set_load_btn(false);
        if (error.code === 4001) {
          onCloseModalLoading();
          set_wallet_action(false);
          set_err_message("You declined an action in your wallet.");
          setTimeout(() => {
            onOpenModal();
          }, 300);
        } else {
          onCloseModalLoading();
          if (error.message) {
            set_err_message(error.message);
          } else {
            set_err_message(
              "Something went wrong. Transaction didn't push through."
            );
          }
        }
      });
  }

  async function handleDeposit(input_value) {
    const allowance = await dappx_contract.methods
      .allowance(wallet_address, DEPOSIT_CONTRACT_ADDRESS)
      .call();

    let BN = web3.utils.BN;
    let _allowance = new BN(allowance);
    let _inputWei = web3.utils.toWei(`${input_value}`, "ether");
    let _input = new BN(`${_inputWei}`);

    if (_allowance.gte(_input)) {
      executeDeposit(_inputWei);
    } else {
      if (wallet_address != null) {
        executeApproval(input_value, _inputWei);
      } else {
        set_err_message(
          "Something went wrong! Refresh the page and try again."
        );
      }
    }
  }

  async function executeApproval(input_value, _inputWei) {
    set_load_btn(true);

    await dappx_contract.methods
      .approve(
        DEPOSIT_CONTRACT_ADDRESS,
        web3.utils.toWei(input_value.toString(), "ether")
      )
      .send({ from: wallet_address })
      .then((success) => {
        console.log("approval success: ", success);
        executeDeposit(_inputWei);
      })
      .catch((error) => {
        set_wallet_action(false);
        error.message && set_err_message(error.message.split("{")[0]);
        set_load_btn(false);

        if (error.code === 4001) {
          onCloseModalLoading();
          set_err_message("You declined an action in your wallet.");
        } else {
          onCloseModalLoading();
        }
      });
  }

  async function executeDeposit(_inputWei) {
    set_load_btn(true);
    onOpenModalLoading();

    await deployed_contract.methods
      .deposit(TOKEN_CONTRACT_ADDRESS, _inputWei)
      .send({ from: wallet_address })
      .then((tx) => {
        set_load_btn(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        set_load_btn(false);
        if (error.code === 4001) {
          onCloseModalLoading();
          set_wallet_action(false);
          set_err_message("You declined an action in your wallet.");
          setTimeout(() => {
            onOpenModal();
          }, 300);
        } else {
          onCloseModalLoading();
          if (error.message) {
            set_err_message(error.message);
          } else {
            set_err_message(
              "Something went wrong. Transaction didn't push through."
            );
          }
        }
      });
  }

  const onClear = () => {
    setTimeout(() => {
      set_wallet_action(false);
      set_open_modal(false);
      set_input_val("");
      set_input_fiat_val(0);
      set_err_message("");
      set_outline_error([]);
      set_load_btn(false);
      set_open_modal_loading(false);
    }, 400);
  };

  return (
    <>
      {isAcent && BtnComponent ? (
        <MuiThemeProvider theme={theme}>
          <BtnComponent
            disabled={
              balance && parseFloat(balance.value) > 0 && notDisabled
                ? false
                : true
            }
            onClick={onOpenModal}
          />
        </MuiThemeProvider>
      ) : (

        <>
          {tokenType === "DAPPX" && (
            <div className="option-item-area-main">
             <Button
              className="deposit-btn with-dropdown"
              variant="outlined"

              onClick={() => set_pre_open_modal(!pre_open_modal)}
            >
              {(tokenType === "DAPPX") | (tokenType === "ACE") && (
                <>
                  {/* <img src={plusCircle} alt="" style={{ marginRight: "3.5px" }} /> */}
                  Top Up
                  
                </>
              )}
            </Button>
            

            <div className={`option-item-area ${pre_open_modal ? 'option-item-area-active' : ''}`} ref={myRef}>
                    <Link className="option-item" onClick={e => {
                      e.preventDefault()
                      set_open_modal(true)
                      set_pre_open_modal(false)
                    }}>
                      <img src={wallet_logo} alt="" />
                      <p>Top Up Using Your Crypto</p>
                    </Link>
                    {/* <Link to="/user-profile/dappx-paypal" className="option-item">
                      <img src={cart_logo} alt="" />
                      <p>Top Up Using Your Fiat</p>
                    </Link> */}
                  </div>
            </div>
           
          )}

          {tokenType !== "DAPPX" && (
            <Button
              className="deposit-btn"
              variant="outlined"
              disabled={
                balance && parseFloat(balance.value) > 0 && notDisabled
                  ? false
                  : true
              }
              onClick={onOpenModal}
            >
              {(tokenType === "DAPPX") | (tokenType === "ACE") && (
                <>
                  {/* <img src={plusCircle} alt="" style={{ marginRight: "3.5px" }} /> */}
                  Top Up
                </>
              )}
            </Button>
          )}


        </>

      )}

      <CustomDialog
        open={open_modal}
        className="deposit-dialog"
        onClose={onCloseModal}
        header={`Deposit ${tokenType}`}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={
          <>
            {!wallet_action && (
              <div className="container-balance">
                <label>Available {tokenType} Balance</label>
                <div className="block">
                  <div className="icon-block">
                    <img src={tokenLogo} alt={token} />
                  </div>
                  <div className="amount-block">
                    {value && (
                      <>
                        <p className="amount">
                          {whole_num}.<span>{decimal_num}</span>
                        </p>
                        <p className="convertion">
                          {fiat_value} {currency}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div
              className={`container-textfield ${wallet_action ? "wallet-action" : ""
                }`}
            >
              <div className="number-label">
                <label>{wallet_action ? "Deposit" : "Amount"}</label>
                <Button disableRipple onClick={maxVal}>
                  Max
                </Button>
              </div>
              <div className="textfield-block">
                <TextField
                  fullWidth
                  className="number-field"
                  error={outline_error.includes("amount")}
                  value={input_val}
                  onChange={onChange_num}
                  autoComplete="off"
                  inputProps={{
                    min: 0,
                  }}
                  variant="outlined"
                  id="amount"
                  placeholder="Enter Amount"
                  disabled={wallet_action ? true : false}
                  name="number-field"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <img className="coin-logo" src={tokenLogo} alt={token} />
              </div>
              <div className="convertion">≈ {input_fiat_val} USD</div>
            </div>
            {wallet_action && (
              <TransactionPreview
                bal_1_title="Wallet Balance"
                bal_2_title="dAppstore Balance"
                current_bal_1={parseFloat(value).toFixed(6)}
                current_bal_2={parseFloat(digital_balance.coin_value).toFixed(
                  6
                )}
                after_bal_1={(
                  parseFloat(value) - parseFloat(input_val)
                ).toFixed(6)}
                after_bal_2={(
                  parseFloat(input_val) + parseFloat(digital_balance.coin_value)
                ).toFixed(6)}
                error_message={set_err_message}
                token_code={tokenType}
                open={wallet_action}
              />
            )}
          </>
        }
        footer={
          <>
            {err_message && (
              <Fade
                className="error custom-error"
                in={err_message ? true : false}
                timeout={500}
              >
                <Alert severity="error">
                  <WarningIcon className="icon" />
                  {err_message}
                </Alert>
              </Fade>
            )}
            <div className="button-block">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disableElevation
                onClick={onSubmit}
                type="submit"
                disabled={load_btn}
              >
                <span className="text">
                  {wallet_action ? "Submit" : "Continue"}
                </span>
                <Loader
                  appear={load_btn}
                  timeout={1000}
                  width="22"
                  coloredBg
                  svgProps={{
                    direction: "right",
                    negative: true,
                  }}
                />
              </Button>
              <Button
                variant="contained"
                color="default"
                fullWidth
                disableElevation
                disabled={load_btn}
                onClick={onCloseModal}
              >
                Cancel
              </Button>
            </div>
          </>
        }
      />


      <CustomDialog
        open={open_modal_loading}
        className="deposit-dialog loading-dialog"
        onClose={onCloseModalLoading}
        header="Your deposit is on its way!"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        body={
          <>
            <SuccessIcon className="icon" />
            <p>
              Please confirm the deposit action on your wallet to submit the
              request to the network.
            </p>

            <p>
              Updated status will be reflected on your wallet transaction
              history.
            </p>

            <p>
              For successful transaction, reflecting the balance on your
              dAppstore Balances may take longer than usual.
            </p>
          </>
        }
        footer={
          <>
            <div className="button-block">
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={onCloseModalLoading}
                type="submit"
              >
                OK
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};

export default DepositModal;
