import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TopChartCommon from './TopChartCommon';
import image1 from '../../assets/images/section-top-charts/open-sea.jpg';
import image2 from '../../assets/images/section-top-charts/nft-meme.jpg';
import image3 from '../../assets/images/section-top-charts/nft-bank.jpg';
import image4 from '../../assets/images/section-top-charts/mobox.jpg';
import image5 from '../../assets/images/section-top-charts/rarible.jpg';
import image6 from '../../assets/images/section-top-charts/myth-market.jpg';
import image8 from '../../assets/images/section-top-charts/mintable.jpg';
import image9 from '../../assets/images/section-top-charts/axie-infinity.jpg';
import image10 from '../../assets/images/section-top-charts/airnfts.jpg';
import image11 from '../../assets/images/section-top-charts/league-of-kingdoms.jpg';
import image12 from '../../assets/images/section-top-charts/gods-unchained.jpg';
import { useWindowSize } from '../../constant/useWindowSize';
import './TopCharts.scss';

const NftCharts = ({ searchState }) => {
  const [mobileView, setMobileView] = useState(false);

  const [ window_width ] = useWindowSize();
  useEffect(() => {
    if(window_width < 960) setMobileView(true)
    else setMobileView(false)
  }, [window_width]);

  const data = [
    {
      _id: uuidv4(),
      image: image1,
      name: 'open-sea',
      href: 'https://opensea.io/?ref=0x3067b9df58fcbe7fdb29a5de52d12d17e3dcfb1f',
    },
    {
      _id: uuidv4(),
      image: image2,
      name: 'nft-meme',
      href: 'https://dontbuymeme.com/',
    },
    {
      _id: uuidv4(),
      image: image3,
      name: 'nft-bank',
      href: 'https://nftbank.ai/',
    },
    {
      _id: uuidv4(),
      image: image4,
      name: 'mobox',
      href: 'https://www.mobox.io/#/cmcairdrop?source=10346322',
    },
    {
      _id: uuidv4(),
      image: image5,
      name: 'rarible',
      href: 'https://rarible.com/',
    },
    {
      _id: uuidv4(),
      image: image6,
      name: 'myth-market',
      href: 'https://myth.market/',
    },
    // {
    //   _id: uuidv4(),
    //   withLongImage: true,
    //   image: image7,
    //   image_long: image7_long,
    //   name: 'dapp-arcade',
    //   videoOnHover: video_arcade,
    //   href: process.env.REACT_APP_DAPPARCADE_URL,
    // },
    {
      _id: uuidv4(),
      image: image8,
      name: 'mintable',
      href: 'https://mintable.app/',
    },
    {
      _id: uuidv4(),
      image: image9,
      name: 'axie-infinity',
      href: 'https://axieinfinity.com/',
    },
    {
      _id: uuidv4(),
      image: image10,
      name: 'airnfts',
      href: 'https://www.airnfts.com/',
    },
    {
      _id: uuidv4(),
      image: image11,
      name: 'league-of-kingdoms',
      href: 'https://www.leagueofkingdoms.com/',
      hidden: mobileView ? false : true 
    },
    {
      _id: uuidv4(),
      image: image12,
      name: 'gods-unchained',
      href: 'https://godsunchained.com/',
      hidden: mobileView ? false : true 
    },
  ]

  const otherChartsData = [
    {
      _id: uuidv4(),
      name: 'Game',
      href: '/top-game-charts'
    },
    {
      _id: uuidv4(),
      name: 'DeFi',
      href: '/top-defi-charts'
    },
    {
      _id: uuidv4(),
      name: 'Exchange',
      href: '/exchange'
    }
  ]

  return (
    <TopChartCommon 
      searchState={searchState}
      data={data}
      mainChartTitle="NFT"
      otherChartsData={otherChartsData} 
    />
  );
}

export default NftCharts;