import React from 'react';
import { Modal, Backdrop, Zoom, IconButton } from '@material-ui/core';
import osiris from '../../assets/images/dappxpr/osiris-browser.png';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'
import { ReactComponent as Windows } from '../../assets/images/icons/windows.svg';
import { ReactComponent as Google } from '../../assets/images/icons/google-play.svg';
import { ReactComponent as Apple } from '../../assets/images/icons/apple.svg';
import GetButton from '../../components/download-btn/GetButton';

const ModalNotice = ({
  open,
  onClose
}) => {
  return (
    <div>
      <Modal
        aria-labelledby="notice-title"
        aria-describedby="notice-desc"
        className="notice-modal custom-scroll dappxPR-modal"
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Zoom in={open}>
          <div className="paper-wrapper">
            <div className="paper">
              <div className="mdl-header">
                <h2>Notice</h2>
                <IconButton
                  onClick={() => onClose()} className="close-btn">
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="mdl-body">
                <p className="desc">Install Osiris Browser for Ultimate WEB 3.0 experience!</p>
                <div className="img-container">
                  <img src={osiris} alt="Osiris Browser" className="img-fluid" />
                </div>
                <GetButton
                  className="windows-btn"
                  href="https://downloads.decenternet.com/Osiris%20Setup.exe"
                  icon={<Windows className="icon" />}
                  text="Windows PC"
                  small_text="GET IT ON"
                />
                <GetButton
                  className="google-btn"
                  href="https://play.google.com/store/apps/details?id=com.osiris.browser"
                  icon={<Google className="icon" />}
                  text="Google Play"
                  small_text="GET IT ON"
                />
                <GetButton
                  className="apple-btn"
                  href="https://apps.apple.com/us/app/osiris-browser/id1487907355"
                  icon={<Apple className="icon" />}
                  text="App Store"
                  small_text="Download on the"
                />
              </div>
            </div>
          </div>
        </Zoom>
      </Modal>
    </div>
  )
}

export default ModalNotice;