import React, {useState, useEffect, useContext} from 'react'
import ace_logo from '../../../../assets/images/icons/acent-logo.svg';
import {
  Button,
  TextField,
  Tooltip,
  Fade,
  IconButton
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Web3 from 'web3';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CustomDialog from '../../../../components/Modal/CustomDialog'
import {DAPPX_BASE_URL, TESTNET__CONTRACT_ADDRESS_WITHDRAW_ACE, MAINNET_CONTRACT_ADDRESS_WITHDRAW_ACE, MAINNET_CONTRACT_ADDRESS_WITHDRAW_ACE_PROD} from '../../../../constant/config';
import { NumberFormatCustom } from '../../../../components/CustomInput/';
import infoIcon from '../../../../assets/images/dappstore-info.png'
import { IS_SAT, IS_UAT } from '../../../../constant/config';
import axios from 'axios'
import { AuthContext } from '../../../../context/AuthContext';
import { useHistory } from "react-router-dom";
import blacklisted from '../../../../utils/blacklisted';
import { useLogout } from '../../../../utils/cutom-hook';
import './index.scss'

const WithdrawModal = ({checkOpen, onClose, vaultBalance, withdrawLimit, nitroFee, ubiFee, coreFee, usdValue, vaultFiat, onSuccess, refetch}) => {
  const handleLogout = useLogout();
  let web3 = new Web3(window.ethereum);
  const sessionID = localStorage.getItem('session-id')
  const { dispatch } = useContext(AuthContext);
  // const isNative = localStorage.getItem("current_network") === '0x22b8' || localStorage.getItem('current_network') === "0x231d" ? true : false;
  
  const DEPOSIT_CONTRACT_ADDRESS = IS_SAT ? TESTNET__CONTRACT_ADDRESS_WITHDRAW_ACE : ( IS_UAT? MAINNET_CONTRACT_ADDRESS_WITHDRAW_ACE : MAINNET_CONTRACT_ADDRESS_WITHDRAW_ACE_PROD)
  const depositContractPath = '/withdraw-contract.json'

    const [loading, setLoading] = useState(false);
    const [showToolTip, setShowToolTip] = useState(false)
    const [feesTooltip, setFeesTooltip] = useState(false)
    const [inputVal, setInputVal] = useState(0)
    const [inputFiatVal, setInputFiatVal] = useState(0)
    const [error, setError] = useState(false)
    const [deployedContract, setDeployedContract] = useState()
    const [policyModal, setPolicyModal] = useState(false)

  const getWholeNumber = (value) => {
    return value ? value.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
  }
  const getDecimalNumber = (value) => {
    return value ? value.split('.')[1].substring(0, 6) : '';
  }

  const initDepositTokenContract = async () => {
    await axios.get(DAPPX_BASE_URL + depositContractPath)
      .then((responseContract) => {
        setDeployedContract(
          new web3.eth.Contract(responseContract.data.abi, DEPOSIT_CONTRACT_ADDRESS)
        );
      }).catch((error) => {
        console.log('contract error: ', error)
      })
  }

  useEffect(() => {
    initDepositTokenContract()
  },[])

  const onChangeInput = (e) => {
    const target_val = e.target.value;
    const decimals = target_val.split('.')[1] || '';

    if (target_val !== '' && decimals.length <= 6) {
      let convertion_value = (target_val * usdValue).toFixed(2)
      setInputVal(target_val);
      setInputFiatVal(convertion_value);
    }

    if (target_val === '') {
      setInputVal(target_val);
      setInputFiatVal(target_val * usdValue);
    }
  }

  const handleCloseWithdrawModal = () => {
    setInputVal(0)
    setInputFiatVal(0)
    setError(false)
    onClose()
  }

  const onSubmit = async () => {
    if (blacklisted.includes(localStorage.getItem('wallet_address')?.toLocaleLowerCase())) {
      handleLogout()
      setLoading(false)
      dispatch({ type: 'LOGOUT' })
      return
    }
    // const tokenType = isNative ? 'native' : 'token'
    setLoading(true)
    await axios.post(`${DAPPX_BASE_URL}/users/acent/withdraw`, {amount: Number(inputVal)}, {headers: {'session-id': sessionID}})
    .then((res) => {
      console.log(res.data)
      localStorage.setItem("withdraw_id", res.data.withdraw_id);
      checkFees(res.data.token, res.data.amount, res.data.nonce, res.data.signature, res.data.withdraw_id)
    }).catch((error) => {
      setLoading(false)
      setError(error.response.data.message)
    })
  }

  const cancelWithdrawal = async (withdrawId) => {
    await axios.post(`${DAPPX_BASE_URL}/users/acent/withdraw/${withdrawId}/cancel`, null, {headers: {'session-id': localStorage.getItem('session-id')}})
  }


  const checkFees = async (token, amount, nonce, signature, withdrawId) => {
    // const tokenType = isNative ? 'native' : 'token'
    await axios.get(DAPPX_BASE_URL + `/users/acent/withdraw/settings`, {headers: {'session-id': sessionID}})
    .then(async(res) => {
      if( nitroFee != res.data.withdrawal_fee_nitro || ubiFee != res.data.withdrawal_fee_ubi || coreFee != res.data.withdrawal_fee_core)
      {
        await axios.post(`${DAPPX_BASE_URL}/users/acent/withdraw/${withdrawId}/cancel`, null, {headers: {'session-id': localStorage.getItem('session-id')}})
        .then(() => {
          refetch()
          setPolicyModal(true)
        })
      } else {
        submitWithdraw(token, amount, nonce, signature, withdrawId)
      } 
    })
  }

  const withdrawNative = async(withdrawId, amount) => {
    const data = {
      requestId: withdrawId,
      amount
    }

    axios.post(`${DAPPX_BASE_URL}/users/acent/withdraw-native`, data, {headers: {'session-id': localStorage.getItem('session-id')}})
     .then(async () => {
        await axios.post(`${DAPPX_BASE_URL}/users/acent/withdraw/${withdrawId}/fulfill`, null, {headers: {'session-id': localStorage.getItem('session-id')}})
        setLoading(false)
        onSuccess()
     })
     .catch(async (error) => {
      cancelWithdrawal(withdrawId)
       setLoading(false)
       setError(error.message)
     });
  }

  const submitWithdraw = async (token, amount, nonce, signature, withdrawId) => {
    console.log(amount)
    const walletAddress = localStorage.getItem('wallet_address')

     await deployedContract.methods.withdraw(token, amount, nonce, signature)
     .send({ from: walletAddress})
     .then(async (tx) => {
        await axios.post(`${DAPPX_BASE_URL}/users/acent/withdraw/${withdrawId}/fulfill`, null, {headers: {'session-id': localStorage.getItem('session-id')}})
        setLoading(false)
        onSuccess()
     })
     .catch(async (error) => {
      cancelWithdrawal(withdrawId)
       setLoading(false)
       if (error.code === 4001) {
         setError("You declined an action in your wallet.")
       } else {
         if (error.message) {
            cancelWithdrawal(withdrawId)
           console.log(error.message)
           setError(error.message)
         } else {
           setError("Something went wrong. Transaction didn't push through.")
         }
       }
     });
  }

  const closePolicyModal = () => {
    setPolicyModal(false)
    setLoading(false)
  }

  return (
    <>
    <CustomDialog 
    open={checkOpen}
    header={' Withdraw Ace'}
    body={
      <>
      <div className="container-balance">
      <label>Ace Vault Balance</label>
      <div className="block">
        <div className="icon-block">
          <img src={ace_logo} alt={ace_logo} />
        </div>
        <div className="amount-block">
          {/* {value && */}
            <>
              <p className="amount">{getWholeNumber(vaultBalance)}.<span>{getDecimalNumber(vaultBalance)}</span></p>
              <p className="fiat-convertion ace-convert-fiat">{vaultFiat} {'USD'}</p>
            </>
          {/* } */}
        </div>
      </div>
    </div>
     {/* <div className={`container-textfield ${wallet_action ? 'wallet-action' : ''}`}> */}
     <div className={`container-textfield wallet-action`}>
      <div className="number-label">
        <label>{'To'}</label>
      </div>
      <div className="textfield-block">
        <TextField
          fullWidth
          className="number-field"
          value={localStorage.getItem('wallet_address')}
          autoComplete="off"
          inputProps={{
            min: 0
          }}
          variant="outlined"
          id='amount'
          placeholder="Enter Address"
          disabled={true}
          name="number-field"
        />
      </div>
    </div>
    <div className={`container-textfield wallet-action WA-container`}>
      <div className="number-label">
        <label>Withdraw Amount</label>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={()=> setShowToolTip(false)}
          open={showToolTip}
          // disableFocusListener
          disableHoverListener
          // disableTouchListener
          arrow
          placement="right"
          title={
            <div className="tooltip-content">
              <h5>Withdrawal Limit</h5>
              <p>Withdraw limit for today: <span className='tooltip-span'>{withdrawLimit} ACE</span></p>
            </div>
          }
        >
          <IconButton 
          onClick={()=> setShowToolTip(true)} 
          className={`info-btn open}`}><InfoOutlinedIcon/></IconButton>
        </Tooltip>
      </div>
      <div className="textfield-block">
        <TextField
          fullWidth
          className="number-field"
          // error={outline_error.includes('amount')}
          value={inputVal}
          onChange={onChangeInput}
          autoComplete="off"
          inputProps={{
            min: 0
          }}
          variant="outlined"
          id='amount'
          placeholder="0"
          name="number-field"
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
        <img className="coin-logo" style={{width: 20, height: 20}} src={ace_logo} alt={ace_logo} />
      <div className="test-convertion">≈ {inputFiatVal} USD</div>
      </div>
      {/* <div className="convertion">≈ {input_fiat_val} USD</div> */}
    </div>
    <div className="WA-text-style">
      <div className='WA-text-container'>
        <p>Withdraw Fee</p>
        <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={()=> setFeesTooltip(false)}
            open={feesTooltip}
            disableHoverListener
            arrow
            placement="right"
            title={
              <div className="tooltip-content">
                <h5>Withdrawal Fees</h5>
                <ul>
                  <li><p><span className='tooltip-span'>{nitroFee} ACE</span> Nitro Fees.</p></li>
                  <li> <p><span className='tooltip-span'>{ubiFee} ACE</span> UBI protocol fee will be used to compensate token holders, including land owners.</p></li>
                  <li><p><span className='tooltip-span'>{coreFee} ACE</span> Core Ecosystem development fee to deploy a self-sufficient, robust, decentralized immersive web service.</p></li>
                </ul>  
              </div>
            }
          >
            <IconButton 
            onClick={()=> setFeesTooltip(true)} 
            className={`info-btn open}`}><InfoOutlinedIcon/></IconButton>
          </Tooltip>
      </div>
      <div style={{display: 'flex', gap: '5px'}}>
        <p>{nitroFee + ubiFee + coreFee}</p>
        <img className="coin-logo" style={{width: 20, height: 20, marginRight: '5px'}} src={ace_logo} alt={ace_logo} />
      </div>
      </div>
    <div className="WA-text-style">
      <p>{'Total Amount'}</p>
      <div style={{display: 'flex', gap: '5px'}}>
        <p>{nitroFee + ubiFee + coreFee + Number(inputVal)}</p>
        <img className="coin-logo" style={{width: 20, height: 20, marginRight: '5px'}} src={ace_logo} alt={ace_logo} />
      </div>
    </div>
    </>
    }
    footer={<>
      {error &&
        <Fade className="error custom-error submit-error" in={error ? true : false} timeout={500} >
          <Alert severity="error">{error}</Alert>
        </Fade>
      }
      <div className="footer-btns">
        <Button variant="contained" color="primary" fullWidth disableElevation
          onClick={onSubmit}
          type="submit"
          disabled={loading}
        >
          <span className="text">Submit</span>
          {/* <Loader
            appear={loading}
            timeout={1000}
            width="22"
            coloredBg
            svgProps={{
              direction: "right",
              negative: true,
            }}
          /> */}
        </Button>
        <Button variant="contained" color="default" disabled={loading} fullWidth disableElevation 
        onClick={handleCloseWithdrawModal}
      >Cancel</Button>
      </div>
    </>}
  />
  <CustomDialog 
      open={policyModal}
      header={
        <p>Policy Update!</p>
      }
      body={
          <div className='mobile-modal-conatainer'>
              <img src={infoIcon} alt='' className='mobile-modal-logo'/>
              <p className='policy-modal-body'>Daily withdraw policy was updated.<br/>Kindly check and process your withdrawal again.</p>
          </div>
      }
      footer={
        <div className='policy-button-container'>
          <button className='policy-button'onClick={closePolicyModal}>Ok</button>
        </div>
      }
  />
  </>
  )
}

export default WithdrawModal