import React, { useState, useRef } from 'react';
import { Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@material-ui/core';
import './TopNav.scss';
import { useTranslation } from 'react-i18next';

const MenuDropdown = ({
  value
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const {t} = useTranslation()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const onMouseEnter = () => {
    setOpen(true)
  }

  const onMouseLeave = () => {
    setOpen(false)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  let val_id = value.label.toLowerCase().replace(/ /g, "_");
  let val_dd_list = value.dropdown_menu ? value.dropdown_menu : [];

  return (
    <div className="dropdown_menu"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Button
        ref={anchorRef}
        aria-controls={open ? val_id : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={`${value.label.toLowerCase().replace(/ /g, "-")}-btn`}
      >
       {t(value.label)}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className="dd-list">
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper square={true}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id={val_id}>
                  {val_dd_list.length > 0 && val_dd_list.map((item) => (
                    <MenuItem key={item.label.toLowerCase().replace(/ /g, "_")}>
                      <a href={item.href}
                        id={item.label.toLowerCase().replace(/ /g, "_")}
                        onClick={handleClose}
                        target={item.target ? item.target : item.href.includes('https') ? '_blank' : ''}>
                        {/* {item.label} */}
                        {t(item.label)}
                      </a>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default MenuDropdown;
