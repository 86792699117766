import React from 'react';
import { Toolbar, IconButton, Drawer, Box, ButtonBase } from "@material-ui/core";
import { ReactComponent as MenuIcon } from '../../assets/images/icons/hamburger.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search.svg';
// import { ReactComponent as Logo } from '../../assets/images/dappx-logo-2.svg';
import Logo  from "../../assets/images/dappx-logo-2.png";
import { Link as RouterLink } from "react-router-dom";
import MenuCollapsible from './MenuCollapsible';
import ConnectWalletButton from './ConnectWalletButton';
import { isMobile } from 'react-device-detect';
import CloseIcon from '@material-ui/icons/Close';
import './TopNav.scss';

const TopnavMobile = ({
  setMenu,
  searchOpen,
  drawerOpen,
  menu_data,
  search_bar
}) => {

  const handleDrawerOpen = () => setMenu((prevState) => ({ ...prevState, drawerOpen: true }));
  const handleDrawerClose = () => setMenu((prevState) => ({ ...prevState, drawerOpen: false }));
  const onOpenSearch = () => setMenu((prevState) => ({ ...prevState, searchOpen: true }));
  const onCloseSearch = () => setMenu((prevState) => ({ ...prevState, searchOpen: false }));

  return (
    <Toolbar>
      <RouterLink to="/" className="logo-block">
        
        {/* <Logo /> */}
        
        <img src={Logo} alt="Logo" />
        </RouterLink>

      <Box ml={'auto'} className="mobile-box">
        {/* <IconButton
          onClick={searchOpen ? onCloseSearch : onOpenSearch}
          edge="end"
          className="search-mobile">
          {searchOpen ? <CloseIcon /> : <SearchIcon />}
        </IconButton> */}

        <ConnectWalletButton />

        <IconButton
          {...{
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
          }}
          className="hamburger"
          edge="end"
          onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}
        >
          {drawerOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </Box>

      <Drawer
        anchor="top"
        open={searchOpen}
        onClose={() => {
          // if(searchInput === ""){
          // setSearchInput("");
          // searchDispatch({ type: 'SEARCH_INPUT', payload: '' })
          // }
          onCloseSearch();
        }}
        className="search-mobile-content">
        {search_bar()}
      </Drawer>

      <Drawer {...{
        className: "hamburger-content",
        anchor: "right",
        open: drawerOpen,
        onClose: handleDrawerClose,
      }}>
        <Box mb={10}>
          {menu_data.length > 0 && menu_data.map((val) => {
            if (val.dropdown) {
              return (
                <MenuCollapsible key={val.label.toLowerCase().replace(/ /g, "_")} value={val} />
              )
            } else {
              return (
                <ButtonBase
                  {...{
                    key: val.label.toLowerCase().replace(/ /g, "_"),
                    to: val.target ? null : val.href,
                    href: val.href,
                    component: val.target ? 'button' : RouterLink,
                    target: val.target,
                  }}
                  disabled={val.disabled}
                  className={val.sp_hidden && isMobile ? 'd-none' : ''}
                  onClick={handleDrawerClose}
                >
                  {val.label}
                </ButtonBase>
              );
            }
          })}
        </Box>
        {/* <Box className="language-mobile">
          <ButtonGroup variant="text" color="default" aria-label="text default button group">
            <Button>ENG</Button>
            <Button>KOR</Button>
          </ButtonGroup>
        </Box> */}
      </Drawer>
    </Toolbar>
  );
}

export default TopnavMobile;