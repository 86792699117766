import React, { useState } from 'react';
import { List, ListItem, Collapse, Link } from "@material-ui/core";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import './TopNav.scss'

const MenuCollapsibe = ({ value }) => {

  const [open, setOpen] = useState(false);

  const onToggleOpen = (id) => {
    if (id === open) {
      setOpen()
    } else {
      setOpen(id)
    }
  };

  let val_id = value.label.toLowerCase().replace(/ /g, "_");
  let val_dd_list = value.dropdown_menu ? value.dropdown_menu : [];

  return (
    <List className="collapsible" disablePadding>
      <li key={val_id} className={`collapsible-btn ${open === val_id ? 'active' : ''}`}>
        <ListItem
          button
          onClick={() => onToggleOpen(val_id)}>
          {value.label} {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open === val_id} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className="collapsible-list">
            {val_dd_list.length > 0 && val_dd_list.map((item) => (
              <ListItem
                button
                key={item.label.toLowerCase().replace(/ /g, "_")}
                component={Link}
                href={item.href}
                target={item.target ? item.target : item.href.includes('https') ? '_blank' : ''}>
                {item.label}
              </ListItem>
            ))}
          </List>
        </Collapse>
      </li>
    </List>
  );
}

export default MenuCollapsibe;