import React, { useState, useEffect, useContext } from 'react';
import { AppBar, Container, InputBase, useScrollTrigger } from "@material-ui/core";
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search.svg';
import Web3 from 'web3';
import { SearchContext } from '../../context/SearchContext';
import { AuthContext } from '../../context/AuthContext';
import { IS_UAT, IS_SAT } from '../../constant/config';
import Desktop from './TopnavDesktop';
import Mobile from './TopnavMobile';
import { useWindowSize } from '../../constant/useWindowSize';
import './TopNav.scss';
import { useLocation } from 'react-router-dom';

// menu
const MENU_DATA = [
  {
    label: "Learn",
    dropdown: true,
    dropdown_menu: [
      {
        label: "About DAPPX",
        href: IS_SAT
          ? 'https://sat.dappstore.me'
          : IS_UAT
            ? 'https://uat.dappstore.me'
            : 'https://dappstore.me/intro',
      },
      // {
      //   label: "Announcement",
      //   href: "https://medium.com/dappstore/announcements/home"
      // },
      {
        label: "Blog",
        href: IS_SAT
          ? 'https://play-sat.dappstore.me/blog'
          : IS_UAT
            ? 'https://play-uat.dappstore.me/blog'
            : 'https://dappstore.me/blog',
        target: "_self"
      },
      // {
      //   label: "News",
      //   href: "https://dappstore.me/intro/updates/"
      // },
    ]
    // dropdown_menu: [
    //   {
    //     label: "About dAppstore",
    //     href: "https://dappstore.me/intro",
    //   }, {
    //     label: "Docs",
    //     href: IS_SAT
    //       ? 'https://play-sat.dappstore.me/docs'
    //       : IS_UAT
    //         ? 'https://play-uat.dappstore.me/docs'
    //         : 'https://dappstore.me/docs',
    //     target: "_self"
    //   }, {
    //     label: "Blog",
    //     href: IS_SAT
    //       ? 'https://play-sat.dappstore.me/blog'
    //       : IS_UAT
    //         ? 'https://play-uat.dappstore.me/blog'
    //         : 'https://dappstore.me/blog',
    //     target: "_self"
    //   }, {
    //     label: "FAQ",
    //     href: IS_SAT
    //       ? 'https://play-sat.dappstore.me/faq'
    //       : IS_UAT
    //         ? 'https://play-uat.dappstore.me/faq'
    //         : 'https://dappstore.me/faq',
    //     target: "_self"
    //   },
    // ]
  },
  {
    label: "DeFi",
    href: "/top-defi-charts",
  },
  // {
  //   label: "NFT",
  //   href: "/nft-marketplace",
  //   // href: "/top-nft-charts",
  //   // sp_hidden: true,
  //   // pc_hidden: tabletView ? true : false,
  // },
  // {
  //   label: "Games",
  //   href: "/games",
  // },
  // {
  //   label: "Exchange",
  //   href: "/exchange",
  // },
  {
    label: "Download",
    href: "/download",
    pc_hidden: true,
  },
];


const TopNav = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const transparent_top_nav = pathname === '/games';

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const transparent_BG = transparent_top_nav && !scrollTrigger;

  const [menu, setMenu] = useState({
    mobileView: false,
    drawerOpen: false,
    searchOpen: false,
  });
  const [tabletView, setTabletView] = useState(false)
  const { mobileView, drawerOpen, searchOpen } = menu;

  const [window_width] = useWindowSize();
  useEffect(() => {
    if (window_width < 960) {
      setMenu((prevState) => ({ ...prevState, mobileView: true }))
    } else {
      setMenu((prevState) => ({ ...prevState, mobileView: false }));
    }

    if (window_width < 1280) {
      setTabletView(true)
    } else {
      setTabletView(false)
    }
  }, [window_width]);

  // search
  const [searchFocus, setSearchFocus] = useState(false);
  const { searchState, searchDispatch } = useContext(SearchContext);
  const onChangeSearch = (e) => {
    let str = e.target.value
    searchDispatch({ type: 'SEARCH_INPUT', payload: str })
  }

  const SearchBar = () => {
    return (
      <div className={`search ${searchFocus || searchState.searchValue ? 'focus' : ''}`}>
        <div className="searchIcon">
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: "inputRoot",
            input: "inputInput",
          }}
          onBlur={() => setSearchFocus(false)}
          onFocus={() => setSearchFocus(true)}
          value={searchState.searchValue}
          onChange={(e) => onChangeSearch(e)}
          inputProps={{
            'aria-label': 'search',

          }}
        />
      </div>
    );
  }

  // enable web3
  const { authState, dispatch } = useContext(AuthContext);
  useEffect(() => {
    if (!authState.web3) dispatch({ type: 'INIT_WEB3', payload: new Web3(window.ethereum) })
  }, [authState.web3]);

  return (
    <nav className={`top-nav-area ${transparent_BG && 'transparent-bg'}`} role="navigation" aria-label="main navigation">
      <AppBar color="default">
        <Container className="full-container">
          {mobileView
            ? <Mobile
              setMenu={setMenu}
              searchOpen={searchOpen}
              drawerOpen={drawerOpen}
              menu_data={MENU_DATA}
              search_bar={SearchBar}
            />
            : <Desktop
              mobileView={mobileView}
              menu_data={MENU_DATA}
              search_bar={SearchBar}
            />
          }
        </Container>
      </AppBar>
    </nav>
  );
}

export default TopNav;