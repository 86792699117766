import React, {useState, useEffect} from 'react';
import { ReactComponent as BackgroundImage } from '../../assets/images/marketplace/hero/hero.svg';
import heroImg from '../../assets/images/marketplace/hero/PageBanner-Mobile.png';
import NewHero from '../../assets/images/marketplace/hero/PageBanner-Desktop.png';
import SelectInput from "../SearchBox";
import ConnectToIMX from "../IMX/ConnectToIMX"
import './index.scss'

const Breadcrumb = (props) => {
  const [searchField, setSearchField] = useState(false)

  useEffect(() => {
    console.log('BREAD', window.location)
    console.log('CRUMB', window.location.pathname)
    if (window.location.pathname === "/explore-3") {
      setSearchField(true)
    }
    // console.log('Salama-Decenternet',window.location.pathname)
  },[])
    
        return (
            <section className="breadcrumb-area d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Breamcrumb Content */}
                            <div className="breadcrumb-content text-center">
                                {/* <h2 className="breadcrumb-area__title">{props.title}</h2> */}
                                {searchField ?
                                <div className="breadcrumb-area__search-field">
                                  <SelectInput />
                                </div>
                                : ''}
                                
                               {/*  <ol className="breadcrumb d-flex justify-content-center">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#">{props.subpage}</a></li>
                                    <li className="breadcrumb-item active">{props.page}</li>
                                </ol> */}

                                {props.imxbutton ? 
                                  <ConnectToIMX />
                                  : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className='shape image-holder'>
                    <img src={NewHero} alt='' className='hero-img'/>
                    </div>
                </div>
                
            </section>
        );
    
}

export default Breadcrumb;