import { useState, useEffect, useContext } from "react";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  useTheme,
} from "@material-ui/core/styles";
import axios from "axios";
import Web3 from "web3";
import { Grid, Button, Typography, useMediaQuery } from "@material-ui/core";
import { AuthContext } from "../../../context/AuthContext";
import { ReactComponent as InGameIcon } from "../../../assets/images/icons/in-game.svg";
import { ReactComponent as VaultIcon } from "../../../assets/images/icons/vault.svg";
import DP_Placeholder from "../../../assets/images/dappxpr/dp-placeholder.png";
import "./index.scss";
import { DAPPX_BASE_URL } from "../../../constant/config";
import { Ticker } from "react-flip-ticker";
import { useInterval } from "../../../utils/util";
import { Transfer } from "../common";
import SideBarData from "../../../components/UserSidebar/SideBarData";

const APP_URL = DAPPX_BASE_URL;
const sessionID = localStorage.getItem("session-id");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#016ce9",
    },
    secondary: {
      main: "#f44336",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#111111",
    padding: "2rem",
    "& .amount": {
      "& > *": {
        display: "inline-block",
      },
    },
    "& button": {
      textTransform: "none",
      fontSize: "16px",

      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },

    [theme.breakpoints.down("xs")]: {
      padding: "2rem .5rem",
    },

    [theme.breakpoints.down("sm")]: {
      paddingTop: "5rem",
    },
  },
  divider: {
    width: "1rem",
    visibility: "hidden",
  },
  dot: {
    fontWeight: "bold",
    lineHeight: 2,
  },
  zeraBalanceLabel: {
    fontWeight: "bold",
    lineHeight: 1,
    color: "#111111",

    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  bgLightGray: {
    backgroundColor: "#F7F7F7",
  },
  comingSoonContainer: {
    width: "100%",
    borderRadius: "0.6rem",
    backgroundColor: "rgba(0,0,0,.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1rem",
  },
  comingSoonText: {
    position: "absolute",
    fontWeight: "bold",
    color: "#FFF",
    lineHeight: 1,

    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  detailsTitle: {
    fontSize: "20px",
    lineHeight: "1.2",
    color: "#626262",
    fontWeight: "normal",

    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  btn: {
    fontSize: "1rem",
    lineHeight: 1.6,
    borderRadius: "5px",
    borderColor: "#016ce9",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
}));

const defaultFloatVal = "0.000000";
const dummyData = {
  // balance = vault + ingame
  earning: "2033.333333",
  // earning - earning from pledging
  vault: "1433.333333",
  ingame: "600.000000",
};

const Zera = () => {
  const { authState } = useContext(AuthContext);
  const { user_details } = authState;
  let user_username = user_details.username;
  const classes = useStyles();
  const displayDecimals = 6;
  const computeDecimals = 12;

  const [reloadData, setReloadData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    mainBal: defaultFloatVal,
    pocketBal: defaultFloatVal,
    // earningBal: defaultFloatVal,
  });
  const [pledgedBal, setPledgedBal] = useState(defaultFloatVal);
  const [earningBal, setEarningBal] = useState(defaultFloatVal);
  const [miningRate, setMiningRate] = useState(defaultFloatVal);
  const { mainBal, pocketBal } = data;
  const balance = (parseFloat(mainBal) + parseFloat(pocketBal)).toFixed(
    computeDecimals
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setReloadData(true);
    setOpen(false);
  };

  const handleTransferZera = () => { };

  useEffect(async () => {
    setLoading(true);
    const startTime = new Date().getTime();
    const minLoadTime = 1000;

    function hideLoader() {
      const endTime = new Date().getTime();
      const waitTime = minLoadTime - (endTime - startTime);

      setTimeout(() => {
        setLoading(false);
      }, waitTime);
    }

    const [vault, ingame, dappx, earning] = await Promise.all([
      axios
        .get(APP_URL + "/users/zera/main/balance", {
          headers: {
            "session-id": sessionID,
          },
        })
        .catch((error) => error),
      axios
        .get(APP_URL + "/users/zera/pocket/balance", {
          headers: {
            "session-id": sessionID,
          },
        })
        .catch((error) => error),
      axios
        .get(APP_URL + "/users/getMyDappxBalance", {
          headers: {
            "session-id": sessionID,
          },
        })
        .catch((error) => error),
      axios
        .post(
          APP_URL + "/users/getEarnedZeraBalance",
          {
            token_type: "DAPPX",
          },
          {
            headers: {
              "session-id": sessionID,
            },
          }
        )
        .catch((error) => error),
    ]);

    if (
      (vault && vault.status === 200 && vault.data) ||
      (ingame && ingame.status === 200 && ingame.data)
    ) {
      setData({
        ...data,
        mainBal:
          vault && vault.data
            ? vault.data.zera_balance.toFixed(computeDecimals)
            : defaultFloatVal,
        pocketBal:
          ingame && ingame.data
            ? ingame.data.zera_balance.toFixed(computeDecimals)
            : defaultFloatVal,
        // pocketBal: '12323878404.398394',
      });
    }

    if (dappx && dappx.status === 200 && dappx.data) {
      const convertedPledged = Web3.utils.fromWei(
        dappx.data.pledged_dappx.toString(),
        "ether"
      );
      setPledgedBal(convertedPledged);
    }

    if (earning && earning.status === 200 && earning.data) {
      const convertedEarned =
        parseFloat(earning.data.earned_zera) > 0
          ? Web3.utils.fromWei(earning.data.earned_zera.toString(), "ether")
          : defaultFloatVal;
      const convertedMining =
        parseFloat(earning.data.earned_zera) > 0
          ? Web3.utils.fromWei(earning.data.mining_rate.toString(), "ether")
          : defaultFloatVal;
      setEarningBal(convertedEarned);
      setMiningRate(convertedMining);
    }

    hideLoader();
  }, []);

  useEffect(async () => {
    if (reloadData) {
      const [vault, ingame] = await Promise.all([
        axios.get(APP_URL + "/users/zera/main/balance", {
          headers: {
            "session-id": sessionID,
          },
        }),
        axios.get(APP_URL + "/users/zera/pocket/balance", {
          headers: {
            "session-id": sessionID,
          },
        }),
      ]);

      setData({
        ...data,
        mainBal: vault.data.zera_balance.toFixed(computeDecimals),
        pocketBal: ingame.data.zera_balance.toFixed(computeDecimals),
        // pocketBal: '12323878404.398394',
      });

      setReloadData(false);
    }
  }, [reloadData]);

  const formatIntegers = (val) => {
    return new Intl.NumberFormat().format(val);
  };

  const modifiedMiningRate =
    (parseFloat(miningRate) * parseFloat(pledgedBal)) / 600;
  useInterval(() => {
    if (parseFloat(miningRate) > 0 && parseFloat(pledgedBal) > 0) {
      const newVault = (parseFloat(mainBal) + modifiedMiningRate).toFixed(
        computeDecimals
      );
      const newEarning = (parseFloat(earningBal) + modifiedMiningRate).toFixed(
        computeDecimals
      );
      setEarningBal(newEarning);
      setData({ ...data, mainBal: newVault });
    }
  }, 100);

  const builtInTheme = useTheme();
  const isXS = useMediaQuery(builtInTheme.breakpoints.down("xs"));
  const isSM = useMediaQuery(builtInTheme.breakpoints.down("sm"));

  let acTotal = Number(mainBal) + Number(pocketBal);
  acTotal = String(acTotal);
  console.log(acTotal, typeof mainBal, typeof pocketBal);

  return (
    <div className=" user-sidebar-wrapper user-sidebar-wrapper-v2">

      <div className="content-wrapper-v2">
        <div className="content">
          <div
            className="section s1"
            style={{ display: "flex", borderBottom: "1px solid #dedede" }}
          >
            <div
              className="img-container"
              style={{ display: "flex", margin: "15px", alignItems: "center" }}
            >
              <img
                src={DP_Placeholder}
                alt="place your cool face here"
                style={{ height: 60, width: 60, margin: 10 }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  style={{ fontSize: "1.5rem", color: "#21212", fontWeight: 700 }}
                >
                  {user_username ? user_username : "Unnamed"}
                </p>
                <a href="/user-profile" style={{ textDecoration: "underline" }}>
                  My Digital Assets
                </a>
              </div>
            </div>
          </div>

          <MuiThemeProvider theme={theme}>

            <div
              className={classes.root}
            // className="zera-wrapper"
            >
              <Typography
                variant="h5"
                gutterBottom
                className={classes.zeraBalanceLabel}
              >
                Current Zera Balance
              </Typography>
              <Grid container spacing={isXS ? 1 : 2}>
                {/* <Grid item xs={6} style={{ display: 'flex', alignItems: 'flex-end' }}> */}
                <Grid item xs={12} md={6}>
                  {acTotal && (
                    <div className="amount">
                      <Ticker textClassName="whole-num-large">
                        {formatIntegers(acTotal.split(".")[0])}
                      </Ticker>
                      <Ticker textClassName="decimal-num-large" duration=".1s">
                        {acTotal?.split(".")[1] == 0
                          ? ""
                          : `${acTotal?.split(".")[1]
                            ? "." + acTotal?.split(".")[1]
                            : ""
                          }`}
                      </Ticker>
                    </div>
                  )}
                </Grid>
                {/* <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}> */}
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h6"
                    className={classes.detailsTitle}
                    gutterBottom
                  >
                    Accumulated Earning
                  </Typography>
                  <div className="amount">
                    <Ticker textClassName="whole-num">
                      {formatIntegers(earningBal.split(".")[0])}
                    </Ticker>
                    <Ticker textClassName="decimal-num" duration=".1s">
                      <span className={classes.dot}></span>
                      {earningBal.split(".")[1].substr(0, displayDecimals) == 0
                        ? ""
                        : `.${earningBal
                          .split(".")[1]
                          .substr(0, displayDecimals)}`}
                    </Ticker>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="token-details">
                    <div className="details-text-container">
                      <Typography
                        variant="h6"
                        className={classes.detailsTitle}
                        gutterBottom
                      >
                        Zera Vault
                      </Typography>
                      <div className="amount">
                        <Ticker textClassName="whole-num">
                          {formatIntegers(mainBal.split(".")[0])}
                        </Ticker>
                        <Ticker textClassName="decimal-num" duration=".1s">
                          <span className={classes.dot}></span>
                          {mainBal.split(".")[1].substr(0, displayDecimals) == 0
                            ? ""
                            : `.${mainBal
                              .split(".")[1]
                              .substr(0, displayDecimals)}`}
                        </Ticker>
                      </div>
                    </div>
                    <VaultIcon className="details-icon" />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="token-details">
                    <div className="details-text-container">
                      <Typography
                        variant="h6"
                        className={classes.detailsTitle}
                        gutterBottom
                      >
                        In-Game Zera
                      </Typography>
                      <div className="amount">
                        <Ticker textClassName="whole-num">
                          {formatIntegers(pocketBal.split(".")[0])}
                        </Ticker>
                        <Ticker textClassName="decimal-num">
                          <span className={classes.dot}></span>
                          {pocketBal.split(".")[1].substr(0, displayDecimals) == 0
                            ? ""
                            : `.${pocketBal
                              .split(".")[1]
                              .substr(0, displayDecimals)}`}
                        </Ticker>
                      </div>
                    </div>
                    <InGameIcon className="details-icon" />
                  </div>
                </Grid>
                {!isXS && <Grid item sm={9}></Grid>}
                {!isSM && (
                  <>
                    <Grid item xs={6} sm={3}>
                      <div className="buttons-block">
                        <Button
                          variant="outlined"
                          color="primary"
                          fullWidth
                          onClick={handleOpen}
                          className={classes.btn}
                        >
                          Transfer
                        </Button>
                      </div>
                    </Grid>

                  </>
                )}
              </Grid>
            </div>


            <Transfer
              token="zera"
              open={open}
              handleClose={handleClose}
              data={data}
              decimals={displayDecimals}
            />

          </MuiThemeProvider>
        </div>
      </div>

      <SideBarData />
    </div>

  );
};

export default Zera;
