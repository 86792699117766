import { useLayoutEffect, useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Home from '../domain/home/Home';
import Download from '../domain/download/Download';
import Games from '../domain/games/Games';
import TopCharts from '../domain/top-charts/TopCharts';
import MarketPlace from '../domain/marketplace/market-place';
import Terms from '../domain/terms/Terms';
import Disclaimer from '../domain/disclaimer/Disclaimer';
import UserProfile from '../domain/user-profile/UserProfile';
import Maintenance from '../domain/maintenance';
import Login from '../domain/login/Login';
import MarketPlaceRoutes from '../routers/routes';
import TopNav from '../components/top-nav/TopNav';
import Footer from '../components/footer/Footer';
import AuthProvider from '../context/AuthContext';
import SearchProvider from '../context/SearchContext';
import MoralisContextProvider from '../context/MoralisContext';
import SnackbarProvider from '../context/SnackBarContext';
import GIF_loader from '../assets/images/page-loader.gif';
import { MoralisProvider } from "react-moralis";
import './global.scss';
import axios from 'axios';
import { DAPPX_BASE_URL } from '../constant/config';
import { UnAuthenticated } from "../domain/user-profile/UnAuthenticated";
import { useTranslation } from 'react-i18next';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const APP_URL = DAPPX_BASE_URL;
const appId = process.env.REACT_APP_MORALIS_APP_ID;
const serverUrl = process.env.REACT_APP_MORALIS_SERVER_URL;
const PAYPAL_CLIENT_KEY =
  process.env.REACT_APP_PAYPAL_CLIENT_KEY ||
  "AX3rzT9PsR0YKni2m8Z6TCN_FFMSpKk1HB6cs2MfGhT-318u_P4hmIGaSD2u4AEN169k3laGL1CVE8jf";
const App = () => {
  const location = useLocation();
  let history = useHistory();
  const pathname = location.pathname;
  const {t} = useTranslation()
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let tokenQuery = params.get('token');
    let userIdQuery = params.get('userId');
    if (window.location.href.includes('verify-user')){
      verifyEmailToken(userIdQuery, tokenQuery)
      history.push("/");
    }
  },[])

  const verifyEmailToken = async (userId, token) => {
    await axios.get(`${DAPPX_BASE_URL}/users/validateVerificationToken?userId=${userId}&token=${token}`)
    .then((res) => {
      console.log('Token Validate', res.data)
    })
    .catch((err) => {
      console.log('Token Error', err.data)
    })
  }

  const transparent_top_nav = pathname === '/games';

  // Global API Loading
  const [apiLoading, setApiLoader] = useState(false);

  // User profile
  let session_id = localStorage.getItem('session-id');

  // Referral Code
  let ref_code = location.search.split('=')[1]

  useEffect(() => {
    if (ref_code) {
      localStorage.setItem('ref', ref_code);
    }
  }, [ref_code])

  const [maintenance, setMaintenance] = useState(false);
  const [whitelistedIp, setWhiteListedIP] = useState(false)

  const fetchMaintenance = async () => {
    await axios.get(
      APP_URL + "/settings/getMaintenanceMode",
    )
    // .then((res) => {
    //   if (res && res.status == 200) {
    //     setWhiteListedIP(res.data.ip_whitelist)
    //     setMaintenance(res.data.maintenance_mode);
    //   }
    // })


    .then((res) => {
      if (res.status == 200) {
        // setWhiteListedIP(res.data.ip_whitelist)
        setMaintenance(false);
      }
    })
    .catch((error) => {
      console.log(error)
      setMaintenance(true)
    })
  }

  useEffect(() => {
    fetchMaintenance()
  }, []);

  return (
    <PayPalScriptProvider
      options={{
        "client-id": PAYPAL_CLIENT_KEY,
        "disable-funding": ['card','credit'],
      }}
    >
      <MoralisProvider appId={appId} serverUrl={serverUrl}>
        {/* Temporarily fix for maintenance page until backend is fix */}
        {/* {maintenance && <Redirect to="/maintenance" component={Maintenance} />} */}
        <AuthProvider>
          <SearchProvider>
            <MoralisContextProvider>
              <SnackbarProvider>
                <div className="wrapper">
                  <AnimatePresence exitBeforeEnter>
                    {apiLoading && (
                      <PlaceholderContent key={apiLoading} idKey={apiLoading} />
                    )}
                  </AnimatePresence>
                  <TopNav />
                  {/* {!maintenance && <TopNav />} */}
                  <div
                    className={`main-area ${
                      transparent_top_nav && "transparent-top-nav"
                    }`}
                  >
                    <Switch>
                      <Route
                        exact
                        path="/"
                        render={(props) => (
                          <Home
                            {...props}
                            setApiLoader={setApiLoader}
                            apiLoading={apiLoading}
                          />
                        )}
                      />
                      <Route path="/top-defi-charts" component={TopCharts} />
                      <Route path="/top-game-charts" component={TopCharts} />
                      <Route path="/top-nft-charts" component={TopCharts} />
                      {/* <Route path="/nft-marketplace" component={MarketPlace} /> */}
                      <Route path="/exchange" component={TopCharts} />
                      <Route path="/games" component={Games} />
                      <Route path="/download" component={Download} />
                      <Route path="/login" component={Login} />
                      <Route path="/terms" component={Terms} />
                      <Route path="/disclaimer" component={Disclaimer} />
                      <Route path="/maintenance" component={Maintenance}>
                        {!maintenance && <Redirect to="/" />}
                      </Route>
                      {session_id && (
                        <Route path="/user-profile" component={UserProfile} />
                      )}
                      {!session_id && (
                        <Route
                          path="/user-profile"
                          component={UnAuthenticated}
                        />
                      )}

                      <MarketPlaceRoutes />
                      <Route path="*">
                        <Redirect from="/" to="/" />
                      </Route>
                    </Switch>
                  </div>
                  <Footer />
                  {/* {!maintenance && <Footer />} */}
                </div>
              </SnackbarProvider>
            </MoralisContextProvider>
          </SearchProvider>
        </AuthProvider>
      </MoralisProvider>
    </PayPalScriptProvider>
  );
}

const animateGenerate = {
  initial: {
    opacity: 1
  },
  visible: {
    opacity: 1
  },
  exit: {
    opacity: 0,
  }
}

const PlaceholderContent = ({ idKey }) => {
  const [loaded, setLoaded] = useState(false)

  return (
    <motion.div
      className="lazy-load"
      key={idKey}
      variants={animateGenerate}
      initial="initial"
      animate="visible"
      exit="exit"
    >
      <img
        className="img-fluid"
        style={{
          opacity: loaded ? 1 : 0,
          transition: 'opacity .4s ease-in-out'
        }}
        src={GIF_loader}
        alt="dAppstore"
        onLoad={() => setLoaded(true)} />
    </motion.div>
  )
}

export default App;
